import classnames from 'classnames';
import React, { FC } from 'react';

import { UserDto } from '@shared/api';
import { Avatar, Icon, AvatarColors } from '@shared/ui';

import { TaskEventFileManager, TaskEventProps } from '../../';
import { CommentUIProps, useComment } from '../../model';
import styles from '../styles.module.scss';

import { Content } from './content';
import { CommentHeader } from './header';

export interface CommentProps extends Omit<TaskEventProps, 'type'>, CommentUIProps {
	viewer?: Nullable<UserDto>;
	fileManagerAttach?: TaskEventFileManager;
}

export const Comment: FC<CommentProps> = React.memo(
	({ viewer, author, onRemove, onSave, className, fileManagerAttach, ...props }) => {
		const { updateDate, createDate, editable } = props;
		const wasUpdated = Boolean(updateDate) && updateDate !== createDate;
		const { view, startEdit, onSaveDraft, onCancel, editingActions, value } = useComment({
			onSave,
			comment: props,
		});

		const iAmCommentAuthor = !!viewer && author && viewer?.id === author?.id;

		return (
			<div className={classnames(styles.comment, className)}>
				{author && (
					<Avatar
						size={'md'}
						name={author.fullName || ''}
						color={author.avatarColor as AvatarColors}
					/>
				)}

				<div className={styles.comment__main}>
					<CommentHeader
						{...value}
						author={author}
						startEdit={onSave ? startEdit : undefined}
						onRemove={onRemove}
						allowEdit={editable && iAmCommentAuthor}
					/>
					<Content
						{...value}
						fileManagerAttach={fileManagerAttach}
						editingActions={editingActions}
						onSave={onSaveDraft}
						onCancel={onCancel}
						view={view}
					/>
					{wasUpdated && (
						<div className={styles.wasUpdated}>
							изменено <Icon id={'edit'} />
						</div>
					)}
				</div>
			</div>
		);
	}
);
