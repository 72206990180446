/**
 * rpharm-todo
 * rpharm-todo documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BoardPreviewDto } from './boardPreviewDto';
import { TaskApprovalDto } from './taskApprovalDto';
import { TaskPreviewDto } from './taskPreviewDto';
import { TaskReassignRequestDto } from './taskReassignRequestDto';
import { UserDto } from './userDto';

export interface NotificationDto {
	id?: string;
	type?: NotificationDtoTypeEnum;
	title?: string;
	text?: string;
	read?: boolean;
	hidden?: boolean;
	taskApproval?: TaskApprovalDto;
	taskReassignRequest?: TaskReassignRequestDto;
	task?: TaskPreviewDto;
	board?: BoardPreviewDto;
	sender?: UserDto;
	consumer?: UserDto;
	createDate?: string;
	tagsAddedCount?: number;
	tagsRemovedCount?: number;
}
export enum NotificationDtoTypeEnum {
	REMINDER = 'REMINDER',
	TASK_CREATED = 'TASK_CREATED',
	TASK_APPROVAL = 'TASK_APPROVAL',
	BOARD_INVITE = 'BOARD_INVITE',
	BOARD_INVITE_PRIVATE = 'BOARD_INVITE_PRIVATE',
	BOARD_KICK = 'BOARD_KICK',
	BOARD_KICK_PRIVATE = 'BOARD_KICK_PRIVATE',
	PERMISSION_UPDATE = 'PERMISSION_UPDATE',
	REVIEW_FINISHED = 'REVIEW_FINISHED',
	TASK_INVITE = 'TASK_INVITE',
	TASK_REASSIGN_OPEN = 'TASK_REASSIGN_OPEN',
	TASK_REASSIGN_ACCEPTED = 'TASK_REASSIGN_ACCEPTED',
	TASK_REASSIGN_DECLINED = 'TASK_REASSIGN_DECLINED',
	BOARD_RECOVERY = 'BOARD_RECOVERY',
	BOARD_ARCHIVED = 'BOARD_ARCHIVED',
	TASK_ARCHIVED = 'TASK_ARCHIVED',
	TASK_RECOVERY = 'TASK_RECOVERY',
	BOARD_ROLE_UPDATED_VIEWER = 'BOARD_ROLE_UPDATED_VIEWER',
	BOARD_ROLE_UPDATED_EDITOR = 'BOARD_ROLE_UPDATED_EDITOR',
	BOARD_UPDATED = 'BOARD_UPDATED',
	BOARD_TASK_CREATED = 'BOARD_TASK_CREATED',
	TASK_UPDATED_ASSIGNEE = 'TASK_UPDATED_ASSIGNEE',
	TASK_UPDATED_ASSIGNEE_ADDED_PRIVATE = 'TASK_UPDATED_ASSIGNEE_ADDED_PRIVATE',
	TASK_UPDATED_DEADLINES_ADDED = 'TASK_UPDATED_DEADLINES_ADDED',
	TASK_UPDATED_DEADLINES_CHANGED = 'TASK_UPDATED_DEADLINES_CHANGED',
	TASK_UPDATED_DEADLINES_DELETED = 'TASK_UPDATED_DEADLINES_DELETED',
	TASK_UPDATED_TAGS_ADDED = 'TASK_UPDATED_TAGS_ADDED',
	TASK_UPDATED_TAGS_REMOVED = 'TASK_UPDATED_TAGS_REMOVED',
	TASK_UPDATED_TAGS_CHANGED = 'TASK_UPDATED_TAGS_CHANGED',
	TASK_UPDATED_FILES_ADDED = 'TASK_UPDATED_FILES_ADDED',
	TASK_UPDATED_FILES_DELETED = 'TASK_UPDATED_FILES_DELETED',
	TASK_UPDATED_TITLE = 'TASK_UPDATED_TITLE',
	TASK_UPDATED_DESCRIPTION = 'TASK_UPDATED_DESCRIPTION',
	TASK_UPDATED_CO_ASSIGNEES_ADDED = 'TASK_UPDATED_CO_ASSIGNEES_ADDED',
	TASK_UPDATED_CO_ASSIGNEES_DELETED = 'TASK_UPDATED_CO_ASSIGNEES_DELETED',
	TASK_STATUS_UPDATE_NEW = 'TASK_STATUS_UPDATE_NEW',
	TASK_STATUS_UPDATE_STOPPED = 'TASK_STATUS_UPDATE_STOPPED',
	TASK_STATUS_UPDATE_IN_PROGRESS = 'TASK_STATUS_UPDATE_IN_PROGRESS',
	TASK_STATUS_UPDATE_REVIEW = 'TASK_STATUS_UPDATE_REVIEW',
	TASK_STATUS_UPDATE_DONE = 'TASK_STATUS_UPDATE_DONE',
	SUBTASK_ARCHIVED = 'SUBTASK_ARCHIVED',
	LINKED_SUBTASK_DETACHED = 'LINKED_SUBTASK_DETACHED',
	SUBTASK_CONVERTED = 'SUBTASK_CONVERTED',
}
