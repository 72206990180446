import { combine, createEffect, createEvent, createStore, restore, sample } from 'effector';

import { tagsRequests, UpdateTagRequest, TagDto } from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { tagListModel, tagManagerModel } from '@entities/tag-manager/model';

type DoneCallback = (tag?: TagDto) => void;
type DoneFxArgs = { cb: Nullable<DoneCallback>; tag: Nullable<TagDto> };

const updateTag = createEvent<{ data: UpdateTagRequest; done?: DoneCallback }>();
const updateTagFx = createEffect(tagsRequests.updateTag);
const updateTagDoneFx = createEffect(({ cb, tag }: DoneFxArgs) => cb && cb(tag || undefined));

const $response = createStore<Nullable<TagDto>>(null).reset(updateTag);
const $error = restore<Error>(updateTagFx.failData, null).reset(updateTag);
const $doneCb = createStore<Nullable<DoneCallback>>(null).reset(updateTag);

const $updateTagStatus = combine({
	pending: updateTagFx.pending,
	response: $response,
	error: $error,
});

sample({
	clock: updateTag,
	fn: ({ data }) => data,
	target: updateTagFx,
});

sample({
	clock: updateTag,
	fn: ({ done }) => done || null,
	target: $doneCb,
});

sample({
	clock: updateTagFx.doneData,
	target: $response,
});

sample({
	clock: updateTagFx.done,
	source: combine({
		cb: $doneCb,
		tag: $response,
	}),
	target: updateTagDoneFx,
});

sample({
	clock: updateTagFx.doneData,
	fn: (result) => ({
		type: 'success' as NoticeType,
		text: `Метка "<b>${result.name}</b>" изменена.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: updateTagFx.done,
	target: tagListModel.revalidateTagList,
});

sample({
	clock: updateTagFx.done,
	fn: () => ({
		type: null,
	}),
	target: tagManagerModel.pushAction,
});

sample({
	clock: updateTagFx.fail,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	updateTag,
	updateTagFx,
	$updateTagStatus,
};
