import { offset, shift, useFloating } from '@floating-ui/react-dom';
import { useStore } from 'effector-react';
import React, { FC, FocusEventHandler, MouseEventHandler, useLayoutEffect } from 'react';

import { useDelayUnmount } from '@shared/lib/hooks';
import { Portal, Tooltip } from '@shared/ui';
import { appTooltipsModel } from '@shared/ui/molecules/tooltips';

/*
 * Usage
 * <span
		style={{ cursor: 'pointer' }}
		tabIndex={0}
		onFocus={(e) =>
			appTooltipsModel.showTooltip({
				text: 'Текст подсказки',
				element: e.target as HTMLElement,
				delayIn: 200,
			})
		}
		onMouseEnter={(e) =>
			appTooltipsModel.showTooltip({
				text: 'Текст подсказки',
				element: e.target as HTMLElement,
			})
		}
		onBlur={() => appTooltipsModel.hideTooltip()}
		onMouseLeave={() => appTooltipsModel.hideTooltip()}>
		Tooltip trigger
	</span>
 */

export interface TooltipsEventHandlers {
	onMouseEnter?: MouseEventHandler<HTMLElement>;
	onMouseLeave?: MouseEventHandler<HTMLElement>;
	onFocus?: FocusEventHandler<HTMLElement>;
	onBlur?: FocusEventHandler<HTMLElement>;
}

export const Tooltips: FC = () => {
	const tooltip = useStore(appTooltipsModel.$tooltip);
	const {
		text,
		element = null,
		align,
		placement = 'bottom',
		margin,
		delayIn = 1000,
	} = tooltip || {};

	const { x, y, reference, floating, strategy } = useFloating({
		placement: align ? `${placement}-${align}` : placement,
		strategy: 'fixed',
		middleware: [shift({ padding: 8 }), offset(8)],
	});

	useLayoutEffect(() => {
		reference(element);
	}, [element, reference]);

	const shouldRender = useDelayUnmount(!!tooltip, 400);

	return (
		<Portal>
			{shouldRender && (
				<Tooltip
					ref={floating}
					style={{
						position: strategy,
						zIndex: 100,
						top: y ?? 0,
						left: x ?? 0,
						margin,
						animationDelay: !!tooltip ? `${delayIn}ms` : undefined,
						maxWidth: 'calc(var(--vw) - 32px)',
						whiteSpace: 'normal',
						wordBreak: 'break-word',
					}}
					isShown={!!tooltip}
					showWithAnimation={true}
					aria-hidden={true}
					text={text}
				/>
			)}
		</Portal>
	);
};
