import classNames from 'classnames';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { useState, useRef, useEffect } from 'react';

import { UserDto } from '@shared/api';
import { useOutsideClick } from '@shared/lib/hooks';
import { setFocusToId, checkEscape } from '@shared/lib/utils';
import { Button, Icon, Dropdown, ConfirmModalBody, ConfirmModalHead } from '@shared/ui';

import { subtasksModel, assigneeModel, coAssigneesModel } from '@entities/task';

import { SearchAssignee } from '@features/search-assignee';

import { useShouldUpdate } from '@widgets/task-modal/lib';

import styles from './styles.module.scss';

interface AddAssigneeDd {
	type: 'none' | 'search' | 'notification';
	user?: UserDto;
}

interface AddAssigneeToSubtaskProps {
	editable?: boolean;
	onUpdate?: () => void;
}

export const AddAssigneeToSubtask = ({ editable, onUpdate }: AddAssigneeToSubtaskProps) => {
	const addAssigneeDdRef = useRef(null);
	const addAssigneeDdBtnRef = useRef(null);

	const resolveRef = useRef<(value: void | PromiseLike<void>) => void>();

	const createSubtaskAssignee = useStore(subtasksModel.$createdSubtaskAssignee);
	const assignee = useStore(assigneeModel.$assignee);
	const coAssignees = useStore(coAssigneesModel.$coAssignees);

	const [addAssigneeDd, setAddAssigneeDd] = useState<AddAssigneeDd>({ type: 'none' });

	const setShouldUpdate = useShouldUpdate({ editable, onUpdate }, coAssignees);

	useOutsideClick(addAssigneeDdRef, () => setAddAssigneeDd({ type: 'none' }));

	const addAssignee = (user: UserDto) => {
		const assigneeIsParticipant =
			assignee?.id === user.id || coAssignees.some((coAssignee) => coAssignee.id === user.id);

		if (assigneeIsParticipant) {
			subtasksModel.addAssigneeToBeingCreatedSubtask(user);
			setAddAssigneeDd({ type: 'none' });
			setFocusToId('add-assignee-to-subtask');
		} else {
			setAddAssigneeDd({ type: 'notification', user });
		}
	};

	const addAssigneeToSubtask = () => {
		if (addAssigneeDd.user) {
			subtasksModel.addAssigneeToBeingCreatedSubtask(addAssigneeDd.user);
			coAssigneesModel.addCoAssignee(addAssigneeDd.user);
			setShouldUpdate(true);
			setAddAssigneeDd({ type: 'none' });
			setFocusToId('add-assignee-to-subtask');
		}
	};

	useEffect(() => {
		if (addAssigneeDd.type === 'notification') {
			resolveRef.current && resolveRef.current();
		}
	}, [addAssigneeDd.type]);

	return (
		<Dropdown
			placement="bottomEnd"
			isOpen={addAssigneeDd.type !== 'none'}
			openOnButtonClick={false}
			closeOnClickOutside={false}
			targetButton={
				<Button
					ref={addAssigneeDdBtnRef}
					id="add-assignee-to-subtask"
					onlyIcon={<Icon id="people-add" />}
					design="transparent"
					onClick={() =>
						setAddAssigneeDd((prevState) => {
							if (prevState.type === 'none') {
								return { type: 'search' };
							} else {
								return { type: 'none' };
							}
						})
					}
				/>
			}
			dropdownData={(props, ref) => null}
			dropdownElement={
				<div ref={addAssigneeDdRef}>
					<div className={classNames(addAssigneeDd.type !== 'search' && styles.disabled)}>
						<SearchAssignee
							assignees={createSubtaskAssignee}
							addAssignee={addAssignee}
							removeAssignee={() => subtasksModel.removeAssigneeFromBeingCreatedSubtask()}
							outsideClickExcludeRef={addAssigneeDdBtnRef}
						/>
					</div>

					<div className={classNames(addAssigneeDd.type !== 'notification' && styles.disabled)}>
						<FocusTrap
							focusTrapOptions={{
								escapeDeactivates: true,
								allowOutsideClick: true,
								checkCanFocusTrap: () =>
									new Promise<void>((resolve) => (resolveRef.current = resolve)),
							}}>
							<div
								className={styles.notification}
								onKeyDown={(e) => {
									if (checkEscape(e)) {
										e.stopPropagation();
										setFocusToId('add-assignee-to-subtask');
										setAddAssigneeDd({ type: 'search' });
									}
								}}>
								<ConfirmModalHead
									size="sm"
									title="Исполнитель подзадачи"
									onClickBack={() => setAddAssigneeDd({ type: 'search' })}
								/>
								<ConfirmModalBody
									content={
										<>
											<b>{addAssigneeDd.user?.fullName}</b> будет также добавлен(а) в участники
											родительской задачи.
										</>
									}
									onAccept={addAssigneeToSubtask}
									onDiscard={() => setAddAssigneeDd({ type: 'search' })}
									acceptLabel="Назначить"
									acceptColor="primary"
									textAlign="left"
								/>
							</div>
						</FocusTrap>
					</div>
				</div>
			}
		/>
	);
};
