import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { TaskDto } from '@shared/api';
import { noticesModel } from '@shared/ui';

import { FileManager } from '@entities/file-manager';
import { taskFilesModel } from '@entities/task/model';

import { useShouldUpdate } from '@widgets/task-modal/lib';

interface TaskTagsProps extends Pick<TaskDto, 'tags'> {
	loading?: boolean;
	editable?: boolean;
	onUpdate?: () => void;
}

export const TaskFiles: FC<TaskTagsProps> = ({ editable, onUpdate }) => {
	const files = useStore(taskFilesModel.$files);

	const setShouldUpdate = useShouldUpdate({ editable, onUpdate }, files);

	return (
		<FileManager
			files={files}
			onFileUploaded={(file) => {
				taskFilesModel.addFile(file);
				setShouldUpdate(true);
			}}
			removeFile={(file) => {
				taskFilesModel.removeFile(file);
				setShouldUpdate(true);

				const attachment = Array.isArray(file) ? file[0] : file;
				noticesModel.add({
					type: 'success',
					text: `Файл "<b>${attachment.name || attachment.url}</b>" удален.`,
				});
			}}
			editable={editable}
		/>
	);
};
