import { combine, createEffect, createEvent, createStore, sample } from 'effector';
import { condition } from 'patronum';

import { ToggleBoardEmailNotificationRequest, boardsRequests, BoardPreviewDto } from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { boardModel, boardsModel } from '@entities/boards/model';

interface ToggleBoardEmailNotificationArgs extends ToggleBoardEmailNotificationRequest {
	revalidateList?: boolean;
}

const toggleBoardEmailNotification = createEvent<ToggleBoardEmailNotificationArgs>();
const toggleBoardEmailNotificationFx = createEffect(boardsRequests.toggleBoardEmailNotification);

const $config = createStore<ToggleBoardEmailNotificationRequest | null>(null).reset(
	toggleBoardEmailNotification
);
const $revalidateList = createStore(false).reset(toggleBoardEmailNotification);
const $board = createStore<BoardPreviewDto | null>(null).reset(toggleBoardEmailNotification);
const $error = createStore<Error | null>(null).reset(toggleBoardEmailNotification);

const $toggleBoardEmailNotificationStatus = combine({
	pending: combine($config, toggleBoardEmailNotificationFx.pending, (config, pending) =>
		pending && config?.boardId ? config.boardId : false
	),
	data: $board,
	error: $error,
});

sample({
	clock: toggleBoardEmailNotification,
	fn: ({ revalidateList }) => !!revalidateList,
	target: $revalidateList,
});

sample({
	clock: toggleBoardEmailNotification,
	fn: ({ boardId }) => ({ boardId }),
	target: [$config, toggleBoardEmailNotificationFx],
});

const revalidateBoardsList = createEvent<any>();
const mutateCurrentBoard = createEvent<any>();

sample({
	clock: [toggleBoardEmailNotificationFx.done, toggleBoardEmailNotificationFx.fail],
	target: mutateCurrentBoard,
});

condition({
	source: toggleBoardEmailNotificationFx.done,
	if: $revalidateList,
	then: revalidateBoardsList,
});

sample({
	clock: revalidateBoardsList,
	source: boardsModel.$boardsListConfig,
	target: boardsModel.getBoardsList,
});

sample({
	clock: mutateCurrentBoard,
	target: boardModel.revalidateBoard,
});

sample({
	clock: toggleBoardEmailNotificationFx.doneData,
	target: $board,
});

sample({
	clock: toggleBoardEmailNotificationFx.failData,
	target: $error,
});

sample({
	clock: toggleBoardEmailNotificationFx.failData,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	toggleBoardEmailNotification,
	toggleBoardEmailNotificationFx,
	$toggleBoardEmailNotificationStatus,
};
