import { useStore } from 'effector-react';
import React, { useState, useMemo, useRef } from 'react';

import { UserDto } from '@shared/api';
import { REMOVE_ASSIGNEE_DIALOG_TEXT, REMOVE_ASSIGNEE_DIALOG_TITLE } from '@shared/lib/constants';
import { setFocusToId } from '@shared/lib/utils';
import { Button, Icon, Avatar, Dropdown, AvatarColors } from '@shared/ui';

import { AssigneeDropdown } from '@entities/assignee-dropdown';
import { assigneeModel, coAssigneesModel, subtasksModel } from '@entities/task';

import { SearchAssignee } from '@features/search-assignee';

import { useShouldUpdate } from '@widgets/task-modal/lib';

interface AssigneeProps {
	editable?: boolean;
	onUpdate?: (message?: string) => void;
}

export const Assignee = ({ editable, onUpdate }: AssigneeProps) => {
	const assignee = useStore(assigneeModel.$assignee);
	const coAssignees = useStore(coAssigneesModel.$coAssignees);

	const searchAssigneeDdBtnRef = useRef(null);
	const setShouldUpdate = useShouldUpdate(
		{
			editable,
			onUpdate: () => {
				onUpdate && onUpdate(!!assignee ? 'Исполнитель добавлен' : 'Исполнитель удален');
			},
		},
		assignee
	);

	const [assigneeDd, setAssigneeDd] = useState(false);
	const [searchAssigneeDd, setSearchAssigneeDd] = useState(false);

	const excludeUserIds = useMemo(
		() => coAssignees.map((coAssignee) => `${coAssignee.id}`),
		[coAssignees]
	);

	const hasAssignee = assignee && !!Object.keys(assignee).length;

	const subtasks = useStore(subtasksModel.$subtasks);
	const checkUserHasSubtasks = (user: UserDto) =>
		!!subtasks?.some(({ assignee }) => assignee && assignee.id === user.id);

	return (
		<>
			{hasAssignee && (
				<Dropdown
					placement="bottomEnd"
					indent={10}
					isOpen={assigneeDd}
					openOnButtonClick={false}
					closeOnClickOutside={false}
					targetButton={
						<Avatar
							id="assignee-button"
							onClick={editable ? () => setAssigneeDd(true) : undefined}
							type="withNameBG"
							name={
								assignee.fullName && assignee.fullName?.length > 30
									? assignee.fullName?.slice(0, 30) + '...'
									: assignee.fullName || ''
							}
							color={assignee.avatarColor as AvatarColors}
						/>
					}
					dropdownData={(props, ref) => null}
					dropdownElement={
						<AssigneeDropdown
							user={assignee}
							removeAssignee={
								editable
									? () => {
											assigneeModel.removeAssignee();
											setAssigneeDd(false);
											setFocusToId('assignee-button');
											setShouldUpdate(true);
									  }
									: undefined
							}
							removeDialogTitle={REMOVE_ASSIGNEE_DIALOG_TITLE}
							removeDialogText={REMOVE_ASSIGNEE_DIALOG_TEXT}
							closeDropdown={() => {
								setAssigneeDd(false);
								setFocusToId('assignee-button');
							}}
							editable={editable}
							userHasSubtasks={checkUserHasSubtasks(assignee)}
						/>
					}
				/>
			)}

			{!hasAssignee && (
				<Dropdown
					placement="bottomEnd"
					isOpen={editable && searchAssigneeDd}
					openOnButtonClick={false}
					targetButton={
						<Button
							ref={searchAssigneeDdBtnRef}
							id="assignee-button"
							onClick={() => setSearchAssigneeDd((prev) => !prev)}
							size="sm"
							design="filled"
							color="secondary"
							disabled={!editable}
							iconRight={<Icon id="plus" />}>
							Добавить
						</Button>
					}
					dropdownData={(props, ref) => (
						<SearchAssignee
							assignees={assignee}
							addAssignee={(user) => {
								assigneeModel.addAssignee(user);
								setSearchAssigneeDd(false);
								setFocusToId('assignee-button');
								setShouldUpdate(true);
							}}
							closeDropdown={() => {
								setSearchAssigneeDd(false);
								setFocusToId('assignee-button');
							}}
							excludeUserIds={excludeUserIds}
							outsideClickExcludeRef={searchAssigneeDdBtnRef}
						/>
					)}
				/>
			)}
		</>
	);
};
