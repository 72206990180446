import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { boardScrollModel } from '@shared/model';

import styles from './styles.module.scss';

interface TaskBoardColumnHeadProps {
	title?: string;
	counter?: number;
}

export const TaskBoardColumnHead: FC<TaskBoardColumnHeadProps> = React.memo(
	({ title, counter }) => {
		const boardScrollY = useStore(boardScrollModel.$boardScrollY);

		return (
			<div
				className={classNames(styles.titleWrap, {
					[styles.isAbove]: boardScrollY > 0,
				})}>
				<p className={styles.title}>{title}</p>
				{counter && counter > 0 && <p className={styles.titleAmount}>{counter}</p>}
			</div>
		);
	}
);
