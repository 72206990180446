import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { TaskReassignRequestDtoStatusEnum } from '@shared/api';
import { NoticeLabel, Button, Icon } from '@shared/ui';

import { notificationReassignModel } from '@entities/notifications';

import { NotificationToolsProps } from '../notification-tools';
import { ReassignDropdown } from '../reassign-dropdown';
import styles from '../styles.module.scss';

export interface NotificationReassignProps
	extends Pick<
		NotificationToolsProps,
		'notification' | 'goToTask' | 'acceptReassign' | 'declineReassign' | 'retryReassign' | 'viewer'
	> {}

export const NotificationReassign: FC<NotificationReassignProps> = ({
	notification,
	goToTask,
	acceptReassign,
	declineReassign,
	retryReassign,
}) => {
	const pending = useStore(notificationReassignModel.$pending);
	const retried = useStore(notificationReassignModel.$retried);
	const wasRetried =
		retried &&
		notification?.taskReassignRequest?.id &&
		retried[notification?.taskReassignRequest?.id];

	const userIsReassignConsumer =
		notification?.taskReassignRequest?.currentUserConsumerOfTaskReassign;

	switch (notification?.taskReassignRequest?.status) {
		case TaskReassignRequestDtoStatusEnum.OPEN:
			return (
				<>
					{userIsReassignConsumer && (
						<>
							<ReassignDropdown
								onAccept={() => declineReassign && declineReassign()}
								dropdownType="decline"
								dropdownTitle={notification.title}
								taskTitle={notification.task?.title}
								isPending={pending.decline}
								portalId="notifications-modal-portal"
							/>
							<ReassignDropdown
								onAccept={() => acceptReassign && acceptReassign()}
								dropdownType="accept"
								dropdownTitle={notification.title}
								taskTitle={notification.task?.title}
								isPending={pending.accept}
								portalId="notifications-modal-portal"
							/>
						</>
					)}
				</>
			);
		case TaskReassignRequestDtoStatusEnum.ACCEPTED:
			return (
				<>
					{userIsReassignConsumer && (
						<>
							<Button
								size="md"
								design="filled"
								color="secondary"
								className={classnames(styles.button, styles.buttonWithArrow)}
								iconRight={<Icon id="arrow-right" />}
								onClick={() => goToTask && goToTask()}>
								Перейти
							</Button>
							<NoticeLabel text="Принято" className={styles.acceptNotice} accepted={true} />
						</>
					)}
				</>
			);
		case TaskReassignRequestDtoStatusEnum.DECLINED:
			return (
				<>
					{userIsReassignConsumer && (
						<>
							<Button
								size="md"
								design="filled"
								color="secondary"
								className={classnames(styles.button, styles.buttonWithArrow)}
								iconRight={<Icon id="arrow-right" />}
								onClick={() => goToTask && goToTask()}>
								Перейти
							</Button>
							<NoticeLabel text="Отклонено" className={styles.acceptNotice} accepted={false} />
						</>
					)}
					{!userIsReassignConsumer && (
						<>
							<Button
								size="md"
								design="filled"
								color="secondary"
								className={classnames(styles.button, styles.buttonWithArrow)}
								iconRight={<Icon id="arrow-right" />}
								onClick={() => goToTask && goToTask()}>
								Перейти
							</Button>
							{wasRetried ? (
								<NoticeLabel text="Отправлено" className={styles.acceptNotice} accepted={true} />
							) : (
								<Button
									size="md"
									design="filled"
									color="primary"
									className={classnames(styles.button, styles.buttonRetry)}
									onClick={() => retryReassign && retryReassign()}
									isLoading={pending.retry}>
									Отправить снова
								</Button>
							)}
						</>
					)}
				</>
			);
		default:
			return null;
	}
};
