import {
	createEvent,
	createEffect,
	createStore,
	sample,
	restore,
	combine,
	forward,
} from 'effector';
import { createGate } from 'effector-react';

import { GetTasksList, TaskPreviewForStatusDto, tasksRequests, TagDtoTypeEnum } from '@shared/api';
import { TaskPreviewDto, TagDto } from '@shared/api/model/models';
import { isEmptyObject } from '@shared/lib/utils';
import { noticesModel, NoticeType } from '@shared/ui';

import {
	getClassicColumns,
	getTableStructure,
	getTransposedTableStructure,
	TaskColumnsRecord,
	TasksTableArray,
	TasksTransposedTableArray,
} from '@entities/task/lib';
import { taskStatusesModel } from '@entities/task/model/task-statuses';

import { taskListTagsModel } from '../task-list-tags';
interface UpdateTargetTaskProps {
	task: TaskPreviewDto;
	index?: number;
}

const getTaskList = createEvent<GetTasksList>();
const getTaskListDirect = createEvent();

const getTaskListFx = createEffect(tasksRequests.getTasksList);

const updateTargetTask = createEvent<UpdateTargetTaskProps>();
const updateTargetTaskData = createEvent<TaskPreviewDto>();

const $taskList = createStore<TaskPreviewDto[]>([]);
const $localTaskList = createStore<TaskPreviewDto[]>([]);
const $taskListCounters = createStore<Record<string, number>>({});
const $taskListCountersByTags = createStore<Record<string, number>>({});
const $taskColumns = createStore<Nullable<TaskColumnsRecord>>(null).reset(getTaskList);
const $taskTable = createStore<Nullable<TasksTableArray>>(null).reset(getTaskList);
const $taskTransposedTable =
	createStore<Nullable<TasksTransposedTableArray>>(null).reset(getTaskList);
const $sortedActiveTags = createStore<TagDto[]>([]);

const $config = createStore<GetTasksList>({}).reset(getTaskList);
const $error = restore<Error>(getTaskListFx.failData, null).reset(getTaskList);

const $status = combine({
	loading: combine(
		{
			list: getTaskListFx.pending,
			statuses: taskStatusesModel.getTaskStatusesLoading,
		},
		({ list, statuses }) => list || statuses
	),
	error: $error,
	list: $taskList,
	localList: $localTaskList,
	columns: $taskColumns,
	table: $taskTable,
	transposedTable: $taskTransposedTable,
});

/* *
 * Task list
 */
const TasksListGate = createGate<GetTasksList>();

forward({
	from: TasksListGate.state,
	to: getTaskList,
});

sample({
	clock: getTaskList,
	source: TasksListGate.status,
	filter: (source, config) => source && config?.view !== null,
	fn: (_, config) => config,
	target: [$config, taskStatusesModel.getTaskStatuses],
});

sample({
	clock: [getTaskListDirect, taskStatusesModel.getTaskStatusesFx.done],
	source: $config,
	filter: (config) => !isEmptyObject(config),
	target: getTaskListFx,
});

sample({
	clock: getTaskListFx.doneData,
	source: $config,
	fn: (config, data) => {
		const isBoard = !config.url && !!config.boardId;
		return isBoard
			? (data as TaskPreviewDto[])
			: (data as TaskPreviewForStatusDto[])?.reduce((list, { tasks }) => {
					list = tasks ? [...list, ...tasks] : list;
					return list;
			  }, [] as TaskPreviewDto[]);
	},
	target: $taskList,
});

/**
 * Get active tags
 */
sample({
	clock: taskListTagsModel.$taskListTags,
	fn: (clock) => {
		const customTags = clock.filter((tag) => tag.type === TagDtoTypeEnum.CUSTOM);
		const systemTags = clock.filter((tag) => tag.type === TagDtoTypeEnum.SYSTEM_WITHOUT_TAG);

		return [...customTags, ...systemTags].filter((tag) => tag.active);
	},
	target: $sortedActiveTags,
});

/**
 * Filtering the list of tasks by active tags
 */
sample({
	source: { taskList: $taskList, tags: taskListTagsModel.$taskListTags, config: $config },
	clock: [$taskList, taskListTagsModel.$taskListTags],
	fn: ({ taskList, tags, config }) => {
		/**
		 * Если нет типа доски или по какой то причине мы не получили список тегов,
		 * то возвращаем список задач без фильтрации
		 */
		if (!config.view || !tags.length) {
			return taskList;
		}

		/**
		 * Получаем активные теги
		 */
		const activeTags = tags.filter((tag) => tag.active);

		const result = taskList.filter((task) => {
			/**
			 * Type guard (Если возникнет кейс, когда нет поля тегов, то возвращаем таску)
			 */
			if (!task.tags || !task.tags.length) {
				return true;
			}

			/**
			 * Фильтруем список задач и оставляем только задачи с активными тегами
			 */
			return task.tags.some((tag) => activeTags.some((activeTag) => activeTag.id === tag.id));
		});

		return result;
	},
	target: $localTaskList,
});

/**
 * Set $taskListCounters
 */
sample({
	clock: $localTaskList,
	source: {
		config: $config,
		statuses: taskStatusesModel.$statuses,
	},
	filter: ({ config, statuses }) => config?.view === 'CUSTOM' && statuses.length > 0,
	fn: (_, list) => {
		const counters = {} as Record<string, number>;
		list.forEach(({ status }) => {
			if (status?.type) {
				counters[status.type] = counters[status.type] !== undefined ? counters[status.type] + 1 : 1;
			}
		});
		return counters;
	},
	target: $taskListCounters,
});

/**
 * Set $taskListCountersByTags
 */
sample({
	clock: $localTaskList,
	source: {
		config: $config,
		tags: taskListTagsModel.$taskListTags,
	},
	filter: ({ config }) => config?.view === 'CUSTOM' && config?.viewRowType === 'STATUS',
	fn: (_, list) => {
		const counters: Record<string, number> = {};

		list.forEach(({ tags }) => {
			if (tags) {
				tags.forEach(({ id }) => {
					counters[id] = counters[id] !== undefined ? counters[id] + 1 : 1;
				});
			}
		});

		return counters;
	},
	target: $taskListCountersByTags,
});

/**
 * Set $taskColumns
 */
sample({
	clock: $localTaskList,
	source: {
		config: $config,
		statuses: taskStatusesModel.$statuses,
	},
	filter: ({ config, statuses }) =>
		(config?.view !== 'CUSTOM' && statuses.length > 0) || Boolean(config.archived),
	fn: ({ statuses }, data) => {
		return (data as TaskPreviewDto[])?.reduce((columns, card) => {
			if (card?.status?.type) columns[card.status.type]?.items?.push(card);
			return columns;
		}, getClassicColumns(statuses));
	},
	target: $taskColumns,
});

/**
 * Set $taskTable
 */
sample({
	clock: [taskListTagsModel.getTaskListTagsFx.doneData, $localTaskList],
	source: {
		list: $localTaskList,
		config: $config,
		sortedActiveTags: $sortedActiveTags,
		statuses: taskStatusesModel.$statuses,
	},
	filter: ({ config, statuses }) =>
		config?.view === 'CUSTOM' && config?.viewRowType === 'TAG' && statuses.length > 0,
	fn: ({ sortedActiveTags, statuses, list }) => {
		const table = getTableStructure(sortedActiveTags, statuses);

		list.forEach((task) => {
			if (!task?.status?.id) return;

			if (task.tags && task.tags.length > 0) {
				task.tags.forEach(({ id }) => {
					if (task?.status?.id && table[id]) {
						table[id].columns[task.status.id].items.push({ ...task });
					}
				});
			}
		});

		return table
			? Object.values(table).map(({ order, tag, columns }) => {
					return {
						order,
						tag,
						columns: columns ? Object.values(columns) : [],
					};
			  })
			: [];
	},
	target: $taskTable,
});

/**
 * Set $taskTransposedTable
 */
sample({
	clock: [taskListTagsModel.getTaskListTagsFx.doneData, $localTaskList],
	source: {
		list: $localTaskList,
		config: $config,
		sortedActiveTags: $sortedActiveTags,
		statuses: taskStatusesModel.$statuses,
	},
	filter: ({ config, statuses }) =>
		config?.view === 'CUSTOM' && config?.viewRowType === 'STATUS' && statuses.length > 0,
	fn: ({ sortedActiveTags, statuses, list }) => {
		const table = getTransposedTableStructure(sortedActiveTags, statuses);

		list.forEach((task) => {
			if (!task.status?.type) return;

			const row = table[task.status.type];

			if (task.tags && task.tags.length > 0) {
				task.tags.forEach(({ id }) => {
					if (row.columns[id]) row.columns[id].items.push({ ...task });
				});
			}
		});

		return table
			? Object.values(table).map(({ order, status, columns }) => {
					return {
						order,
						status,
						columns: columns ? Object.values(columns) : [],
					};
			  })
			: [];
	},
	target: $taskTransposedTable,
});

/* *
 * Error handler
 */
sample({
	clock: getTaskListFx.fail,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

/* *
 * Update task with status in list
 */
sample({
	source: $taskList,
	clock: updateTargetTask,
	fn: (source, { task: newTask, index }) => {
		const targetTaskIndex = source.findIndex((task) => task.id === newTask.id);
		const initialTask = source.find((task) => task.id === newTask.id);

		/**
		 * Если индекс не задан или индекс равен 0,
		 * то ставим задачу первой в списке,
		 * так она всегда будет первой в своей колонке
		 */
		if (!index) {
			const newArray = [...source.slice(0, targetTaskIndex), ...source.slice(targetTaskIndex + 1)];

			return [newTask, ...newArray];
		}

		/**
		 * Получаем список задач со статусом колонки назначения
		 */
		const taskListWithNewStatus = source.filter(
			(task) => task?.status?.type === newTask.status?.type
		);

		/**
		 * Перемещается ли задача в пределах одной колонки
		 */
		const movesWithinOneColumn = initialTask?.status?.type === newTask.status?.type;

		/**
		 * Если перемещаем задачу на последнее место в колонке,
		 * то ставим задачу в конец списка,
		 * так она всегда будет последней в своей колонке
		 *
		 * Первое условие проверяет, что задача перемащается на последнее место,
		 * когда перемещение происходит в рамках одной колонки
		 *
		 * Второе условие проверяет, что задача перемащается на последнее место,
		 * когда задача перемещается в другую колонку
		 */
		if (
			(index === taskListWithNewStatus.length - 1 && movesWithinOneColumn) ||
			(index > taskListWithNewStatus.length - 1 && !movesWithinOneColumn)
		) {
			const newArray = [...source.slice(0, targetTaskIndex), ...source.slice(targetTaskIndex + 1)];

			return [...newArray, newTask];
		}

		/**
		 * Если задача перемещается в середину колонки
		 */
		let newIndex: number = 0;

		/**
		 * Если перемещение происходит внутри одной колонки,
		 * то получаем новый индеск задачи относительно всего списка задач
		 */
		if (movesWithinOneColumn) {
			newIndex = source.findIndex((task) => task?.id === taskListWithNewStatus[index].id);
		}

		const newArray = [...source.slice(0, targetTaskIndex), ...source.slice(targetTaskIndex + 1)];

		/**
		 * Если перемещаем задачу из одной колонки в середину другой,
		 * то получаем новый индекс задачи относительно нового массива без исходной задачи
		 */
		if (!movesWithinOneColumn) {
			newIndex = newArray.findIndex((task) => task?.id === taskListWithNewStatus[index].id);
		}

		return [...newArray.slice(0, newIndex), newTask, ...newArray.slice(newIndex)];
	},
	target: $taskList,
});

/* *
 * Update task data in list
 */
sample({
	source: $taskList,
	clock: updateTargetTaskData,
	fn: (source, clock) => {
		return source.map((task) =>
			task.id === clock.id ? { ...task, ...clock, status: task.status } : task
		);
	},
	target: $taskList,
});

/* *
 * Revalidate task list
 */
const revalidateTaskList = createEvent();

sample({
	clock: revalidateTaskList,
	source: $config,
	filter: (config) => !isEmptyObject(config),
	target: getTaskListFx,
});

export const model = {
	TasksListGate,
	getTaskList,
	getTaskListFx,
	revalidateTaskList,
	updateTargetTask,
	updateTargetTaskData,
	$config,
	$status,
	$taskList,
	$taskListCounters,
	$taskListCountersByTags,
	$taskColumns,
	$taskTable,
};
