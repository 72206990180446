import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './app';

/* *
 * Отключен React.StrictMode, так как компонент react-beautiful-dnd имеет баг в React 18:
 * https://github.com/atlassian/react-beautiful-dnd/issues/2407
 */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);
