import { createEvent, createStore, sample, forward } from 'effector';
import { createGate } from 'effector-react';

import { FileDto } from '@shared/api';

/*
 * Files in comment form
 */
const setFiles = createEvent<FileDto[]>();
const resetFiles = createEvent();

const addFile = createEvent<FileDto | FileDto[]>();
const removeFile = createEvent<FileDto>();

const $files = createStore<Nullable<FileDto[]>>([]).reset(resetFiles);

sample({
	clock: setFiles,
	target: $files,
});

sample({
	clock: addFile,
	source: $files,
	fn: (store, file) => {
		const add = Array.isArray(file) ? [...file] : [file];
		return store ? [...store, ...add] : add;
	},
	target: $files,
});

sample({
	clock: removeFile,
	source: $files,
	fn: (store, file) => {
		return store?.filter(({ id }) => id !== file.id) || null;
	},
	target: $files,
});

const commentFilesGate = createGate();

forward({
	from: commentFilesGate.close,
	to: resetFiles,
});

export const model = {
	$files,
	addFile,
	removeFile,
	setFiles,
	resetFiles,
	commentFilesGate,
};
