import { MutableRefObject, useCallback, useEffect } from 'react';

export const useEscape = (
	condition: boolean,
	callback: (e?: KeyboardEvent) => void,
	ref?: MutableRefObject<HTMLElement>
) => {
	const listener = useCallback(
		(e: KeyboardEvent) => {
			const keyCode = 'which' in e ? e.which : e.keyCode;

			if (e.key === 'Escape' || keyCode === 27) {
				callback(e);
			}
		},
		[callback]
	);

	useEffect(() => {
		const element = ref?.current || document.documentElement;

		element.removeEventListener('keydown', listener);

		if (condition) {
			element.addEventListener('keydown', listener);
		} else {
			element.removeEventListener('keydown', listener);
		}

		return () => element.removeEventListener('keydown', listener);
	}, [condition, ref, listener]);
};
