import classnames from 'classnames';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { FC, HTMLAttributes, RefObject, useEffect, useRef, useState } from 'react';

import { BoardRoleDtoNameEnum, TaskDto, UserDto } from '@shared/api';
import { toolsMenuStyles as styles } from '@shared/lib/styles';
import { Button, FormControl, Icon, Skeleton } from '@shared/ui';

import { taskModel, toggleTaskSubscribeToChangesModel } from '@entities/task';

import { useUserRoles } from '@features/user-roles';

export interface TaskToolsProps extends HTMLAttributes<HTMLElement> {
	transferTask?: (task?: TaskDto) => void;
	removeTask?: () => void;
	parentBtnRef?: RefObject<HTMLButtonElement>;
	closeDropdown?: () => void;
	viewer?: Nullable<UserDto>;
}

export const TaskTools: FC<TaskToolsProps> = React.memo(
	({ removeTask, transferTask, parentBtnRef, closeDropdown }) => {
		const { data: taskData } = useStore(taskModel.$taskStatus);
		const {
			userOnBoard: { role: roleOnBoard },
			userOnTask: { isAssignee, isAuthor: isTaskAuthor },
			isTaskOrBoardAuthor: userIsAuthor,
		} = useUserRoles();

		const userIsTaskOrBoardAuthorOrEditor =
			userIsAuthor || roleOnBoard === BoardRoleDtoNameEnum.EDITOR;

		const userIsAssigneeAndNotAuthorOrEditor =
			isAssignee && !isTaskAuthor && roleOnBoard !== BoardRoleDtoNameEnum.EDITOR;

		const timer = useRef(0);

		useEffect(() => {
			window.clearTimeout(timer.current);
			const parentBtn = parentBtnRef?.current;
			return () => {
				timer.current = window.setTimeout(() => {
					parentBtn?.focus();
				}, 100);
			};
		}, [parentBtnRef]);

		const transferTaskHandler = () => {
			closeDropdown && closeDropdown();
			taskData && transferTask && transferTask(taskData);
		};

		const [currentUserSubscribedToChanges, setCurrentUserSubscribedToChanges] = useState(
			taskData?.currentUserSubscribedToChanges || false
		);

		useEffect(() => {
			setCurrentUserSubscribedToChanges(!!taskData?.currentUserSubscribedToChanges);
		}, [taskData?.currentUserSubscribedToChanges]);

		if (!taskData) return <TaskToolsSkeleton />;

		return (
			<FocusTrap
				focusTrapOptions={{
					escapeDeactivates: true,
					allowOutsideClick: true,
					checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
				}}>
				<div className={styles.wrap}>
					<div className={classnames(styles.group)}>
						{userIsTaskOrBoardAuthorOrEditor && (
							<Button
								className={styles.actionButton}
								onClick={removeTask}
								size="sm"
								design="transparent"
								iconLeft={<Icon id="trash" style={{ fill: 'currentColor', stroke: 'none' }} />}>
								Переместить в архив
							</Button>
						)}
						{userIsAssigneeAndNotAuthorOrEditor && (
							<Button
								className={styles.actionButton}
								onClick={transferTaskHandler}
								size="sm"
								design="transparent"
								iconLeft={<Icon id="arrow-right" />}>
								Передать задачу
							</Button>
						)}
					</div>
					<div className={classnames(styles.group, styles.groupStroked)}>
						<FormControl
							className={styles.settingsChoice}
							type="checkbox"
							name="currentUserSubscribedToChanges"
							checked={currentUserSubscribedToChanges}
							onChange={({ target }) => {
								setCurrentUserSubscribedToChanges(target.checked);
								if (taskData?.id)
									toggleTaskSubscribeToChangesModel.toggleTaskSubscribeToChanges({
										taskId: taskData?.id,
									});
							}}>
							Моментально уведомлять об изменениях на почту
						</FormControl>
					</div>
				</div>
			</FocusTrap>
		);
	}
);

export const TaskToolsSkeleton: FC = () => {
	return (
		<div className={styles.wrap}>
			<div className={classnames(styles.group)}>
				<Skeleton width={162} height={16} margin="2px 4px" />
				<Skeleton width={156} height={16} margin="2px 4px" />
			</div>
			<div className={classnames(styles.group, styles.groupStroked)}>
				<div style={{ display: 'flex' }}>
					<Skeleton width={16} height={16} margin="0 4px" />
					<Skeleton width={176} height={16} margin="0 4px" />
				</div>
			</div>
		</div>
	);
};
