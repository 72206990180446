import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

import { BoardPreviewDto } from '@shared/api';
import { Colors } from '@shared/lib/constants';
import { useAccessibility } from '@shared/lib/hooks';

import { getFormattedUpdateDate } from '../../model';
import styles from '../styles.module.scss';

export interface BoardCardProps extends Omit<HTMLAttributes<HTMLElement>, 'id'>, BoardPreviewDto {
	href?: string;
	cardFooter?: React.ReactNode;
	isMock?: boolean;
	isDisabled?: boolean;
	onClick?: () => void;
}

export const BoardCard: FC<BoardCardProps> = ({
	id,
	name,
	href = '/',
	updatedDate,
	archived = false,
	archivedDate,
	backgroundColor = 'green',
	cardFooter,
	isDisabled,
	onClick,
}) => {
	const thisMouse = useAccessibility();

	const boardColor =
		Object.keys(Colors).find((key) => Colors[key as keyof typeof Colors] === backgroundColor) ||
		'Green';

	return (
		<div
			className={classnames(
				styles.board,
				styles.fullBoard,
				thisMouse ? styles.mouse : styles.keyboard,
				{ [styles.boardArchived]: archived, [styles.isDisabled]: isDisabled }
			)}>
			<Link
				to={href}
				className={styles.boardLink}
				onClick={!isDisabled ? onClick : undefined}
				tabIndex={isDisabled ? -1 : undefined}>
				<div key={`board-${id}`} className={classnames(styles.boardBody, styles[boardColor])}>
					<p className={styles.name}>{name}</p>

					{updatedDate && !archived && (
						<div className={styles.time}>{getFormattedUpdateDate(updatedDate, 'Изменено')}</div>
					)}
					{archivedDate && archived && (
						<div className={styles.time}>
							{getFormattedUpdateDate(archivedDate, 'Архивировано')}
						</div>
					)}
				</div>
			</Link>
			{cardFooter}
		</div>
	);
};
