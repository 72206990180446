import { createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';

import { UserDto, userRequests } from '@shared/api';

const currentUserLoad = createGate();

const fetchCurrentUserFx = createEffect(userRequests.getCurrentUser);

const $currentUser = createStore<Nullable<UserDto>>(null);

sample({
	source: $currentUser,
	clock: currentUserLoad.open,
	filter: (source) => !(source && Boolean(Object.keys(source).length)),
	target: fetchCurrentUserFx,
});

sample({
	clock: fetchCurrentUserFx.doneData,
	target: $currentUser,
});

export const model = {
	currentUserLoad,
	$currentUser,
};
