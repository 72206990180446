import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import { Icon, Typo } from '@shared/ui';

import styles from './styles.module.scss';

interface NoticeLabelProps {
	text?: string;
	className?: string;
	accepted?: boolean;
	fadeOutTimer?: number;
	fadeOutCallback?: () => void;
}

export const NoticeLabel: FC<NoticeLabelProps> = React.memo(
	({ text, className, accepted = true, fadeOutTimer = 0, fadeOutCallback }) => {
		const [shown, setShown] = useState(false);
		const [render, setRender] = useState(false);

		useEffect(() => {
			setRender(true);
			const timerIn = window.setTimeout(() => setShown(true), 200);
			const timerOut =
				fadeOutTimer > 0 ? window.setTimeout(() => setShown(false), fadeOutTimer) : 0;
			return () => {
				window.clearTimeout(timerIn);
				window.clearTimeout(timerOut);
			};
		}, [fadeOutTimer]);

		return (
			<>
				{render && (
					<Typo
						className={classnames(className, styles.label, {
							[styles.shown]: shown,
							[styles.accepted]: accepted,
						})}
						onTransitionEndCapture={() => {
							if (!shown) {
								setRender(false);
								fadeOutCallback && fadeOutCallback();
							}
						}}
						design="text-13-regular">
						{text}
						{accepted && <Icon id="check" />}
					</Typo>
				)}
			</>
		);
	}
);
