import React, { HTMLAttributes } from 'react';

import { UserDto } from '@shared/api/model/userDto';
import { AvatarRoundImage, AvatarColors } from '@shared/ui';

import styles from './styles.module.scss';

export interface AvatarUser extends UserDto {
	isCurrentUser?: boolean;
}

interface AvatarGroupProps extends HTMLAttributes<HTMLElement> {
	names: AvatarUser[];
	size?: 'sm' | 'md';
	onAvatarClick?: (e: AvatarUser, i: number) => void;
}

export const AvatarsGroup = ({ names, size, onAvatarClick }: AvatarGroupProps) => {
	return (
		<div className={styles.groupWrap}>
			{names.map((name, i) => (
				<AvatarRoundImage
					id={'avatarId' + name.id}
					onClick={() => onAvatarClick && onAvatarClick(name, i)}
					icon={name.isCurrentUser}
					key={name.id ?? i}
					name={name?.fullName ?? ''}
					size={size}
					color={name.avatarColor as AvatarColors}
				/>
			))}
		</div>
	);
};
