import classnames from 'classnames';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { FC, HTMLAttributes, RefObject, useEffect, useRef, useState } from 'react';

import { BoardDto, BoardWithViewDto, UserDto, BoardRoleDtoNameEnum } from '@shared/api';
import { Button, FormControl, Icon, Skeleton } from '@shared/ui';

import { boardModel, toggleBoardEmailNotificationModel } from '@entities/boards';

import styles from '../styles.module.scss';

export interface BoardToolsProps extends HTMLAttributes<HTMLElement> {
	viewer?: Nullable<UserDto>;
	parentBtnRef?: RefObject<HTMLButtonElement>;
	editBoard?: (board?: BoardDto | BoardWithViewDto) => void;
	removeBoard?: (board?: BoardDto | BoardWithViewDto, hasUnsavedData?: boolean) => void;
	manageTags?: () => void;
	closeDropdown?: () => void;
	hasUnsavedData?: boolean;
}

export const BoardTools: FC<BoardToolsProps> = React.memo(
	({ parentBtnRef, editBoard, removeBoard, manageTags, closeDropdown, viewer, hasUnsavedData }) => {
		const boardData = useStore(boardModel.$board);

		const userIsAuthor = viewer?.id && viewer.id === boardData?.author?.id;
		const userIsEditor =
			boardData?.currentParticipantBoardRole?.name === BoardRoleDtoNameEnum.EDITOR;

		const timer = useRef(0);

		useEffect(() => {
			window.clearTimeout(timer.current);
			const parentBtn = parentBtnRef?.current;
			return () => {
				timer.current = window.setTimeout(() => {
					parentBtn?.focus();
				}, 100);
			};
		}, [parentBtnRef]);

		const editTagsHandler = () => {
			manageTags && manageTags();
		};

		const editBoardHandler = () => {
			closeDropdown && closeDropdown();
			boardData && editBoard && editBoard(boardData);
		};

		const removeBoardHandler = () => {
			closeDropdown && closeDropdown();
			boardData && removeBoard && removeBoard(boardData, hasUnsavedData);
		};

		const [emailNotificationEnabled, setEmailNotificationEnabled] = useState(
			boardData?.emailNotificationEnabled
		);

		useEffect(() => {
			setEmailNotificationEnabled(!!boardData?.emailNotificationEnabled);
		}, [boardData?.emailNotificationEnabled]);

		if (!boardData) return <BoardToolsSkeleton />;

		return (
			<FocusTrap
				focusTrapOptions={{
					escapeDeactivates: true,
					allowOutsideClick: true,
					checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
				}}>
				<div className={styles.wrap}>
					<div className={classnames(styles.group)}>
						{(userIsAuthor || userIsEditor) && (
							<>
								<Button
									className={styles.actionButton}
									onClick={editBoardHandler}
									size="sm"
									design="transparent"
									iconLeft={<Icon id="edit" />}>
									Редактировать доску
								</Button>
								<Button
									className={styles.actionButton}
									onClick={editTagsHandler}
									size="sm"
									design="transparent"
									iconLeft={<Icon id="tag" style={{ fill: 'currentColor', stroke: 'none' }} />}>
									Управление метками
								</Button>
							</>
						)}
						{userIsAuthor && (
							<Button
								className={styles.actionButton}
								onClick={removeBoardHandler}
								size="sm"
								design="transparent"
								iconLeft={<Icon id="trash" style={{ fill: 'currentColor', stroke: 'none' }} />}>
								Удалить доску
							</Button>
						)}
					</div>
					<div
						className={classnames(styles.group, {
							[styles.groupStroked]: userIsAuthor || userIsEditor,
						})}>
						<FormControl
							className={styles.settingsChoice}
							type="checkbox"
							name="emailNotificationEnabled"
							checked={emailNotificationEnabled}
							onChange={({ target }) => {
								setEmailNotificationEnabled(target.checked);
								if (boardData?.id)
									toggleBoardEmailNotificationModel.toggleBoardEmailNotification({
										boardId: boardData.id,
									});
							}}>
							Моментально уведомлять об изменениях на почту
						</FormControl>
					</div>
				</div>
			</FocusTrap>
		);
	}
);

export const BoardToolsSkeleton: FC = () => {
	return (
		<div className={styles.wrap}>
			<div className={classnames(styles.group)}>
				<Skeleton width={162} height={16} margin="2px 4px" />
				<Skeleton width={156} height={16} margin="2px 4px" />
				<Skeleton width={114} height={16} margin="2px 4px" />
			</div>
			<div className={classnames(styles.group, styles.groupStroked)}>
				<div style={{ display: 'flex' }}>
					<Skeleton width={16} height={16} margin="0 4px" />
					<Skeleton width={176} height={16} margin="0 4px" />
				</div>
			</div>
		</div>
	);
};
