import React, {
	FC,
	PropsWithChildren,
	useEffect,
	useState,
	createContext,
	useContext,
} from 'react';

import {
	Breakpoint,
	isTouchDevice,
	getBpFlag,
	getScrollBarWidth,
	getVpHelpers,
} from '@shared/lib/utils';

interface ViewportInterface {
	vw: number;
	vh: number;
	sb: number;
	out: number;
	bp?: Breakpoint;
	isMob?: boolean;
	isTablet?: boolean;
}

const initialState = {
	vw: 0,
	vh: 0,
	sb: 0,
	out: 0,
	bp: undefined,
	isMob: undefined,
	isTablet: undefined,
};
const isTouch = isTouchDevice();
const scrollbarWidth = getScrollBarWidth();
const [helper, helperContainer] = getVpHelpers();

export const ViewportContext = createContext<ViewportInterface>(initialState);
export const ViewportProvider: FC<PropsWithChildren> = ({ children }) => {
	const [state, setState] = useState<ViewportInterface>(initialState);

	useEffect(() => {
		let mounted = true;
		const isSSR = typeof window === 'undefined';

		if (isSSR || !mounted) return;

		//document.documentElement.classList.toggle(`is-touch`, isTouch);
		//document.documentElement.classList.toggle(`is-mouse`, !isTouch);

		const handleResize = () => {
			let vw: number,
				vh: number,
				bp: Breakpoint,
				out: number,
				isMobBp: boolean,
				isTabletBp: boolean;
			const sb = scrollbarWidth;

			vw = document.documentElement.clientWidth;
			vh = helper ? helper.offsetHeight : document.documentElement.clientHeight;
			bp = getBpFlag(vw);
			isMobBp = ['xs'].includes(bp);
			isTabletBp = ['xs', 'sm'].includes(bp);
			out = helperContainer?.offsetLeft;

			setState({
				vw,
				vh,
				sb,
				out,
				bp,
				isMob: isMobBp,
				isTablet: isTabletBp,
			});

			document.documentElement.style.setProperty('--vw', `${Math.max(320, vw)}px`);
			document.documentElement.style.setProperty('--vh', `${vh}px`);
			document.documentElement.style.setProperty('--ow', `${vw + sb}px`);
			document.documentElement.style.setProperty('--out', `${out}px`);
			document.documentElement.style.setProperty('--sbw', `${sb}px`);
		};

		handleResize();
		window.addEventListener('load', handleResize, { passive: true });
		// window.addEventListener('resize', handleResize, { passive: true });

		if (!isTouch) {
			window.addEventListener('resize', handleResize, { passive: true });
		} else {
			window.addEventListener('orientationchange', handleResize, { passive: true });
		}

		return () => {
			mounted = false;
			window.removeEventListener('load', handleResize);
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('orientationchange', handleResize);
		};
	}, []);

	return <ViewportContext.Provider value={{ ...state }}>{children}</ViewportContext.Provider>;
};

export const useViewport = (): ViewportInterface => {
	const context = useContext(ViewportContext);

	if (context === undefined) {
		throw new Error('useViewport was used outside of its Provider');
	}

	return context;
};
