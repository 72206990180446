import { createEvent, createEffect, sample, combine, createStore } from 'effector';

import {
	NotificationDto,
	TaskApprovalDtoStatusEnum,
	taskApprovalRequests,
	TaskApprovalDto,
} from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { notificationsListModel } from '../notifications-list';

interface TaskApprovalEventArgs {
	taskApproval: TaskApprovalDto;
	notification?: NotificationDto;
	id?: string;
	title?: string;
}

interface NoticeLabelType {
	text: string;
	accepted: boolean;
	id?: string;
}

const reset = createEvent();

const setNotification = createEvent<NotificationDto>();
const setNoticeLabel = createEvent<Nullable<NoticeLabelType>>();

const approveTask = createEvent<TaskApprovalEventArgs>();
const approveTaskFx = createEffect(({ taskApproval }: TaskApprovalEventArgs) =>
	taskApprovalRequests.updateTaskApproval(taskApproval)
);

const disapproveTask = createEvent<TaskApprovalEventArgs>();
const disapproveTaskFx = createEffect(({ taskApproval }: TaskApprovalEventArgs) =>
	taskApprovalRequests.updateTaskApproval(taskApproval)
);

const $pending = combine({
	approve: approveTaskFx.pending,
	decline: disapproveTaskFx.pending,
});

const $label = createStore<Nullable<NoticeLabelType>>(null).reset(reset);
const $notification = createStore<Nullable<NotificationDto>>(null).reset(reset);

sample({
	clock: setNoticeLabel,
	target: $label,
});

sample({
	clock: setNotification,
	target: $notification,
});

sample({
	clock: [approveTask, disapproveTask],
	fn: ({ notification }) => notification || null,
	target: $notification,
});

sample({
	clock: approveTask,
	filter: ({ taskApproval }: TaskApprovalEventArgs) => !!taskApproval?.id,
	fn: (taskApproval) => ({ ...taskApproval, status: TaskApprovalDtoStatusEnum.APPROVED }),
	target: approveTaskFx,
});

sample({
	clock: disapproveTask,
	filter: ({ taskApproval }: TaskApprovalEventArgs) => !!taskApproval?.id,
	fn: (taskApproval) => ({ ...taskApproval, status: TaskApprovalDtoStatusEnum.DECLINED }),
	target: disapproveTaskFx,
});

sample({
	clock: [approveTaskFx.doneData, disapproveTaskFx.doneData],
	source: $notification,
	filter: (notification) => !!notification,
	fn: (notification, taskApproval) => {
		return {
			...notification,
			taskApproval,
		} as NotificationDto;
	},
	target: notificationsListModel.updateInList,
});

sample({
	clock: [approveTaskFx.doneData, disapproveTaskFx.doneData],
	target: notificationsListModel.revalidate,
});

sample({
	clock: approveTaskFx.done,
	fn: (clock) => ({
		type: 'success' as NoticeType,
		text: `Задача "<b>${clock.params.title}</b>" была перенесена в статус "<b>Выполнены</b>"`,
	}),
	target: noticesModel.add,
});

sample({
	clock: disapproveTaskFx.done,
	fn: (clock) => ({
		type: 'success' as NoticeType,
		text: `Задача "<b>${clock.params.title}</b>" была возвращена в статус "<b>В работе</b>"`,
	}),
	target: noticesModel.add,
});

sample({
	clock: [approveTaskFx.fail, disapproveTaskFx.fail],
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

sample({
	clock: approveTaskFx.done,
	fn: (clock) => ({
		text: 'Согласовано',
		accepted: true,
		id: clock.params.id,
	}),
	target: setNoticeLabel,
});

sample({
	clock: disapproveTaskFx.done,
	fn: (clock) => ({
		text: 'Не согласовано',
		accepted: false,
		id: clock.params.id,
	}),
	target: setNoticeLabel,
});

export const model = {
	reset,
	approveTask,
	approveTaskFx,
	disapproveTask,
	disapproveTaskFx,
	setNotification,
	setNoticeLabel,
	$pending,
	$label,
	$notification,
};
