/**
 * rpharm-todo
 * rpharm-todo documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface BoardViewDto {
	id?: string;
	type: BoardViewDtoTypeEnum;
	showTagsOnTasks?: boolean;
	rowType?: BoardViewDtoRowTypeEnum;
	columnType?: BoardViewDtoColumnTypeEnum;
}
export enum BoardViewDtoTypeEnum {
	CLASSIC = 'CLASSIC',
	CUSTOM = 'CUSTOM',
}
export enum BoardViewDtoRowTypeEnum {
	TAG = 'TAG',
	STATUS = 'STATUS',
}
export enum BoardViewDtoColumnTypeEnum {
	TAG = 'TAG',
	STATUS = 'STATUS',
}
