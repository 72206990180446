/**
 * rpharm-todo
 * rpharm-todo documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TaskStatusDto {
	id?: string;
	name?: string;
	type?: TaskStatusDtoTypeEnum;
}
export enum TaskStatusDtoTypeEnum {
	NEW = 'NEW',
	IN_PROGRESS = 'IN_PROGRESS',
	STOPPED = 'STOPPED',
	REVIEW = 'REVIEW',
	DONE = 'DONE',
}
