import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { FormError } from '@shared/ui';

import styles from './styles.module.scss';

interface FormElementInterface {
	error?: string;
	className?: string;
}

type FormElementProps = PropsWithChildren<FormElementInterface>;

export const FormElement: FC<FormElementProps> = ({ error, className, children }) => {
	return (
		<div className={classnames(className, styles.formElement, { 'has-error': error })}>
			{children}
			<FormError error={error} />
		</div>
	);
};
