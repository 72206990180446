import { createEffect, createEvent, createStore, restore, sample, combine } from 'effector';
import { Transition } from 'history';

import { BoardViewDto, boardViewsRequests } from '@shared/api';
import { modalsModel, noticesModel, NoticeType } from '@shared/ui';

import { boardViewModel } from '@entities/boards';

const saveBoardView = createEvent<Nullable<Transition['retry']>>();
const saveBoardViewFx = createEffect(boardViewsRequests.putBoardView);
const retryRouteFx = createEffect(
	(retry: Nullable<Transition['retry']>) => retry && queueMicrotask(retry)
);

const $retryRoute = createStore<Nullable<Transition['retry']>>(null).reset(saveBoardView);
const $response = createStore<Nullable<BoardViewDto>>(null).reset(saveBoardView);
const $error = restore<Error>(saveBoardViewFx.failData, null).reset(saveBoardView);

const $status = combine({
	pending: saveBoardViewFx.pending,
	response: $response,
	error: $error,
});

sample({
	clock: saveBoardView,
	target: $retryRoute,
});

sample({
	clock: saveBoardView,
	source: boardViewModel.$store,
	filter: (boardView) => boardView !== null,
	fn: (boardView) => {
		const view = boardView as BoardViewDto;
		return {
			payload: view.type === 'CLASSIC' ? { id: view.id, type: view.type } : view,
		};
	},
	target: saveBoardViewFx,
});

sample({
	clock: saveBoardViewFx.done,
	source: $retryRoute,
	filter: (source) => !!source,
	target: retryRouteFx,
});

sample({
	clock: saveBoardViewFx.done,
	fn: () => ({
		type: 'success' as NoticeType,
		text: 'Изменение вида доски сохранено',
	}),
	target: [noticesModel.add, modalsModel.closeLastModal, boardViewModel.setStoreToCurrent],
});

sample({
	clock: saveBoardViewFx.fail,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	saveBoardView,
	saveBoardViewFx,
	$status,
};
