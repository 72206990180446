import { Transition } from 'history';
import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

interface UseHistoryBlockerArgs {
	when?: boolean;
	callback?: (retry: Transition['retry']) => void;
}

export const useHistoryBlocker = ({ when = true, callback }: UseHistoryBlockerArgs) => {
	const { navigator } = useContext(NavigationContext);

	useEffect(() => {
		if (!when) return;
		// @ts-ignore
		return navigator.block(({ retry }) => {
			callback && callback(retry);
		});
	}, [navigator, when, callback]);
};
