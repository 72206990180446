import classnames from 'classnames';
import React, { useState } from 'react';

import { UserDto } from '@shared/api';
import { isEmptyObject } from '@shared/lib/utils';
import { Dropdown, Avatar, AvatarColors } from '@shared/ui';

import { AssigneeDropdown } from '@entities/assignee-dropdown';

import styles from './styles.module.scss';

interface SubtaskAssigneesProps {
	assignee: UserDto;
	removeAssignee?: (assignee: UserDto) => void;
	editable?: boolean;
	disabled?: boolean;
}

export const SubtaskAssignees = ({
	assignee,
	removeAssignee,
	editable,
	disabled,
}: SubtaskAssigneesProps) => {
	const [assigneeDd, setAssigneeDd] = useState(false);

	if (isEmptyObject(assignee)) {
		return null;
	}

	return (
		<div className={classnames(styles.avatars, { [styles.disabled]: disabled })}>
			<Dropdown
				isOpen={assigneeDd}
				openOnButtonClick={false}
				placement="bottomEnd"
				indent={10}
				targetButton={
					<Avatar
						id="assignee-button"
						onClick={editable ? () => setAssigneeDd(true) : undefined}
						name={assignee.fullName || ''}
						color={assignee.avatarColor as AvatarColors}
					/>
				}
				dropdownData={(props, ref) => (
					<AssigneeDropdown
						user={assignee}
						removeAssignee={(user) => {
							if (removeAssignee) {
								removeAssignee(user);
								setAssigneeDd(false);
							}
						}}
						closeDropdown={() => setAssigneeDd(false)}
						editable={Boolean(editable && removeAssignee)}
					/>
				)}
			/>
		</div>
	);
};
