import { createEvent, createEffect, createStore, sample } from 'effector';

import { tasksRequests, TaskStatusDto } from '@shared/api';

import { TaskStatusDtoTypeKey } from '@entities/task/lib';

export type TaskStatusUpdate = { newStatusType: TaskStatusDtoTypeKey; newPosition: number };

const $statuses = createStore<TaskStatusDto[]>([]);

const getTaskStatuses = createEvent();
const getTaskStatusesFx = createEffect(async (statuses: TaskStatusDto[]) => {
	if (statuses.length === 0) {
		return tasksRequests.getTasksStatuses();
	} else {
		return statuses;
	}
});

const getTaskStatusesLoading = getTaskStatusesFx.pending;

sample({
	clock: getTaskStatuses,
	source: $statuses,
	target: getTaskStatusesFx,
});

sample({
	clock: getTaskStatusesFx.doneData,
	target: $statuses,
});

export const model = {
	getTaskStatusesFx,
	getTaskStatuses,
	getTaskStatusesLoading,
	$statuses,
};
