import React, { ChangeEventHandler, FC, useCallback, useState } from 'react';

import { FileDto, FileDtoTypeEnum } from '@shared/api';
import { MAX_FILE_SIZE_50MB } from '@shared/lib/constants';
import { useDownloadFile } from '@shared/lib/hooks';
import { filesModel } from '@shared/model';
import { noticesModel, Tag } from '@shared/ui';

import { FileManagerAttach, FileManagerList, FileManagerRemove } from '@entities/file-manager';
import { fileManagerModel } from '@entities/file-manager/model';

import styles from './styles.module.scss';

interface FileManagerProps {
	files: Nullable<FileDto[]>;
	removeFile?: (file: FileDto) => void;
	onFileUploaded?: (file: FileDto | FileDto[]) => void;
	editable?: boolean;
}

export const FileManager: FC<FileManagerProps> = ({
	files,
	removeFile,
	onFileUploaded,
	editable,
}) => {
	const hasFiles = files && files.length > 0;
	const manyFiles = files && files.length > 1;

	const createFile: ChangeEventHandler<HTMLInputElement> = useCallback(
		(e) => {
			e.stopPropagation();
			e.preventDefault();
			const file = e.target.files ? e.target.files[0] : null;
			const formData = new FormData();

			if (file) {
				if (file.size > MAX_FILE_SIZE_50MB) {
					noticesModel.add({
						type: 'error',
						text: 'Размер файла не должен превышать 50МБ.',
					});
					return;
				}

				formData.append('files', file);

				if (!onFileUploaded) return;

				filesModel
					.uploadFilesOrFileLinksFx(Object(formData))
					.then((response) => {
						onFileUploaded(response);
						noticesModel.add({
							type: 'success',
							text: `Файл "<b>${response[0].name || response[0].url}</b>" прикреплен.`,
						});
					})
					.catch((error) => {
						console.warn(error);
						noticesModel.add({
							type: 'error',
							text: `Что-то пошло не так, попробуйте позже.`,
						});
					});
			}
		},
		[onFileUploaded]
	);

	const [downloading, setDownloading] = useState(false);
	const handleDownload = useDownloadFile(setDownloading);

	const handleFileClick = (file: FileDto) => {
		if (!file) return;

		switch (file.type) {
			case FileDtoTypeEnum.LINK:
				window.open(file.url, '_blank');
				break;
			case FileDtoTypeEnum.FILE:
			default:
				handleDownload(file.id, file.name);
				break;
		}
	};

	const handleRemoveClick = (file: FileDto) => {
		fileManagerModel.pushAction({
			type: 'REMOVE_FILE' as const,
			payload: file,
		});
	};

	const handleRemoveInListClick = (file: FileDto) => {
		fileManagerModel.pushAction({
			type: 'REMOVE_FILE_IN_LIST' as const,
			payload: file,
		});
	};

	const getFileName = (file: FileDto) => {
		let filename = file.name ? file.name : file.url?.replace(/^((ftp|http|https):\/\/)/gm, '');
		if (filename && filename.length > 30) filename = filename.slice(0, 30) + '...';
		return filename;
	};

	return (
		<div className={styles.filesWrap}>
			{hasFiles && (
				<>
					<Tag
						onClick={() => handleFileClick(files[0])}
						onCancel={editable ? () => handleRemoveClick(files[0]) : undefined}
						editable={editable}
						hoverable={true}
						isLoading={downloading}>
						<span>{getFileName(files[0])}</span>
					</Tag>
					<div className={styles.toolsSlot}>
						{manyFiles && (
							<FileManagerList
								files={files.slice(1)}
								createFile={createFile}
								onRemove={handleRemoveInListClick}
								onRemoveAccept={removeFile}
								editable={editable}
							/>
						)}
						<FileManagerRemove removeFile={removeFile} />
					</div>
				</>
			)}

			<FileManagerAttach
				full={!hasFiles}
				createFile={createFile}
				onFileUploaded={onFileUploaded}
				editable={editable}
			/>
		</div>
	);
};
