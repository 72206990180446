import classNames from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';

import styles from './styles.module.scss';

interface MoreButtonProps extends HTMLAttributes<HTMLButtonElement> {
	count: number;
	disabled?: boolean;
}

export const MoreButton = forwardRef<HTMLButtonElement, MoreButtonProps>(
	({ count, className, ...props }, ref) => {
		return (
			<button ref={ref} className={classNames(styles.moreBtn, className)} {...props}>
				+{count}
			</button>
		);
	}
);
