import classNames from 'classnames';
import React, { useState, useEffect, useRef, MutableRefObject, ReactNode, FormEvent } from 'react';

import { useEscape, useOutsideClick } from '@shared/lib/hooks';
import { Input, Dropdown, InputInterface } from '@shared/ui';

import styles from './styles.module.scss';

interface SearchUsersDropdownWrapperProps {
	onSearch: (event: FormEvent<HTMLInputElement>) => void;
	debounced: string;
	children: ReactNode;
	className?: string;
	theme?: InputInterface['theme'];
}

export const SearchUsersDropdownWrapper = ({
	onSearch,
	debounced,
	className,
	theme,
	children,
}: SearchUsersDropdownWrapperProps) => {
	const inputRef = useRef(null);
	const listRef = useRef(null);
	const ddRef = useRef<HTMLDivElement>(null);

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (debounced.length > 2) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	}, [debounced]);

	useEscape(
		true,
		(e) => {
			e?.stopPropagation();
			setIsOpen(false);
		},
		ddRef as MutableRefObject<HTMLElement>
	);
	useOutsideClick([inputRef, listRef], () => setIsOpen(false));

	return (
		<Dropdown
			ref={ddRef}
			indent={4}
			placement="bottomStart"
			openOnButtonClick={false}
			isOpen={isOpen}
			delay={0}
			targetButton={
				<Input
					ref={inputRef}
					className={classNames(styles.searchInput, className)}
					onChange={onSearch}
					icon="search"
					theme={theme}
					placeholder="Поиск по имени"
				/>
			}
			dropdownData={(props, ref) => null}
			dropdownElement={<div ref={listRef}>{children}</div>}
		/>
	);
};
