import React, { FC } from 'react';

import { modalsModel, ConfirmModalHead, ConfirmModalBody, BaseModal } from '@shared/ui';

interface RemovalWindowProps {
	onAccept: () => void;
	title?: React.ReactNode;
	text?: React.ReactNode;
	isPending?: boolean;
}

export const RemovalWindow: FC<RemovalWindowProps> = ({ title, text, onAccept, isPending }) => {
	const discardHandler = () => modalsModel.closeLastModal();
	const acceptHandler = () => onAccept();

	return (
		<BaseModal
			size={362}
			design="sm"
			header={<ConfirmModalHead title={title} />}
			showCloseBtn
			closeModalOnEscape
			closeModalOnOutsideClick>
			<ConfirmModalBody
				content={text}
				onAccept={acceptHandler}
				onDiscard={discardHandler}
				acceptLabel="Удалить"
				acceptColor="danger"
				isPending={isPending}
			/>
		</BaseModal>
	);
};
