import classnames from 'classnames';
import React, { TextareaHTMLAttributes, useState } from 'react';

import { Icon } from '@shared/ui';
import { IconId } from '@shared/ui/icons/svg-sprite';

import styles from './styles.module.scss';

export interface InputInterface extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	error?: string;
	isLoading?: boolean;
	icon?: IconId;
	theme?: 'light' | 'dark' | 'light-gray';
	paddingSize?: 'small' | 'normal';
	minHeight?: number;
	maxHeight?: number | string;
	prepareLocalValue?: (value: string) => string;
}

export const InputMulti = React.forwardRef<HTMLTextAreaElement, InputInterface>(
	(
		{
			className,
			icon,
			error,
			isLoading,
			theme,
			paddingSize = 'normal',
			minHeight,
			maxHeight,
			prepareLocalValue = (value) => value,
			...props
		},
		ref
	) => {
		const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
			props?.onFocus && props.onFocus(e);
		};

		const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
			props?.onBlur && props.onBlur(e);
		};

		const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
			props?.onChange && props.onChange(e);
		};

		const [localValue, setLocalValue] = useState('');

		return (
			<label
				className={classnames(
					className,
					styles.input,
					styles.inputMultirow,
					paddingSize && styles[`${paddingSize}-helper`],
					theme && styles[theme],
					icon && styles.withIcon,
					error && styles.error
				)}
				style={{ minHeight, maxHeight }}
				data-replicated={localValue}>
				{icon && <Icon id={icon} />}

				<textarea
					{...props}
					ref={(node) => {
						if (node) {
							if (typeof ref === 'function') {
								ref(node);
							}
							setLocalValue(node.value);
						}
					}}
					className={classnames(className, paddingSize && styles[paddingSize])}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onChange={handleChange}
					onInput={(e) => setLocalValue(prepareLocalValue((e.target as HTMLTextAreaElement).value))}
				/>

				{error && <small>{error}</small>}
			</label>
		);
	}
);
