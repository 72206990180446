import { useStore } from 'effector-react';
import { useState } from 'react';

import { TaskPreviewDto } from '@shared/api';

import { toggleArchiveTaskModel } from '@entities/task';

export const useToggleArchiveTask = () => {
	const loading = useStore(toggleArchiveTaskModel.$status) === 'pending';
	const [activeTask, setActiveTask] = useState('');

	const toggleArchiveTask = (task: TaskPreviewDto, callback?: () => void) => {
		if (task.id && task.title) {
			setActiveTask(task.id);
			toggleArchiveTaskModel.toggleArchiveTask({
				taskId: task.id,
				title: task.title,
				archived: task.archived,
				callback,
			});
		}
	};

	return { activeTask, toggleArchiveTask, loading };
};
