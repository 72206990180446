import { TagDto, TaskPreviewDto, TaskStatusDto, TaskStatusDtoTypeEnum } from '@shared/api';

type TaskStatusDtoTypeKey = keyof typeof TaskStatusDtoTypeEnum;

export interface TasksTableColumnType {
	status?: TaskStatusDto;
	items: TaskPreviewDto[];
}

export interface TasksTableRowType {
	order: number;
	tag: TagDto;
	columns: Record<string, TasksTableColumnType>;
}

export type TasksTableRecord = Record<TagDto['id'], TasksTableRowType>;

export type TasksTableRowEntity = {
	order: number;
	tag: TagDto;
	columns: TasksTableColumnType[];
};

export type TasksTableArray = Array<TasksTableRowEntity>;

export const getTableStructure = (tags: TagDto[] = [], statuses: TaskStatusDto[]) => {
	return tags.reduce((rows, tag, i) => {
		const rowId = tag.id;
		rows[rowId] = {
			tag,
			order: i,
			columns: statuses.reduce((columns, status) => {
				if (status?.id) {
					columns[status?.id] = {
						status,
						items: [],
					};
				}
				return columns;
			}, {} as Record<string, TasksTableColumnType>),
		};
		return rows;
	}, {} as TasksTableRecord);
};

export interface TasksTransposedTableColumnType {
	tag: TagDto;
	items: TaskPreviewDto[];
}

export interface TasksTransposedTableRowType {
	order: number;
	status: TaskStatusDto;
	columns: Record<string, TasksTransposedTableColumnType>;
}

export type TasksTransposedTableRecord = Record<TaskStatusDtoTypeKey, TasksTransposedTableRowType>;

export type TasksTransposedTableRowEntity = {
	order: number;
	status: TaskStatusDto;
	columns: TasksTransposedTableColumnType[];
};

export type TasksTransposedTableArray = Array<TasksTransposedTableRowEntity>;

export const getTransposedTableStructure = (tags: TagDto[] = [], statuses: TaskStatusDto[]) => {
	return statuses.reduce((rows, status, i) => {
		const type = status.type as TaskStatusDtoTypeKey;
		rows[type] = {
			order: i,
			status: status,
			columns: {
				...tags.reduce((columns, tag) => {
					columns[tag.id] = {
						tag,
						items: [],
					};
					return columns;
				}, {} as Record<string, TasksTransposedTableColumnType>),
			},
		};
		return rows;
	}, {} as TasksTransposedTableRecord);
};
