import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC, useMemo, useState } from 'react';

import { TagDto } from '@shared/api';
import { Icon, Tag, tooltipEventHandlersFactory, TooltipsEventHandlers } from '@shared/ui';

import { taskTagsTableColumnsModel } from '@entities/task';

import styles from '../styles.module.scss';

interface TaskTransposedTableHeadProps extends TagDto {
	count: number;
}

export const TaskTransposedTableHead: FC<TaskTransposedTableHeadProps> = React.memo(
	({ count, ...tag }) => {
		const columnsState = useStore(taskTagsTableColumnsModel.$tagsTableColumns);

		const toggleColumnExpand = () => {
			if (!tag?.id) return;
			taskTagsTableColumnsModel.tagsTableColumnToggle(tag.id);
		};

		const [hasEllipsis, setHasEllipsis] = useState(false);

		const tooltipEventHandlers: TooltipsEventHandlers = useMemo(
			() => (hasEllipsis ? tooltipEventHandlersFactory(tag.name) : {}),
			[hasEllipsis, tag.name]
		);

		return (
			<div
				role="columnheader"
				className={classnames(styles.taskTableHeadCell, {
					[styles.collapsed]: !columnsState[tag.id],
				})}>
				<Tag
					{...tag}
					{...tooltipEventHandlers}
					className={classnames(styles.taskTableTag, { [styles.collapsed]: !columnsState[tag.id] })}
					onClick={toggleColumnExpand}>
					<span className={styles.tagText}>
						<span className={styles.tagCounter}>{count || 0}</span>
						<span
							className={styles.tagName}
							ref={(node) => {
								if (node) setHasEllipsis(node.offsetWidth < node.scrollWidth);
							}}>
							{tag.name}
						</span>
					</span>
					<Icon id="chevron-right" className="svg-path" />
				</Tag>
			</div>
		);
	}
);
