import FocusTrap from 'focus-trap-react';
import React from 'react';

import { ConfirmModalHead, ConfirmModalBody } from '@shared/ui';

import { taskDateProps, taskProps } from './lib';
import styles from './styles.module.scss';

interface RemoveDropdownProps {
	onAccept: () => void;
	onDiscard: () => void;
	isPending: boolean;
	taskTitle?: string;
	isSubtask?: boolean;
	target: 'task' | 'date';
}

export const RemoveDropdown = ({
	onAccept,
	onDiscard,
	isPending,
	taskTitle,
	isSubtask = false,
	target,
}: RemoveDropdownProps) => {
	const targetIsTask = target === 'task';

	const { title, content, acceptColor, acceptLabel } = targetIsTask
		? taskProps(taskTitle)
		: taskDateProps(isSubtask);

	return (
		<FocusTrap
			focusTrapOptions={{
				escapeDeactivates: true,
				allowOutsideClick: true,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
			}}>
			<div className={styles.removeDropdown}>
				<ConfirmModalHead
					title={title}
					size="sm"
					onClickBack={targetIsTask ? onDiscard : undefined}
				/>
				<ConfirmModalBody
					content={content}
					onAccept={onAccept}
					onDiscard={onDiscard}
					acceptLabel={acceptLabel}
					acceptColor={acceptColor}
					textAlign="left"
					isPending={isPending}
				/>
			</div>
		</FocusTrap>
	);
};
