import classnames from 'classnames';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	NotificationDto,
	NotificationDtoTypeEnum,
	TaskApprovalDto,
	UserDto,
} from '@shared/api/model/models';
import { getTagsChangedText } from '@shared/lib/utils';
import { modalsModel, Typo } from '@shared/ui';

import { getDateText, getDeadlineText } from '@entities/notifications';

import { NotificationTools } from '../notification-tools';
import styles from '../styles.module.scss';

export interface NotificationCardProps {
	notification: NotificationDto;
	viewer: Nullable<UserDto>;
	entity?: React.ReactElement;
	acceptReassign?: () => void;
	declineReassign?: () => void;
	retryReassign?: () => void;
	approveTask?: () => Promise<Nullable<TaskApprovalDto>>;
	disapproveTask?: (data: Partial<TaskApprovalDto>) => Promise<Nullable<TaskApprovalDto>>;
}

export const NotificationCard: FC<NotificationCardProps> = ({
	notification,
	viewer,
	entity,
	acceptReassign,
	declineReassign,
	retryReassign,
	approveTask,
	disapproveTask,
}) => {
	const {
		type,
		title,
		text,
		read,
		createDate,
		sender,
		consumer,
		task,
		tagsAddedCount,
		tagsRemovedCount,
	} = notification;
	const isReminderType = type === NotificationDtoTypeEnum.REMINDER;
	const isTagsChangedType = type === NotificationDtoTypeEnum.TASK_UPDATED_TAGS_CHANGED;

	const userIsSender = !!viewer && viewer?.id === sender?.id;
	const userIsConsumer = !!viewer && viewer?.id === consumer?.id;

	const textWithDot = text ? (text[text.length - 1] !== '.' ? `${text}.` : text) : '';
	const message = isTagsChangedType
		? getTagsChangedText(textWithDot, tagsAddedCount, tagsRemovedCount)
		: textWithDot;

	const navigate = useNavigate();

	const boardRoute = useMemo(
		() => notification?.board?.id && `/boards/${notification?.board?.id}`,
		[notification?.board]
	);

	const taskRoute = useMemo(() => {
		if (!notification?.task?.id) return undefined;
		const task = `/task/${notification?.task?.id}`;
		const page = notification?.task?.board?.currentParticipantBoardRole
			? `/boards/${notification?.task?.board?.id}`
			: `/my-tasks`;
		return `${page}${task}`;
	}, [notification?.task]);

	const goToBoard = () => {
		boardRoute && navigate(boardRoute);
		modalsModel.closeSubModal();
		modalsModel.closeLastModal();
	};
	const goToTask = () => {
		taskRoute && navigate(taskRoute);
		modalsModel.closeSubModal();
		modalsModel.closeLastModal();
	};

	return (
		<div className={classnames(styles.card, 'notification')}>
			{entity && <div className={styles.entity}>{entity}</div>}
			<div className={styles.content}>
				<div className={styles.head}>
					<Typo className={styles.title} design="header-16-semi-bold">
						{title}
						<span className={classnames(styles.marker, { [styles.active]: !read })} />
					</Typo>
					<Typo className={styles.date} design="text-13-regular">
						{getDateText(createDate)}
					</Typo>
				</div>
				<Typo className={styles.message} design="text-13-regular">
					{!isReminderType && (
						<>
							<b>{sender?.fullName}</b> {message}
						</>
					)}
					{isReminderType && getDeadlineText(task?.endDate, createDate)}
				</Typo>
				<NotificationTools
					notification={notification}
					goToTask={goToTask}
					goToBoard={goToBoard}
					acceptReassign={acceptReassign}
					declineReassign={declineReassign}
					retryReassign={retryReassign}
					approveTask={approveTask}
					disapproveTask={disapproveTask}
					viewer={viewer}
					userIsSender={userIsSender}
					userIsConsumer={userIsConsumer}
				/>
			</div>
		</div>
	);
};
