import { createEvent, createEffect, sample } from 'effector';
import { status } from 'patronum';

import { tasksRequests, RemoveTaskPayload } from '@shared/api';
import { modalsModel, noticesModel } from '@shared/ui';

import { taskListModel } from '../task-list';

interface RemoveTaskEvent extends RemoveTaskPayload {
	title: string;
	closeModalOnRemove?: boolean;
	callback?: () => void;
}

const resetStatus = createEvent();
const removeTask = createEvent<RemoveTaskEvent>();
const removeTaskFx = createEffect(({ taskId }: RemoveTaskEvent) =>
	tasksRequests.removeTask({ taskId })
);

const doneCallbackFx = createEffect(
	(callback: RemoveTaskEvent['callback']) => callback && callback()
);

const $status = status({ effect: removeTaskFx }).reset(resetStatus);

/**
 * Removing a task
 */
sample({
	clock: removeTask,
	target: removeTaskFx,
});

/*
 * Notifications
 */
sample({
	clock: removeTaskFx.done,
	fn: (source) => ({
		type: 'success' as const,
		text: `Задача "<b>${source.params.title}</b>" была удалена.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: removeTaskFx.failData,
	fn: () => ({
		type: 'error' as const,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

/**
 * Close modal, done callback and revalidate task list
 */
sample({
	clock: removeTaskFx.done,
	filter: (clock) => Boolean(clock.params.closeModalOnRemove),
	target: modalsModel.closeLastModal,
});

sample({
	clock: removeTaskFx.done,
	filter: (clock) => Boolean(clock.params.callback),
	fn: (clock) => clock.params.callback,
	target: doneCallbackFx,
});

sample({
	clock: removeTaskFx.doneData,
	target: taskListModel.revalidateTaskList,
});

export const model = {
	resetStatus,
	removeTask,
	$status,
};
