import { combine, createEffect, createEvent, createStore, restore, sample, Store } from 'effector';

import { SubtaskDto, TaskDto, tasksRequests, UpdateTaskPayload } from '@shared/api';
import { isEmptyObject } from '@shared/lib/utils';
import { noticesModel, NoticeType } from '@shared/ui';

import { taskModel } from '@entities/task';
import {
	assigneeModel,
	coAssigneesModel,
	datesModel,
	subtasksModel,
	tagsModel,
	taskFilesModel,
	taskListModel,
	taskEventsListModel,
} from '@entities/task';

import { updateTaskStatusModel } from '@features/task/update-task-status';

interface UpdateTaskEventOptions {
	revalidate?: boolean;
	showMessage?: boolean | string;
	mutateTaskPreview?: boolean;
}

interface UpdateTaskEvent {
	payload: UpdateTaskPayload;
	options?: UpdateTaskEventOptions;
}

const updateTask = createEvent<UpdateTaskEvent>();
const updateTaskAction = createEvent<UpdateTaskPayload>();
const updateTaskFx = createEffect(tasksRequests.updateTask);

const mutateTaskPreview = createEvent<UpdateTaskPayload>();

const $options = createStore<Nullable<UpdateTaskEventOptions>>(null).reset(updateTask);
const $response = createStore<Nullable<TaskDto>>(null).reset(updateTask);
const $error = restore<Error>(updateTaskFx.failData, null).reset(updateTask);

const $status = combine({
	pending: updateTaskFx.pending,
	response: $response,
	error: $error,
});

const $localData = combine(
	{
		assignee: assigneeModel.$assignee.map((assignee) =>
			assignee && !isEmptyObject(assignee) ? assignee : null
		),
		coAssignees: coAssigneesModel.$coAssignees,
		endDate: datesModel.$dates.map(({ endDate }) => endDate ?? null),
		startDate: datesModel.$dates.map(({ startDate }) => startDate ?? null),
		deadlineReminderDate: datesModel.$dates.map(
			({ deadlineReminderDate }) => deadlineReminderDate ?? null
		),
		tags: tagsModel.$tags.map((tags) => tags),
		subtasks: subtasksModel.$subtasks as Store<SubtaskDto[]>,
		files: taskFilesModel.$files.map((files) => files ?? null),
	},
	({ assignee, endDate, startDate, deadlineReminderDate, files, ...values }) => ({
		assignee: assignee ?? undefined,
		endDate: endDate ?? undefined,
		startDate: startDate ?? undefined,
		deadlineReminderDate: deadlineReminderDate ?? undefined,
		files: files ?? undefined,
		...values,
	})
);

sample({
	clock: updateTask,
	fn: ({ options }) => options || null,
	target: $options,
});

sample({
	clock: updateTask,
	fn: ({ payload }) => payload,
	target: updateTaskAction,
});

sample({
	clock: updateTaskAction,
	target: updateTaskFx,
});

sample({
	clock: updateTaskFx.doneData,
	target: $response,
});

sample({
	clock: updateTaskFx.done,
	source: { options: $options, config: taskListModel.$config },
	filter: ({ options }) => !!options?.revalidate,
	fn: ({ config }) => config,
	target: taskListModel.getTaskList,
});

sample({
	clock: updateTaskFx.done,
	target: taskEventsListModel.revalidate,
});

sample({
	source: taskModel.$task,
	clock: updateTaskFx.doneData,
	fn: (source, clock) => ({ ...clock, authorities: source?.authorities }),
	target: taskModel.$task,
});

sample({
	clock: updateTaskFx.doneData,
	source: $options,
	filter: (options, data) => !!options?.mutateTaskPreview && !!data,
	fn: (_, data) => data,
	target: mutateTaskPreview,
});

sample({
	clock: mutateTaskPreview,
	fn: ({ id, title, tags, status, endDate, archived, files, subtasks, requireApprove }) => {
		return {
			id,
			title,
			tags,
			status,
			endDate,
			archived,
			requireApprove,
			attachmentsAmount: files?.length || 0,
			completedSubtasksAmount: subtasks?.filter(({ completed }) => !!completed)?.length || 0,
			subtasksAmount: subtasks?.length || 0,
		};
	},
	target: taskListModel.updateTargetTaskData,
});

/**
 * Обновляем поле taskApproval если задача находится на согласовании
 * и проставили поле taskApproval
 */
sample({
	clock: updateTaskFx.done,
	filter: (clock) => clock.params.status?.type === 'REVIEW' && !clock.params.taskApproval,
	fn: (clock) => ({ ...clock.result }),
	target: updateTaskStatusModel.updateTaskApprovalDirectly,
});

sample({
	clock: updateTaskFx.doneData,
	source: $options,
	filter: (options) => !!options?.showMessage,
	fn: (options, { title }) => ({
		type: 'success' as NoticeType,
		text:
			typeof options?.showMessage === 'string'
				? options.showMessage
				: `Задача "<b>${title}</b>" изменена`,
	}),
	target: noticesModel.add,
});

sample({
	clock: updateTaskFx.fail,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const updateTaskFeature = {
	updateTask,
	mutateTaskPreview,
	$status,
	$localData,
};
