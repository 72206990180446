import classnames from 'classnames';
import React, { FC } from 'react';

import { Button, ButtonColorType, Typo } from '@shared/ui';

import styles from '../../styles.module.scss';

interface ConfirmModalBodyProps {
	content: React.ReactNode;
	onDiscard: () => void;
	onAccept?: () => void;
	acceptLabel?: React.ReactNode;
	acceptColor?: ButtonColorType;
	discardLabel?: React.ReactNode;
	textAlign?: 'left' | 'center' | 'right';
	isPending?: boolean;
	disabled?: boolean;
}

export const ConfirmModalBody: FC<ConfirmModalBodyProps> = ({
	content,
	onAccept,
	onDiscard,
	acceptLabel,
	acceptColor = 'danger',
	discardLabel = 'Отменить',
	textAlign,
	isPending,
	disabled,
}) => {
	return (
		<div className={styles.body}>
			<Typo
				className={classnames(styles.text, textAlign && styles[textAlign])}
				design="text-13-regular">
				{content}
			</Typo>
			<div className={styles.buttons}>
				<Button
					size="md"
					design="filled"
					color="secondary"
					type="button"
					onClick={onDiscard}
					className={styles.actionButton}>
					{discardLabel}
				</Button>
				{onAccept && acceptLabel && (
					<Button
						type="button"
						size="md"
						design="filled"
						color={acceptColor}
						onClick={onAccept}
						isLoading={!!isPending}
						className={styles.actionButton}
						disabled={disabled}>
						{acceptLabel}
					</Button>
				)}
			</div>
		</div>
	);
};
