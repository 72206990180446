import classnames from 'classnames';
import React, { FC } from 'react';

import { Avatar, Datetime, AvatarColors } from '@shared/ui';

import { TaskEventProps } from '../../';

import styles from './styles.module.scss';

export interface SystemEventProps extends Omit<TaskEventProps, 'type'> {}

export const SystemEvent: FC<SystemEventProps> = React.memo(
	({ createDate, author, className, ...props }) => {
		return (
			<div className={classnames(styles.event, className)}>
				{author?.fullName && (
					<Avatar size={'md'} name={author.fullName} color={author.avatarColor as AvatarColors} />
				)}
				<div className={styles.main}>
					<div className={styles.text}>
						<span className={styles.name}>{author?.fullName || ''}</span> {props.text}
					</div>
					{createDate && <Datetime date={createDate} dateFormat={'HH:mm'} />}
				</div>
			</div>
		);
	}
);
