import { useStore } from 'effector-react';
import { useGate } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { useState, useRef, MutableRefObject, useMemo } from 'react';

import { BoardViewDto, TagDtoTypeEnum } from '@shared/api';
import { TAGS_EMPTY } from '@shared/lib/constants';
import { useOutsideClick, useEscape } from '@shared/lib/hooks';
import { Button, Icon, Dropdown, Typo, ToggleBtn, NotFoundMessage, Scroller } from '@shared/ui';

import { toggleTagsOnTaskModel } from '@entities/boards';
import { TagsManagerListItem } from '@entities/tag-manager';
import { taskListTagsModel } from '@entities/task';

import styles from './styles.module.scss';

interface PageTagsProps {
	boardView: Nullable<BoardViewDto>;
	boardId?: string;
}

export const PageTags = ({ boardView, boardId }: PageTagsProps) => {
	useGate(taskListTagsModel.PageTagsGate, { boardId, showOnlyCustom: false });
	useGate(toggleTagsOnTaskModel.PageTagsGate, { boardId: boardView?.id });

	const ddRef = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);

	const taskListTags = useStore(taskListTagsModel.$taskListTags);
	const showTagsOnTasks = useStore(toggleTagsOnTaskModel.$showTagsOnTasks);

	const filteredList = useMemo(() => {
		const systemTags = taskListTags.filter((tag) => tag.type === TagDtoTypeEnum.SYSTEM_WITHOUT_TAG);
		const customTags = taskListTags.filter((tag) => tag.type === TagDtoTypeEnum.CUSTOM);

		return [...systemTags, ...customTags];
	}, [taskListTags]);

	useOutsideClick(ddRef, () => setOpen(false));

	useEscape(
		true,
		(e) => {
			e?.stopPropagation();
			setOpen(false);
		},
		ddRef as MutableRefObject<HTMLElement>
	);

	return (
		<Dropdown
			ref={ddRef}
			placement="bottomEnd"
			isOpen={open}
			openOnButtonClick={false}
			closeOnClickOutside={false}
			targetButton={
				<Button
					color="secondary"
					iconRight={open ? <Icon id="top" /> : <Icon id="bottom" />}
					className={styles.selectTags}
					onClick={() => setOpen((prev) => !prev)}>
					Отображение
				</Button>
			}
			dropdownData={(props, ref) => null}
			dropdownElement={
				<FocusTrap
					focusTrapOptions={{
						allowOutsideClick: true,
						initialFocus: false,
						checkCanFocusTrap: () =>
							new Promise<void>((resolve) => filteredList.length && setTimeout(resolve, 50)),
					}}>
					<div className={styles.tags}>
						<Typo design="text-13-bold">Метки на карточках</Typo>

						{boardView?.type !== 'CLASSIC' && showTagsOnTasks !== null && (
							<>
								<div className={styles.duplicateTags}>
									<Typo className={styles.duplicateTagsText} design="text-13-regular">
										Дублировать метки из полей таблицы на карточках
									</Typo>
									<ToggleBtn
										checked={showTagsOnTasks}
										onChange={() => toggleTagsOnTaskModel.setShowTagsOnTasks(!showTagsOnTasks)}
									/>
								</div>
								<Typo design="text-13-bold">Поля таблицы</Typo>
							</>
						)}

						<div className={styles.tagsList}>
							<Scroller axis="y" className={styles.tagsListScroller}>
								{filteredList.length ? (
									filteredList.map((tag) => (
										<TagsManagerListItem
											className={styles.tagsListItem}
											tag={tag}
											isActive={tag.active}
											onClick={(tag) =>
												taskListTagsModel.changeStateTag({ ...tag, active: !tag.active })
											}
											key={tag.id}
										/>
									))
								) : (
									<NotFoundMessage align="left">{TAGS_EMPTY.list}</NotFoundMessage>
								)}
							</Scroller>
						</div>
					</div>
				</FocusTrap>
			}
		/>
	);
};
