import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { boardScrollModel } from '@shared/model';

import styles from '../styles.module.scss';

interface TaskTableHeaderWithShadowProps {
	content: React.ReactElement;
}

export const TaskTableHeaderWithShadow: FC<TaskTableHeaderWithShadowProps> = React.memo(
	({ content }) => {
		const boardScroll = useStore(boardScrollModel.$boardScroll);

		return (
			<div
				className={classnames(styles.taskTableHead, {
					[styles.shadow]: boardScroll.y > 0,
				})}
				role="row">
				{content}
			</div>
		);
	}
);

/* *
 * Можно добавить подложку с фоном под левую колонку
 * Ставится после .taskTableHead
 *
<div
	style={{ top: headerOffset, left: pageOffset }}
	className={classnames(styles.taskTableSideSubstrate, {
		[styles.shadow]: boardScroll.x > 0,
	})}
/> */
