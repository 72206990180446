import FocusTrap from 'focus-trap-react';
import React, { FC, useState } from 'react';

import { Button, ConfirmModalBody, ConfirmModalHead, DropdownControlled } from '@shared/ui';

import styles from '../../styles.module.scss';

interface NotificationsClearAllProps {
	onAccept?: () => void;
	isPending?: boolean;
}

export const NotificationsClearAll: FC<NotificationsClearAllProps> = React.memo(
	({ onAccept, isPending }) => {
		const [menuIsOpen, setMenuIsOpen] = useState(false);

		return (
			<DropdownControlled
				opened={menuIsOpen}
				setOpened={setMenuIsOpen}
				className={styles.clearAll}
				portal={true}
				portalId="notifications-modal-portal"
				placement="bottom"
				targetButton={
					<Button
						size="sm"
						design="transparent"
						onClick={() => {
							setMenuIsOpen((prev) => !prev);
						}}>
						Очистить уведомления
					</Button>
				}
				content={
					<FocusTrap
						focusTrapOptions={{
							escapeDeactivates: true,
							allowOutsideClick: true,
							checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
						}}>
						<div className={styles.clearAllConfirm}>
							<ConfirmModalHead title="Очистить уведомления" size="sm" />
							<ConfirmModalBody
								content={<>Удалить все уведомления?</>}
								onAccept={() => {
									if (onAccept) {
										onAccept();
									}
								}}
								onDiscard={() => {
									setMenuIsOpen(false);
								}}
								acceptLabel="Удалить"
								acceptColor="danger"
								isPending={isPending}
							/>
						</div>
					</FocusTrap>
				}
			/>
		);
	}
);
