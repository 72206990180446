import { createEvent, createEffect, sample } from 'effector';
import { status } from 'patronum';

import { UserDto } from '@shared/api';
import { tasksRequests } from '@shared/api';
import { noticesModel } from '@shared/ui';

interface ReassignTaskEvent {
	taskId: string;
	user: UserDto;
}

const reassignTask = createEvent<ReassignTaskEvent>();
const reassignTaskFx = createEffect(({ taskId, user }: ReassignTaskEvent) =>
	tasksRequests.reassignTask({ taskId, targetUserId: user.id! })
);

const $status = status({ effect: reassignTaskFx });

sample({
	clock: reassignTask,
	target: reassignTaskFx,
});

sample({
	clock: reassignTaskFx.done,
	fn: (source) => ({
		type: 'success' as const,
		text: `Запрос на передачу задачи был отправлен пользователю <b>${source.params.user.fullName}</b>.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: reassignTaskFx.failData,
	fn: () => ({
		type: 'error' as const,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	reassignTask,
	$status,
};
