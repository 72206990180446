import classnames from 'classnames';
import React, {
	FC,
	KeyboardEventHandler,
	MouseEvent,
	MouseEventHandler,
	useMemo,
	useState,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { TagDtoTypeEnum, TaskApprovalDto, TaskPreviewDto, UserDto } from '@shared/api/model/models';
import { checkSpace, countdown, formatDate, parentIsButton } from '@shared/lib/utils';
import {
	Button,
	Tag,
	TagColorType,
	Icon,
	modalsModel,
	noticesModel,
	DropdownControlled,
	NotificationApprovalMessage,
} from '@shared/ui';

import { RemoveDropdown } from '../remove-dropdown';

import './styles.scss';
import styles from './styles.module.scss';

export interface TaskCardProps extends TaskPreviewDto {
	id: string;
	onClick?: () => void;
	archiveTask?: () => void;
	toggleArchiveLoading?: boolean;
	removeTask?: () => void;
	restoreTask?: () => void;
	approveTask?: () => Promise<Nullable<TaskApprovalDto>>;
	disapproveTask?: (data: Partial<TaskApprovalDto>) => Promise<Nullable<TaskApprovalDto>>;
	approveTaskPending?: boolean;
	disapproveTaskPending?: boolean;
	isMock?: boolean;
	isDisabled?: boolean;
	currentUser?: Nullable<UserDto>;
	isBoardAuthor?: boolean;
	userRole?: string;
}

export const TaskCard: FC<TaskCardProps> = ({
	title,
	board,
	tags,
	status,
	authorities,
	endDate,
	canRecoverFromArchive,
	canRemoveFromArchive,
	archived,
	requireApprove,
	attachmentsAmount,
	taskEventsAmount,
	completedSubtasksAmount,
	subtasksAmount,
	linkedTask,
	archiveTask,
	onClick,
	toggleArchiveLoading,
	removeTask,
	restoreTask,
	approveTask,
	disapproveTask,
	approveTaskPending,
	disapproveTaskPending,
	isMock,
	isDisabled,
	completeDate,
}) => {
	const { pathname } = useLocation();
	const isArchiveBoard = pathname.includes('/boards/archive');

	const userIsAuthor = authorities?.isAuthor;
	const userIsBoardAuthor = authorities?.isBoardAuthor;
	const userIsBoardEditor = authorities?.isBoardEditor;

	const userIsTaskOrBoardAuthorOrEditor = userIsAuthor || userIsBoardAuthor || userIsBoardEditor;

	const isDone = status?.type === 'DONE';

	const showArchiveButton = !archived && isDone && userIsTaskOrBoardAuthorOrEditor;

	const getCountdown = endDate && endDate.length > 0 ? countdown(endDate) : null;
	const countdownDateText = getCountdown?.dateText || '';
	const completeDateText = completeDate ? formatDate(completeDate, 'dd.MM.yy') : '';
	const dateText = !isDone ? countdownDateText : completeDateText;
	const expiredDate = getCountdown?.expiredDate || false;

	const [approvalMessageOpen, setApprovalMessageOpen] = useState(false);
	const [removeDropdownOpen, setRemoveDropdownOpen] = useState(false);

	const clickHandler: MouseEventHandler<HTMLElement> = (e) => {
		const target = e.target as HTMLElement;
		if (parentIsButton(target) || target.closest('.task-preview-approval-message')) return;
		onClick && onClick();
	};

	const keydownHandler: KeyboardEventHandler<HTMLElement> = (e) => {
		if (checkSpace(e) && !parentIsButton(e.target as HTMLElement)) {
			e.preventDefault();
			onClick && onClick();
		}
	};

	const tagList = useMemo(
		() =>
			tags
				? Array.from(tags)?.filter(({ type }) => type !== TagDtoTypeEnum.SYSTEM_WITHOUT_TAG)
				: null,
		[tags]
	);

	const navigate = useNavigate();

	return (
		<div
			tabIndex={!isDisabled ? 0 : -1}
			onClick={!isDisabled ? clickHandler : undefined}
			onKeyDown={!isDisabled ? keydownHandler : undefined}
			className={classnames(styles.taskCard, { [styles.isDisabled]: isDisabled })}>
			{linkedTask && <Icon id="linked-task" className={styles.linkedTaskMark} />}

			{showArchiveButton && (
				<DropdownControlled
					opened={removeDropdownOpen}
					setOpened={setRemoveDropdownOpen}
					portal={true}
					dropdownClassName="task-preview-approval-message"
					placement="bottomStart"
					targetButton={
						<Button
							onClick={(e: MouseEvent<HTMLElement>) => {
								e.preventDefault();
								setRemoveDropdownOpen((prev) => !prev);
							}}
							size="sm"
							design="transparent"
							type="button"
							className={styles.archiveLink}
							iconRight={
								toggleArchiveLoading ? (
									<Icon id="loading" className={styles.loadingIcon} />
								) : (
									<Icon id="arrow-right" />
								)
							}>
							Добавить в архив
						</Button>
					}
					content={
						<RemoveDropdown
							target="task"
							taskTitle={title}
							onAccept={() => archiveTask && archiveTask()}
							onDiscard={() => setRemoveDropdownOpen(false)}
							isPending={Boolean(toggleArchiveLoading)}
						/>
					}
				/>
			)}

			{tagList && (
				<div className={classnames(styles.tagsWrap, 'taskTags')}>
					{tagList?.map(({ id, name, color = 'gray' }) => (
						<Tag key={id} color={color as TagColorType} className={styles.tag} maxWidth>
							{name}
						</Tag>
					))}
				</div>
			)}

			<h2 className={styles.title}>{title}</h2>

			{board && (
				<div className={styles.board}>
					Доска:{' '}
					<Button
						design="no-style"
						className={styles.boardLink}
						onClick={
							board.currentParticipantBoardRole
								? (e: MouseEvent<HTMLElement>) => {
										e.preventDefault();
										modalsModel.closeLastModal();
										navigate(`/boards/${board.id}`);
								  }
								: () =>
										noticesModel.add({
											type: 'warning',
											text: 'Вы не являетесь участником доски',
										})
						}>
						{board.currentParticipantBoardRole ? board.name : 'Название скрыто'}
					</Button>
				</div>
			)}

			<div className={styles.shortInfo}>
				{dateText && dateText?.length > 0 && (
					<span className={classnames({ [styles.expired]: expiredDate && !isDone })}>
						{<Icon id="clock" className={styles.shortInfoIcon} />}
						{dateText}
					</span>
				)}

				{typeof attachmentsAmount !== 'undefined' && attachmentsAmount !== 0 && (
					<span>
						{<Icon id="file" className={styles.shortInfoIcon} />}
						{attachmentsAmount}
					</span>
				)}

				{typeof subtasksAmount !== 'undefined' && subtasksAmount !== 0 && (
					<span>
						{<Icon id="done" className={styles.shortInfoIcon} />}
						{completedSubtasksAmount}/{subtasksAmount}
					</span>
				)}

				{typeof taskEventsAmount !== 'undefined' && taskEventsAmount !== 0 && (
					<span>
						<Icon id="message" className={styles.shortInfoIcon} />
						{taskEventsAmount}
					</span>
				)}
			</div>

			{!isMock && !isArchiveBoard && archived && (canRemoveFromArchive || canRecoverFromArchive) && (
				<div className={styles.footerButtons}>
					{canRemoveFromArchive && (
						<Button
							onClick={(e: MouseEvent<HTMLElement>) => {
								e.preventDefault();
								removeTask && removeTask();
							}}
							design="filled"
							color="secondary"
							className={styles.footerButton}>
							Удалить
						</Button>
					)}
					{canRecoverFromArchive && (
						<Button
							onClick={(e: MouseEvent<HTMLElement>) => {
								e.preventDefault();
								restoreTask && restoreTask();
							}}
							design="filled"
							color="primary"
							className={styles.footerButton}
							isLoading={toggleArchiveLoading}>
							Восстановить
						</Button>
					)}
				</div>
			)}

			{!isMock && requireApprove && status?.type === 'REVIEW' && !archived && userIsAuthor && (
				<div className={classnames(styles.footerButtons, { [styles.shown]: approvalMessageOpen })}>
					<DropdownControlled
						opened={approvalMessageOpen}
						setOpened={setApprovalMessageOpen}
						portal={true}
						className={styles.footerDropDown}
						dropdownClassName="task-preview-approval-message"
						placement="bottomStart"
						targetButton={
							<Button
								onClick={() => setApprovalMessageOpen((prev) => !prev)}
								design="filled"
								color="secondary"
								type="button">
								В работу
							</Button>
						}
						content={
							<NotificationApprovalMessage
								onAccept={(data) => {
									disapproveTask && disapproveTask(data).then(() => setApprovalMessageOpen(false));
								}}
								onDiscard={() => setApprovalMessageOpen(false)}
								isPending={disapproveTaskPending}
							/>
						}
					/>
					<Button
						onClick={() => {
							approveTask && approveTask().then(() => setApprovalMessageOpen(false));
						}}
						isLoading={approveTaskPending}
						className={styles.footerButton}
						design="filled"
						color="primary"
						type="button">
						Согласовать
					</Button>
				</div>
			)}
		</div>
	);
};
