export const getNumEndings = (num: number, endings: [string, string, string]) => {
	let result, i;
	num = num % 100;

	if (num >= 11 && num <= 19) {
		result = endings[2];
	} else {
		i = num % 10;
		switch (i) {
			case 1:
				result = endings[0];
				break;
			case 2:
			case 3:
			case 4:
				result = endings[1];
				break;
			default:
				result = endings[2];
		}
	}

	return result;
};
