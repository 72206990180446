import React, { useCallback } from 'react';

import { filesRequests } from '@shared/api';
import { noticesModel } from '@shared/ui';

export const useDownloadFile = (setState?: React.Dispatch<React.SetStateAction<boolean>>) => {
	return useCallback(
		(id?: string, name?: string) => {
			if (!id || !name) return;

			setState && setState(true);

			filesRequests
				.downloadFileById(id)
				.then((file) => {
					if (file && file instanceof Blob) {
						const a = document.createElement('a');
						a.href = window.URL.createObjectURL(file);
						a.target = '_blank';
						a.download = name;
						setTimeout(() => {
							a.click();
							a.remove();
						}, 200);
					}
				})
				.catch((error) => {
					console.warn(error);
					noticesModel.add({
						type: 'error',
						text: `Что-то пошло не так, попробуйте позже.`,
					});
				})
				.finally(() => setState && setState(false));
		},
		[setState]
	);
};
