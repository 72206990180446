import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import classnames from 'classnames';
import { useGate, useStore } from 'effector-react';
import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { object } from 'yup';

import {
	CreateTaskPayload,
	TaskApprovalDtoStatusEnum,
	TaskReassignRequestDtoStatusEnum,
} from '@shared/api';
import { reassignRequestKey } from '@shared/lib/constants';
import { replaceTaskUrlPattern } from '@shared/lib/utils';
import { commonValidators } from '@shared/lib/validation';
import {
	modalsModel,
	FormControl,
	ModalTextarea,
	ModalTextareaSkeleton,
	Skeleton,
	BaseModal,
	TaskModalProps,
} from '@shared/ui';

import { boardModel } from '@entities/boards';
import {
	notificationApprovalModel,
	notificationReassignModel,
	taskReassignRequestByIdModel,
} from '@entities/notifications';
import { taskModel, datesModel, requireApproveModel } from '@entities/task';

import {
	CreateTaskActions,
	TaskStatusDropDown,
	ArchivedTaskButtons,
	ApprovalTaskButtons,
	ReassignTaskActions,
	TaskCommentForm,
	updateTaskFeature,
} from '@features/task';
import { useUserRoles } from '@features/user-roles';

import styles from './styles.module.scss';
import { ApprovalStatusLabels } from './ui/approval-status';
import { Assignee } from './ui/assignee';
import { CoAssignees } from './ui/co-assignees';
import { ParentTask } from './ui/parent-task';
import { ReassignStatusLabels } from './ui/reassign-status';
import { Subtasks } from './ui/subtasks';
import { TaskAuthorDropDown } from './ui/task-author';
import { TaskDates } from './ui/task-dates';
import { TaskEvents } from './ui/task-events';
import { TaskFiles } from './ui/task-files';
import { TaskRow } from './ui/task-row';
import { TaskTags } from './ui/task-tags';
import { TaskToolsMenu } from './ui/tools-menu';

const Component: FC<TaskModalProps> = React.memo(
	({ id, status, viewer, mode = 'view', isSubmodal, leftOffset = 0, pageOffset = 0 }) => {
		/**
		 * Проверяем является пользователь автором или редактором доски не
		 * через поле authorities, так как эти данные нужны при создании задачи
		 * для раздела меток, а при создании задачи еще нету authorities
		 */
		const {
			userOnBoard: { isAuthor: isBoardAuthorHook, isEditor: isBoardEditorHook },
		} = useUserRoles();

		const board = useStore(boardModel.$board);

		useGate(taskModel.TaskGate, { id: mode !== 'create' && id });

		const { data, loading } = useStore(taskModel.$taskStatus);
		const displaySkeletons = loading && mode !== 'create';

		const title = data?.title;
		const description = data?.description;
		const archived = data?.archived;

		/**
		 * Для всех остальных случаем ролевой модели
		 * используем данные из задачи из поля authorities
		 */
		const {
			isBoardAuthor,
			isAuthor: isTaskAuthor,
			isAssignee: userIsAssignee,
			isCoAssignee: userIsCoAssignee,
			isBoardEditor,
			isBoardViewer,
		} = data?.authorities || {};

		const userIsAuthor = isBoardAuthor || isTaskAuthor;
		const userIsParticipantBoard = isBoardEditor || isBoardViewer;

		const userIsAuthorOrEditor = Boolean(userIsAuthor || isBoardEditor);
		const userIsAssigneeOrCoAssignee = Boolean(userIsAssignee || userIsCoAssignee);

		const navigate = useNavigate();
		const { pathname } = useLocation();

		const [URLSearchParams] = useSearchParams();
		const reassignRequestId = URLSearchParams.get(reassignRequestKey);
		const taskReassignRequest = useStore(taskReassignRequestByIdModel.$taskReassignRequest);

		useGate(taskReassignRequestByIdModel.TaskReassignRequestGate, reassignRequestId);

		useGate(boardModel.TaskBoardGate, {
			boardId:
				!pathname.includes('/boards') && data?.board?.currentParticipantBoardRole
					? data?.board?.id
					: undefined,
			isResetBoard: !pathname.includes('/boards'),
		});

		const openedTaskModals = useStore(modalsModel.$modals).filter(
			({ name }) => name === 'TASK_MODAL'
		);

		const closeModalNavigate = () => {
			const prevTaskModal = openedTaskModals[openedTaskModals.length - 2];
			if (prevTaskModal) {
				navigate(-1);
				return;
			}

			const target = replaceTaskUrlPattern(pathname);
			if (target !== pathname) {
				navigate(target);
			}
			modalsModel.closeLastModal();
		};

		const closeModal = (closeAll?: boolean) => {
			if (isSubmodal) {
				modalsModel.closeSubModal();
				notificationReassignModel.reset();
				notificationApprovalModel.reset();
				return;
			}

			const target = replaceTaskUrlPattern(pathname);
			if (target !== pathname) {
				navigate(target);
			}

			closeAll ? modalsModel.closeAllModal() : modalsModel.closeLastModal();
		};

		const {
			control,
			handleSubmit,
			setValue,
			getValues,
			formState: { errors, isValid },
		} = useForm<CreateTaskPayload['data']>({
			defaultValues: {
				title: title ?? '',
				description: description ?? '',
			},
			resolver: yupResolver(object({ title: commonValidators.limitedRequiredString(500) })),
			mode: 'all',
		});

		useEffect(() => {
			if (!data) return;
			setValue('title', data.title);
			setValue('description', data.description);
			setValue('requireApprove', data.requireApprove);
			setValue('currentUserSubscribedToChanges', data.currentUserSubscribedToChanges);
		}, [data, setValue]);

		const localTaskData = useStore(updateTaskFeature.$localData);

		const taskUpdate = useCallback(
			(formData?: CreateTaskPayload['data'], message?: string) => {
				if (mode === 'create') return;
				if (!formData) formData = getValues();
				updateTaskFeature.updateTask({
					payload: { ...data, ...formData, ...localTaskData },
					options: {
						revalidate: false,
						mutateTaskPreview: true,
						showMessage: message,
					},
				});
			},
			[mode, data, localTaskData, getValues]
		);

		const requireApprove = useStore(requireApproveModel.$requireApprove);
		const notificationReassign = useStore(notificationReassignModel.$notification);

		const showReassignButtons =
			!archived &&
			((notificationReassign &&
				notificationReassign?.taskReassignRequest?.active &&
				notificationReassign?.taskReassignRequest?.status ===
					TaskReassignRequestDtoStatusEnum.OPEN &&
				notificationReassign?.taskReassignRequest?.currentUserConsumerOfTaskReassign) ||
				taskReassignRequest?.active);

		const shownApprovalButtons =
			!archived &&
			requireApprove &&
			data?.status?.type === 'REVIEW' &&
			data?.taskApproval?.active &&
			data?.taskApproval?.status === TaskApprovalDtoStatusEnum.OPEN &&
			data?.taskApproval?.currentUserApproverOfTaskApproval;

		const checkIsEditable = useCallback(
			(editingConditions: boolean) => {
				if (mode === 'create') {
					return true;
				}

				if (archived) {
					return false;
				}

				if (mode === 'edit') {
					return editingConditions;
				}

				if (mode === 'view') {
					return false;
				}
			},
			[mode, archived]
		);

		return (
			<BaseModal
				design="md"
				left={isSubmodal}
				isSubmodal={isSubmodal}
				pageOffset={isSubmodal ? pageOffset + leftOffset - 12 : 0}
				initialFocus={false}
				showCloseBtn={mode !== 'create'}
				closeModalNavigate={closeModalNavigate}
				header={
					<div className={styles.header}>
						<div className={styles.headerLeft}>
							{archived && data && (
								<ArchivedTaskButtons task={data} closeModal={closeModal} isSubmodal={isSubmodal} />
							)}
							{showReassignButtons && (
								<ReassignTaskActions
									notification={notificationReassign}
									reassignRequestId={reassignRequestId}
									taskTitle={data?.title}
									id={data?.id}
								/>
							)}
							{shownApprovalButtons && (
								<ApprovalTaskButtons
									taskApproval={data?.taskApproval}
									id={data?.id}
									title={data.title}
								/>
							)}
							{!archived && !showReassignButtons && !shownApprovalButtons && (
								<>
									{mode === 'create' && (
										<CreateTaskActions
											onDiscard={closeModal}
											handleSubmit={handleSubmit}
											disabled={!isValid}
										/>
									)}
									{mode !== 'create' && (
										<>
											<TaskStatusDropDown
												id={id}
												status={data?.status || status}
												editable={userIsAuthorOrEditor || userIsAssignee || userIsCoAssignee}
												isLoading={loading}
												requireApprove={!!requireApprove}
											/>
											<TaskAuthorDropDown author={data?.author} />
											<ReassignStatusLabels id={data?.id} />
											<ApprovalStatusLabels id={data?.id} />
										</>
									)}
								</>
							)}
						</div>
						<div className={styles.headerRight}>
							{mode !== 'create' && !archived && (
								<TaskToolsMenu
									taskId={data?.id}
									title={title}
									viewer={viewer}
									isLoading={loading}
									closeModalNavigate={closeModalNavigate}
								/>
							)}
						</div>
					</div>
				}
				footer={
					mode === 'edit' && (
						<div className={styles.footer}>
							<TaskCommentForm taskId={data?.id} isLoading={loading} editable />
						</div>
					)
				}>
				<div className={styles.body}>
					{displaySkeletons ? (
						<ModalTextareaSkeleton />
					) : (
						<Controller
							name="title"
							control={control}
							render={({ field: { name, onChange, onBlur, value } }) => (
								<ModalTextarea
									name={name}
									value={value}
									onChange={onChange}
									onBlur={() => {
										onBlur();
										if (mode === 'edit') {
											handleSubmit((data) => {
												if (data.title !== title) {
													taskUpdate(data, 'Название изменено');
												}
											})();
										}
									}}
									placeholder="Название задачи"
									error={errors.title?.message}
									disabled={!checkIsEditable(userIsAuthorOrEditor || !!userIsAssignee)}
								/>
							)}
						/>
					)}
					{data?.parentTask && (
						<TaskRow title="Родительская задача:" className={styles.colWrap}>
							<ParentTask {...data.parentTask} />
						</TaskRow>
					)}
					<TaskRow title="Описание задачи:" className={styles.colWrap} wide={true}>
						{displaySkeletons ? (
							<Skeleton width={200} height={16} />
						) : (
							<Controller
								name="description"
								control={control}
								render={({ field: { name, onChange, onBlur, value } }) => (
									<ModalTextarea
										name={name}
										value={value}
										onChange={onChange}
										onBlur={() => {
											onBlur();
											if (mode === 'edit') {
												handleSubmit((data) => {
													if (data.description !== description) {
														taskUpdate(data, 'Описание изменено');
													}
												})();
											}
										}}
										placeholder="Введите описание"
										size="text-13-regular"
										separator={false}
										disabled={!checkIsEditable(userIsAuthorOrEditor || !!userIsAssignee)}
									/>
								)}
							/>
						)}
					</TaskRow>

					<TaskRow title="Исполнитель:" className={styles.colWrap}>
						<Assignee
							editable={checkIsEditable(userIsAuthorOrEditor)}
							onUpdate={(message) => taskUpdate(undefined, message)}
						/>
					</TaskRow>

					<TaskRow title="Участники:" className={styles.colWrap}>
						<CoAssignees
							editable={checkIsEditable(
								userIsAuthorOrEditor ||
									!!userIsAssignee ||
									!!(userIsCoAssignee && userIsParticipantBoard)
							)}
							onUpdate={(message) => taskUpdate(undefined, message)}
						/>
					</TaskRow>

					<TaskRow title="Срок:" className={styles.colWrap}>
						<TaskDates
							model={datesModel}
							placement="right"
							dropdownId="task-modal-portal"
							loading={loading}
							editable={checkIsEditable(userIsAuthorOrEditor)}
							onUpdate={(message) => taskUpdate(undefined, message)}
						/>
					</TaskRow>

					<div className={styles.colWrap}>
						<Subtasks
							mode={mode}
							editable={checkIsEditable(userIsAuthorOrEditor || userIsAssigneeOrCoAssignee)}
							task={data}
							taskId={data?.id}
							onUpdate={taskUpdate}
						/>
					</div>

					{board && (
						<TaskRow title="Метки:" className={styles.colWrap}>
							<TaskTags
								board={board}
								loading={loading}
								onUpdate={(message) => taskUpdate(undefined, message)}
								editable={checkIsEditable(userIsAuthorOrEditor || !!userIsAssignee)}
								tagManagerAvailable={isBoardAuthorHook || isBoardEditorHook}
							/>
						</TaskRow>
					)}

					<TaskRow title="Файлы:" className={styles.colWrap}>
						<TaskFiles
							loading={loading}
							editable={checkIsEditable(userIsAuthorOrEditor || !!userIsAssignee)}
							onUpdate={taskUpdate}
						/>
					</TaskRow>

					<div className={classnames(styles.subscribe, styles.colWrap)}>
						{(mode === 'create' || isTaskAuthor) && (
							<Controller
								name="requireApprove"
								control={control}
								render={({ field: { name, onChange, value } }) => (
									<FormControl
										type="checkbox"
										name={name}
										checked={!!value}
										onChange={(e) => {
											onChange(e);
											if (mode === 'edit') {
												handleSubmit((data) => {
													taskUpdate(data);
												})();
												requireApproveModel.setRequireApprove(e.target.checked);
											}
										}}
										disabled={board?.archived || data?.archived}
										className={styles.subscribeControl}>
										Прислать задачу на согласование
									</FormControl>
								)}
							/>
						)}
						{mode === 'create' && (
							<Controller
								name="currentUserSubscribedToChanges"
								control={control}
								render={({ field: { name, onChange, value } }) => (
									<FormControl
										type="checkbox"
										name={name}
										checked={!!value}
										onChange={onChange}
										disabled={board?.archived || data?.archived}
										className={styles.subscribeControl}>
										Моментально уведомлять об изменениях на почту
									</FormControl>
								)}
							/>
						)}
					</div>

					{mode !== 'create' && (
						<TaskEvents
							viewer={viewer}
							taskId={data?.id}
							className={styles.colWrap}
							editable={!archived}
						/>
					)}
				</div>
				<div id="task-modal-portal" />
			</BaseModal>
		);
	}
);

export const TaskModal = {
	name: 'TASK_MODAL',
	Component,
};
