import classNames from 'classnames';
import { format } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { isEqual } from 'lodash';
import React, { useState, useEffect, FC, useMemo } from 'react';

import { MS_PER_MINUTE } from '@shared/lib/constants';
import { helpersStyles } from '@shared/lib/styles';
import { checkEscape } from '@shared/lib/utils';
import { DatePicker, ToggleBtn, Button, Icon, Dropdown } from '@shared/ui';
import {
	dateIsEarlier,
	dateIsLater,
	dateTimesIsEqual,
	timeIsLater,
	withinOneDay,
} from '@shared/ui/atoms/datepicker/lib';

import { checkedDate } from '../../lib';
import { DatePickerInput } from '../datepicker-input';

import {
	getHoursOrMinutes,
	prepareInitialDate,
	prepareInitialReminder,
	reminderOptions,
} from './lib';
import styles from './styles.module.scss';

export interface DatePickerBoardProps {
	initialEndDate?: string;
	initialStartDate?: string;
	initialReminderDate?: string;
	onChange?: (endDate: Date | null, startDate: Date | null, reminderDate: Date | null) => void;
	singleSelectDate?: boolean;
	showReminder?: boolean;
	onDiscard: () => void;
	onAccept: () => void;
}

export const DatePickerBoard: FC<DatePickerBoardProps> = ({
	initialEndDate,
	initialStartDate,
	initialReminderDate,
	onChange,
	singleSelectDate,
	showReminder = true,
	onDiscard,
	onAccept,
}) => {
	/*
	 * Начальный стейт
	 * NB! Важно не рендерить компонент, пока стор не готов
	 */
	const [initialDates, initialTimes, initialReminder, initialRange] = useMemo(() => {
		const initialEnd = prepareInitialDate(initialEndDate);
		const initialStart = prepareInitialDate(initialStartDate);
		const initialReminder = prepareInitialReminder(initialReminderDate);

		const initialDates = initialEnd.full
			? initialStart.full
				? [initialStart.full, initialEnd.full]
				: [initialEnd.full]
			: [new Date()];
		const initialTimes = initialEnd.time
			? initialStart.time
				? [initialStart.time, initialEnd.time]
				: [initialEnd.time]
			: null;
		/*
		 * Здесь требуется поправка на 9 часов для кейсов,
		 * когда время не указывалось явно
		 */
		let initialReminderValue = null;

		if (initialEnd.full && initialReminder) {
			const seemsTimeWasNotInputtedByUser =
				initialEnd.full.getHours() === 0 && initialReminder.getHours() === 9;
			const factor = seemsTimeWasNotInputtedByUser ? MS_PER_MINUTE : 0;
			initialReminderValue =
				(initialEnd.full.getTime() + 540 * factor - initialReminder.getTime()) / MS_PER_MINUTE;
		}

		const initialRange =
			initialDates?.length === 2 && !dateTimesIsEqual(initialStart.full, initialEnd.full);

		return [initialDates, initialTimes, initialReminderValue, initialRange];
	}, [initialEndDate, initialStartDate, initialReminderDate]);

	const [selectsRange, setSelectsRange] = useState(initialRange);
	const [selectsTime, setSelectsTime] = useState(initialTimes !== null);

	const [dates, setDates] = useState<Date[] | null>(initialDates);
	const [times, setTimes] = useState<string[] | null>(initialTimes);
	const [reminder, setReminder] = useState<number | null>(initialReminder ?? null);

	const startDate = dates && dates[0];
	const startDateString = startDate
		? startDate > new Date()
			? format(startDate, 'dd.MM.yyyy')
			: format(new Date(), 'dd.MM.yyyy')
		: null;

	const endDate = selectsRange ? dates && dates[1] : null;
	const endDateString = endDate
		? endDate > new Date()
			? format(endDate, 'dd.MM.yyyy')
			: format(new Date(), 'dd.MM.yyyy')
		: null;

	/*
	 * Проверяет правильный порядок времени,
	 * если установили дату в пределах одного дня
	 */
	const checkTimes = (newDates: Date[] | null) => {
		if (!(times && times[0] && times[1])) {
			return;
		}
		if (withinOneDay(newDates) && timeIsLater(times[0], times[1])) {
			setTimes([times[1], times[0]]);
		}
	};

	const isDirty =
		!isEqual(initialDates, dates) ||
		!Boolean(initialEndDate) ||
		!isEqual(initialTimes, times) ||
		initialReminder !== reminder ||
		initialRange !== selectsRange;

	useEffect(() => {
		let startDate = dates && new Date(dates[0].valueOf());
		let endDate = dates && dates[1] && new Date(dates[1].valueOf());

		/*
		 * Разбираем на часы и минуты
		 */
		const startTimeArray = getHoursOrMinutes(times && times[0]);
		const endTimeArray = getHoursOrMinutes(times && times[1]);

		startDate?.setHours(startTimeArray[0], startTimeArray[1], 0, 0);
		endDate?.setHours(endTimeArray[0], endTimeArray[1], 0, 0);

		const singleDate = !endDate;

		if (!endDate) {
			endDate = startDate;
			startDate = null;
		}

		/*
		 * Если время не указано явно,
		 * то время напоминалок ставим на 9 утра
		 */
		const endTime = singleDate ? startTimeArray[0] : endTimeArray[0];
		const reminderHours = endTime || 9;
		const reminderDate =
			reminder && endDate
				? new Date(new Date(endDate).setHours(reminderHours) - reminder * MS_PER_MINUTE)
				: null;

		onChange && onChange(startDate, endDate, reminderDate);
	}, [dates, times, reminder, onChange]);

	return (
		<div className={styles.datepicker}>
			<div className={styles.dates}>
				{!selectsRange ? (
					<DatePickerInput
						title="Выбранная дата:"
						withTime={selectsTime}
						date={startDateString || ''}
						time={(times && times[0]) || ''}
						onChangeDate={(value) => {
							if (!value.length) {
								setDates(null);
							} else {
								const newDate = checkedDate(value);
								if (newDate) {
									setDates([newDate]);
								}
							}
						}}
						onChangeTime={(value) => {
							setTimes([value]);
						}}
					/>
				) : (
					<div className={styles.group_dates}>
						<DatePickerInput
							title="Дата начала:"
							withTime={selectsTime}
							date={startDateString || ''}
							time={(times && times[0]) || ''}
							onChangeDate={(value) => {
								const newStartDate = checkedDate(value);
								if (newStartDate) {
									setDates((prev) => {
										const prevStartDate = prev && prev[0];
										const prevEndDate = (prev && prev[1]) || prevStartDate;

										const isLaterOfEnd =
											newStartDate &&
											prevEndDate &&
											dateIsLater(newStartDate, prevEndDate || prevStartDate);

										const newDatesState = newStartDate
											? prevEndDate
												? isLaterOfEnd
													? [prevEndDate, newStartDate]
													: [newStartDate, prevEndDate]
												: [newStartDate]
											: null;

										checkTimes(newDatesState);

										return newDatesState;
									});
								}
							}}
							onChangeTime={(value) => {
								if (value.length === 5) {
									setTimes((prev) => {
										return prev && prev[1]
											? withinOneDay(dates) && timeIsLater(value, prev[1])
												? [prev[1], prev[1]]
												: [value, prev[1]]
											: [value];
									});
								}
							}}
						/>
						<DatePickerInput
							title="Дата окончания:"
							withTime={selectsTime}
							date={endDateString || startDateString || ''}
							time={(times && times[1]) || ''}
							onChangeDate={(value) => {
								const newEndDate = checkedDate(value);
								if (newEndDate) {
									setDates((prev) => {
										const prevStartDate = prev && prev[0];

										const isEarlierOfStart =
											newEndDate && prevStartDate && dateIsEarlier(newEndDate, prevStartDate);

										const newDatesState = newEndDate
											? prevStartDate
												? isEarlierOfStart
													? [newEndDate, prevStartDate]
													: [prevStartDate, newEndDate]
												: [newEndDate, newEndDate]
											: null;

										checkTimes(newDatesState);

										return newDatesState;
									});
								}
							}}
							onChangeTime={(value) => {
								if (value.length === 5) {
									setTimes((prev) => {
										return prev && prev[0]
											? withinOneDay(dates) && timeIsLater(prev[0], value)
												? [prev[0], prev[0]]
												: [prev[0], value]
											: ['', value];
									});
								}
							}}
						/>
					</div>
				)}
			</div>

			<div className={styles.datepicker_wrapper}>
				<DatePicker
					dates={dates}
					setDates={setDates}
					setDatesCallback={(dates) => {
						checkTimes(dates);
					}}
					multiple={selectsRange}
				/>
			</div>

			<div className={classNames(helpersStyles.separator, styles.separator)} />

			<div className={styles.switches}>
				<div className={styles.switch}>
					<div className={styles.switch_title}>Время</div>
					<ToggleBtn
						checked={selectsTime}
						onChange={(event) => {
							const flag = event.target.checked;
							setSelectsTime(flag);
							if (flag) {
								const currentTime = format(new Date(), 'HH:mm');
								setTimes([currentTime, currentTime]);
							}
							if (!flag) setTimes(null);
							if (!flag && reminder && reminder < 1440) setReminder(null);
						}}
					/>
				</div>
				{!singleSelectDate && (
					<div className={styles.switch}>
						<div className={styles.switch_title}>Дата начала и окончания</div>
						<ToggleBtn
							checked={selectsRange}
							onChange={(event) => {
								const flag = event.target.checked;
								setSelectsRange(flag);

								if (!flag) {
									setDates((prev) => prev && prev.slice(0, 1));
									if (times && times.length > 0) {
										setTimes([times[0]]);
									}
								}

								if (flag && times?.length) {
									setDates((prev) => prev && [...prev, ...prev]);
									setTimes([times[0], times[0]]);
								}
							}}
						/>
					</div>
				)}
			</div>

			<div className={classNames(helpersStyles.separator, styles.separator)} />

			{showReminder && (
				<>
					<div className={styles.reminder}>
						<div className={styles.reminder_title}>Напоминание</div>
						<div className={styles.reminder_title}>
							<Dropdown
								className={styles.reminder_drop}
								placement="right"
								portal={false}
								customCoordinates={{
									top: 'auto',
									right: 'auto',
									transform: 'none',
									left: 'calc(100% + 8px)',
									bottom: 0,
								}}
								targetButton={
									<Button
										size="sm"
										design="transparent"
										iconRight={<Icon id="chevron-right" />}
										className={styles.reminder_dropToggle}>
										{reminderOptions.find(({ value }) => value === reminder)?.label}
									</Button>
								}
								dropdownData={({ setOpen }, ref) => {
									return (
										<FocusTrap
											focusTrapOptions={{
												escapeDeactivates: true,
												allowOutsideClick: true,
												checkCanFocusTrap: () =>
													new Promise<void>((resolve) => setTimeout(resolve, 50)),
											}}>
											<div
												className={styles.reminder_dropBox}
												onKeyDown={(e) => {
													e.stopPropagation();
													checkEscape(e) && setOpen(false);
												}}>
												{reminderOptions.map(({ value, label }) => {
													const withTime = value && value < 1440 ? selectsTime : true;
													return withTime ? (
														<Button
															key={label}
															size="sm"
															design="transparent"
															onClick={() => {
																setReminder(value);
																setOpen(false);
															}}
															className={styles.reminder_dropAction}>
															<span>{label}</span>
															{!selectsTime && <time>09:00</time>}
														</Button>
													) : null;
												})}
											</div>
										</FocusTrap>
									);
								}}
							/>
						</div>
					</div>

					<div className={classNames(helpersStyles.separator, styles.separator)} />
				</>
			)}

			<div className={styles.actionButtons}>
				<Button size="md" onClick={onDiscard} design="filled" color="secondary" type="button">
					Отменить
				</Button>
				<Button
					size="md"
					onClick={onAccept}
					disabled={!isDirty}
					design="filled"
					color="primary"
					type="button">
					Сохранить
				</Button>
			</div>
		</div>
	);
};
