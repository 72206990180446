import React, { useState } from 'react';

import { UserDto } from '@shared/api';
import {
	REMOVE_COASSIGNEE_DIALOG_TEXT,
	REMOVE_COASSIGNEE_DIALOG_TITLE,
} from '@shared/lib/constants';
import {
	Button,
	ConfirmModalBody,
	ConfirmModalHead,
	DropdownControlled,
	Icon,
	UserInfo,
} from '@shared/ui';

import styles from './styles.module.scss';

interface SearchAssigneeListItemProps {
	user: UserDto;
	addAssignee: () => void;
	removeAssignee: () => void;
	added: boolean;
	userHasSubtasks?: boolean;
}

export const SearchAssigneeListItem = ({
	user,
	addAssignee,
	removeAssignee,
	added,
	userHasSubtasks,
}: SearchAssigneeListItemProps) => {
	const [showLockedRemoveNotification, setShowLockedRemoveNotification] = useState(false);

	return (
		<li className={styles.listItem}>
			<UserInfo user={user} showJobTitle nameInOneLine />
			{added ? (
				<>
					{!userHasSubtasks && (
						<Button
							onClick={removeAssignee}
							className={styles.button}
							size="sm"
							design="transparent"
							iconRight={<Icon id="remove" />}>
							Удалить
						</Button>
					)}
					{userHasSubtasks && (
						<DropdownControlled
							opened={showLockedRemoveNotification}
							setOpened={setShowLockedRemoveNotification}
							dropdownClassName={styles.notification}
							contentClassName={styles.notificationContent}
							portal={true}
							portalId="search-assignee-list"
							placement="bottomEnd"
							targetButton={
								<Button
									size="sm"
									design="transparent"
									iconRight={<Icon id="remove" />}
									onClick={() => setShowLockedRemoveNotification((prev) => !prev)}
									className={styles.button}>
									Удалить
								</Button>
							}
							content={
								<>
									<ConfirmModalHead
										size="sm"
										title={REMOVE_COASSIGNEE_DIALOG_TITLE}
										onClickBack={() => setShowLockedRemoveNotification(false)}
									/>
									<ConfirmModalBody
										content={
											<>
												{REMOVE_COASSIGNEE_DIALOG_TEXT[0]} <b>{user.fullName}</b>{' '}
												{REMOVE_COASSIGNEE_DIALOG_TEXT[1]}
											</>
										}
										onDiscard={() => setShowLockedRemoveNotification(false)}
										discardLabel="Понятно"
										textAlign="left"
									/>
								</>
							}
						/>
					)}
				</>
			) : (
				<Button
					onClick={addAssignee}
					className={styles.button}
					size="sm"
					design="transparent"
					iconRight={<Icon id="plus" />}>
					Добавить
				</Button>
			)}
		</li>
	);
};
