import {
	createEvent,
	createEffect,
	createStore,
	sample,
	restore,
	combine,
	forward,
} from 'effector';
import { createGate } from 'effector-react';

import {
	GetNotificationsRequest,
	NotificationDto,
	notificationsRequests,
	TaskEventPageableResponse,
} from '@shared/api';
import { isEmptyObject } from '@shared/lib/utils';
import { noticesModel, NoticeType } from '@shared/ui';

import { notificationsReadedModel } from '../notifications-readed';

const reset = createEvent();
const revalidate = createEvent();

const addToList = createEvent<NotificationDto>();
const updateInList = createEvent<NotificationDto>();
const removeFromList = createEvent<Nullable<NotificationDto>>();

const $list = createStore<NotificationDto[]>([]).reset(reset);
const $pageable = createStore<Record<number, NotificationDto[]>>({ 0: [] }).reset(reset);

const getList = createEvent<GetNotificationsRequest>();
const getListFx = createEffect(notificationsRequests.getNotifications);

const $config = createStore<Nullable<GetNotificationsRequest>>(null).reset([getList, reset]);
const $error = restore<Error>(getListFx.failData, null).reset([getList, reset]);
const $pagination = createStore<Omit<TaskEventPageableResponse, 'data'>>({}).reset(reset);
const $complete = createStore<boolean>(false).reset(reset);

const $status = combine({
	loading: combine($list, getListFx.pending, (data, pending) => pending && data.length === 0),
	lazyLoading: combine($list, getListFx.pending, (data, pending) => pending && data.length > 0),
	data: $list,
	error: $error,
	pagination: $pagination,
	complete: $complete,
});

/* *
 * Гейт
 */
const NotificationListGate = createGate<GetNotificationsRequest>();

forward({
	from: NotificationListGate.state,
	to: getList,
});

forward({
	from: NotificationListGate.close,
	to: [reset, notificationsReadedModel.markAllAsRead],
});

sample({
	clock: getList,
	filter: (params) => !isEmptyObject(params),
	target: [getListFx, $config],
});

/* *
 * Складываем респонсы в page-map
 */
sample({
	clock: getListFx.doneData,
	source: $pageable,
	fn: (store, response) => {
		const map = { ...store };
		map[response?.currentPage || 0] = response?.data || [];
		return map;
	},
	target: $pageable,
});

/*
 * Преобразуем page-map в список
 */
sample({
	clock: $pageable,
	fn: (map) => {
		return map ? Object.values(map).reduce((acc, val) => acc.concat(val), []) : [];
	},
	target: $list,
});

/*
 * Добавляем в начало списка
 */
sample({
	clock: addToList,
	source: $list,
	fn: (list, add) => (add ? [add, ...list] : list),
	target: $list,
});

/*
 * Удаляем из списка
 */
sample({
	clock: removeFromList,
	source: $list,
	fn: (list, remove) => list.filter(({ id }) => id !== remove?.id),
	target: $list,
});

/*
 * Обновляем в списке
 */
sample({
	clock: updateInList,
	source: $list,
	fn: (list, update) => list.map((item) => (item.id === update.id ? update : item)),
	target: $list,
});

/*
 * Храним пагинацию отдельно
 */
sample({
	clock: getListFx.doneData,
	fn: ({ totalElements, currentPage, nextPage, prevPage, totalPages }) => ({
		totalElements,
		currentPage,
		nextPage,
		prevPage,
		totalPages,
	}),
	target: $pagination,
});

/*
 * Вычисляем, закончен список или нет
 */
sample({
	clock: $list,
	source: $pagination,
	fn: ({ nextPage, totalPages }) => nextPage === totalPages,
	target: $complete,
});

/*
 * Уведомления
 */
sample({
	clock: getListFx.failData,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

/* *
 * Ревалидация всех страниц
 */
const revalidateFx = createEffect(async (requests: GetNotificationsRequest[]) => {
	const response = await Promise.all(
		requests.map((cfg) => notificationsRequests.getNotifications(cfg))
	);
	return response.reduce((map, chunk, i) => {
		if (chunk.data) map[i] = chunk.data;
		return map;
	}, {} as Record<number, NotificationDto[]>);
});

sample({
	clock: revalidate,
	source: {
		config: $config,
		pages: $pageable,
	},
	fn: ({ config, pages }) =>
		Object.keys(pages).map(
			(page) => ({ ...config, page: Number(page) } as GetNotificationsRequest)
		),
	target: revalidateFx,
});

sample({
	clock: revalidateFx.doneData,
	target: $pageable,
});

export const model = {
	NotificationListGate,
	$list,
	$status,
	addToList,
	removeFromList,
	updateInList,
	revalidate,
};
