import { useStore } from 'effector-react';
import React from 'react';

import { modalsModel } from '../model';
import { modalsCollection } from '../model';

export const Modals = ({ pageOffset }: { pageOffset: number }) => {
	const openedModals = useStore(modalsModel.$modals);
	const lastModal = openedModals[openedModals.length - 1];
	const props = lastModal?.data || {};

	// Передаём pageOffset во все модалки, используем где нужно
	const propsWithPageOffset: Record<string, any> = { ...props, pageOffset };
	const Modal = modalsCollection[lastModal?.name];

	const openedSubmodal = useStore(modalsModel.$subModal);
	const submodalProps = { ...openedSubmodal?.data, isSubmodal: true, pageOffset };
	const Submodal = openedSubmodal ? modalsCollection[openedSubmodal.name] : null;

	return (
		<>
			{/* Добавляем key для того, чтобы реакт понимал, когда у нас разные модалки.
          Иначе если в стеке есть две одинаковых модалки и одна меняет другую, 
          то не происходит размонтирование первой модалки
       */}
			{Modal && <Modal {...propsWithPageOffset} key={openedModals.length - 1} />}
			{Submodal && <Submodal {...submodalProps} />}
		</>
	);
};
