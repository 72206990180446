import { fetcher, nullablePromise } from '@shared/api/config';
import {
	BoardDto,
	BoardParticipantDto,
	BoardPreviewDto,
	BoardRoleDto,
	BoardWithViewDto,
	BoardShortDto,
} from '@shared/api/model/models';

const getBoardsRoles = () => fetcher<BoardRoleDto[]>({ url: '/board-roles' });

export interface GetBoardsList {
	name?: string;
	starred?: boolean;
	archived?: boolean;
}

const getBoardsList = ({ name, starred = false, archived = false }: GetBoardsList) =>
	fetcher<BoardPreviewDto[]>({
		url: '/boards',
		method: 'GET',
		body: {
			name,
			archived,
			starred,
		},
	});

export interface GetBoardByIdRequest {
	boardId?: string;
}

const getBoardById = ({ boardId }: GetBoardByIdRequest) =>
	boardId
		? fetcher<BoardWithViewDto>({
				url: `/boards/${boardId}`,
		  })
		: nullablePromise();

export interface CreateBoardRequest extends BoardDto {
	name: string;
	backgroundColor: string;
	participants: BoardParticipantDto[];
}

const createBoard = (data: CreateBoardRequest) =>
	fetcher<BoardDto>({
		url: '/boards',
		method: 'POST',
		body: data,
	});

export interface UpdateBoardRequest extends BoardDto {}

const updateBoard = (data: UpdateBoardRequest) =>
	fetcher<BoardDto>({
		url: '/boards',
		method: 'PUT',
		body: data,
	});

export interface DeleteBoardRequest {
	boardId?: BoardDto['id'];
	boardName?: BoardDto['name'];
}

const deleteBoard = ({ boardId }: DeleteBoardRequest) =>
	fetcher<BoardDto>({
		url: `/boards/${boardId}`,
		method: 'DELETE',
	});

export interface ToggleBoardStarredRequest {
	boardId: string;
}

const toggleBoardStarred = ({ boardId }: ToggleBoardStarredRequest) =>
	fetcher<BoardPreviewDto>({
		url: `/boards/${boardId}/starred`,
		method: 'PUT',
	});

export interface ToggleBoardArchivedRequest {
	boardId: string;
}

const toggleBoardArchived = ({ boardId }: ToggleBoardArchivedRequest) =>
	fetcher<BoardPreviewDto>({
		url: `/boards/${boardId}/archived`,
		method: 'PUT',
	});

export interface ToggleBoardEmailNotificationRequest {
	boardId: string;
}

const toggleBoardEmailNotification = ({ boardId }: ToggleBoardEmailNotificationRequest) =>
	fetcher<BoardPreviewDto>({
		url: `/boards/${boardId}/email-notification`,
		method: 'PUT',
	});

export interface AddParticipantParams {
	boardId: string;
	participant: BoardParticipantDto;
}

const addParticipant = ({ boardId, participant }: AddParticipantParams) =>
	fetcher<BoardParticipantDto>({
		method: 'POST',
		url: `/boards/${boardId}/participant`,
		body: participant,
	});

export interface GetBoardsShortRequest {
	taskArchived?: boolean;
	nonArchivedOnly?: boolean;
	includeCounters?: boolean;
}

const getBoardsShort = ({
	taskArchived,
	nonArchivedOnly,
	includeCounters,
}: GetBoardsShortRequest) =>
	fetcher<BoardShortDto[]>({
		url: '/boards/short',
		body: {
			'task-archived': taskArchived,
			'non-archived-only': nonArchivedOnly,
			'include-counters': includeCounters,
		},
	});

export const boardsRequests = {
	getBoardsRoles,
	getBoardsList,
	getBoardById,
	createBoard,
	updateBoard,
	deleteBoard,
	toggleBoardStarred,
	toggleBoardArchived,
	toggleBoardEmailNotification,
	addParticipant,
	getBoardsShort,
};
