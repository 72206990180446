import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';

import { FileDto, FileDtoTypeEnum } from '@shared/api';
import { ConfirmModalBody, ConfirmModalHead } from '@shared/ui';

import { fileManagerModel } from '@entities/file-manager/model';

import style from './styles.module.scss';

interface FileManagerRemoveModalProps {
	onAccept: (file: FileDto) => void;
	onDiscard: () => void;
}

export const FileManagerRemoveModal: FC<FileManagerRemoveModalProps> = ({
	onAccept,
	onDiscard,
}) => {
	const file = useStore(fileManagerModel.$file);

	const title = useMemo(
		() => (file?.type !== FileDtoTypeEnum.LINK ? `Удаление файла` : `Удаление ссылки на файл`),
		[file]
	);
	const fileName = useMemo(() => {
		if (!file) return '';
		switch (file.type) {
			case FileDtoTypeEnum.LINK:
				return `${file.name || file.url} (ссылка)`;
			case FileDtoTypeEnum.FILE:
			default:
				return file.name;
		}
	}, [file]);

	const handleAccept = () => {
		file && onAccept && onAccept(file);
	};

	return (
		<div className={style.fileRemoveModalWrap}>
			<ConfirmModalHead size="sm" title={title} onClickBack={onDiscard} />
			<ConfirmModalBody
				content={
					<>
						Вы действительно хотите удалить файл <b>{fileName}</b>?
					</>
				}
				onAccept={handleAccept}
				onDiscard={onDiscard}
				acceptLabel="Удалить"
				acceptColor="danger"
				textAlign="left"
			/>
		</div>
	);
};
