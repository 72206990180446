import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import React, { useRef, MutableRefObject, useState } from 'react';

import { UserDto } from '@shared/api';
import { REMOVE_ASSIGNEE_DIALOG_TITLE, REMOVE_ASSIGNEE_DIALOG_TEXT } from '@shared/lib/constants';
import { useEscape, useOutsideClick } from '@shared/lib/hooks';
import { helpersStyles } from '@shared/lib/styles';
import { UserInfo, Button, Icon, ConfirmModalHead, ConfirmModalBody } from '@shared/ui';

import styles from './styles.module.scss';

interface AssigneeDropdownProps {
	user: UserDto;
	closeDropdown: () => void;
	removeAssignee?: (user: UserDto) => void;
	removeDialogTitle?: string;
	removeDialogText?: [string, string];
	focusOnMount?: false;
	userHasSubtasks?: boolean;
	editable?: boolean;
}

export const AssigneeDropdown = ({
	user,
	removeAssignee,
	removeDialogTitle = REMOVE_ASSIGNEE_DIALOG_TITLE,
	removeDialogText = REMOVE_ASSIGNEE_DIALOG_TEXT,
	closeDropdown,
	focusOnMount,
	userHasSubtasks,
	editable,
}: AssigneeDropdownProps) => {
	const [showLockedRemoveNotification, setShowLockedRemoveNotification] = useState(false);
	const assigneeDropdownRef = useRef<HTMLDivElement>(null);

	useEscape(
		true,
		(e) => {
			e?.stopPropagation();
			closeDropdown();
		},
		assigneeDropdownRef as MutableRefObject<HTMLElement>
	);

	useOutsideClick(assigneeDropdownRef, closeDropdown);

	return (
		<FocusTrap
			focusTrapOptions={{
				allowOutsideClick: true,
				initialFocus: focusOnMount,
				checkCanFocusTrap: () =>
					new Promise<void>((resolve) => editable && setTimeout(resolve, 50)),
			}}>
			<div className={styles.assigneeDropdown} ref={assigneeDropdownRef}>
				{!showLockedRemoveNotification && (
					<>
						<UserInfo user={user} showEmail showJobTitle showDepartment />
						{editable && (
							<>
								<div className={classNames(helpersStyles.separator, styles.separator)} />
								<Button
									id="remove-assignee"
									onClick={() => {
										if (userHasSubtasks) {
											setShowLockedRemoveNotification((prev) => !prev);
											return;
										}
										removeAssignee && removeAssignee(user);
									}}
									size="sm"
									design="transparent"
									iconLeft={<Icon id="remove" />}
									className={styles.button}>
									Удалить
								</Button>
							</>
						)}
					</>
				)}
				{showLockedRemoveNotification && (
					<div className={styles.notification}>
						<ConfirmModalHead
							size="sm"
							title={removeDialogTitle}
							onClickBack={() => setShowLockedRemoveNotification(false)}
						/>
						<ConfirmModalBody
							content={
								<>
									{removeDialogText[0]} <b>{user.fullName}</b> {removeDialogText[1]}
								</>
							}
							onDiscard={() => setShowLockedRemoveNotification(false)}
							discardLabel="Понятно"
							textAlign="left"
						/>
					</div>
				)}
			</div>
		</FocusTrap>
	);
};
