import { createEffect, createEvent, sample } from 'effector';

import {
	tasksRequests,
	TaskStatusDto,
	GetTaskByIdRequest,
	TaskPreviewDto,
	TaskDto,
} from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { taskModel, taskListModel, taskStatusesModel } from '@entities/task';
import { TaskStatusUpdate } from '@entities/task/model/task-statuses/task-statuses';

interface UpdateTaskStatusParams {
	itemId: string;
	itemNewStatus: TaskStatusUpdate;
	shouldUpdateTask?: boolean;
}

interface UpdateTaskStatusFxArgs {
	params: UpdateTaskStatusParams;
	statuses: TaskStatusDto[];
}

const updateTaskStatus = createEvent<UpdateTaskStatusParams>();
const updateTaskStatusFx = createEffect({
	handler: async ({ statuses, params }: UpdateTaskStatusFxArgs) => {
		const { itemId: taskId, itemNewStatus } = params;
		const newStatusId = statuses.find(({ type }) => type === itemNewStatus.newStatusType)?.id;
		const newPosition = itemNewStatus.newPosition;

		if (!newStatusId) return null;

		await tasksRequests.updateTaskStatus({
			taskId,
			data: {
				newStatusId,
				newPosition,
			},
		});
	},
});

const updateTaskApproval = createEvent<GetTaskByIdRequest>();
const updateTaskApprovalFx = createEffect(tasksRequests.getTaskById);

const updateTaskApprovalDirectly = createEvent<TaskDto>();

const updateTaskDone = createEvent<GetTaskByIdRequest>();
const updateTaskDoneFx = createEffect(tasksRequests.getTaskById);

sample({
	clock: updateTaskStatus,
	source: taskStatusesModel.$statuses,
	fn: (statuses, params) => ({ statuses, params }),
	target: updateTaskStatusFx,
});

sample({
	clock: updateTaskApproval,
	target: updateTaskApprovalFx,
});

sample({
	clock: updateTaskDone,
	target: updateTaskDoneFx,
});

/**
 * Revalidate task if we changed task status inside task
 * ToDo: dead code?
 */
sample({
	clock: updateTaskStatusFx.done,
	filter: (source) => Boolean(source.params.params.shouldUpdateTask),
	target: taskModel.revalidateTask,
});

sample({
	clock: updateTaskStatusFx.failData,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

/**
 * Get task and update field after moved task to REVIEW
 */
sample({
	clock: updateTaskStatusFx.done,
	filter: (clock) => clock.params.params.itemNewStatus.newStatusType === 'REVIEW',
	fn: (clock) => ({ id: clock.params.params.itemId }),
	target: updateTaskApproval,
});

/**
 * Get task and update field after moved task to DONE
 */
sample({
	clock: updateTaskStatusFx.done,
	filter: (clock) => clock.params.params.itemNewStatus.newStatusType === 'DONE',
	fn: (clock) => ({ id: clock.params.params.itemId }),
	target: updateTaskDone,
});

sample({
	source: taskListModel.$taskList,
	clock: [updateTaskApprovalFx.doneData, updateTaskApprovalDirectly],
	fn: (source, clock) => {
		return source.map((item) =>
			item.id === clock?.id
				? ({
						...item,
						taskApproval: clock?.taskApproval,
				  } as TaskPreviewDto)
				: item
		);
	},
	target: taskListModel.$taskList,
});

sample({
	source: taskListModel.$taskList,
	clock: updateTaskDoneFx.doneData,
	fn: (source, clock) => {
		return source.map((item) =>
			item.id === clock?.id
				? ({
						...item,
						completeDate: clock?.completeDate,
				  } as TaskPreviewDto)
				: item
		);
	},
	target: taskListModel.$taskList,
});

sample({
	clock: updateTaskApprovalFx.doneData,
	target: taskModel.updateTaskApproval,
});

export const updateTaskStatusModel = {
	updateTaskStatus,
	updateTaskApprovalDirectly,
	updateTaskStatusFx,
	updateTaskApprovalFx,
};
