import { createStore, createEvent, sample } from 'effector';

import { FileDto } from '@shared/api';

export type FileManagerActionType = 'REMOVE_FILE' | 'REMOVE_FILE_IN_LIST';

export type PushFileManagerActionType = {
	type: Nullable<FileManagerActionType>;
	payload?: Nullable<FileDto>;
};

const pushAction = createEvent<PushFileManagerActionType>();

const $action = createStore<Nullable<FileManagerActionType>>(null).reset(pushAction);
const $file = createStore<Nullable<FileDto>>(null).reset(pushAction);

sample({
	clock: pushAction,
	fn: ({ type }) => type,
	target: $action,
});

sample({
	clock: pushAction,
	fn: ({ payload }) => payload || null,
	target: $file,
});

export const model = {
	$action,
	$file,
	pushAction,
};
