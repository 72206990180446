import { fetcher } from '@shared/api/config';
import { TaskReassignRequestDto, TaskReassignRequestDtoStatusEnum } from '@shared/api/model/models';

const updateTaskReassignRequestStatus = ({ id, status }: TaskReassignRequestDto) =>
	fetcher<TaskReassignRequestDtoStatusEnum>({
		url: `/task-reassign-requests/${id}?new-status=${status}`,
		method: 'PUT',
	}).then(() => status);

const taskReassignRequestRetry = (payload: TaskReassignRequestDto) =>
	fetcher<TaskReassignRequestDto>({
		url: `/task-reassign-requests/${payload.id}/retry`,
		method: 'POST',
	}).then(() => payload);

const getTaskReassignRequestById = (id: string) =>
	fetcher<TaskReassignRequestDto>({
		url: `/task-reassign-requests/${id}`,
	});

export const taskReassignRequests = {
	updateTaskReassignRequestStatus,
	taskReassignRequestRetry,
	getTaskReassignRequestById,
};
