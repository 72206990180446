import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

import { Icon } from '@shared/ui';

import styles from './styles.module.scss';

interface ParticipantRoleProps extends HTMLAttributes<HTMLButtonElement> {
	description?: string;
	isSelected: boolean;
	disabled?: boolean;
}

export const Role = ({ description, isSelected, className, ...props }: ParticipantRoleProps) => {
	return (
		<button className={classNames(styles.role, className)} {...props}>
			<div className={styles.roleDescription}>{description}</div>
			{isSelected && (
				<div className={styles.iconCheck}>
					<Icon id="done" />
				</div>
			)}
		</button>
	);
};
