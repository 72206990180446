import { useStore } from 'effector-react';
import React, { FC, useRef, useEffect, useState, useMemo } from 'react';

import { BoardParticipantDto } from '@shared/api';
import { modalsModel, BaseModal } from '@shared/ui';

import { boardModel } from '@entities/boards';

import { ParticipantsSearchList } from '@features/participants-search-list';
import { useUserRoles } from '@features/user-roles';

const Component: FC = () => {
	const {
		userOnBoard: { isAuthor: isBoardAuthor, role },
	} = useUserRoles();
	const board = useStore(boardModel.$board);
	const [heightWrapper, setHeightWrapper] = useState(0);
	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		wrapperRef.current && setHeightWrapper(wrapperRef.current?.offsetHeight);
	}, []);

	const participants = useMemo(() => {
		const author = board?.author;
		const participants = board?.participants ?? [];

		return [...participants, { user: author }].sort((a, b) =>
			a?.user?.fullName &&
			b?.user?.fullName &&
			a?.user?.fullName.toLowerCase() > b?.user?.fullName.toLowerCase()
				? 1
				: -1
		) as Partial<BoardParticipantDto>[];
	}, [board?.author, board?.participants]);

	return (
		<BaseModal
			size={362}
			design="sm"
			closeModalOnEscape
			closeModalOnOutsideClick
			transparentBackground>
			<div ref={wrapperRef} style={{ height: heightWrapper ? `${heightWrapper}px` : 'initial' }}>
				<ParticipantsSearchList
					title="Участники"
					participants={participants}
					setRole={(role, id) =>
						boardModel.setRoleParticipant({
							participantId: id as string,
							roleId: role.role.id as string,
						})
					}
					remove={(_, participantId) => boardModel.removeParticipant(participantId as string)}
					currentUserRole={role}
					isDisabled={!!board?.archived}
					disableRolesChanging={!isBoardAuthor}
				/>
			</div>
		</BaseModal>
	);
};

export const BoardParticipantsModal = {
	name: 'BOARD_PARTICIPANTS_MODAL',
	Component,
};

export const boardParticipants = () => {
	modalsModel.pushModal({
		name: 'BOARD_PARTICIPANTS_MODAL',
	});
};
