import { useStore } from 'effector-react';
import { useState } from 'react';

import { TaskApprovalDto, TaskApprovalDtoStatusEnum } from '@shared/api';

import { notificationApprovalModel } from '@entities/notifications';
import { taskListModel, taskModel } from '@entities/task';

interface UseTaskApprovalActionArgs {
	id?: string;
	taskApproval?: TaskApprovalDto;
	title?: string;
}

export const useTaskApproval = () => {
	const pending = useStore(notificationApprovalModel.$pending);
	const [activeTask, setActiveTask] = useState<Nullable<string>>(null);

	const approveTask = async ({ id, taskApproval, title }: UseTaskApprovalActionArgs) => {
		setActiveTask && id && setActiveTask(id);
		return id && taskApproval
			? notificationApprovalModel
					.approveTaskFx({
						taskApproval: {
							...taskApproval,
							status: TaskApprovalDtoStatusEnum.APPROVED,
						},
						id,
						title,
					})
					.then((response) => {
						taskModel.revalidateTask();
						taskListModel.revalidateTaskList();
						return response;
					})
			: null;
	};
	const disapproveTask = async ({ id, taskApproval, title }: UseTaskApprovalActionArgs) => {
		setActiveTask && id && setActiveTask(id);
		return id && taskApproval
			? notificationApprovalModel
					.disapproveTaskFx({
						taskApproval: {
							...taskApproval,
							message: taskApproval.message,
							status: TaskApprovalDtoStatusEnum.DECLINED,
						},
						id,
						title,
					})
					.then((response) => {
						taskModel.revalidateTask();
						taskListModel.revalidateTaskList();
						return response;
					})
			: null;
	};

	return {
		approveTask,
		disapproveTask,
		activeApprovalTask: activeTask,
		setActiveApprovalTask: setActiveTask,
		pending,
	};
};
