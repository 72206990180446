import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import classnames from 'classnames';
import { useGate, useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { object } from 'yup';

import { MAX_FILE_SIZE_50MB } from '@shared/lib/constants';
import { commonValidators } from '@shared/lib/validation';
import { filesModel } from '@shared/model';
import { Button, FormElement, Icon, InputMulti, noticesModel, Skeleton } from '@shared/ui';

import { FileManagerAttach } from '@entities/file-manager';
import { taskEventsListModel } from '@entities/task';

import { taskCommentFilesModel, createTaskCommentModel } from '../../model';
import { TaskCommentFormFile } from '../task-comment-form-file';

import styles from './styles.module.scss';

export interface TaskCommentFormProps {
	taskId?: string;
	isLoading?: boolean;
	editable?: boolean;
}

interface CommentFormValues {
	text: string;
}

export const TaskCommentForm: FC<TaskCommentFormProps> = ({ taskId, isLoading, editable }) => {
	useGate(taskCommentFilesModel.commentFilesGate);

	const files = useStore(taskCommentFilesModel.$files);
	const { pending } = useStore(createTaskCommentModel.$status);

	const [focused, setFocused] = useState(false);
	const [withFiles, setWithFiles] = useState(false);

	const {
		register,
		trigger,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<CommentFormValues>({
		resolver: yupResolver(
			object({
				text: commonValidators.conditionalRequiredString(withFiles),
			})
		),
		mode: 'onSubmit',
	});

	useEffect(() => {
		setWithFiles(!!files && files.length > 0);
	}, [files]);

	useEffect(() => {
		if (withFiles) trigger('text');
	}, [withFiles, trigger]);

	const handleFiles = (files: FileList | null) => {
		if (!files || Array.from(files).length === 0) return;

		if (Array.from(files).some((file) => file.size > MAX_FILE_SIZE_50MB)) {
			noticesModel.add({
				type: 'error',
				text: 'Размер файла не должен превышать 50МБ.',
			});
			return;
		}

		const formData = new FormData();
		Array.from(files).forEach((file) => formData.append('files', file));

		filesModel
			.uploadFilesOrFileLinksFx(Object(formData))
			.then((response) => {
				taskCommentFilesModel.addFile(response);
				noticesModel.add({
					type: 'success',
					text: `Файл "<b>${response[0].name || response[0].url}</b>" прикреплен.`,
				});
			})
			.catch((error) => {
				console.warn(error);
				noticesModel.add({
					type: 'error',
					text: `Что-то пошло не так, попробуйте позже.`,
				});
			});
	};

	const handleCommentSubmit = (data: CommentFormValues) => {
		if (!taskId) return;
		const payload = {
			taskId,
			text: data.text.length > 0 ? data.text : undefined,
			files: files || [],
		};
		/*
		 * Можно на onSuccess заюзать taskEventsListModel.addToList(taskEvent),
		 * но бэк сразу после создания ивента отдает TaskEventDto без юзера,
		 * поэтому taskEventsListModel.revalidate()
		 */
		createTaskCommentModel.createTaskEvent({
			payload,
			onSuccess: () => {
				setValue('text', '');
				taskEventsListModel.revalidate();
			},
		});
	};

	if (isLoading) return <Skeleton width="100%" height={32} />;

	return (
		<form className={styles.form} onSubmit={handleSubmit(handleCommentSubmit)}>
			<FormElement className={styles.formElement} error={errors?.text?.message}>
				<div className={classnames(styles.commentArea, { [styles.focus]: focused })}>
					<div
						className={styles.commentText}
						onFocusCapture={() => setFocused(true)}
						onBlurCapture={() => setFocused(false)}
						onDragEnter={() => setFocused(true)}
						onDragLeave={() => setFocused(false)}
						onDragOver={(e) => {
							e.preventDefault();
							e.dataTransfer.dropEffect = 'copy';
						}}
						onDrop={(e) => {
							e.preventDefault();
							setFocused(false);
							handleFiles(e.dataTransfer.files);
						}}>
						<InputMulti
							theme="light-gray"
							placeholder="Напишите комментарий"
							onKeyDown={(e) => {
								if (
									e.key === 'Enter' &&
									(e.metaKey || e.ctrlKey) &&
									document.activeElement === e.target
								) {
									handleSubmit(handleCommentSubmit)();
								}
							}}
							{...register('text')}
						/>
					</div>
					{files && files.length > 0 && (
						<div className={styles.commentFiles}>
							{files?.map((file) => (
								<TaskCommentFormFile
									file={file}
									key={file.id}
									onRemove={(file) => {
										taskCommentFilesModel.removeFile(file);
										noticesModel.add({
											type: 'success',
											text: `Файл "<b>${file.name || file.url}</b>" удален.`,
										});
									}}
								/>
							))}
						</div>
					)}
				</div>
			</FormElement>
			<FileManagerAttach
				btnSize="md"
				className={styles.attachTools}
				createFile={(e) => handleFiles(e.target.files)}
				onFileUploaded={(file) => {
					taskCommentFilesModel.addFile(file);
				}}
				editable={editable}
			/>
			<Button
				type="submit"
				color="primary"
				design="filled"
				onlyIcon={<Icon id="send" />}
				className={styles.submitBtn}
				disabled={!taskId}
				isLoading={pending}
			/>
		</form>
	);
};
