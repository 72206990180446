import React, { FC } from 'react';

import { Skeleton } from '@shared/ui';

import styles from '../../styles.module.scss';

export const NotificationsSkeleton: FC = React.memo(() => {
	return (
		<>
			<div className={styles.buttonsBlock}>
				<Skeleton theme="light" width={158} height={20} />
			</div>
			<Skeleton theme="light" width="100%" height={116} />
			<Skeleton theme="light" width="100%" height={116} />
			<Skeleton theme="light" width="100%" height={116} />
		</>
	);
});
