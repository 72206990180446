import {
	offset,
	Placement,
	flip,
	useFloating,
	autoUpdate,
	ReferenceType,
} from '@floating-ui/react-dom';
import classNames from 'classnames';
import React, {
	forwardRef,
	ForwardRefRenderFunction,
	useRef,
	useState,
	ReactElement,
	useEffect,
	ForwardedRef,
	MutableRefObject,
	useMemo,
} from 'react';

import { useDidMount, useEscape } from '@shared/lib/hooks';
import { useDelayUnmount } from '@shared/lib/hooks';
import { kebabize } from '@shared/lib/utils';

import { DropdownPortal } from './dropdown-portal';
import { getRelativePosition } from './lib';
import styles from './styles.module.scss';

interface Coordinates {
	left?: number | string;
	top?: number | string;
	right?: number | string;
	bottom?: number | string;
	transform?: string;
}

interface DropdownDataProps {
	setOpen: (state: boolean) => void;
}

export type DropdownPlacement =
	| 'left'
	| 'right'
	| 'top'
	| 'bottom'
	| 'leftStart'
	| 'leftEnd'
	| 'rightStart'
	| 'rightEnd'
	| 'topStart'
	| 'topEnd'
	| 'bottomStart'
	| 'bottomEnd';

export type DropdownDataFC<P extends DropdownDataProps = DropdownDataProps> =
	ForwardRefRenderFunction<HTMLElement, P>;

interface DropdownProps<P extends DropdownDataProps = DropdownDataProps> {
	targetButton: ReactElement;
	dropdownData: DropdownDataFC<P>;
	className?: string;
	placement?: DropdownPlacement;
	indent?: number;
	closeOnClickOutside?: boolean;
	onScrollCloseThreshold?: number;
	portal?: boolean;
	visibilityHandler?: (isOpen: boolean) => void;
	isOpen?: boolean;
	closeOnEsc?: boolean;
	openOnButtonClick?: boolean;
	delay?: number;
	customCoordinates?: Coordinates;
	dropdownId?: string;
	dropdownElement?: ReactElement;
	dropdownClassName?: string;
	externalReference?: Nullable<ReferenceType>;
}

export const Dropdown = forwardRef(
	(
		{
			targetButton,
			dropdownData,
			className,
			placement = 'leftStart',
			indent = 8,
			closeOnClickOutside = true,
			onScrollCloseThreshold = 5,
			portal = false,
			visibilityHandler,
			isOpen = false,
			closeOnEsc = false,
			openOnButtonClick = true,
			delay = 0,
			customCoordinates = {},
			dropdownId,
			dropdownElement,
			dropdownClassName,
			externalReference,
		}: DropdownProps,
		forwardedRef: ForwardedRef<HTMLDivElement | null>
	) => {
		const buttonRef = useRef<HTMLDivElement | null>(null);
		const dropdownRef = useRef<HTMLDivElement | null>(null);

		const [open, setOpen] = useState(false);
		const shouldRender = useDelayUnmount(open || isOpen, delay);

		useEffect(() => {
			if (visibilityHandler) visibilityHandler(open);
		}, [open, visibilityHandler]);

		useDidMount(() => {
			const clickHandler = (event: React.MouseEvent | MouseEvent) => {
				if (
					(dropdownRef.current?.contains(event.target as Node) ||
						buttonRef.current?.contains(event.target as Node)) &&
					!(event.target as HTMLElement).closest('.close-dd')
				) {
					return;
				}
				closeOnClickOutside && setOpen(false);
			};
			document.body.addEventListener('click', clickHandler);
			return () => document.body.removeEventListener('click', clickHandler);
		});

		const [coordinates, setCoordinates] = useState<Coordinates>({});

		/*
		 * Позиционирование для портала
		 */
		const { x, y, reference, floating, strategy } = useFloating({
			placement: kebabize(placement) as Placement,
			strategy: 'fixed',
			middleware: [flip({ padding: 10 }), offset(indent)],
			whileElementsMounted: (reference, floating, update) => {
				return autoUpdate(reference, floating, update, {
					elementResize: true,
				});
			},
		});

		/*
		 * Относительное позиционирование
		 */
		useEffect(() => {
			if (portal) return;
			if (shouldRender) setCoordinates(getRelativePosition(placement, indent));
		}, [shouldRender, placement, indent, portal]);

		/*
		 * Обработчик клика
		 */
		const handleButtonClick = () => {
			setOpen((prevState) => !prevState);
		};

		/*
		 * Обработчики прокрутки родителя
		 */
		const [parentScroll, setParentScroll] = useState<HTMLDivElement | null>(null);
		const refCallback = (node: HTMLDivElement) => {
			setParentScroll(node?.closest<HTMLDivElement>('.scrollbar-container'));

			if (forwardedRef) {
				(forwardedRef as MutableRefObject<HTMLDivElement>).current = node;
			}

			if (reference) {
				if (externalReference) {
					reference(externalReference);
				} else {
					reference(node);
				}
			}
		};

		useEffect(() => {
			let top = 0;
			let left = 0;

			if (!shouldRender) return;

			if (shouldRender && parentScroll) {
				top = parentScroll?.scrollTop || 0;
				left = parentScroll?.scrollLeft || 0;
			}

			const onScroll = () => {
				if (shouldRender && parentScroll) {
					const newTop = parentScroll.scrollTop;
					const newLeft = parentScroll.scrollLeft;
					const shouldClose =
						Math.abs(newTop - top) > onScrollCloseThreshold ||
						Math.abs(newLeft - left) > onScrollCloseThreshold;

					if (shouldClose) {
						setOpen(false);
						if (visibilityHandler) visibilityHandler(false);
					}
				}
			};

			parentScroll?.addEventListener('scroll', onScroll);

			return () => {
				parentScroll?.removeEventListener('scroll', onScroll);
			};
		}, [onScrollCloseThreshold, visibilityHandler, parentScroll, shouldRender]);

		useEscape(
			closeOnEsc,
			(e) => {
				e?.stopPropagation();
				setOpen(false);
			},
			forwardedRef as MutableRefObject<HTMLElement>
		);

		/*
		 * Мемоизируем рендер, чтобы не аффектился на изменение стейтов и хуков компонента
		 */
		const DropdownData = useMemo(() => forwardRef(dropdownData), [dropdownData]);

		return (
			<>
				{portal ? (
					<div ref={refCallback} className={className}>
						<div
							onClick={() => openOnButtonClick && handleButtonClick()}
							ref={buttonRef}
							className={styles.target_button}>
							{targetButton}
						</div>

						<DropdownPortal id={dropdownId}>
							<div
								ref={(node) => {
									if (floating) floating(node);
									if (dropdownRef) dropdownRef.current = node;
								}}
								className={classNames(
									dropdownClassName,
									styles.dropdown,
									shouldRender && styles.open
								)}
								style={{
									position: strategy,
									top: y ?? 0,
									left: x ?? 0,
									...customCoordinates,
								}}>
								{shouldRender &&
									(dropdownElement ? dropdownElement : <DropdownData setOpen={setOpen} />)}
							</div>
						</DropdownPortal>
					</div>
				) : (
					<div ref={forwardedRef} className={classNames(styles.wrapper, className)}>
						<div
							onClick={() => openOnButtonClick && handleButtonClick()}
							ref={buttonRef}
							className={styles.target_button}>
							{targetButton}
						</div>

						<div
							ref={dropdownRef}
							style={{ ...coordinates, ...customCoordinates }}
							className={classNames(
								dropdownClassName,
								styles.dropdown,
								shouldRender && styles.open
							)}>
							{shouldRender &&
								(dropdownElement ? dropdownElement : <DropdownData setOpen={setOpen} />)}
						</div>
					</div>
				)}
			</>
		);
	}
);
