import { createEffect, createEvent, sample } from 'effector';
import { createGate } from 'effector-react';
import { status } from 'patronum';

import { authRequests, LoginProps } from '@shared/api';
import { setJWT } from '@shared/lib/utils';

const loginGate = createGate<LoginProps>();

const login = createEvent<LoginProps>();
const loginFx = createEffect(authRequests.login);

const $status = status({ effect: loginFx });

loginFx.doneData.watch(({ accessToken, refreshToken }) => {
	setJWT({
		access: accessToken?.token,
		refresh: refreshToken?.token,
		accessExpiration: accessToken?.expirationDate,
		refreshExpiration: refreshToken?.expirationDate,
	});
	window.location.replace('/');
});

sample({
	clock: loginGate.open,
	target: login,
});

sample({
	clock: login,
	target: loginFx,
});

export const model = {
	loginGate,
	$status,
};
