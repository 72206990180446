import classnames from 'classnames';
import React, { FC, useState, useEffect } from 'react';

import { TaskStatusDto, TaskStatusDtoTypeEnum } from '@shared/api';
import { TaskStatus } from '@shared/lib/constants';
import { Button, Select, SelectOption, Skeleton } from '@shared/ui';

import { useSetTaskStatus } from '@features/task';

import styles from '../styles.module.scss';

export interface TaskStatusDropDownProps {
	id: string;
	status?: TaskStatusDto;
	editable?: boolean;
	isLoading?: boolean;
	requireApprove?: boolean;
}

const statusOptions: SelectOption[] = Object.entries(TaskStatus).map(([value, label]) => ({
	label,
	value,
}));

export const TaskStatusDropDown: FC<TaskStatusDropDownProps> = ({
	id,
	status,
	editable = true,
	isLoading = false,
	requireApprove,
}) => {
	const { setTaskStatus, taskStatusStatusPending } = useSetTaskStatus();

	const statusName = status?.type && TaskStatus[status?.type as keyof typeof TaskStatus];
	const initialOption =
		statusOptions.find(({ value }) => value === status?.type) || statusOptions[0];

	const [selectedStatus, setSelectedStatus] = useState(initialOption);

	useEffect(
		() =>
			setSelectedStatus(
				statusOptions.find(({ value }) => value === status?.type) || statusOptions[0]
			),
		[status?.type]
	);

	const changeStatusHandler = (option: SelectOption) => {
		const prevOption = selectedStatus;

		setTaskStatus({
			id,
			nextStatus: option.value as TaskStatusDtoTypeEnum,
			callback: () => {
				setSelectedStatus(option);
			},
			fallback: () => {
				setSelectedStatus(prevOption);
			},
		});
	};

	if (isLoading) return <Skeleton width={158} height={32} />;

	return (
		<>
			{editable && (
				<Select
					portal={false}
					options={
						requireApprove ? statusOptions.slice(0, statusOptions.length - 1) : statusOptions
					}
					initial={initialOption}
					value={selectedStatus}
					onChange={changeStatusHandler}
					isLoading={taskStatusStatusPending}
					className={classnames({
						[styles.isPending]: taskStatusStatusPending,
					})}
				/>
			)}
			{!editable && (
				<Button type="button" size="md" design="filled" color="secondary" disabled={true}>
					{statusName}
				</Button>
			)}
		</>
	);
};
