import React, { FC } from 'react';

import { Datetime } from '@shared/ui';

import { CommentProps } from '../';

import { CommentControls } from './controls';
import styles from './styles.module.scss';

export interface CommentHeaderProps {
	author?: CommentProps['author'];
	createDate?: CommentProps['createDate'];
	updateDate?: CommentProps['updateDate'];
	onRemove?: CommentProps['onRemove'];
	startEdit?: () => void;
	allowEdit?: boolean;
}

export const CommentHeader: FC<CommentHeaderProps> = React.memo(
	({ author, createDate, updateDate, onRemove, startEdit, allowEdit = false }) => {
		const date = updateDate || createDate;
		return (
			<div className={styles.header}>
				<div className={styles.info}>
					{author && <div className={styles.authorName}>{author.fullName}</div>}
					{date && <Datetime date={date} dateFormat={'HH:mm'} />}
				</div>

				{allowEdit && <CommentControls onRemove={onRemove} startEdit={startEdit} />}
			</div>
		);
	}
);
