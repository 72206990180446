import classnames from 'classnames';
import React from 'react';

import { substituteLinksWithTags } from '@shared/lib/utils';
import { Attachment } from '@shared/ui';

import { ContentProps } from '../';
import styles from '../styles.module.scss';

export const ContentDefault = ({ text, files }: ContentProps) => {
	return (
		<div className={classnames(styles.content, styles.content_default)}>
			{text && <div dangerouslySetInnerHTML={{ __html: substituteLinksWithTags(text) }} />}
			{files && files.length > 0 && (
				<div className={styles.files}>
					{files.map((file) => (
						<Attachment key={'attach_' + file.id} {...file} />
					))}
				</div>
			)}
		</div>
	);
};
