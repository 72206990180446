import React, { PropsWithChildren } from 'react';

import { boardScrollModel } from '@shared/model';
import { Scroller } from '@shared/ui';

export interface PageContentWrapperProps {
	headerOffset: number;
}

export const PageContentWrapper = ({
	children,
	headerOffset,
}: PropsWithChildren<PageContentWrapperProps>) => {
	const scrollHandler = (x: number, y: number) => {
		boardScrollModel.setBoardScrollY(y);
		boardScrollModel.setBoardScrollX(x);
	};

	return (
		<Scroller
			className="page__scroller"
			onScroll={scrollHandler}
			height={`calc(var(--vh) - ${headerOffset}px)`}>
			<div className="page__inner">{children}</div>
		</Scroller>
	);
};
