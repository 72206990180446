import { useStore, useGate } from 'effector-react';
import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { NotificationDto, TaskReassignRequestDtoStatusEnum } from '@shared/api';
import { reassignRequestKey } from '@shared/lib/constants';

import { ReassignDropdown, notificationReassignModel } from '@entities/notifications';
import { taskModel } from '@entities/task';

export interface ReassignTaskActionsProps {
	notification?: Nullable<NotificationDto>;
	reassignRequestId?: Nullable<string>;
	taskTitle?: string;
	id?: string;
}

export const ReassignTaskActions: FC<ReassignTaskActionsProps> = ({
	notification,
	reassignRequestId,
	taskTitle,
	id,
}) => {
	useGate(notificationReassignModel.NotificationGate);
	const pending = useStore(notificationReassignModel.$pending);

	const [URLSearchParams, setURLSearchParams] = useSearchParams();

	const removeReassignParam = () => {
		if (URLSearchParams.has(reassignRequestKey)) {
			URLSearchParams.delete(reassignRequestKey);
			setURLSearchParams(URLSearchParams);
		}
	};

	if (!notification && !reassignRequestId) return null;

	const requestParams = notification?.taskReassignRequest ?? { id: reassignRequestId! };

	const handleAccept = () => {
		if (notification?.taskReassignRequest) {
			notificationReassignModel.setNotification(notification);
		}

		notificationReassignModel
			.acceptReassignFx({
				taskReassignRequest: {
					...requestParams,
					status: TaskReassignRequestDtoStatusEnum.ACCEPTED,
				},
				taskId: id,
			})
			.then(() => {
				if (reassignRequestId) {
					removeReassignParam();
				}
				taskModel.revalidateTask();
			});
	};

	const handleDecline = () => {
		if (notification?.taskReassignRequest) {
			notificationReassignModel.setNotification(notification);
		}

		notificationReassignModel
			.declineReassignFx({
				taskReassignRequest: {
					...requestParams,
					status: TaskReassignRequestDtoStatusEnum.DECLINED,
				},
				taskId: id,
			})
			.then(() => {
				if (reassignRequestId) {
					removeReassignParam();
				}
			});
	};

	return (
		<>
			<ReassignDropdown
				onAccept={handleAccept}
				dropdownType="accept"
				dropdownTitle={notification?.title}
				taskTitle={notification?.task?.title ?? taskTitle}
				isPending={pending.accept}
			/>

			<ReassignDropdown
				onAccept={handleDecline}
				dropdownType="decline"
				dropdownTitle={notification?.title}
				taskTitle={notification?.task?.title ?? taskTitle}
				isPending={pending.decline}
			/>
		</>
	);
};
