import { createEvent, createStore, sample } from 'effector';

const asideSizes = {
	full: 208,
	min: 80,
};

const $width = createStore(asideSizes.full);
const collapse = createEvent();
const expand = createEvent();

sample({
	clock: collapse,
	fn: () => asideSizes.min,
	target: $width,
});

sample({
	clock: expand,
	fn: () => asideSizes.full,
	target: $width,
});

export const asideModel = {
	$width,
	collapse,
	expand,
};
