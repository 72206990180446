import { createEvent, createEffect, createStore, sample } from 'effector';
import { condition, status } from 'patronum';

import { userRequests, SearchApiParams } from '@shared/api';
import { PageableResponseUserDto } from '@shared/api/model/pageableResponseUserDto';

const resetUsers = createEvent();
const fetchUsers = createEvent<SearchApiParams>();
const fetchUsersFx = createEffect(userRequests.searchUsers);
const $statusUsers = status({ effect: fetchUsersFx });

const $users = createStore<PageableResponseUserDto | null>(null).reset(resetUsers);

condition({
	source: fetchUsers,
	if: (source) => source.fullName.length > 2,
	then: fetchUsersFx,
	else: resetUsers,
});

sample({
	clock: fetchUsersFx.doneData,
	target: $users,
});

export const model = {
	fetchUsers,
	$users,
	$statusUsers,
};
