import React from 'react';

import { ButtonColorType } from '@shared/ui';

interface TargetProps {
	title: React.ReactNode;
	content: React.ReactNode;
	acceptLabel: React.ReactNode;
	acceptColor: ButtonColorType;
}

export const taskProps = (taskTitle?: string): TargetProps => ({
	title: 'Перемещение в архив',
	content: (
		<>
			Задача <b>{taskTitle ?? ''}</b> будет перемещена в архив.
		</>
	),
	acceptLabel: 'В архив',
	acceptColor: 'primary',
});

export const taskDateProps = (isSubtask: boolean): TargetProps => ({
	title: 'Удаление даты',
	content: `Дата в ${isSubtask ? 'подзадаче' : 'задаче'} будет удалена.`,
	acceptLabel: 'Удалить',
	acceptColor: 'danger',
});
