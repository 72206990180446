import { createEvent, createStore, sample } from 'effector';

import { UserDto } from '@shared/api';

const addAssignee = createEvent<UserDto>();
const removeAssignee = createEvent();

const $assignee = createStore<Nullable<UserDto>>(null).reset(removeAssignee);

sample({
	clock: addAssignee,
	target: $assignee,
});

export const model = {
	addAssignee,
	removeAssignee,
	$assignee,
};
