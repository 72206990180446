import { createEvent, createStore, sample, forward } from 'effector';
import { createGate } from 'effector-react';

export type Modal = {
	name: string;
	data?: Record<string, any>;
};

/* *
 * Modals
 */
const pushModal = createEvent<Modal>();
const closeLastModal = createEvent();
const closeAllModal = createEvent();
const replaceLastModal = createEvent<Modal>();

const $modals = createStore<Modal[]>([]).reset(closeAllModal);

const $isPush = createStore(false);

sample({
	source: $modals,
	clock: pushModal,
	fn: (source, clock) => [...source, clock],
	target: $modals,
});

sample({
	source: $modals,
	clock: closeLastModal,
	fn: (source) => [...source.slice(0, -1)],
	target: $modals,
});

sample({
	source: $modals,
	clock: replaceLastModal,
	fn: (source, clock) => [...source.slice(0, -1), clock],
	target: $modals,
});

sample({
	clock: pushModal,
	fn: () => true,
	target: $isPush,
});

sample({
	clock: closeLastModal,
	fn: () => false,
	target: $isPush,
});

/* *
 * Submodal
 */
const pushSubModal = createEvent<Modal>();
const replaceSubModal = createEvent<Modal>();
const closeSubModal = createEvent();

const $subModal = createStore<Nullable<Modal>>(null).reset([closeAllModal, closeSubModal]);

sample({
	clock: [pushSubModal, replaceSubModal],
	target: $subModal,
});

/* *
 * Gate
 */
const ModalGate = createGate<Modal>();

forward({
	from: ModalGate.open,
	to: pushModal,
});

export const model = {
	pushModal,
	closeLastModal,
	closeAllModal,
	replaceLastModal,
	pushSubModal,
	replaceSubModal,
	closeSubModal,
	$modals,
	$subModal,
	ModalGate,
	$isPush,
};
