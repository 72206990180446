import classNames from 'classnames';
import React, { useRef, MutableRefObject } from 'react';

import { BoardParticipantDto, BoardRoleDto, BoardRoleDtoNameEnum } from '@shared/api';
import { useEscape, useOutsideClick } from '@shared/lib/hooks';
import { helpersStyles } from '@shared/lib/styles';
import { Role, UserInfo } from '@shared/ui';

import styles from './styles.module.scss';

interface CurrentUserDropdownProps {
	participant: Partial<BoardParticipantDto>;
	boardRoles: BoardRoleDto[];
	onEscape?: () => void;
	outsideClickExcludeRef?: MutableRefObject<HTMLElement | null>;
	icon?: boolean;
}

export const CurrentUserDropdown = ({
	participant,
	boardRoles,
	onEscape,
	outsideClickExcludeRef,
	icon,
}: CurrentUserDropdownProps) => {
	const currentUserDropdownRef = useRef<HTMLDivElement>(null);

	const role = boardRoles.find((role) => role.name === BoardRoleDtoNameEnum.EDITOR);

	useEscape(
		true,
		(e) => {
			if (onEscape) {
				e?.stopPropagation();
				onEscape();
			}
		},
		currentUserDropdownRef as MutableRefObject<HTMLElement>
	);

	useOutsideClick([currentUserDropdownRef, outsideClickExcludeRef], () => onEscape && onEscape());

	return (
		<div className={styles.participant} ref={currentUserDropdownRef}>
			{participant.user && (
				<UserInfo user={participant.user} showEmail showJobTitle showDepartment />
			)}
			<div className={classNames(helpersStyles.separator, styles.separator)} />
			<div className={styles.main}>
				{role && (
					<Role
						className={styles.role}
						description={participant.role?.description ?? role.description}
						isSelected
					/>
				)}
			</div>
		</div>
	);
};
