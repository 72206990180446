import { createEvent, createEffect, createStore, sample, forward } from 'effector';
import { createGate } from 'effector-react';

import { GetNotificationsRequest, notificationsRequests } from '@shared/api';

const reset = createEvent();

const getHasUnread = createEvent();
const getHasUnreadFx = createEffect(notificationsRequests.getNotificationsHasUnread);

const markAllAsRead = createEvent();
const markAllAsReadFx = createEffect(notificationsRequests.setNotificationsAsRead);

const $hasUnread = createStore<boolean>(false).reset(reset);

/* *
 * Гейт
 */
const NotificationHasUnreadGate = createGate<GetNotificationsRequest>();

forward({
	from: NotificationHasUnreadGate.open,
	to: getHasUnread,
});

forward({
	from: NotificationHasUnreadGate.close,
	to: reset,
});

/* *
 * Сэмплы
 */
sample({
	clock: getHasUnread,
	target: getHasUnreadFx,
});

sample({
	clock: getHasUnreadFx.doneData,
	target: $hasUnread,
});

/* *
 * Отметить все как прочитанные
 */
sample({
	clock: markAllAsRead,
	target: markAllAsReadFx,
});

sample({
	clock: markAllAsReadFx.done,
	target: getHasUnreadFx,
});

export const model = {
	$hasUnread,
	getHasUnread,
	markAllAsRead,
	NotificationHasUnreadGate,
};
