import { FileDto, UploadFilesOrFileLinksRequest } from '@shared/api';
import { fetcher } from '@shared/api/config';

export const uploadFilesOrFileLinks = (body: UploadFilesOrFileLinksRequest) =>
	fetcher<FileDto[]>({
		url: '/files/upload',
		method: 'POST',
		body,
	});

export const downloadFileById = (id: string) =>
	fetcher<FileDto[]>({
		url: `/files/${id}`,
		method: 'GET',
		responseType: 'blob',
	});

export const filesRequests = {
	uploadFilesOrFileLinks,
	downloadFileById,
};
