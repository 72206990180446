import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { Button, Icon, Typo } from '@shared/ui';

import styles from '../../styles.module.scss';

interface ConfirmModalHeadProps {
	title: React.ReactNode;
	size?: 'sm' | 'md';
	onClickBack?: () => void;
	onClickClose?: () => void;
}

export const ConfirmModalHead: FC<PropsWithChildren<ConfirmModalHeadProps>> = ({
	title,
	onClickBack,
	onClickClose,
	size = 'md',
}) => {
	return (
		<div className={classnames(styles.head, styles[size])}>
			{onClickBack && (
				<Button
					design="transparent"
					color="secondary"
					className={styles.headBackBtn}
					onlyIcon={<Icon id="chevron-left" />}
					onClick={onClickBack}
				/>
			)}
			<Typo design="text-14-medium">{title}</Typo>
			{onClickClose && (
				<Button
					size="sm"
					design="transparent"
					color="secondary"
					className={styles.headCloseBtn}
					onlyIcon={<Icon id="close" />}
					onClick={onClickClose}
				/>
			)}
		</div>
	);
};
