import {
	createEvent,
	createEffect,
	createStore,
	sample,
	restore,
	combine,
	forward,
} from 'effector';
import { createGate } from 'effector-react';
import { reset } from 'patronum';

import { GetTagListRequest, TagDto, TagDtoTypeEnum, tagsRequests } from '@shared/api';

const $tagList = createStore<TagDto[]>([]);
const $tagListFull = createStore<TagDto[]>([]);

const getTagList = createEvent<Nullable<GetTagListRequest>>();
const getTagListFx = createEffect(tagsRequests.getTagList);

const revalidateTagList = createEvent();

const setTagListSearch = createEvent<string>();
const resetSearch = createEvent();

const $tagListSearch = createStore<string>('').reset(resetSearch);

const $tagListConfig = createStore<GetTagListRequest | {}>({});
const $tagListError = restore<Error>(getTagListFx.failData, null);

const $tagListStatus = combine({
	loading: getTagListFx.pending,
	data: $tagList,
	full: $tagListFull,
	error: $tagListError,
	search: $tagListSearch,
});

const TagListGate = createGate<GetTagListRequest>();

forward({
	from: TagListGate.open,
	to: getTagList,
});

sample({
	clock: revalidateTagList,
	source: { config: $tagListConfig, search: $tagListSearch },
	fn: ({ config, search }) => ({
		...config,
		search: search.length > 2 ? search : undefined,
	}),
	target: getTagListFx,
});

sample({
	clock: getTagList,
	source: $tagListSearch,
	fn: (source, clock) => ({
		...clock,
		search: source.length > 2 ? source : undefined,
	}),
	target: [$tagListConfig, getTagListFx],
});

sample({
	clock: getTagListFx.doneData,
	target: $tagList,
});

sample({
	clock: getTagListFx.doneData,
	source: $tagListSearch,
	filter: (search) => search?.length === 0,
	fn: (_, clock) =>
		Array.isArray(clock)
			? clock.filter(({ type }) => type !== TagDtoTypeEnum.SYSTEM_WITHOUT_TAG)
			: clock,
	target: $tagListFull,
});

sample({
	clock: setTagListSearch,
	source: { config: $tagListConfig, search: $tagListSearch },
	filter: ({ search: prevSearch }, search) =>
		search.length > 2 || (prevSearch.length > 2 && search.length <= 2),
	fn: ({ config }, search) => ({
		...config,
		search: search.length > 2 ? search : undefined,
	}),
	target: getTagListFx,
});

sample({
	clock: setTagListSearch,
	target: $tagListSearch,
});

reset({
	clock: TagListGate.close,
	target: [$tagList, $tagListFull, $tagListSearch, $tagListConfig, $tagListError],
});

export const model = {
	TagListGate,
	getTagList,
	revalidateTagList,
	$tagListStatus,
	$tagListConfig,
	$tagList,
	$tagListFull,
	$tagListSearch,
	setTagListSearch,
	resetSearch,
};
