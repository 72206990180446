import { useStore } from 'effector-react';
import React, { useRef, useState, Dispatch, SetStateAction } from 'react';

import { BoardRoleDto, BoardRoleDtoNameEnum, UserDto } from '@shared/api';
import { Button, Icon, Dropdown, UserInfo } from '@shared/ui';

import { boardModel } from '@entities/boards';
import { Roles } from '@entities/roles-component';

import styles from './styles.module.scss';

interface AddParticipantListItemProps {
	user: UserDto;
	boardRoles: BoardRoleDto[];
	setActiveUserId: Dispatch<SetStateAction<Nullable<string>>>;
	isLoading: boolean;
	disableRolesChanging?: boolean;
}

export const AddParticipantListItem = ({
	user,
	boardRoles,
	setActiveUserId,
	isLoading,
	disableRolesChanging,
}: AddParticipantListItemProps) => {
	const listItemRef = useRef<HTMLLIElement>(null);
	const ddBtnRef = useRef<HTMLButtonElement>(null);
	const [open, setOpen] = useState(false);
	const board = useStore(boardModel.$board);

	const viewer = boardRoles.find(
		(role) => role.name === BoardRoleDtoNameEnum.VIEWER
	) as BoardRoleDto;

	const participantExist = board?.participants?.find(
		(participant) => participant.user.id === user.id
	);

	const closeAndReturnFocusToButton = () => {
		setTimeout(() => {
			setOpen(false);
			const button = listItemRef?.current?.querySelector<HTMLButtonElement>(
				'#add-participant-to-board-button'
			);
			button?.focus();
		}, 100);
	};

	return (
		<li className={styles.listItem} ref={listItemRef}>
			<UserInfo user={user} showJobTitle nameInOneLine />
			{participantExist ? (
				<Dropdown
					portal
					delay={0}
					isOpen={open}
					visibilityHandler={setOpen}
					onScrollCloseThreshold={20}
					openOnButtonClick={false}
					closeOnClickOutside={false}
					dropdownId="add-participant-to-board"
					placement="bottomEnd"
					targetButton={
						<Button
							ref={ddBtnRef}
							id="add-participant-to-board-button"
							className={styles.button}
							size="sm"
							design="transparent"
							iconRight={
								isLoading ? (
									<Icon id="loading" className={styles.actionButtonLoading} />
								) : open ? (
									<Icon id="top" />
								) : (
									<Icon id="bottom" />
								)
							}
							onClick={() => setOpen((prevState) => !prevState)}
							disabled={isLoading}>
							{participantExist.role.description}
						</Button>
					}
					dropdownData={(props, ref) => (
						<Roles
							boardRoles={boardRoles}
							setRole={(role) => {
								boardModel.setRoleParticipant({
									participantId: participantExist.id as string,
									roleId: role.id as string,
								});
								setActiveUserId(user.id);
								closeAndReturnFocusToButton();
							}}
							removeParticipant={() => {
								boardModel.removeParticipant(participantExist.id as string);
								setActiveUserId(user.id);
								closeAndReturnFocusToButton();
							}}
							selectedRole={participantExist.role.name}
							onEscape={closeAndReturnFocusToButton}
							focusOnMount
							outsideClickExcludeRef={ddBtnRef}
							disableRolesChanging={disableRolesChanging}
						/>
					)}
				/>
			) : (
				<Button
					id="add-participant-to-board-button"
					onClick={() => {
						boardModel.addParticipant({
							boardId: board?.id ?? '',
							participant: { user, role: viewer, emailNotificationEnabled: true, starred: false },
						});
						setActiveUserId(user.id);
						closeAndReturnFocusToButton();
					}}
					className={styles.button}
					size="sm"
					design="transparent"
					iconLeft={
						isLoading ? (
							<Icon id="loading" className={styles.actionButtonLoading} />
						) : (
							<Icon id="plus" />
						)
					}
					disabled={isLoading}>
					Добавить
				</Button>
			)}
		</li>
	);
};
