import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useStore, useGate } from 'effector-react';
import { isEqual } from 'lodash';
import React, { FC, useState, useRef, MutableRefObject, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { object } from 'yup';

import { BoardParticipantDto } from '@shared/api';
import { BoardDto, BoardWithViewDto, CreateBoardRequest } from '@shared/api';
import { Colors } from '@shared/lib/constants';
import { useOutsideClick, useEscape } from '@shared/lib/hooks';
import { commonValidators } from '@shared/lib/validation';
import {
	Button,
	ModalTextarea,
	modalsModel,
	Dropdown,
	AvatarsGroup,
	MoreButton,
	AvatarUser,
	RoundColor,
	BaseModal,
} from '@shared/ui';

import { useBoardRoles } from '@entities/board-roles';
import { useCurrentUser } from '@entities/current-user';

import { createBoardFeature, updateBoardFeature } from '@features/board';
import { ParticipantDropdown } from '@features/board-participant-dropdown';
import { ParticipantsSearchList } from '@features/participants-search-list';

import { participantsModel } from '@widgets/board-modal/model';

import { SearchParticipants } from '../search-participants';

import styles from './styles.module.scss';

interface BoardFormValues extends Omit<CreateBoardRequest, 'participants'> {}

interface BoardModalProps extends BoardFormValues {
	mode?: 'create' | 'edit';
	board?: BoardDto | BoardWithViewDto;
	revalidateList?: boolean;
}

const Component: FC<BoardModalProps> = ({
	mode = 'create',
	board: boardWithView,
	revalidateList = false,
}) => {
	const board = useMemo(() => {
		if (!boardWithView) return null;
		const copy = { ...(boardWithView as BoardWithViewDto) };
		delete copy.boardView;
		return copy as BoardWithViewDto;
	}, [boardWithView]);

	useGate(participantsModel.boardModalGate, board);

	const { currentUser } = useCurrentUser();
	const { boardRoles } = useBoardRoles();

	const isBoardAuthor = board?.author?.id === currentUser?.id;

	const participants = useStore(participantsModel.$participants);
	const selectedParticipant = useStore(participantsModel.$selectedParticipant);

	const participantDropdownRef = useRef<HTMLDivElement>(null);
	const listParticipantsDropdownRef = useRef<HTMLDivElement>(null);

	const [participantDropdownOpen, setParticipantDropdownOpen] = useState(false);
	const [participantDropdownPosition, setParticipantDropdownPosition] = useState(0);
	const [stateListParticipantsDropdown, setStateListParticipantsDropdown] = useState(false);

	const {
		register,
		control,
		handleSubmit,
		watch,
		formState: { errors, isValid, isDirty },
	} = useForm<BoardFormValues>({
		defaultValues: {
			name: board?.name || '',
			backgroundColor: board?.backgroundColor || '#CCEDD2',
		},
		resolver: yupResolver(object({ name: commonValidators.limitedRequiredString(500) })),
		mode: 'all',
	});

	const color = watch('backgroundColor');

	const { pending: createBoardPending } = useStore(createBoardFeature.$createBoardStatus);

	const createBoardSubmit = async (data: BoardFormValues) => {
		createBoardFeature.createBoard({ ...data, participants });
	};

	const { pending: updateBoardPending } = useStore(updateBoardFeature.$updateBoardStatus);

	const editBoardSubmit = async (data: BoardFormValues) => {
		const updatedBoard = { ...board, ...data, participants } as BoardDto;
		updateBoardFeature.updateBoard({
			body: updatedBoard,
			revalidateList,
		});
	};

	useEscape(
		true,
		(e) => {
			e?.stopPropagation();
			setParticipantDropdownOpen(false);
		},
		participantDropdownRef as MutableRefObject<HTMLElement>
	);

	useOutsideClick(participantDropdownRef, () => setParticipantDropdownOpen(false));

	useEscape(
		true,
		(e) => {
			e?.stopPropagation();
			setStateListParticipantsDropdown(false);
		},
		listParticipantsDropdownRef as MutableRefObject<HTMLElement>
	);

	useOutsideClick(listParticipantsDropdownRef, () => setStateListParticipantsDropdown(false));

	const creator = mode === 'edit' ? board?.author : currentUser;
	const names = participants.map((name) => name.user);
	const namesWithCreator =
		creator && names ? [{ ...creator, isCurrentUser: true }, ...names] : names || [];

	const setOpenParticipantDropdown = (event: AvatarUser, index: number) => {
		participantsModel.setSelectedParticipant(event);
		setParticipantDropdownPosition(index);
		setParticipantDropdownOpen(true);
	};

	const participantsWithAuthor = useMemo(() => {
		return [...participants, { user: creator }].sort((a, b) =>
			a?.user?.fullName &&
			b?.user?.fullName &&
			a?.user?.fullName.toLowerCase() > b?.user?.fullName.toLowerCase()
				? 1
				: -1
		) as Partial<BoardParticipantDto>[];
	}, [creator, participants]);

	const isDirtyForm = isDirty || !isEqual(board?.participants, participants);

	return (
		<BaseModal design="md" size={388} initialFocus={false} showCloseBtn={false}>
			<div className={styles.smPlusModal}>
				<Controller
					name="name"
					control={control}
					render={({ field: { name, onChange, onBlur, value } }) => (
						<ModalTextarea
							name={name}
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							placeholder="Название доски"
							error={errors.name?.message}
						/>
					)}
				/>

				<div className={classNames(styles.performers, styles.block)}>
					<div className={styles.performersWrap}>
						<h3 className={classNames('h3')}>Участники:</h3>
						<div className={styles.participants}>
							<Dropdown
								ref={participantDropdownRef}
								isOpen={participantDropdownOpen}
								openOnButtonClick={false}
								closeOnClickOutside={false}
								placement="bottomEnd"
								customCoordinates={{
									right: `${
										names.length > 4
											? (4 - participantDropdownPosition) * 24
											: (names.length - participantDropdownPosition) * 24 - 4
									}px`,
								}}
								targetButton={
									<AvatarsGroup
										onAvatarClick={setOpenParticipantDropdown}
										names={namesWithCreator.slice(0, 5)}
									/>
								}
								dropdownData={(props, ref) => (
									<ParticipantDropdown
										participant={selectedParticipant}
										boardRoles={boardRoles}
										setRole={(role) => {
											participantsModel.setRoleParticipant(role);
											setParticipantDropdownOpen(false);
										}}
										remove={(id) => {
											participantsModel.removeParticipant(id);
											setParticipantDropdownOpen(false);
										}}
										disableRolesChanging={!isBoardAuthor && mode !== 'create'}
									/>
								)}
							/>
							{names.length > 4 && (
								<Dropdown
									ref={listParticipantsDropdownRef}
									isOpen={stateListParticipantsDropdown}
									openOnButtonClick={false}
									placement="rightStart"
									customCoordinates={{ top: '-93px' }}
									targetButton={
										<MoreButton
											onClick={() => setStateListParticipantsDropdown(true)}
											count={names.length - 4}
										/>
									}
									dropdownData={(props, ref) => (
										<ParticipantsSearchList
											title="Участники"
											participants={participantsWithAuthor}
											setRole={(role) => participantsModel.setRoleParticipant(role)}
											remove={(id) => participantsModel.removeParticipant(id)}
											mode={mode}
											disableRolesChanging={!isBoardAuthor && mode !== 'create'}
										/>
									)}
								/>
							)}
						</div>
					</div>
					<div className={styles.searchUsers}>
						<SearchParticipants />
					</div>
				</div>
				<div className={classNames(styles.blockLast, styles.block)}>
					<h3 className={classNames('h3')}>Цвет:</h3>

					<div className={styles.colorsWrap}>
						<div className={styles.board}>
							<p style={{ background: color }} className={styles.boardHead} />
							<p>
								<span className={styles.boardRow} />
								<span className={styles.boardRow} />
								<span className={styles.boardRow} />
							</p>
							<span className={styles.boardRow} />
						</div>
						<div className={styles.boardColors}>
							{Object.values(Colors).map((colorItem) => (
								<RoundColor
									key={colorItem}
									color={colorItem}
									value={colorItem}
									{...register('backgroundColor')}
								/>
							))}
						</div>
					</div>
				</div>
				<div className={styles.btnWrap}>
					<Button
						onClick={() => modalsModel.closeLastModal()}
						size="md"
						design="filled"
						color="secondary"
						type="button">
						Отменить
					</Button>
					{mode === 'create' && (
						<Button
							onClick={handleSubmit(createBoardSubmit)}
							size="md"
							design="filled"
							color="primary"
							type="button"
							disabled={!isValid}
							isLoading={createBoardPending}>
							Создать доску
						</Button>
					)}
					{mode === 'edit' && (
						<Button
							onClick={handleSubmit(editBoardSubmit)}
							size="md"
							design="filled"
							color="primary"
							type="button"
							disabled={!isValid || !isDirtyForm}
							isLoading={updateBoardPending}>
							Сохранить
						</Button>
					)}
				</div>
			</div>
		</BaseModal>
	);
};

export const BoardModal = {
	name: 'BOARD_MODAL',
	Component,
};

export const createBoard = () => {
	modalsModel.pushModal({
		name: 'BOARD_MODAL',
		data: {},
	});
};

export const editBoard = (board?: BoardWithViewDto, revalidateList?: boolean) => {
	modalsModel.pushModal({
		name: 'BOARD_MODAL',
		data: {
			mode: 'edit',
			board,
			revalidateList,
		},
	});
};
