import classnames from 'classnames';
import React, { FC } from 'react';

import {
	NotificationDto,
	NotificationDtoTypeEnum,
	TaskApprovalDto,
	UserDto,
} from '@shared/api/model/models';
import { Button, Icon } from '@shared/ui';

import { NotificationApproval, NotificationReassign } from '@entities/notifications';

import styles from '../styles.module.scss';

export interface NotificationToolsProps {
	notification: NotificationDto;
	goToTask?: () => void;
	goToBoard?: () => void;
	acceptReassign?: () => void;
	declineReassign?: () => void;
	retryReassign?: () => void;
	approveTask?: () => Promise<Nullable<TaskApprovalDto>>;
	disapproveTask?: (data: Partial<TaskApprovalDto>) => Promise<Nullable<TaskApprovalDto>>;
	viewer: Nullable<UserDto>;
	userIsSender?: boolean;
	userIsConsumer?: boolean;
}

export const NotificationTools: FC<NotificationToolsProps> = ({
	notification,
	goToTask,
	goToBoard,
	acceptReassign,
	declineReassign,
	retryReassign,
	approveTask,
	disapproveTask,
	viewer,
}) => {
	switch (notification.type) {
		/* *
		 * Уведомления по задачам
		 */
		case NotificationDtoTypeEnum.REMINDER:
		case NotificationDtoTypeEnum.TASK_INVITE:
		case NotificationDtoTypeEnum.TASK_CREATED:
		case NotificationDtoTypeEnum.TASK_UPDATED_ASSIGNEE:
		case NotificationDtoTypeEnum.TASK_UPDATED_ASSIGNEE_ADDED_PRIVATE:
		case NotificationDtoTypeEnum.TASK_UPDATED_DEADLINES_ADDED:
		case NotificationDtoTypeEnum.TASK_UPDATED_DEADLINES_CHANGED:
		case NotificationDtoTypeEnum.TASK_UPDATED_DEADLINES_DELETED:
		case NotificationDtoTypeEnum.TASK_UPDATED_TAGS_ADDED:
		case NotificationDtoTypeEnum.TASK_UPDATED_TAGS_REMOVED:
		case NotificationDtoTypeEnum.TASK_UPDATED_TAGS_CHANGED:
		case NotificationDtoTypeEnum.TASK_UPDATED_FILES_ADDED:
		case NotificationDtoTypeEnum.TASK_UPDATED_FILES_DELETED:
		case NotificationDtoTypeEnum.TASK_UPDATED_TITLE:
		case NotificationDtoTypeEnum.TASK_UPDATED_DESCRIPTION:
		case NotificationDtoTypeEnum.TASK_UPDATED_CO_ASSIGNEES_ADDED:
		case NotificationDtoTypeEnum.TASK_UPDATED_CO_ASSIGNEES_DELETED:
		case NotificationDtoTypeEnum.TASK_STATUS_UPDATE_NEW:
		case NotificationDtoTypeEnum.TASK_STATUS_UPDATE_STOPPED:
		case NotificationDtoTypeEnum.TASK_STATUS_UPDATE_IN_PROGRESS:
		case NotificationDtoTypeEnum.TASK_STATUS_UPDATE_REVIEW:
		case NotificationDtoTypeEnum.TASK_STATUS_UPDATE_DONE:
		case NotificationDtoTypeEnum.TASK_ARCHIVED:
		case NotificationDtoTypeEnum.TASK_RECOVERY:
		case NotificationDtoTypeEnum.PERMISSION_UPDATE:
		case NotificationDtoTypeEnum.SUBTASK_ARCHIVED:
		case NotificationDtoTypeEnum.LINKED_SUBTASK_DETACHED:
		case NotificationDtoTypeEnum.SUBTASK_CONVERTED:
		case NotificationDtoTypeEnum.BOARD_TASK_CREATED:
			return (
				<div className={styles.buttons}>
					<Button
						color="secondary"
						className={classnames(styles.button, styles.buttonWithArrow)}
						iconRight={<Icon id="arrow-right" />}
						onClick={() => goToTask && goToTask()}>
						Перейти
					</Button>
				</div>
			);
		case NotificationDtoTypeEnum.TASK_REASSIGN_OPEN:
		case NotificationDtoTypeEnum.TASK_REASSIGN_ACCEPTED:
		case NotificationDtoTypeEnum.TASK_REASSIGN_DECLINED:
			return notification?.taskReassignRequest?.active ? (
				<div className={styles.buttons}>
					<NotificationReassign
						notification={notification}
						goToTask={goToTask}
						acceptReassign={acceptReassign}
						declineReassign={declineReassign}
						retryReassign={retryReassign}
						viewer={viewer}
					/>
				</div>
			) : null;
		case NotificationDtoTypeEnum.TASK_APPROVAL:
		case NotificationDtoTypeEnum.REVIEW_FINISHED:
			return notification?.taskApproval?.active ? (
				<div className={styles.buttons}>
					<NotificationApproval
						notification={notification}
						goToTask={goToTask}
						approveTask={approveTask}
						disapproveTask={disapproveTask}
						viewer={viewer}
					/>
				</div>
			) : null;
		/* *
		 * Уведомления по доскам
		 */
		case NotificationDtoTypeEnum.BOARD_INVITE:
		case NotificationDtoTypeEnum.BOARD_INVITE_PRIVATE:
		case NotificationDtoTypeEnum.BOARD_ARCHIVED:
		case NotificationDtoTypeEnum.BOARD_RECOVERY:
		case NotificationDtoTypeEnum.BOARD_UPDATED:
		case NotificationDtoTypeEnum.BOARD_ROLE_UPDATED_VIEWER:
		case NotificationDtoTypeEnum.BOARD_ROLE_UPDATED_EDITOR:
			return (
				<div className={styles.buttons}>
					<Button
						color="secondary"
						className={classnames(styles.button, styles.buttonWithArrow)}
						iconRight={<Icon id="arrow-right" />}
						onClick={() => goToBoard && goToBoard()}>
						Перейти
					</Button>
				</div>
			);
		case NotificationDtoTypeEnum.BOARD_KICK:
		case NotificationDtoTypeEnum.BOARD_KICK_PRIVATE:
			return null;
		default:
			return null;
	}
};
