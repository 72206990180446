import React from 'react';

import { TaskPreviewDto } from '@shared/api';
import { modalsModel } from '@shared/ui';

import { removeTaskModel } from '@entities/task';

export const removeTask = (task: TaskPreviewDto) => {
	if (!task) return;

	modalsModel.pushModal({
		name: 'REMOVE_TASK_MODAL',
		data: {
			title: 'Удаление задачи',
			text: (
				<>
					Задача <b>{task.title}</b> будет безвозвратно удалена.
				</>
			),
			onAccept: () => {
				if (!task.id || !task.title) return;

				removeTaskModel.removeTask({
					taskId: task.id,
					title: task.title,
					closeModalOnRemove: true,
				});
			},
		},
	});
};
