import { useStore } from 'effector-react';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import {
	Button,
	Icon,
	Select,
	SelectOption,
	Skeleton,
	tooltipEventHandlersFactory,
	TooltipsEventHandlers,
} from '@shared/ui';

import { boardViewModel, toggleView, toggleViewTransposition, boardModel } from '@entities/boards';

import { TaskBoardPageTableSettings } from './page-table-settings';
import { PageTags } from './page-tags';
import styles from './styles.module.scss';

const boardViewOptions: SelectOption[] = [
	{ label: 'Классический вид', value: 'CLASSIC' },
	{ label: 'Табличный вид', value: 'CUSTOM' },
];

export const TaskBoardPageViews = () => {
	const { boardId } = useParams();

	const boardStatus = useStore(boardModel.$boardStatus);
	const boardView = useStore(boardViewModel.$store);
	const boardViewValue = useMemo(
		() => boardViewOptions.find(({ value }) => value === boardView?.type) || boardViewOptions[0],
		[boardView?.type]
	);

	const handleTableViewChange = useCallback(() => {
		boardViewModel.setBoardView(toggleView(boardView));
	}, [boardView]);

	const handleTableTransposition = useCallback(() => {
		boardViewModel.setBoardView(toggleViewTransposition(boardView));
	}, [boardView]);

	const showTranspositionButton = boardView?.type === 'CUSTOM';
	const transpositionButtonTooltipEventHandlers: TooltipsEventHandlers = useMemo(
		() =>
			showTranspositionButton
				? tooltipEventHandlersFactory('Повернуть таблицу', { align: null, delayIn: 400 })
				: {},
		[showTranspositionButton]
	);

	const tableSettings = useMemo(
		() => <TaskBoardPageTableSettings onClick={handleTableTransposition} />,
		[handleTableTransposition]
	);

	return (
		<div className={styles.viewsWrapper}>
			{boardStatus === 'done' && (
				<>
					{showTranspositionButton && (
						<Button
							onClick={handleTableTransposition}
							color="secondary"
							onlyIcon={<Icon id="rotate" className="svg-path" />}
							{...transpositionButtonTooltipEventHandlers}
						/>
					)}
					<PageTags boardView={boardView} boardId={boardId} />

					<Select
						className={styles.boardViewSelect}
						onChange={handleTableViewChange}
						options={boardViewOptions}
						value={boardViewValue}
						initial={boardViewValue}
						additionalElement={tableSettings}
						additionalElementAncestorValue="CUSTOM"
						closeOnAdditionalElementClick={false}
						placement="bottomEnd"
					/>
					<Button
						to={`/boards/${boardId}/archive`}
						color="secondary"
						iconLeft={<Icon id="clock" />}>
						Архив задач
					</Button>
				</>
			)}
			{boardStatus === 'pending' && <Skeleton width={148} height={32} />}
		</div>
	);
};
