import React, { FC } from 'react';

import { Skeleton } from '@shared/ui';

import { TaskRow } from '../../task-row';
import styles from '../styles.module.scss';

export const TaskEventsSkeleton: FC = () => {
	return (
		<>
			<TaskRow className={styles.head} title={() => <Skeleton width={83} height={16} />}>
				<Skeleton width={124} height={24} />
			</TaskRow>
			<Skeleton width={72} height={16} margin="16px auto" />
			<div className={styles.mock}>
				<Skeleton className={styles.mockAvatar} width={32} height={32} />
				<div className={styles.mockContent}>
					<Skeleton width={256} height={12} />
					<Skeleton width={34} height={12} />
				</div>
			</div>
		</>
	);
};
