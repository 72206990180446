import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import React, { FC, memo, useMemo, useState } from 'react';

import { TagDto } from '@shared/api';
import { checkEscape } from '@shared/lib/utils';
import {
	Button,
	Dropdown,
	Icon,
	tooltipEventHandlersFactory,
	TooltipsEventHandlers,
	Typo,
} from '@shared/ui';

import styles from './styles.module.scss';

interface TagsListItemProps {
	tag: TagDto;
	editTag?: (tag: TagDto) => void;
	deleteTag?: (tag: TagDto) => void;
	onClick?: (tag: TagDto) => void;
	className?: string;
	isActive?: boolean;
	editable?: boolean;
}

export const TagsManagerListItem: FC<TagsListItemProps> = memo(
	({ tag, editTag, deleteTag, onClick, editable, className, isActive }) => {
		const [hasEllipsis, setHasEllipsis] = useState(false);
		const firstBtnRefCallback = (node: HTMLButtonElement) => setTimeout(() => node?.focus(), 100);

		const color = useMemo(
			() => (tag.color = tag.color === 'gray' ? 'var(--tag-grey)' : tag.color),
			[tag]
		);

		const tagView = (
			<>
				<span className={styles.listItemText}>
					<span className={styles.listItemColorDot} style={{ backgroundColor: color }} />
					<span
						className={styles.listItemString}
						ref={(node) => {
							if (node) setHasEllipsis(node.offsetWidth < node.scrollWidth);
						}}>
						{tag.name}
					</span>
				</span>
				{isActive && <Icon className={styles.listItemCheck} id="done" />}
			</>
		);

		const tooltipEventHandlers: TooltipsEventHandlers = useMemo(
			() => (hasEllipsis ? tooltipEventHandlersFactory(tag.name) : {}),
			[hasEllipsis, tag.name]
		);

		return (
			<div className={classnames(className, styles.listItem)}>
				{!onClick && (
					<Typo
						design="text-13-medium"
						className={styles.listItemContent}
						{...tooltipEventHandlers}>
						{tagView}
					</Typo>
				)}
				{onClick && (
					<button
						onClick={() => onClick(tag)}
						className={styles.listItemContent}
						{...tooltipEventHandlers}>
						{tagView}
					</button>
				)}
				{editable && (
					<div className={styles.listItemTools}>
						<Dropdown
							portal={true}
							closeOnEsc={true}
							dropdownId="tags-list-portal"
							placement="bottomEnd"
							className={styles.listItemDropdown}
							targetButton={
								<Button
									size="sm"
									design="transparent"
									color="secondary"
									onlyIcon={<Icon id="kebab" />}
									className={styles.listItemToolsToggle}
								/>
							}
							dropdownData={({ setOpen }, ref) => {
								return (
									<FocusTrap
										focusTrapOptions={{
											initialFocus: false,
											allowOutsideClick: true,
											checkCanFocusTrap: () =>
												new Promise<void>((resolve) => setTimeout(resolve, 100)),
										}}>
										<div
											className={styles.listItemToolsDropMenu}
											onKeyDown={(e) => {
												if (checkEscape(e)) {
													e?.stopPropagation();
													setOpen(false);
												}
											}}>
											<Button
												ref={firstBtnRefCallback}
												className={styles.listItemToolsDropMenuItem}
												onClick={() => {
													editTag && editTag(tag);
													setOpen(false);
												}}
												size="sm"
												design="transparent"
												iconLeft={<Icon id="edit" />}>
												Изменить
											</Button>
											<Button
												className={styles.listItemToolsDropMenuItem}
												onClick={() => {
													deleteTag && deleteTag(tag);
													setOpen(false);
												}}
												size="sm"
												design="transparent"
												iconLeft={
													<Icon id="trash" style={{ fill: 'currentColor', stroke: 'none' }} />
												}>
												Удалить
											</Button>
										</div>
									</FocusTrap>
								);
							}}
						/>
					</div>
				)}
			</div>
		);
	}
);
