import { createEvent, createEffect, sample } from 'effector';

import { notificationsRequests } from '@shared/api';

import { notificationsListModel } from '../notifications-list';
import { notificationsReadedModel } from '../notifications-readed';

const markAllAsHidden = createEvent();
const markAllAsHiddenFx = createEffect(notificationsRequests.setNotificationsAsHidden);

/* *
 * Отметить все как скрытые (будут удалены из выдачи)
 */
sample({
	clock: markAllAsHidden,
	target: markAllAsHiddenFx,
});

sample({
	clock: markAllAsHiddenFx.done,
	target: [notificationsReadedModel.getHasUnread, notificationsListModel.revalidate],
});

export const model = {
	markAllAsHidden,
	pending: markAllAsHiddenFx.pending,
};
