import React from 'react';

import { devLoginPath } from '@shared/lib/constants';

import { Layout } from '@widgets/layout';

import { Boards } from '@pages/boards';
import { ErrorPage } from '@pages/error';
import { TaskBoardPage } from '@pages/task-board-page';
import { TaskUserPage } from '@pages/task-user-page';

import { DevLogin } from './dev-login';
import { DevSignUp } from './dev-signup';
import { Login } from './login';
import { IRouteObject } from './router.types';

const devLoginRoutes: IRouteObject[] = [
	{
		path: devLoginPath,
		private: false,
		element: <DevLogin />,
		layout: Layout,
		title: 'Авторизация',
		isSimple: true,
	},
	{
		path: '/dev-signup',
		private: false,
		element: <DevSignUp />,
		layout: Layout,
		title: 'Авторизация',
		isSimple: true,
	},
];

const loginRoutes: IRouteObject[] = [
	{
		path: '/login/oauth/callback',
		private: false,
		element: <Login />,
		layout: Layout,
		isSimple: true,
	},
];

/**
 * Keys для страниц /my-tasks и /created-by-me нужны для того,
 * чтобы React различал разные страницы и заново их рендерил,
 * чтобы при переходе на новую страницу сбрасывались фильтры
 */
export const routes: IRouteObject[] = [
	/*
	 * Главная страница
	 * зеркалит страницу "Созданные мной"
	 */
	{
		path: '/',
		private: true,
		element: (
			<TaskUserPage title="Созданные мной задачи" baseRoute="/created-by-me" key="/created-by-me" />
		),
		layout: Layout,
	},
	/*
	 * Созданные мной
	 */
	{
		path: '/created-by-me',
		private: true,
		element: (
			<TaskUserPage title="Созданные мной задачи" baseRoute="/created-by-me" key="/created-by-me" />
		),
		layout: Layout,
		children: [
			{
				path: 'task/:taskId',
				element: <></>,
				layout: Layout,
			},
		],
	},
	/**
	 * Архив созданных мной
	 */
	{
		path: '/created-by-me/archive',
		private: true,
		element: (
			<TaskUserPage
				title="Созданные мной задачи"
				baseRoute="/created-by-me"
				key="/created-by-me/archive"
			/>
		),
		layout: Layout,
		children: [
			{
				path: 'task/:taskId',
				element: <></>,
				layout: Layout,
			},
		],
	},
	/*
	 * Мои задачи
	 */
	{
		path: '/my-tasks',
		private: true,
		element: <TaskUserPage title="Мои задачи" baseRoute="/my-tasks" key="/my-tasks" />,
		layout: Layout,
		children: [
			{
				path: 'task/:taskId',
				element: <></>,
				layout: Layout,
			},
		],
	},
	/**
	 * Архив моих задач
	 */
	{
		path: '/my-tasks/archive',
		private: true,
		element: <TaskUserPage title="Мои задачи" baseRoute="/my-tasks" key="/my-tasks/archive" />,
		layout: Layout,
		children: [
			{
				path: 'task/:taskId',
				element: <></>,
				layout: Layout,
			},
		],
	},
	/*
	 * Доски задач
	 */
	{
		path: '/boards',
		private: true,
		element: <Boards />,
		layout: Layout,
		children: [
			/*
			 * Архив досок задач
			 */
			{
				path: 'archive',
				element: <></>,
				layout: Layout,
				children: [
					/*
					 * Просмотр доски в архиве
					 */
					{
						path: 'view/:boardId',
						element: <></>,
						layout: Layout,
					},
				],
			},
			/*
			 * Просмотр/редактирование доски
			 */
			{
				path: 'edit/:boardId',
				element: <></>,
				layout: Layout,
			},
		],
	},
	/*
	 * Доска задач
	 */
	{
		path: '/boards/:boardId',
		private: true,
		element: <TaskBoardPage />,
		layout: Layout,
		children: [
			/*
			 * Архив доски задач
			 */
			{
				path: ':slug',
				element: <></>,
				layout: Layout,
				children: [
					/*
					 * Задача из архива доски задач
					 */
					{
						path: 'task/:taskId',
						element: <></>,
						layout: Layout,
					},
				],
			},
			/*
			 * Задача на доске
			 */
			{
				path: 'task/:taskId',
				element: <></>,
				layout: Layout,
			},
		],
	},
	/*
	 * Архив досок задач
	 */
	{
		path: '/boards/archive/:boardId',
		private: true,
		element: <TaskBoardPage />,
		layout: Layout,
		children: [
			/*
			 * Задача на архивной доске
			 */
			{
				path: 'task/:taskId',
				element: <></>,
				layout: Layout,
			},
		],
	},
	/*
	 * Для прямого перехода к уведомлениям
	 * Попап открывается на странице "Мои задачи"
	 */
	{
		path: '/notifications',
		private: false,
		element: <TaskUserPage title="Мои задачи" baseRoute="/my-tasks" key="/my-tasks" />,
		layout: Layout,
		children: [
			{
				path: ':noticeId',
				element: <></>,
				layout: Layout,
			},
		],
	},
	/*
	 * 404
	 */
	{
		path: '*',
		private: true,
		element: (
			<ErrorPage
				title="Страница не найдена"
				text={
					<>
						<p>Что-то пошло не так, этой страницы не существует.</p>
						<p>Воспользуйтесь боковым меню для навигации по сервису.</p>
					</>
				}
			/>
		),
		layout: Layout,
		title: 'Ошибка 404',
	},
];

export const routeConfig: IRouteObject[] =
	process.env.REACT_APP_STAND === 'PROD'
		? [...routes, ...loginRoutes]
		: [...routes, ...devLoginRoutes];
