import { createEvent, createEffect, createStore, sample, forward } from 'effector';
import { createGate } from 'effector-react';
import { status } from 'patronum';

import { BoardRoleDtoNameEnum, BoardParticipantDto, BoardWithViewDto } from '@shared/api';
import {
	boardsRequests,
	GetBoardByIdRequest,
	boardParticipantsRequests,
	AddParticipantParams,
	SetRoleParticipantParams,
} from '@shared/api/requests';
import { noticesModel, NoticeType } from '@shared/ui';

import { boardViewModel } from '@entities/boards/model/board-view';

import { toggleTagsOnTaskModel } from '../../toggle-tags-on-tasks';

interface TaskBoardGateProps extends GetBoardByIdRequest {
	isResetBoard?: boolean;
}

const addParticipant = createEvent<AddParticipantParams>();
const addParticipantFx = createEffect(boardsRequests.addParticipant);

const setRoleParticipant = createEvent<SetRoleParticipantParams>();
const setRoleParticipantFx = createEffect(boardParticipantsRequests.setRoleParticipant);

const removeParticipant = createEvent<string>();
const removeParticipantFx = createEffect(boardParticipantsRequests.removeParticipant);

const getBoardById = createEvent<GetBoardByIdRequest>();
const getBoardByIdFx = createEffect(boardsRequests.getBoardById);
const updateBoardByIdFx = createEffect(boardsRequests.getBoardById);

const getBoardByIdShallow = createEvent<GetBoardByIdRequest>();
const getBoardByIdShallowFx = createEffect(boardsRequests.getBoardById);

const resetBoard = createEvent();
const revalidateBoard = createEvent();

const $board = createStore<BoardWithViewDto | null>(null).reset(resetBoard);

const $boardConfig = createStore<GetBoardByIdRequest | null>(null);

const $removedParticipant = createStore<BoardParticipantDto | null>(null);

const $boardStatus = status({ effect: getBoardByIdFx }).reset(resetBoard);
const $addParticipantStatus = status({ effect: addParticipantFx });
const $removeParticipantStatus = status({ effect: removeParticipantFx });
const $setRoleParticipantStatus = status({ effect: setRoleParticipantFx });

const BoardGate = createGate<GetBoardByIdRequest>();

forward({
	from: BoardGate.state,
	to: getBoardById,
});

forward({
	from: BoardGate.close,
	to: resetBoard,
});

const TaskBoardGate = createGate<TaskBoardGateProps>();

forward({
	from: TaskBoardGate.state,
	to: getBoardByIdShallow,
});

sample({
	clock: TaskBoardGate.close,
	filter: ({ isResetBoard }) => Boolean(isResetBoard),
	target: resetBoard,
});

sample({
	source: BoardGate.status,
	clock: getBoardById,
	filter: (source) => source,
	fn: (_, config) => config,
	target: [$boardConfig, getBoardByIdFx],
});

sample({
	clock: getBoardByIdShallow,
	filter: ({ boardId }) => !!boardId,
	target: [$boardConfig, getBoardByIdShallowFx],
});

sample({
	clock: [getBoardByIdFx.doneData, getBoardByIdShallowFx.doneData],
	target: $board,
});

sample({
	clock: updateBoardByIdFx.doneData,
	target: $board,
});

sample({
	clock: [getBoardByIdFx.doneData, getBoardByIdShallowFx.doneData],
	fn: (board) => board?.boardView || null,
	target: boardViewModel.setCurrentBoardView,
});

sample({
	clock: [getBoardByIdFx.doneData, getBoardByIdShallowFx.doneData],
	fn: (board) => board?.boardView?.showTagsOnTasks ?? null,
	target: [
		toggleTagsOnTaskModel.setShowTagsOnTasks,
		toggleTagsOnTaskModel.$showTagsOnTasksForCompare,
	],
});

sample({
	clock: addParticipant,
	target: addParticipantFx,
});

sample({
	clock: setRoleParticipant,
	target: setRoleParticipantFx,
});

sample({
	source: $board,
	clock: removeParticipant,
	fn: (source, clock) =>
		source?.participants?.find((participant) => participant.id === clock) ||
		({} as BoardParticipantDto),
	target: $removedParticipant,
});

sample({
	clock: removeParticipant,
	target: removeParticipantFx,
});

sample({
	source: $boardConfig,
	clock: [
		addParticipantFx.doneData,
		setRoleParticipantFx.doneData,
		removeParticipantFx.doneData,
		revalidateBoard,
	],
	fn: (source) => {
		return source ?? { boardId: '' };
	},
	target: updateBoardByIdFx,
});

/*
 * Notifications addParticipant, setRoleParticipant, removeParticipant
 */
sample({
	source: $board,
	clock: addParticipantFx.doneData,
	fn: (source, clock) => ({
		type: 'success' as NoticeType,
		text: `"<b>${clock.user.fullName}</b>" добавлен на доску "<b>${source?.name}</b>"`,
	}),
	target: noticesModel.add,
});

sample({
	source: $board,
	clock: setRoleParticipantFx.doneData,
	fn: (source, clock) => ({
		type: 'success' as NoticeType,
		text: `"<b>${clock.user.fullName}</b>" стал ${
			clock.role.name === BoardRoleDtoNameEnum.EDITOR ? 'редактором' : 'наблюдателем'
		} на доске "<b>${source?.name}</b>"`,
	}),
	target: noticesModel.add,
});

sample({
	source: { removeParticipant: $removedParticipant, board: $board },
	clock: removeParticipantFx.doneData,
	fn: ({ removeParticipant, board }) => ({
		type: 'success' as NoticeType,
		text: `"<b>${removeParticipant?.user.fullName}</b>" удален с доски "<b>${board?.name}</b>"`,
	}),
	target: noticesModel.add,
});

sample({
	clock: [addParticipantFx.failData, setRoleParticipantFx.failData, removeParticipantFx.failData],
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	BoardGate,
	TaskBoardGate,
	getBoardById,
	addParticipant,
	setRoleParticipant,
	removeParticipant,
	revalidateBoard,
	$boardStatus,
	$addParticipantStatus,
	$removeParticipantStatus,
	$setRoleParticipantStatus,
	$boardConfig,
	$board,
};
