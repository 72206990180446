import { fetcher } from '@shared/api/config';
import { TaskEventDto, TaskEventPageableResponse } from '@shared/api/model/models';

export type CommentType = 'ALL' | 'SYSTEM' | 'COMMENT';

export interface GetTasksEventsRequest {
	taskId: string;
	page: number;
	type: CommentType;
	size?: number;
	sortBy?: string;
	sortDirection?: 'ASC' | 'DESC';
	filter?: any | null;
}

const getTasksEvents = ({
	taskId,
	page = 1,
	type = 'ALL',
	size = 10,
	sortBy = 'createDate',
	sortDirection = 'ASC',
	filter,
}: GetTasksEventsRequest) =>
	fetcher<TaskEventPageableResponse>({
		url: `/task-events`,
		method: 'GET',
		body: {
			'task-id': taskId,
			page,
			type,
			size,
			'sort-by': sortBy,
			'sort-direction': sortDirection,
			filter: filter || undefined,
		},
	});

export type CreateTaskEventRequest = Partial<TaskEventDto> & {
	taskId: TaskEventDto['taskId'];
};

const createTaskEvent = (payload: CreateTaskEventRequest) =>
	fetcher<TaskEventDto>({
		url: `/task-events`,
		method: 'POST',
		body: payload,
	});

export type UpdateTaskEventRequest = TaskEventDto;

const putTaskStatusEvent = (payload: UpdateTaskEventRequest) =>
	fetcher<TaskEventDto>({
		url: `/task-events`,
		method: 'PUT',
		body: payload,
	});

export type DeleteTaskEventRequest = TaskEventDto;

const deleteTaskStatusEvent = ({ id: taskEventId }: DeleteTaskEventRequest) =>
	fetcher({
		url: `/task-events/${taskEventId}`,
		method: 'DELETE',
	});

export const tasksEvents = {
	getTasksEvents,
	createTaskEvent,
	putTaskStatusEvent,
	deleteTaskStatusEvent,
};
