import React from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { TaskPreviewDto } from '@shared/api';
import { Skeleton } from '@shared/ui';

import { useCurrentUser } from '@entities/current-user';
import { useToggleArchiveTask } from '@entities/task';
import { TaskCard } from '@entities/task';

import styles from './styles.module.scss';

interface TasksGroupProps {
	tasks: TaskPreviewDto[];
	removeTask: (task: TaskPreviewDto) => void;
	isLoading: boolean;
	isBoardAuthor?: boolean;
	userRole?: string;
}

export const TasksGroup = ({
	tasks,
	removeTask,
	isLoading,
	isBoardAuthor,
	userRole,
}: TasksGroupProps) => {
	const { currentUser } = useCurrentUser();

	const { slug } = useParams();
	const navigate = useNavigate();

	const taskCardClickHandler = (id: string) => {
		navigate(`${slug === 'archive' ? `${slug}/` : ''}task/${id}`);
	};

	const { activeTask, toggleArchiveTask, loading } = useToggleArchiveTask();

	const columns: TaskPreviewDto[][] = [[], [], [], [], []];
	tasks.forEach((item, i) => columns[i % 5].push(item));

	return (
		<div className={styles.taskGroup}>
			{isLoading ? (
				<TaskGroupSkeleton />
			) : (
				columns.map((column, i) => (
					<div className={styles.column} key={i}>
						{column.map((task) => {
							const { id = '', ...props } = task;

							return (
								<TaskCard
									key={id}
									id={id}
									onClick={() => taskCardClickHandler(id)}
									removeTask={() => removeTask(task)}
									restoreTask={() => {
										toggleArchiveTask(task);
									}}
									toggleArchiveLoading={loading && activeTask === task.id}
									currentUser={currentUser}
									isBoardAuthor={isBoardAuthor}
									userRole={userRole}
									{...props}
								/>
							);
						})}
					</div>
				))
			)}
		</div>
	);
};

const TaskGroupSkeleton = () => {
	return (
		<>
			{Array(5)
				.fill(null)
				.map((_, i) => (
					<div className={styles.column} key={i}>
						{Array(Math.floor(1 + Math.random() * 3))
							.fill(null)
							.map((_, i) => (
								<Skeleton display="block" width="100%" height="140px" key={i} />
							))}
					</div>
				))}
		</>
	);
};
