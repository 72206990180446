import classNames from 'classnames';
import React, { forwardRef, MutableRefObject } from 'react';

import { UserDto } from '@shared/api/model/userDto';
import { NOT_FOUND_MESSAGE } from '@shared/lib/constants';
import { useFocusTrap } from '@shared/lib/hooks';
import { NotFoundMessage, Scroller } from '@shared/ui';

import { ParticipantsListItem } from '../participants-list-item';

import styles from './styles.module.scss';

interface SearchListProps {
	participants?: UserDto[];
}

export const ParticipantsList = forwardRef<HTMLDivElement, SearchListProps>(
	({ participants }, ref) => {
		useFocusTrap(ref as MutableRefObject<HTMLDivElement>);

		return (
			<div ref={ref}>
				<Scroller
					axis="y"
					className={classNames(
						styles.searchList,
						!participants?.length && styles.notFound,
						'scroller',
						'scrollbar-container'
					)}>
					{participants?.length ? (
						participants.map((participant) => (
							<ParticipantsListItem
								participant={participant}
								key={participant.id}
								id={participant.id}
							/>
						))
					) : (
						<NotFoundMessage>{NOT_FOUND_MESSAGE.employees}</NotFoundMessage>
					)}
				</Scroller>
				<div id="create-board-dropdowns" />
			</div>
		);
	}
);
