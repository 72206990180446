import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TaskDto } from '@shared/api';
import { Button, Dropdown, ConfirmModalHead, ConfirmModalBody } from '@shared/ui';

import { notificationsListModel } from '@entities/notifications';
import { removeTaskModel, useToggleArchiveTask } from '@entities/task';

import styles from './styles.module.scss';

export interface ArchivedTaskButtonsProps {
	task: TaskDto;
	closeModal: (closeAll?: boolean) => void;
	isSubmodal?: boolean;
}

export const ArchivedTaskButtons: FC<ArchivedTaskButtonsProps> = ({
	task,
	closeModal,
	isSubmodal,
}) => {
	const { pathname } = useLocation();
	const isArchiveBoard = pathname.includes('/boards/archive');
	const isArchiveTask = pathname.includes('archive/task');

	const { id, title, archived, authorities, canRecoverFromArchive, canRemoveFromArchive } = task;
	const { toggleArchiveTask, loading } = useToggleArchiveTask();

	const removeStatus = useStore(removeTaskModel.$status);

	const [dropdown, setDropdown] = useState(false);

	const userIsAuthorOrEditor =
		authorities?.isBoardAuthor || authorities?.isBoardEditor || authorities?.isAuthor;

	if (isArchiveBoard) {
		return null;
	}

	return (
		<>
			{(canRecoverFromArchive || userIsAuthorOrEditor) && (
				<Button
					onClick={() => {
						toggleArchiveTask({ id, title, archived }, () => closeModal(isArchiveTask));
					}}
					isLoading={loading}
					size="md"
					design="filled"
					color="primary"
					type="button">
					Восстановить
				</Button>
			)}
			{(canRemoveFromArchive || authorities?.isBoardAuthor) && (
				<Dropdown
					isOpen={dropdown}
					placement="bottomEnd"
					openOnButtonClick={false}
					closeOnClickOutside={false}
					targetButton={
						<Button
							onClick={() => setDropdown((prev) => !prev)}
							size="md"
							design="filled"
							color="secondary"
							type="button">
							Удалить
						</Button>
					}
					dropdownData={(props, ref) => null}
					dropdownElement={
						<FocusTrap
							focusTrapOptions={{
								escapeDeactivates: true,
								allowOutsideClick: true,
								checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
							}}>
							<div className={styles.removeTask}>
								<ConfirmModalHead
									title="Удаление задачи"
									size="sm"
									onClickBack={() => setDropdown(false)}
								/>
								<ConfirmModalBody
									content={
										<>
											Задача <b>{title}</b> будет удалена навсегда без возможности восстановления.
										</>
									}
									onAccept={() => {
										removeTaskModel.removeTask({
											taskId: id,
											title,
											callback: () => {
												setDropdown(false);
												closeModal(isArchiveTask);

												if (isSubmodal) {
													notificationsListModel.revalidate();
												}
											},
										});
									}}
									onDiscard={() => setDropdown(false)}
									acceptLabel="Удалить"
									acceptColor="danger"
									textAlign="left"
									isPending={removeStatus === 'pending'}
								/>
							</div>
						</FocusTrap>
					}
				/>
			)}
		</>
	);
};
