import { TaskPreviewDto, TaskStatusDto, TaskStatusDtoTypeEnum } from '@shared/api';

type TaskStatusDtoTypeKey = keyof typeof TaskStatusDtoTypeEnum;

export interface TaskColumnDto {
	type: TaskStatusDtoTypeKey;
	title: string;
	order: number;
	items: TaskPreviewDto[];
}

export type TaskColumnsRecord = Record<TaskStatusDtoTypeKey, TaskColumnDto>;

export const getClassicColumns = (statuses: TaskStatusDto[]) => {
	return statuses.reduce((columns, status, i) => {
		const type = status.type as TaskStatusDtoTypeKey;
		columns[type] = {
			type,
			title: status.name || '',
			order: i,
			items: [],
		};
		return columns;
	}, {} as TaskColumnsRecord);
};
