export const gregorianRu = {
	name: 'gregorian_ru',
	startYear: 1,
	yearLength: 365,
	epoch: 1721424,
	century: 21,
	weekStartDayIndex: 2,
	getMonthLengths(isLeap: any) {
		return [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	},
	isLeap(year: any) {
		return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
	},
	getLeaps(currentYear: any) {
		if (currentYear === 0) return [];

		let year = currentYear > 0 ? 1 : -1;

		let leaps = [],
			condition = () => (currentYear > 0 ? year <= currentYear : currentYear <= year),
			increase = () => (currentYear > 0 ? year++ : year--);

		while (condition()) {
			if (this.isLeap(year)) leaps.push(year);

			increase();
		}

		return leaps;
	},
	getDayOfYear({ year, month, day }: any) {
		let monthLengths = this.getMonthLengths(this.isLeap(year));

		for (let i = 0; i < month.index; i++) {
			day += monthLengths[i];
		}

		return day;
	},
	getAllDays(date: any) {
		const { year } = date;

		return this.yearLength * (year - 1) + this.leapsLength(year) + this.getDayOfYear(date);
	},
	leapsLength(year: any) {
		return (((year - 1) / 4) | 0) + (-((year - 1) / 100) | 0) + (((year - 1) / 400) | 0);
	},
	guessYear(days: any, currentYear: any) {
		let year = ~~(days / 365.24);

		return year + (currentYear > 0 ? 1 : -1);
	},
};
