import {
	BoardViewDto,
	BoardViewDtoColumnTypeEnum,
	BoardViewDtoRowTypeEnum,
	BoardViewDtoTypeEnum,
} from '@shared/api';

export const toggleView = (view: Nullable<BoardViewDto>): BoardViewDto => {
	switch (view?.type) {
		case BoardViewDtoTypeEnum.CUSTOM:
			return {
				...view,
				type: 'CLASSIC' as BoardViewDtoTypeEnum,
			};
		case BoardViewDtoTypeEnum.CLASSIC:
		default:
			return {
				...view,
				type: 'CUSTOM' as BoardViewDtoTypeEnum,
				rowType: 'TAG' as BoardViewDtoRowTypeEnum,
				columnType: 'STATUS' as BoardViewDtoColumnTypeEnum,
			};
	}
};

export const toggleViewTransposition = (view: Nullable<BoardViewDto>): Nullable<BoardViewDto> => {
	if (view?.type === BoardViewDtoTypeEnum.CUSTOM) {
		switch (view.rowType) {
			case BoardViewDtoRowTypeEnum.TAG:
				return {
					...view,
					type: 'CUSTOM' as BoardViewDtoTypeEnum,
					rowType: 'STATUS' as BoardViewDtoRowTypeEnum,
					columnType: 'TAG' as BoardViewDtoColumnTypeEnum,
				};
			case BoardViewDtoRowTypeEnum.STATUS:
			default:
				return {
					...view,
					type: 'CUSTOM' as BoardViewDtoTypeEnum,
					rowType: 'TAG' as BoardViewDtoRowTypeEnum,
					columnType: 'STATUS' as BoardViewDtoColumnTypeEnum,
				};
		}
	}

	return view;
};
