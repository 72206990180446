import { format, isValid } from 'date-fns';

export const prepareInitialDate = (iso?: string | null, defaultNow?: boolean) => {
	if (!iso)
		return {
			full: defaultNow ? new Date(new Date().setHours(0, 0, 0, 0)) : null,
			date: null,
			time: null,
		};

	const d = new Date(iso);
	const full = isValid(new Date(d)) ? new Date(d) : null;
	const date = isValid(d) ? new Date(new Date(d).setHours(0, 0, 0, 0)) : null;

	let time = isValid(d) ? format(d, 'HH:mm') : null;
	time = time !== '00:00' ? time : null;

	return { full, date, time };
};

export const prepareInitialReminder = (iso?: string | null) => {
	if (!iso) return null;

	const d = new Date(iso);

	return isValid(d) ? d : null;
};
