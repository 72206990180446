import React, { FC, useMemo } from 'react';

import { FileDto, FileDtoTypeEnum } from '@shared/api';
import { limitStringLength } from '@shared/lib/utils';
import { Tag } from '@shared/ui';

export interface TaskCommentFormFileProps {
	file: FileDto;
	onRemove: (file: FileDto) => void;
}

export const TaskCommentFormFile: FC<TaskCommentFormFileProps> = ({ file, onRemove }) => {
	const name = useMemo(() => {
		switch (file.type) {
			case FileDtoTypeEnum.LINK:
				return `${limitStringLength(file.name || file.url)} (ссылка)`;
			case FileDtoTypeEnum.FILE:
			default:
				return limitStringLength(file.name);
		}
	}, [file.type, file.name, file.url]);

	return (
		<Tag key={file.id} onCancel={() => onRemove(file)} editable={true} hoverable={true}>
			<span>{name}</span>
		</Tag>
	);
};
