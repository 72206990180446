import { useStore } from 'effector-react';
import React, { FC, useRef, useState } from 'react';

import {
	BoardDto,
	BoardPreviewDto,
	BoardRoleDtoNameEnum,
	BoardWithViewDto,
	UserDto,
} from '@shared/api';
import { useDelayUnmount, useOutsideClick } from '@shared/lib/hooks';
import { checkEscape } from '@shared/lib/utils';
import { Button, Dropdown, Icon, Skeleton } from '@shared/ui';

import {
	BoardArchiveTools,
	boardModel,
	BoardTools,
	boardViewModel,
	toggleTagsOnTaskModel,
} from '@entities/boards';
import { TagsManagerEntry } from '@entities/tag-manager';
import { taskListModel, taskListTagsModel } from '@entities/task';

import styles from './styles.module.scss';

interface TaskBoardPageToolsProps {
	currentUser: Nullable<UserDto>;
	editBoard?: (board?: BoardDto | BoardWithViewDto) => void;
	removeBoard?: (board?: BoardDto | BoardWithViewDto) => void;
	restoreBoard?: (board?: BoardPreviewDto | BoardDto | BoardWithViewDto) => void;
	deleteBoard?: (board?: BoardPreviewDto | BoardDto | BoardWithViewDto) => void;
	manageTags?: () => void;
	boardIsArchived?: boolean;
}

export const TaskBoardPageTools: FC<TaskBoardPageToolsProps> = ({
	currentUser,
	editBoard,
	removeBoard,
	restoreBoard,
	deleteBoard,
	boardIsArchived,
}) => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [tagManagerActive, setTagManagerActive] = useState(false);

	const boardViewChanged = useStore(boardViewModel.$changed);
	const boardTagsChanged = useStore(taskListTagsModel.$boardTagsChanged);
	const showTagsOnTasksChanged = useStore(toggleTagsOnTaskModel.$showTagsOnTasksChanged);

	const tagManagerShouldRender = useDelayUnmount(tagManagerActive, 400);
	const displayTagManager = menuIsOpen ? tagManagerActive : tagManagerShouldRender;

	const ddBtnRef = useRef<HTMLButtonElement | null>(null);
	const ddMenuRef = useRef<HTMLDivElement | null>(null);

	useOutsideClick([ddBtnRef, ddMenuRef], () => {
		setMenuIsOpen(false);
		setTagManagerActive(false);
	});

	const boardData = useStore(boardModel.$board);
	const boardLoading = useStore(boardModel.$boardStatus) === 'pending';

	const userIsAuthor = currentUser?.id && currentUser.id === boardData?.author?.id;
	const userIsEditor = boardData?.currentParticipantBoardRole?.name === BoardRoleDtoNameEnum.EDITOR;

	const hasUnsavedData = boardViewChanged || boardTagsChanged || showTagsOnTasksChanged;

	if (boardIsArchived && !userIsAuthor) return null;

	return (
		<div className={styles.toolsWrapper}>
			{!boardLoading && (
				<Dropdown
					placement="bottomEnd"
					portal={true}
					isOpen={menuIsOpen}
					openOnButtonClick={false}
					closeOnClickOutside={false}
					targetButton={
						<Button
							ref={ddBtnRef}
							design="filled"
							color="secondary"
							onlyIcon={<Icon id="kebab" />}
							onClick={() =>
								setMenuIsOpen((prev) => {
									if (!prev) setTagManagerActive(false);
									return !prev;
								})
							}
						/>
					}
					dropdownData={(props, ref) => null}
					dropdownElement={
						<div
							ref={ddMenuRef}
							className={styles.dropBox}
							onKeyDown={(e) => {
								if (checkEscape(e)) {
									if (tagManagerActive) setTagManagerActive(false);
									else setMenuIsOpen(false);
								}
							}}>
							{boardIsArchived ? (
								<BoardArchiveTools
									restoreBoard={restoreBoard}
									deleteBoard={deleteBoard}
									parentBtnRef={ddBtnRef}
									closeDropdown={() => setMenuIsOpen(false)}
								/>
							) : (
								<>
									{!displayTagManager && (
										<BoardTools
											viewer={currentUser}
											parentBtnRef={ddBtnRef}
											editBoard={editBoard}
											removeBoard={removeBoard}
											manageTags={() => setTagManagerActive((prev) => !prev)}
											closeDropdown={() => setMenuIsOpen(false)}
											hasUnsavedData={hasUnsavedData}
										/>
									)}
									{displayTagManager && (
										<TagsManagerEntry
											editable={userIsAuthor || userIsEditor}
											boardId={boardData?.id}
											onUpdateList={(tag) => {
												boardModel.revalidateBoard();
												taskListModel.revalidateTaskList();

												if (tag) {
													taskListTagsModel.addTagToList(tag);
												}
											}}
											onRemoveFromList={(tag) => {
												boardModel.revalidateBoard();
												taskListModel.revalidateTaskList();

												if (tag) {
													taskListTagsModel.removeTagFromList(tag);
												}
											}}
										/>
									)}
								</>
							)}
						</div>
					}
				/>
			)}
			{boardLoading && <Skeleton width={32} height={32} />}
		</div>
	);
};
