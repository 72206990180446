import React, { FC, Fragment, useMemo } from 'react';

import { TaskEventDto, UserDto } from '@shared/api';
import { Typo } from '@shared/ui';

import { FileManagerAttach } from '@entities/file-manager';
import { taskEventDeleteModel, taskEventUpdateModel } from '@entities/task';
import { TaskEvent } from '@entities/task-event';

import { formatDateTitle } from '../lib';
import styles from '../styles.module.scss';

interface TaskEventsGroupedProps {
	groupsByDate: Map<number, TaskEventDto[]>;
	viewer?: Nullable<UserDto>;
	editable?: boolean;
}

export const TaskEventsGrouped: FC<TaskEventsGroupedProps> = ({
	viewer,
	groupsByDate,
	editable,
}) => {
	const groupedArray = Array.from(groupsByDate);
	return (
		<>
			{groupedArray.map(([timestamp, list]) => {
				return (
					<Fragment key={timestamp}>
						<TaskEventsGroupTitle timestamp={timestamp} />
						<div className={styles.itemsList}>
							{list.map((item) => (
								<TaskEvent
									{...item}
									key={item.id}
									viewer={viewer}
									onSave={taskEventUpdateModel.updateTaskEvent}
									onRemove={() => taskEventDeleteModel.deleteTaskEvent(item)}
									editable={editable}
									fileManagerAttach={({ onCreateFile, onFileUploaded }) => (
										<FileManagerAttach
											btnSize="sm"
											btnDesign="transparent"
											classNameButton={styles.commentAttachButton}
											createFile={onCreateFile}
											onFileUploaded={onFileUploaded}
											editable={editable}
										/>
									)}
								/>
							))}
						</div>
					</Fragment>
				);
			})}
		</>
	);
};

export const TaskEventsGroupTitle: FC<{ timestamp: number }> = React.memo(({ timestamp }) => {
	const formatted = useMemo(() => formatDateTitle(timestamp), [timestamp]);
	return (
		<Typo className={styles.dateTile} design="text-13-regular">
			{formatted}
		</Typo>
	);
});
