import classNames from 'classnames';
import React, { useRef, MutableRefObject } from 'react';

import { BoardNameDto, SubtaskDto } from '@shared/api';
import { useFocusTrap, useEscape } from '@shared/lib/hooks';
import { toolsMenuStyles } from '@shared/lib/styles';
import { Button, Icon } from '@shared/ui';
import { TaskMode } from '@shared/ui/molecules/base-modal';

import { useUserRoles } from '@features/user-roles';

import { SubtaskToolsDdType } from '../subtask';

import styles from './styles.module.scss';

interface SubtaskToolsProps {
	setSubtaskToolsDd: (type: Nullable<SubtaskToolsDdType>) => void;
	subtask: Partial<SubtaskDto>;
	mode?: TaskMode;
	taskBoard?: BoardNameDto;
}

export const SubtaskTools = ({
	setSubtaskToolsDd,
	subtask,
	mode,
	taskBoard,
}: SubtaskToolsProps) => {
	const { user, userOnBoard, userOnTask } = useUserRoles();
	const subtaskToolsRef = useRef<HTMLDivElement>(null);

	const parentTaskOnBoard = !!taskBoard?.currentParticipantBoardRole;
	const userIsSubtaskAssignee = user?.id && user?.id === subtask?.assignee?.id;
	const allowConvertToTask =
		!subtask?.linkedTask &&
		(userOnTask.isAuthor ||
			userOnTask.isAssignee ||
			userIsSubtaskAssignee ||
			(parentTaskOnBoard ? userOnBoard.isAuthor || userOnBoard.isEditor : false));

	useFocusTrap(subtaskToolsRef);

	useEscape(
		true,
		(e) => {
			e?.stopPropagation();
			setSubtaskToolsDd('none');
		},
		subtaskToolsRef as MutableRefObject<HTMLElement>
	);

	return (
		<div ref={subtaskToolsRef} className={classNames(toolsMenuStyles.wrap, styles.wrap)}>
			<div className={toolsMenuStyles.group}>
				<Button
					className={toolsMenuStyles.actionButton}
					onClick={() => setSubtaskToolsDd('change-title')}
					size="sm"
					design="transparent"
					iconLeft={<Icon id="edit" />}>
					Изменить название
				</Button>

				<Button
					className={toolsMenuStyles.actionButton}
					onClick={() => setSubtaskToolsDd('assignee')}
					size="sm"
					design="transparent"
					iconLeft={<Icon id="people-add" />}>
					Назначить исполнителя
				</Button>

				<Button
					className={toolsMenuStyles.actionButton}
					onClick={() => setSubtaskToolsDd('date')}
					size="sm"
					design="transparent"
					iconLeft={<Icon id="calendar" />}>
					Установить дату
				</Button>
			</div>
			<div className={classNames(toolsMenuStyles.group, toolsMenuStyles.groupStroked)}>
				{subtask.id && mode !== 'create' && allowConvertToTask && (
					<Button
						className={toolsMenuStyles.actionButton}
						onClick={() => setSubtaskToolsDd('convert-to-task')}
						size="sm"
						design="transparent">
						Преобразовать в задачу
					</Button>
				)}
				<Button
					className={toolsMenuStyles.actionButton}
					onClick={() => setSubtaskToolsDd('remove')}
					size="sm"
					design="transparent"
					iconLeft={<Icon id="remove" />}>
					Удалить
				</Button>
			</div>
		</div>
	);
};
