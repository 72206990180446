import React from 'react';

import { Icon } from '@shared/ui';

export interface PagesConfigItem {
	path: string;
	title: string;
	icon?: React.ReactNode;
	navTitle?: string;
	inAside?: boolean;
	isNotifications?: boolean;
}

export type PagesConfigList = PagesConfigItem[];

export const pagesConfig: PagesConfigList = [
	{
		path: '/created-by-me',
		title: 'Созданные мной',
		icon: <Icon id="link-external" />,
		inAside: true,
	},
	{
		path: '/my-tasks',
		title: 'Мои задачи',
		icon: <Icon id="frames" />,
		inAside: true,
	},
	{
		path: '/boards',
		title: 'Доски задач',
		icon: <Icon id="layers" />,
		inAside: true,
	},
	{
		path: '/notifications',
		title: 'Уведомления',
		icon: <Icon id="bell" />,
		inAside: true,
		isNotifications: true,
	},
];
