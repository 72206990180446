import 'react-perfect-scrollbar/dist/css/styles.css';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, MutableRefObject, useCallback, useEffect, useRef } from 'react';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';

import { useResizeObserver } from '@shared/lib/hooks';

import styles from './styles.module.scss';

interface ScrollerProps extends Omit<HTMLAttributes<HTMLElement>, 'onScroll'> {
	axis?: 'x' | 'y' | 'both';
	options?: ScrollBarProps['options'];
	onScroll?: (x: number, y: number) => void;
	height?: string;
}

export const Scroller: FC<ScrollerProps> = ({
	axis = 'both',
	options,
	onScroll,
	height,
	style,
	className,
	children,
}) => {
	const timer = useRef(0);
	const ref = useRef<Nullable<PerfectScrollbar>>(null);
	const containerRef = useRef<Nullable<HTMLElement>>(null);

	useEffect(() => {
		window.clearTimeout(timer.current);
		timer.current = window.setTimeout(() => ref?.current?.updateScroll(), 10);
	}, []);

	useResizeObserver({
		ref: containerRef as MutableRefObject<HTMLDivElement>,
		callback: useCallback(
			([entry]) => {
				const target = entry.target as HTMLElement;
				onScroll && onScroll(target.scrollLeft, target.scrollTop);
			},
			[onScroll]
		),
	});

	return (
		<PerfectScrollbar
			ref={ref}
			containerRef={(node) => {
				containerRef.current = node;
			}}
			onScroll={(e) => {
				const target = e.target as HTMLElement;
				onScroll && onScroll(target.scrollLeft, target.scrollTop);
			}}
			style={{ height, ...style }}
			options={{ suppressScrollX: axis === 'y', suppressScrollY: axis === 'x', ...options }}
			className={classnames(className, styles.component, styles[axis])}>
			{children}
		</PerfectScrollbar>
	);
};
