import { Transition } from 'history';
import React from 'react';

import { modalsModel } from '@shared/ui';

import { boardViewModel, toggleTagsOnTaskModel } from '@entities/boards';
import { taskListTagsModel } from '@entities/task';

import { saveBoardViewFeature } from '@features/board';

interface saveBoardViewOrTagsProps {
	boardViewChanged?: boolean;
	boardTagsChanged?: boolean;
	showTagsOnTasksChanged?: boolean;
	retry?: Transition['retry'];
}

export const saveBoardViewAction = ({
	boardViewChanged,
	boardTagsChanged,
	showTagsOnTasksChanged,
	retry,
}: saveBoardViewOrTagsProps) => {
	modalsModel.pushModal({
		name: 'SAVE_BOARD_VIEW_MODAL',
		data: {
			title: 'Сохранить изменения',
			text: <>Вы хотите сохранить изменения для всех участников доски?</>,
			onAccept: () => {
				boardViewChanged && saveBoardViewFeature.saveBoardView(retry || null);
				boardTagsChanged && taskListTagsModel.saveBoardTags(retry || null);
				showTagsOnTasksChanged && toggleTagsOnTaskModel.saveShowTagsOnTasks(retry || null);
			},
		},
	});
};

export const confirmSaveBoardData = ({
	boardViewChanged,
	boardTagsChanged,
	showTagsOnTasksChanged,
	retry,
}: saveBoardViewOrTagsProps) => {
	modalsModel.pushModal({
		name: 'SAVE_BOARD_VIEW_MODAL',
		data: {
			title: 'Сохранить изменения',
			text: <>Вы хотите сохранить изменения для всех участников доски?</>,
			onAccept: () => {
				boardViewChanged && saveBoardViewFeature.saveBoardView(retry || null);
				boardTagsChanged && taskListTagsModel.saveBoardTags(retry || null);
				showTagsOnTasksChanged && toggleTagsOnTaskModel.saveShowTagsOnTasks(retry || null);
			},
			onDiscard: () => {
				boardViewChanged && boardViewModel.setCurrentToStore();
				boardTagsChanged && taskListTagsModel.resetTagsChanged();
				showTagsOnTasksChanged && toggleTagsOnTaskModel.resetShowTagsOnTasksChanged();
				retry && setTimeout(retry, 50);
			},
		},
	});
};
