import axios, { ResponseType } from 'axios';
import qs from 'qs';

import { prodLoginPath } from '@shared/lib/constants';
import { devLoginPath } from '@shared/lib/constants';
import {
	getJWT,
	resetJWT,
	getHeaders,
	getUrl,
	refreshToken,
	toFormData,
	dateWillExpireSoon,
} from '@shared/lib/utils';

interface FetchConfig {
	url: string;
	method?: 'OPTIONS' | 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
	headers?: Headers;
	body?: any;
	responseType?: ResponseType;
}

/* *
 * Проверяем дату окончания действия
 * access-токена перед запросом
 */
axios.interceptors.request.use((request) => {
	const { url, headers } = request;

	const isAuthRequest = url?.includes('auth');
	const accessExpirationDate = getJWT('accessExpiration');

	if (!isAuthRequest && dateWillExpireSoon(accessExpirationDate)) {
		return refreshToken()
			.then(() => {
				const newHeaders = getHeaders(
					headers ? new Headers(headers as HeadersInit) : new Headers()
				);
				return Promise.resolve({
					...request,
					headers: newHeaders,
				});
			})
			.catch((error) => {
				resetJWT();
				window.location.href =
					process.env.REACT_APP_STAND === 'PROD' ? prodLoginPath : devLoginPath;
				return error;
			});
	}

	return request;
});

/* *
 * Обрабатываем 401
 * в респонсе
 */
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		const access = getJWT();
		const refresh = getJWT('refresh');

		if (error.response.status === 401) {
			if (error.response.config.url.includes('refresh-token')) {
				resetJWT();
				window.location.href =
					process.env.REACT_APP_STAND === 'PROD' ? prodLoginPath : devLoginPath;
				return error;
			}

			if (!access || !refresh) {
				error.response.status = 401;
				resetJWT();
				window.location.href =
					process.env.REACT_APP_STAND === 'PROD' ? prodLoginPath : devLoginPath;
				return error;
			}

			return refreshToken()
				.then(() => {
					return axios({
						method: error.response.config.method,
						url: error.response.config.url,
						headers: getHeaders(new Headers(error.response.config.headers)),
						data: error.response.config.data,
					})
						.then((response) => {
							return response;
						})
						.catch((error) => {
							throw error;
						});
				})
				.catch((error) => {
					resetJWT();
					window.location.href =
						process.env.REACT_APP_STAND === 'PROD' ? prodLoginPath : devLoginPath;
					return error;
				});
		} else {
			throw error;
		}
	}
);

const getData = (body: FetchConfig['body'], headers: Headers) => {
	if (body && headers?.get('Content-Type') === 'multipart/form-data') {
		return toFormData(body);
	}

	return body;
};

export const fetcher = <T>(args: FetchConfig | string) => {
	const {
		url,
		method = 'GET',
		headers = new Headers({}),
		body = undefined,
		responseType = 'json',
	} = typeof args === 'string' ? { url: args } : args;

	const payload =
		method === 'GET'
			? {
					params: body,
					paramsSerializer: (params: Record<string, any>) =>
						qs.stringify(params, { encode: true, encodeValuesOnly: true }),
			  }
			: { data: getData(body, headers) };

	return axios({
		method: method,
		url: getUrl(url),
		headers: url.includes('auth') ? {} : getHeaders(headers),
		responseType,
		...payload,
	}).then((response) => response.data as T);
};

export const emptyPromise = <T>() =>
	new Promise<T[]>((resolve) => resolve([] as T[])).then((response) => response);

export const nullablePromise = () =>
	new Promise<null>((resolve) => resolve(null)).then((response) => response);
