import classNames from 'classnames';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { useState, useRef, useMemo, useEffect, ChangeEventHandler } from 'react';

import { BoardNameDto, SubtaskDto, UserDto, TaskDto } from '@shared/api';
import { useOutsideClick, useFocusTrap } from '@shared/lib/hooks';
import { setFocusToId, dateToISO, checkEscape } from '@shared/lib/utils';
import {
	Button,
	Icon,
	FormControl,
	Dropdown,
	ConfirmModalHead,
	ConfirmModalBody,
	TaskMode,
	Tag,
	TooltipsEventHandlers,
	tooltipEventHandlersFactory,
} from '@shared/ui';

import { useCurrentUser } from '@entities/current-user';
import {
	subtasksModel,
	assigneeModel,
	coAssigneesModel,
	taskModel,
	RemoveDropdown,
} from '@entities/task';

import { DatePickerBoard } from '@features/datepicker-board';
import { SearchAssignee } from '@features/search-assignee';
import { updateTaskFeature } from '@features/task';

import { getDateForTag } from '@widgets/task-modal/lib';

import { ConvertToTask } from '../convert-to-task';
import { SubtaskAssignees } from '../subtask-assignees';
import { SubtaskChangeTitle } from '../subtask-change-title';
import { SubtaskTools } from '../subtasks-tools';

import { getSubtaskDateUpdateMessage } from './lib';
import styles from './styles.module.scss';

interface SubtaskProps {
	subtask: Partial<SubtaskDto>;
	subtasks: Partial<SubtaskDto>[];
	index: number;
	mode?: TaskMode;
	onUpdate: (subtask: SubtaskDto, message?: string) => void;
	onRemove: (subtask: SubtaskDto, callback: () => void) => void;
	editable?: boolean;
	task?: Nullable<TaskDto>;
	taskBoard?: BoardNameDto;
}

export type SubtaskToolsDdType =
	| 'main'
	| 'change-title'
	| 'assignee'
	| 'assignee-notification'
	| 'date'
	| 'remove'
	| 'remove-date'
	| 'convert-to-task'
	| 'none';

export const Subtask = React.memo(
	({
		subtask,
		subtasks,
		index,
		mode,
		onUpdate,
		onRemove,
		editable,
		task,
		taskBoard,
	}: SubtaskProps) => {
		const { currentUser } = useCurrentUser();

		const assignee = useStore(assigneeModel.$assignee);
		const coAssignees = useStore(coAssigneesModel.$coAssignees);

		const toolsBtnRef = useRef(null);
		const toolsRef = useRef(null);
		const removeSubtaskToolsRef = useRef(null);
		const addAssigneeToolsRef = useRef(null);
		const resolveRef = useRef<(value: void | PromiseLike<void>) => void>();

		const [addAssigneeUser, setAddAssigneeUser] = useState<Nullable<UserDto>>(null);

		const [subtaskToolsDd, setSubtaskToolsDd] = useState<Nullable<SubtaskToolsDdType>>(null);
		const [localDate, setLocalDate] = useState(subtask.dueDate || null);

		const createOrEditMode = mode !== 'view';

		const { isBoardAuthor, isBoardEditor, isBoardViewer, isAuthor, isAssignee, isCoAssignee } =
			task?.authorities || {};

		const userIsSubtaskAssignee = subtask.assignee?.id === currentUser?.id;

		const isBoardparticipant = isBoardEditor || isBoardViewer;

		const subtaskToolsIsAvailable =
			mode === 'create' ||
			userIsSubtaskAssignee ||
			isBoardAuthor ||
			isBoardEditor ||
			isAuthor ||
			isAssignee ||
			(isCoAssignee && isBoardparticipant);

		const userCanSetSubtaskStatus =
			mode === 'create' ||
			userIsSubtaskAssignee ||
			isBoardAuthor ||
			isBoardEditor ||
			isAuthor ||
			isAssignee ||
			isCoAssignee;

		useOutsideClick([toolsRef, toolsBtnRef, document.getElementById('task-modal-portal')], () => {
			setSubtaskToolsDd('none');
		});

		const changeDate = (dueDate: string | null) => {
			const changeDateMessage = getSubtaskDateUpdateMessage(
				subtask.dueDate,
				dueDate,
				subtask.title
			);
			subtask?.id &&
				onUpdate(
					{
						...subtask,
						dueDate,
					} as SubtaskDto,
					changeDateMessage
				);
		};

		useOutsideClick([addAssigneeToolsRef, document.getElementById('task-modal-portal')], () =>
			setSubtaskToolsDd('none')
		);

		useFocusTrap(removeSubtaskToolsRef);

		useEffect(() => {
			if (subtaskToolsDd && subtaskToolsDd !== 'none') {
				setFocusToId(`subtask-tools-button${index}`);
			}
		}, [index, subtaskToolsDd]);

		const addAssignee = (user: UserDto) => {
			const assigneeIsParticipant =
				assignee?.id === user.id || coAssignees.some((coAssignee) => coAssignee.id === user.id);

			if (assigneeIsParticipant) {
				subtasksModel.addAssigneeToSubtask({ value: user, index });
				subtask?.id &&
					onUpdate(
						{
							...subtask,
							assignee: { ...user },
						} as SubtaskDto,
						`Исполнитель подзадачи "<b>${subtask.title}</b>" добавлен.`
					);
				setSubtaskToolsDd('none');
			} else {
				setAddAssigneeUser(user);
				setSubtaskToolsDd('assignee-notification');
			}
		};

		const addAssigneeToSubtask = () => {
			if (!addAssigneeUser) {
				return;
			}
			subtasksModel.addAssigneeToSubtask({ value: addAssigneeUser, index });

			coAssigneesModel.addCoAssignee(addAssigneeUser);

			if (subtask.id) {
				taskModel.updateTaskStore({
					coAssignees: task?.coAssignees
						? [...task?.coAssignees, addAssigneeUser]
						: [addAssigneeUser],
				});

				onUpdate(
					{
						...subtask,
						assignee: { ...addAssigneeUser },
					} as SubtaskDto,
					`Исполнитель подзадачи "<b>${subtask.title}</b>" добавлен.`
				);
			}

			setSubtaskToolsDd('none');
		};

		const removeAssigneeFromSubtask = (user: UserDto, index: number) => {
			subtasksModel.removeAssigneeFromSubtask({ value: user, index });
			subtask?.id &&
				onUpdate(
					{
						...subtask,
						assignee: undefined,
					} as SubtaskDto,
					`Исполнитель подзадачи "<b>${subtask.title}</b>" удалён.`
				);
		};

		const changeTitleOfSubtask = (title: string) => {
			subtasksModel.changeTitleOfSubtask({ value: title, index });
			subtask?.id &&
				onUpdate(
					{
						...subtask,
						title,
					} as SubtaskDto,
					`Название подзадачи "<b>${title}</b>" изменено.`
				);
			setSubtaskToolsDd('none');
		};

		const removeSubtask = (index: number, subtask?: Partial<SubtaskDto>) => {
			if (!subtask) {
				return;
			}

			if (mode === 'create') {
				setSubtaskToolsDd('none');
				subtasksModel.removeSubtask(index);
			}

			if (mode === 'edit' && subtask?.id) {
				onRemove(subtask as SubtaskDto, () => {
					setSubtaskToolsDd('none');
					subtasksModel.removeSubtask(index);
				});
			}
		};

		const removeDate = () => {
			setLocalDate(null);
			subtasksModel.addDateToSubtask({ value: null, index });
			changeDate(null);
			setSubtaskToolsDd('none');
		};

		const handleCheckbox: ChangeEventHandler = (e) => {
			const completed = (e.target as HTMLInputElement).checked;

			subtasksModel.toggleCompletedSubtask({ value: completed, index });

			if (subtask.id) {
				subtasksModel
					.toggleCompletedFx({
						id: subtask.id,
						title: subtask.title || '',
					})
					.then(() => {
						if (task) {
							const newSubtasks = subtasks?.map((item) =>
								item.id === subtask?.id ? { ...item, completed } : item
							) as SubtaskDto[];

							updateTaskFeature.mutateTaskPreview({
								...task,
								subtasks: newSubtasks,
							});

							taskModel.updateTaskStore({ subtasks: newSubtasks });
						}
					})
					.catch(() => {
						subtasksModel.toggleCompletedSubtask({ value: !completed, index });
					});
			}
		};

		useEffect(() => {
			if (subtaskToolsDd === 'assignee-notification') {
				resolveRef.current && resolveRef.current();
			}
		}, [subtaskToolsDd]);

		const dateTooltipEventHandlers: TooltipsEventHandlers = useMemo(
			() =>
				getDateForTag(subtask.dueDate)
					? tooltipEventHandlersFactory(getDateForTag(subtask.dueDate)!, { delayIn: 300 })
					: {},
			[subtask.dueDate]
		);

		return (
			<div className={styles.subtaskWrap}>
				<div className={styles.leftColumn}>
					<FormControl
						className={classNames(styles.checkbox, subtask.completed && styles.checkboxDone)}
						onChange={handleCheckbox}
						checked={subtask.completed}
						type="checkbox"
						disabled={!userCanSetSubtaskStatus}>
						{subtask.title}
					</FormControl>
				</div>

				<div className={styles.rightColumn}>
					{subtask.assignee && (
						<SubtaskAssignees
							assignee={subtask.assignee}
							removeAssignee={(user) => user && removeAssigneeFromSubtask(user, index)}
							editable={editable}
						/>
					)}
					{subtask.dueDate && (
						<Tag
							className={styles.date}
							{...dateTooltipEventHandlers}
							editable={true}
							onCancel={() => {
								setSubtaskToolsDd('remove-date');
							}}>
							<Icon id="clock" />
							{getDateForTag(subtask.dueDate, true)}
						</Tag>
					)}
					<Dropdown
						isOpen={!!subtaskToolsDd || subtaskToolsDd !== 'none'}
						indent={4}
						openOnButtonClick={false}
						closeOnClickOutside={false}
						placement="bottomEnd"
						customCoordinates={
							subtaskToolsDd === 'date' ? { right: 'calc(100% + 4px)', top: '-172px' } : {}
						}
						portal={subtaskToolsDd !== 'date'}
						dropdownId="task-modal-portal"
						dropdownClassName={styles.toolsDrop}
						targetButton={
							<Button
								id={`subtask-tools-button${index}`}
								ref={toolsBtnRef}
								onClick={() =>
									setSubtaskToolsDd((prevState) => {
										if (prevState === 'none' || !prevState) {
											return 'main';
										} else {
											return 'none';
										}
									})
								}
								size="sm"
								design="filled"
								color="secondary"
								onlyIcon={<Icon id="kebab" />}
								disabled={!(createOrEditMode && subtaskToolsIsAvailable)}
							/>
						}
						dropdownData={(props, ref) => null}
						dropdownElement={
							<div ref={toolsRef}>
								{subtaskToolsDd === 'main' && (
									<SubtaskTools
										setSubtaskToolsDd={(type) => {
											setSubtaskToolsDd(type);
										}}
										subtask={subtask}
										mode={mode}
										taskBoard={taskBoard}
									/>
								)}

								{(subtaskToolsDd === 'assignee' || subtaskToolsDd === 'assignee-notification') && (
									<div ref={addAssigneeToolsRef}>
										<div
											className={classNames(
												subtaskToolsDd === 'assignee-notification' && styles.disabled
											)}>
											<SearchAssignee
												assignees={subtask.assignee || null}
												addAssignee={addAssignee}
												removeAssignee={(user) => user && removeAssigneeFromSubtask(user, index)}
												closeDropdown={() => {
													setSubtaskToolsDd('none');
													setFocusToId('add-assignee-to-subtask');
												}}
											/>
										</div>

										<div className={classNames(subtaskToolsDd === 'assignee' && styles.disabled)}>
											<FocusTrap
												focusTrapOptions={{
													escapeDeactivates: true,
													allowOutsideClick: true,
													checkCanFocusTrap: () =>
														new Promise<void>((resolve) => (resolveRef.current = resolve)),
												}}>
												<div
													className={styles.notification}
													onKeyDown={(e) => {
														if (checkEscape(e)) {
															e.stopPropagation();
															setFocusToId('add-assignee-to-subtask');
															setSubtaskToolsDd('assignee');
														}
													}}>
													<ConfirmModalHead
														size="sm"
														title="Исполнитель подзадачи"
														onClickBack={() => setSubtaskToolsDd('assignee')}
													/>
													<ConfirmModalBody
														content={
															<>
																<b>{addAssigneeUser?.fullName}</b> будет также добавлен(а) в
																участники родительской задачи.
															</>
														}
														onAccept={addAssigneeToSubtask}
														onDiscard={() => setSubtaskToolsDd('assignee')}
														acceptLabel="Назначить"
														acceptColor="primary"
														textAlign="left"
													/>
												</div>
											</FocusTrap>
										</div>
									</div>
								)}

								{subtaskToolsDd === 'date' && (
									<FocusTrap
										focusTrapOptions={{
											initialFocus: false,
											escapeDeactivates: true,
											allowOutsideClick: true,
											checkCanFocusTrap: () =>
												new Promise<void>((resolve) => setTimeout(resolve, 50)),
										}}>
										<div
											className={styles.datepicker}
											onKeyDown={(e) => {
												e.stopPropagation();
												checkEscape(e) && setSubtaskToolsDd('none');
											}}>
											<DatePickerBoard
												initialEndDate={subtask.dueDate}
												onChange={(_, date) => {
													setLocalDate(dateToISO(date));
												}}
												onAccept={() => {
													changeDate(localDate);
													subtasksModel.addDateToSubtask({ value: localDate, index });
													setSubtaskToolsDd('none');
												}}
												onDiscard={() => setSubtaskToolsDd('none')}
												singleSelectDate
												showReminder={false}
											/>
										</div>
									</FocusTrap>
								)}

								{subtaskToolsDd === 'remove-date' && (
									<RemoveDropdown
										target="date"
										isSubtask
										onAccept={removeDate}
										onDiscard={() => setSubtaskToolsDd('none')}
										isPending={false}
									/>
								)}

								{subtaskToolsDd === 'remove' && (
									<FocusTrap
										focusTrapOptions={{
											escapeDeactivates: true,
											allowOutsideClick: true,
											checkCanFocusTrap: () =>
												new Promise<void>((resolve) => setTimeout(resolve, 50)),
										}}>
										<div
											className={styles.removeSubtask}
											onKeyDown={(e) => {
												if (checkEscape(e)) {
													e.stopPropagation();
													checkEscape(e) && setSubtaskToolsDd('none');
												}
											}}>
											<ConfirmModalHead
												title="Удаление подзадачи"
												size="sm"
												onClickBack={() => setSubtaskToolsDd('main')}
											/>
											<ConfirmModalBody
												content={
													<>
														Подзадача <b>{subtask.title}</b> будет удалена без возможности
														восстановления.
													</>
												}
												onAccept={() => removeSubtask(index, subtask)}
												onDiscard={() => setSubtaskToolsDd('main')}
												acceptLabel="Удалить"
												acceptColor="danger"
												textAlign="left"
											/>
										</div>
									</FocusTrap>
								)}

								{subtaskToolsDd === 'change-title' && (
									<SubtaskChangeTitle
										subtask={subtask}
										setSubtaskToolsDd={(type) => setSubtaskToolsDd(type)}
										onAccept={changeTitleOfSubtask}
									/>
								)}

								{subtaskToolsDd === 'convert-to-task' && (
									<ConvertToTask
										subtask={subtask}
										setSubtaskToolsDd={(type) => setSubtaskToolsDd(type)}
										taskBoard={taskBoard}
									/>
								)}
							</div>
						}
					/>
				</div>
			</div>
		);
	}
);
