import { URL_TASK_PATTERN } from '@shared/lib/constants';

export const replaceTaskUrlPattern = (url: string, id?: string, tasksOfOneState?: boolean) => {
	if (tasksOfOneState !== undefined && !tasksOfOneState) {
		if (url.includes('archive')) {
			url = url.replace('/archive', '');
		} else {
			url = url.replace('/task', '/archive/task');
		}
	}

	return url.replace(URL_TASK_PATTERN, id ? `/task/${id}` : '');
};
