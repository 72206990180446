import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { RemovalWindow } from '@entities/removal-window';

import { deleteBoardFeature, toggleBoardArchivedModel } from '@features/board';

interface RemoveBoardModalProps {
	onAccept: () => void;
	title?: React.ReactNode;
	text?: React.ReactNode;
}

const Component: FC<RemoveBoardModalProps> = ({ title, text, onAccept }) => {
	const { pending: deletePending } = useStore(deleteBoardFeature.$deleteBoardStatus);
	const { pending: moveToArchivePending } = useStore(
		toggleBoardArchivedModel.$toggleBoardArchivedStatus
	);

	return (
		<RemovalWindow
			onAccept={onAccept}
			title={title}
			text={text}
			isPending={moveToArchivePending || deletePending}
		/>
	);
};

export const RemoveBoardModal = {
	name: 'REMOVE_BOARD_MODAL',
	Component,
};
