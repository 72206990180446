import { createEvent, createStore, sample } from 'effector';

const $height = createStore(0);
const setHeight = createEvent<number>();

sample({
	clock: setHeight,
	target: $height,
});

$height.watch((value) => {
	document.documentElement.style.setProperty('--hdr-offset', `${value}px`);
});

export const headerModel = {
	$height,
	setHeight,
};
