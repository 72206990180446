import { useGate, useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { BoardWithViewDto } from '@shared/api';
import { EmptyBanner } from '@shared/ui';

import { boardsModel } from '@entities/boards';

import {
	moveBoardToArchive,
	restoreBoardFromArchive,
	completelyDeleteBoard,
} from '@features/board';

import { createBoard, editBoard } from '@widgets/board-modal';
import { BoardsGroup } from '@widgets/boards-group';
import { Header, headerModel } from '@widgets/header';
import { PageContentWrapper } from '@widgets/page-content-wrapper';

import { getBoardsEmptyMessage } from './lib';
import { BoardPageActions } from './ui';

export const Boards: FC = () => {
	const { boardId } = useParams();
	const isArchive = !!useMatch('/boards/archive');
	const title = isArchive ? 'Архив досок' : 'Доски задач';
	const backUrl = isArchive ? `/boards` : undefined;

	const headerHeight = useStore(headerModel.$height);

	const [starred, setStarred] = useState<boolean>(false);
	const [search, setSearch] = useState('');

	useGate(boardsModel.BoardsListGate, {
		archived: isArchive,
		starred: !isArchive && starred,
		name: isArchive && search.length > 0 ? search : undefined,
	});
	const { data = [], loading } = useStore(boardsModel.$boardsListStatus);

	const isEmpty = !loading && data.length === 0;
	const emptyBanner = isEmpty ? (
		<EmptyBanner>
			{getBoardsEmptyMessage({
				isArchive,
				isFilteredBySearch: search.length > 0,
			})}
		</EmptyBanner>
	) : null;

	useEffect(() => {
		if (isArchive) setStarred(false);
	}, [isArchive]);

	return (
		<>
			<Header
				title={title}
				backUrl={backUrl}
				topRight={
					<BoardPageActions
						createBoard={() => createBoard()}
						onFilter={({ value }) => {
							setStarred(value === 'starred');
						}}
						onSearch={setSearch}
					/>
				}
				withShadow={true}
			/>

			<PageContentWrapper headerOffset={headerHeight}>
				<BoardsGroup
					createBoard={() => createBoard()}
					boards={data}
					isEmpty={isEmpty}
					isLoading={loading}
					emptyBanner={emptyBanner}
					showEmptyBoardButton={!isArchive}
					manageTags={() => console.log('Управлять метками?')}
					editBoard={(board) => editBoard(board as BoardWithViewDto, true)}
					removeBoard={moveBoardToArchive}
					restoreBoard={restoreBoardFromArchive}
					deleteBoard={(board) => completelyDeleteBoard(board as BoardWithViewDto, true)}
				/>
			</PageContentWrapper>

			{boardId && <></>}
		</>
	);
};
