import React, { useState, useEffect, useRef, MutableRefObject } from 'react';

import { useEscape, useOutsideClick } from '@shared/lib/hooks';
import { Input, Dropdown } from '@shared/ui';

import { useBoardRoles } from '@entities/board-roles';
import { useSearchUsers } from '@entities/search-users';

import { ParticipantsList } from '../participants-list';

import styles from './styles.module.scss';

export const SearchParticipants = () => {
	useBoardRoles();
	const { searchUsers, users, debounced } = useSearchUsers();

	const inputRef = useRef(null);
	const participantsListRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (debounced.length > 2) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	}, [debounced]);

	const ddRef = useRef<HTMLDivElement>(null);

	useEscape(
		true,
		(e) => {
			e?.stopPropagation();
			setIsOpen(false);
		},
		ddRef as MutableRefObject<HTMLElement>
	);
	useOutsideClick([inputRef, participantsListRef], () => setIsOpen(false), {});

	return (
		<Dropdown
			ref={ddRef}
			indent={4}
			placement="bottomStart"
			openOnButtonClick={false}
			isOpen={isOpen}
			delay={0}
			targetButton={
				<Input
					ref={inputRef}
					className={styles.searchInput}
					onChange={searchUsers}
					onFocus={() => {
						if (debounced.length > 2 && users && users.length > 0) {
							setIsOpen(true);
						}
					}}
					icon="search"
					theme="dark"
					placeholder="Поиск по имени"
				/>
			}
			dropdownData={(props, ref) => null}
			dropdownElement={
				users ? <ParticipantsList ref={participantsListRef} participants={users} /> : <></>
			}
		/>
	);
};
