import { useStore } from 'effector-react';

import { TaskStatusDto, TaskStatusDtoTypeEnum } from '@shared/api';

import { taskListModel, taskStatusesModel, taskModel } from '@entities/task';

import { updateTaskStatusModel } from '@features/task';

interface SetTaskStatusProps {
	id: string;
	nextStatus: TaskStatusDtoTypeEnum;
	index?: number;
	callback?: (nextTaskStatus?: TaskStatusDto) => void;
	fallback?: (prevTaskStatus?: TaskStatusDto) => void;
}

export const useSetTaskStatus = () => {
	const { list } = useStore(taskListModel.$status);
	const statuses = useStore(taskStatusesModel.$statuses);

	const updateStatusPending = useStore(updateTaskStatusModel.updateTaskStatusFx.pending);
	const updateTaskApprovalPending = useStore(updateTaskStatusModel.updateTaskApprovalFx.pending);

	const setTaskStatus = ({ id, nextStatus, callback, fallback, index }: SetTaskStatusProps) => {
		const initialTask = list.find((listItem) => listItem.id === id);
		const initialStatus = initialTask?.status;
		const initialIndex = list
			.filter((task) => task?.status?.type === initialStatus?.type)
			.findIndex((task) => task.id === initialTask?.id);

		const newStatusForTask = statuses.find(({ type }) => type === nextStatus);
		const prevTaskStatus = statuses.find(({ type }) => type === initialTask?.status?.type);

		taskListModel.updateTargetTask({
			task: { ...initialTask, status: newStatusForTask },
			index: index,
		});
		taskModel.updateTaskStore({ status: newStatusForTask });

		callback && callback(newStatusForTask);

		updateTaskStatusModel
			.updateTaskStatusFx({
				statuses,
				params: {
					itemId: id,
					itemNewStatus: {
						newStatusType: nextStatus,
						newPosition: index ?? 0,
					},
				},
			})
			.catch(() => {
				fallback && fallback(prevTaskStatus);
				taskListModel.updateTargetTask({ task: { ...initialTask }, index: initialIndex });
				taskModel.updateTaskStore({ status: initialStatus });
			});
	};

	return {
		setTaskStatus,
		taskStatusStatusPending: updateStatusPending || updateTaskApprovalPending,
	};
};
