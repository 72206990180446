import classnames from 'classnames';
import React, { ComponentPropsWithoutRef, PropsWithChildren } from 'react';

import styles from './styles.module.scss';

interface FormControlProps {
	name?: string;
	checked?: boolean;
	value?: string | number;
	disabled?: boolean;
	className?: string;
}

export const ToggleBtn = React.forwardRef<
	HTMLInputElement,
	PropsWithChildren<FormControlProps & ComponentPropsWithoutRef<'input'>>
>(({ name, value, disabled = false, className, checked, ...props }, forwardedRef) => {
	return (
		<label className={classnames(styles.toggle, disabled ? styles.disabled : '', className)}>
			<input
				{...props}
				type={'checkbox'}
				name={name}
				value={value}
				disabled={disabled}
				checked={checked}
				ref={forwardedRef}
			/>
			<span />
		</label>
	);
});
