import React, { FC, useMemo, useState } from 'react';

import { LinkedTaskDto } from '@shared/api';
import { ARCHIVED_MARK } from '@shared/lib/constants';
import { Tag, tooltipEventHandlersFactory, TooltipsEventHandlers } from '@shared/ui';

import styles from './styles.module.scss';

interface ParentTaskProps extends LinkedTaskDto {}

export const ParentTask: FC<ParentTaskProps> = ({ title, archived }) => {
	const [ellipsis, setEllipsis] = useState(false);

	const emailTooltipEventHandlers: TooltipsEventHandlers = useMemo(
		() => (ellipsis && title ? tooltipEventHandlersFactory(title) : {}),
		[ellipsis, title]
	);

	const titleWithMark = `${archived ? `${ARCHIVED_MARK} ` : ''}${title}`;

	return (
		<Tag
			ref={(node) => {
				if (node) setEllipsis(node.offsetWidth < node.scrollWidth);
			}}
			{...emailTooltipEventHandlers}
			className={styles.parentTaskTitle}>
			{titleWithMark}
		</Tag>
	);
};
