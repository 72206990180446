import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { modalsModel, ConfirmModalHead, ConfirmModalBody, BaseModal } from '@shared/ui';

import { toggleTagsOnTaskModel } from '@entities/boards';
import { taskListTagsModel } from '@entities/task';

import { saveBoardViewFeature } from '@features/board';

interface SaveBoardViewModalProps {
	onAccept: () => void;
	onDiscard?: () => void;
	title?: React.ReactNode;
	text?: React.ReactNode;
}

const Component: FC<SaveBoardViewModalProps> = ({ title, text, onAccept, onDiscard }) => {
	const discardHandler = () => {
		modalsModel.closeLastModal();
		onDiscard && onDiscard();
	};
	const acceptHandler = () => onAccept();

	const { pending: saveBoardViewPending } = useStore(saveBoardViewFeature.$status);
	const setActiveTagsPending = useStore(taskListTagsModel.$setActiveTagsStatus) === 'pending';
	const toggleTagsOnTaskPending =
		useStore(toggleTagsOnTaskModel.$toggleTagsOnTasksStatus) === 'pending';

	return (
		<BaseModal
			size={362}
			design="sm"
			header={<ConfirmModalHead title={title} />}
			showCloseBtn
			closeModalOnEscape
			closeModalOnOutsideClick>
			<ConfirmModalBody
				content={text}
				onAccept={acceptHandler}
				onDiscard={discardHandler}
				acceptLabel="Сохранить"
				acceptColor="primary"
				isPending={saveBoardViewPending || setActiveTagsPending || toggleTagsOnTaskPending}
			/>
		</BaseModal>
	);
};

export const SaveBoardViewModal = {
	name: 'SAVE_BOARD_VIEW_MODAL',
	Component,
};
