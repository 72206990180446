import { textDate } from '@shared/lib/utils';

export const formatDateTitle = (timestamp: number) => {
	const today = new Date().setHours(0, 0, 0, 0);
	const todayLabel = timestamp === today ? `Сегодня, ` : ``;

	const date = new Date(timestamp);
	const shouldYear = date.getFullYear() !== new Date().getFullYear();

	return todayLabel + textDate(timestamp, shouldYear ? 'd MMMM yyyy' : 'd MMMM');
};
