import { useCallback, useEffect, useState } from 'react';

import { isTouchDevice } from '@shared/lib/utils';

/*
 * Проверяем, есть ли тач. Если тач есть, то не вешаем
 * addEventListener(keydown) и addEventListener(mousedown)
 * просто вешаем 1 состояние и не трогаем его
 *
 * Если тача нет, то вешаем листенеры.
 * При нажатии на таб возвращаем false
 */

const isTouch = isTouchDevice();

export function useAccessibility() {
	const [usingMouse, setUsingMouse] = useState<boolean>(true);
	const [touch, setTouch] = useState<boolean>(isTouch);

	const keyboardActions = useCallback(
		(e: KeyboardEvent) => {
			if (!usingMouse) return;
			if (e && e.keyCode !== 9) return;

			setUsingMouse(false);
			document.body.classList.toggle('usingKeyboard', true);
		},
		[usingMouse]
	);

	const mouseActions = useCallback(() => {
		if (usingMouse) return;

		setUsingMouse(true);
		document.body.classList.toggle('usingKeyboard', false);
	}, [usingMouse]);

	useEffect(() => {
		if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
			setTouch(false);
			setUsingMouse(false);
		} else {
			setTouch(true);
		}
	}, []);

	useEffect(() => {
		if (!touch) return;

		window.addEventListener('keydown', keyboardActions);
		window.addEventListener('mousedown', mouseActions);
	}, [keyboardActions, mouseActions, touch]);

	return usingMouse;
}
