import React, { FC } from 'react';

import { Skeleton } from '@shared/ui';

import styles from '../styles.module.scss';

export const TaskTableSkeleton: FC = React.memo(
	() => {
		const row = (
			<div className={styles.taskTableRow}>
				<div className={styles.taskTableRowHeader}>
					<Skeleton width={150} height={24} />
				</div>
				<div className={styles.taskTableRowGrid}>
					<div className={styles.taskTableColumn}>
						<div className={styles.taskTableColumnCell}>
							<Skeleton width="100%" height={140} />
						</div>
					</div>
					<div className={styles.taskTableColumn}>
						<div className={styles.taskTableColumnCell}>
							<Skeleton width="100%" height={140} />
						</div>
					</div>
					<div className={styles.taskTableColumn}>
						<div className={styles.taskTableColumnCell}>
							<Skeleton width="100%" height={140} />
						</div>
					</div>
					<div className={styles.taskTableColumn}>
						<div className={styles.taskTableColumnCell}>
							<Skeleton width="100%" height={140} />
						</div>
					</div>
					<div className={styles.taskTableColumn}>
						<div className={styles.taskTableColumnCell}>
							<Skeleton width="100%" height={140} />
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<div className={styles.taskTable}>
				<div className={styles.taskTableHead}>
					<div className={styles.taskTableHeadCell}>
						<Skeleton width={150} height={16} />
					</div>
					<div className={styles.taskTableHeadCell}>
						<Skeleton width={150} height={16} />
					</div>
					<div className={styles.taskTableHeadCell}>
						<Skeleton width={150} height={16} />
					</div>
					<div className={styles.taskTableHeadCell}>
						<Skeleton width={150} height={16} />
					</div>
					<div className={styles.taskTableHeadCell}>
						<Skeleton width={150} height={16} />
					</div>
				</div>
				{row}
				{row}
				{row}
			</div>
		);
	},
	() => true
);
