import { createEvent, createStore, sample } from 'effector';

import { UserDto } from '@shared/api';

const addCoAssignee = createEvent<UserDto>();
const removeCoAssignee = createEvent<UserDto>();
const resetCoAssignees = createEvent();

const $coAssignees = createStore<UserDto[]>([]).reset(resetCoAssignees);

sample({
	source: $coAssignees,
	clock: addCoAssignee,
	fn: (source, clock) => [...source, clock],
	target: $coAssignees,
});

sample({
	source: $coAssignees,
	clock: removeCoAssignee,
	fn: (source, clock) => {
		const index = source.findIndex((user) => user.id === clock.id);
		return [...source.slice(0, index), ...source.slice(index + 1)];
	},
	target: $coAssignees,
});

export const model = {
	addCoAssignee,
	removeCoAssignee,
	resetCoAssignees,
	$coAssignees,
};
