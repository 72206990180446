import React, { CSSProperties } from 'react';

import { NOT_FOUND_MESSAGE } from '@shared/lib/constants';
import { Typo } from '@shared/ui';

import styles from './styles.module.scss';

interface NotFoundMessageProps {
	children?: string;
	align?: CSSProperties['textAlign'];
}

export const NotFoundMessage = ({
	children = NOT_FOUND_MESSAGE.default,
	align = 'center',
}: NotFoundMessageProps) => {
	return (
		<Typo design="text-13-medium" className={styles.message} style={{ textAlign: align }}>
			{children}
		</Typo>
	);
};
