import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { object } from 'yup';

import { CredentialsPasswordDto } from '@shared/api';
import { resetJWT } from '@shared/lib/utils';
import { commonValidators } from '@shared/lib/validation';
import { Input, Button } from '@shared/ui';

import { devLoginModel } from '@entities/auth';

import styles from './styles.module.scss';

export const DevLogin: FC = () => {
	const navigate = useNavigate();

	const isLoading = useStore(devLoginModel.$status) === 'pending';
	const error = useStore(devLoginModel.$error);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm<CredentialsPasswordDto>({
		defaultValues: {
			email: '',
			password: '',
		},
		resolver: yupResolver(
			object({
				email: commonValidators.requiredEmail,
				password: commonValidators.requiredString,
			})
		),
	});

	useEffect(() => {
		resetJWT();
	}, []);

	useEffect(() => {
		if (error?.response?.status === 400) {
			setError('password', { message: 'Некорректный пароль' });
		}
	}, [error?.response?.status, setError]);

	const submit = (credentials: CredentialsPasswordDto) => {
		devLoginModel.login(credentials);
	};

	return (
		<div className={styles.login}>
			<form className={styles.form} onSubmit={handleSubmit(submit)}>
				<Input
					type="email"
					{...register('email')}
					placeholder="E-mail"
					error={errors.email?.message}
				/>
				<Input
					{...register('password')}
					type="password"
					placeholder="Пароль"
					error={errors.password?.message}
				/>
				<div className={styles.buttons}>
					<Button type="submit" isLoading={isLoading}>
						Войти
					</Button>
					<Button onClick={() => navigate('/dev-signup')}>Зарегистрироваться</Button>
				</div>
			</form>
		</div>
	);
};
