import { useStore } from 'effector-react';
import { useGate } from 'effector-react';
import React, { Dispatch, SetStateAction, useMemo } from 'react';

import { BoardNameDto } from '@shared/api';
import { BOARDS_LIST_SELECTOR_EMPTY } from '@shared/lib/constants';
import { SelectOption, Select, EmptyBanner } from '@shared/ui';

import { convertToTaskBoardsListModel } from '@entities/task';

import { withoutBoard } from '..';
import styles from '../styles.module.scss';

interface BoardsListSelectorProps {
	selectedBoard: SelectOption;
	setSelectedBoard: Dispatch<SetStateAction<SelectOption>>;
	taskBoard?: BoardNameDto;
}

export const BoardsListSelector = ({
	selectedBoard,
	setSelectedBoard,
	taskBoard,
}: BoardsListSelectorProps) => {
	useGate(convertToTaskBoardsListModel.BoardsListGate, { nonArchivedOnly: true });

	const boardsList = useStore(convertToTaskBoardsListModel.$boardsList);

	const boardsSelectOptions: SelectOption[] = useMemo(() => {
		const initial = {
			label: taskBoard?.name || '',
			value: taskBoard?.id || '',
		};
		const array = boardsList
			.map(({ id, name }) => ({ label: name || '', value: id || '' }))
			.filter(({ value }) => value && value !== initial.value);

		return taskBoard ? [initial, ...array, withoutBoard] : [...array, withoutBoard];
	}, [boardsList, taskBoard]);

	return (
		<Select
			size="md"
			portal={true}
			portalId="task-modal-portal"
			options={boardsSelectOptions}
			initial={withoutBoard}
			value={selectedBoard}
			onChange={setSelectedBoard}
			className={styles.boardsSelect}
			maxWidth={true}
			maxListSize={5}
			withoutBorders={true}
			withFilter={true}
			filterPlaceholder="Поиск по названию доски"
			filterEmptyBanner={
				<EmptyBanner className={styles.boardsSelectEmpty}>{BOARDS_LIST_SELECTOR_EMPTY}</EmptyBanner>
			}
		/>
	);
};
