import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { memo, useCallback } from 'react';
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
	useMouseSensor,
	useTouchSensor,
} from 'react-beautiful-dnd';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { TaskStatusDtoTypeEnum } from '@shared/api';
import { UserDto } from '@shared/api';
import { noticesModel } from '@shared/ui';
import { viewTask } from '@shared/ui';

import { boardViewModel, toggleTagsOnTaskModel } from '@entities/boards';
import { TaskColumnDto, taskListModel, taskListTagsModel } from '@entities/task';
import { TaskColumnsRecord } from '@entities/task/lib';

import { useSetTaskStatus } from '@features/task';
import { useUserRoles } from '@features/user-roles';

import { TaskCardWrapper } from '@widgets/task-board';

import styles from './styles.module.scss';
import { TaskBoardColumnHead } from './ui/task-board-column-head';
import { TaskBoardHeaderShadow } from './ui/task-board-header-shadow';
import { TaskBoardSkeleton } from './ui/task-board-skeleton';

interface TaskBoardProps {
	viewer: Nullable<UserDto>;
	columns: Nullable<TaskColumnsRecord>;
	isEmpty?: boolean;
	isLoading?: boolean;
	isDisabled?: boolean;
	baseRoute?: string;
}

export const TaskBoard = memo<TaskBoardProps>(({ viewer, columns, isLoading, isDisabled }) => {
	const { userOnBoard } = useUserRoles();
	const { list } = useStore(taskListModel.$status);

	const { setTaskStatus } = useSetTaskStatus();

	const dragEndHandler = useCallback(
		(result: DropResult) => {
			if (!result.destination) return;

			const { draggableId, destination } = result;

			const taskPreview = list.find(({ id }) => id === result.draggableId);

			const requireApprove = !!taskPreview?.requireApprove;
			const wasDragToDone =
				result.destination?.droppableId === 'DONE' && result.source?.droppableId !== 'DONE';

			if (requireApprove && wasDragToDone) {
				noticesModel.add({
					type: 'warning',
					text: 'Задача требует согласования',
				});
				return;
			}
			/**
			 * Разрешаем dnd если пользователь является автором доски,
			 * или редактором доски,
			 * или автором задачи,
			 * или исполнителем задачи,
			 * или соисполнителем задачи
			 */
			if (
				userOnBoard.isAuthor ||
				userOnBoard.isEditor ||
				taskPreview?.authorities?.isAuthor ||
				taskPreview?.authorities?.isAssignee ||
				taskPreview?.authorities?.isCoAssignee
			) {
				setTaskStatus({
					id: draggableId,
					nextStatus: destination.droppableId as TaskStatusDtoTypeEnum,
					index: destination.index,
				});
			} else {
				noticesModel.add({
					type: 'warning',
					text: 'У вас недостаточно прав для изменения статуса текущей задачи',
				});
			}
		},
		[list, setTaskStatus, userOnBoard.isAuthor, userOnBoard.isEditor]
	);

	const { pathname } = useLocation();
	const navigate = useNavigate();
	const boardViewChanged = useStore(boardViewModel.$changed);
	const boardTagsChanged = useStore(taskListTagsModel.$boardTagsChanged);
	const showTagsOnTasksChanged = useStore(toggleTagsOnTaskModel.$showTagsOnTasksChanged);

	const taskCardClickHandler = useCallback(
		(id: string, title = '') => {
			if (boardViewChanged || boardTagsChanged || showTagsOnTasksChanged) {
				viewTask({ id, title, viewer, mode: 'edit' });
				return;
			}
			navigate(`${pathname === '/' ? 'my-tasks/' : ''}task/${id}`);
		},
		[boardTagsChanged, boardViewChanged, navigate, pathname, showTagsOnTasksChanged, viewer]
	);

	const columnsList = columns ? Object.entries(columns) : [];

	return (
		<div className={styles.taskBoard}>
			{!isLoading && (
				<>
					<DragDropContext
						onDragEnd={dragEndHandler}
						sensors={[useMouseSensor, useTouchSensor]}
						enableDefaultSensors={false}>
						{columnsList.map(([columnId, column]) => (
							<div key={columnId} className={styles.column}>
								<TaskBoardColumnHead title={column.title} counter={column.items.length} />
								<div className={styles.droppableColumnWrap}>
									<Droppable key={columnId} droppableId={columnId}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												className={classNames(styles.droppableColumn, {
													[styles.isDraggingOver]: snapshot.isDraggingOver,
												})}
												{...provided.droppableProps}>
												<TaskBoardColumnList
													column={column}
													taskCardClickHandler={taskCardClickHandler}
													isDisabled={isDisabled}
												/>
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</div>
							</div>
						))}
					</DragDropContext>
					<TaskBoardHeaderShadow />
				</>
			)}
			{isLoading && <TaskBoardSkeleton />}
		</div>
	);
});

interface TaskBoardColumnListProps {
	column: TaskColumnDto;
	taskCardClickHandler: (id: string, title?: string) => void;
	isDisabled?: boolean;
}

const TaskBoardColumnList = memo<TaskBoardColumnListProps>(
	({ column, taskCardClickHandler, isDisabled }) => {
		return (
			<>
				{column.items.map((task, index) => {
					const { id = '', title } = task;
					return (
						<Draggable
							key={id}
							draggableId={id}
							index={index}
							isDragDisabled={isDisabled}
							disableInteractiveElementBlocking={true}>
							{(provided, { isDragging }) => (
								<div
									ref={provided.innerRef}
									className={classNames(styles.item, {
										isDragging,
									})}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									tabIndex={-1}>
									<TaskCardWrapper
										task={task}
										onClick={() => taskCardClickHandler(id, title)}
										isDragging={isDragging}
									/>
								</div>
							)}
						</Draggable>
					);
				})}
			</>
		);
	}
);
