import {
	createEvent,
	createEffect,
	createStore,
	sample,
	restore,
	combine,
	forward,
} from 'effector';
import { createGate } from 'effector-react';

import { BoardPreviewDto } from '@shared/api';
import { boardsRequests, GetBoardsList } from '@shared/api/requests';
import { noticesModel, NoticeType } from '@shared/ui';

const $boards = createStore<BoardPreviewDto[]>([]);
const $boardsCount = createStore<number>(0);

const getBoardsList = createEvent<GetBoardsList>();
const getBoardsListFx = createEffect(boardsRequests.getBoardsList);

const $boardsListConfig = createStore<GetBoardsList>({});
const $boardsListError = restore<Error>(getBoardsListFx.failData, null);

const $boardsListStatus = combine({
	loading: getBoardsListFx.pending,
	count: $boardsCount,
	data: $boards,
	error: $boardsListError,
});

const BoardsListGate = createGate<GetBoardsList>();

forward({
	from: BoardsListGate.state,
	to: getBoardsList,
});

sample({
	clock: getBoardsList,
	source: BoardsListGate.status,
	filter: (source) => source,
	fn: (_, config) => config,
	target: [$boardsListConfig, getBoardsListFx],
});

sample({
	clock: getBoardsListFx.doneData,
	target: $boards,
});

sample({
	clock: getBoardsListFx.doneData,
	source: { config: $boardsListConfig, count: $boardsCount },
	fn: ({ config, count }, data) => (!config.archived && !config.starred ? data.length : count),
	target: $boardsCount,
});

sample({
	clock: getBoardsListFx.failData,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	BoardsListGate,
	getBoardsList,
	$boardsListStatus,
	$boardsListConfig,
	$boards,
};
