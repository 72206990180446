import classnames from 'classnames';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { ChangeEventHandler, FC, useRef } from 'react';

import { TagDto } from '@shared/api';
import { TAGS_EMPTY } from '@shared/lib/constants';
import { helpersStyles } from '@shared/lib/styles';
import { Button, Icon, Input, Scroller, Skeleton, Typo } from '@shared/ui';

import { TagsManagerListItem } from '@entities/tag-manager';
import { tagListModel } from '@entities/tag-manager/model';

import styles from './styles.module.scss';

interface TagsManagerListProps {
	boardId?: string;
	createTag: () => void;
	editTag: (tag: TagDto) => void;
	deleteTag: (tag: TagDto) => void;
	clickTag?: (tag: TagDto) => void;
	onDiscard?: () => void;
	onAccept?: () => void;
	tagListInitial?: TagDto[];
	taskTagList?: TagDto[];
	editable?: boolean;
	isDirty?: boolean;
}

export const TagsManagerList: FC<TagsManagerListProps> = ({
	boardId,
	createTag,
	editTag,
	deleteTag,
	clickTag,
	onDiscard,
	onAccept,
	taskTagList,
	editable,
	isDirty,
}) => {
	const searchInputRef = useRef<HTMLInputElement | null>(null);
	const searchInputTimerRef = useRef(0);

	const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
		window.clearTimeout(searchInputTimerRef.current);
		searchInputTimerRef.current = window.setTimeout(() => {
			const search = e.target.value;
			tagListModel.setTagListSearch(search);
		}, 300);
	};

	const { data, loading } = useStore(tagListModel.$tagListStatus);

	const searchString = useStore(tagListModel.$tagListSearch);
	const searchStringEnough = searchString.length > 2;

	const tagsEmpty = !loading && data.length === 0;

	return (
		<FocusTrap
			focusTrapOptions={{
				escapeDeactivates: true,
				allowOutsideClick: true,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
			}}>
			<div className={styles.box}>
				<div className={styles.search}>
					<Input
						ref={searchInputRef}
						className={styles.searchInput}
						onChange={searchHandler}
						icon="search"
						theme="light-gray"
						placeholder="Поиск меток"
						isLoading={loading && searchStringEnough}
					/>
				</div>
				{!tagsEmpty && editable && (
					<div className={styles.createTagContainer}>
						<Button
							design="transparent"
							size="sm"
							iconLeft={<Icon id={'plus'} />}
							onClick={createTag}>
							Создать метку
						</Button>
					</div>
				)}
				<div className={styles.tagsList}>
					{!loading && data.length > 0 && (
						<>
							<Scroller axis="y" className={classnames(styles.tagsListScroller, 'scroller')}>
								{data.map((tag, i) => (
									<TagsManagerListItem
										tag={tag}
										editable={editable}
										editTag={editTag}
										deleteTag={deleteTag}
										onClick={clickTag && (() => clickTag(tag))}
										key={`${tag.id}-${i}`}
										isActive={
											taskTagList
												? taskTagList.findIndex(({ id }) => id === tag.id) > -1
												: tag.active
										}
									/>
								))}
							</Scroller>
							<div id="tags-list-portal" />
						</>
					)}
					{tagsEmpty &&
						(searchStringEnough ? (
							<Typo className={styles.tagsListMessage} design="text-13-regular">
								{TAGS_EMPTY.listWithSearch}
							</Typo>
						) : (
							<Typo className={styles.tagsListMessage} design="text-13-regular">
								{TAGS_EMPTY.list}
							</Typo>
						))}
					{loading && <TagsManagerListSkeleton list={data} />}
				</div>
				<div className={classnames(styles.tools, helpersStyles.buttonsPanel)}>
					{editable && tagsEmpty && (
						<Button
							className={helpersStyles.buttonsPanelItem}
							onClick={createTag}
							size="md"
							design="filled"
							color="primary"
							type="button">
							Создать
						</Button>
					)}

					{!tagsEmpty && onDiscard && onAccept && (
						<>
							<Button
								className={helpersStyles.buttonsPanelItem}
								size="md"
								design="filled"
								color="secondary"
								type="button"
								onClick={onDiscard}>
								Отменить
							</Button>
							<Button
								className={helpersStyles.buttonsPanelItem}
								size="md"
								design="filled"
								color="primary"
								type="button"
								disabled={!isDirty}
								onClick={onAccept}>
								Сохранить
							</Button>
						</>
					)}
				</div>
			</div>
		</FocusTrap>
	);
};

const TagsManagerListSkeleton: FC<{ list: any[] }> = ({ list }) => {
	list = list.length > 0 ? list : [{}, {}, {}];

	return (
		<div className={styles.tagsListScroller}>
			{list.map((_, i) => (
				<div
					key={`tag-list-skeleton-${i}`}
					style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Skeleton width={140} height={16} margin="2px 0" />
					<Skeleton width={16} height={16} margin="2px 0" />
				</div>
			))}
		</div>
	);
};
