import { useState } from 'react';

import { FileDto } from '@shared/api';

import { TaskCommentType } from '@entities/task-event';

export const useComment = ({
	onSave,
	comment,
}: {
	onSave?: (comment: TaskCommentType) => void;
	comment: TaskCommentType;
}) => {
	// Черновик
	const [draft, setDraft] = useState<TaskCommentType>(comment);
	// Последнее сохраненное значение
	const [value, setValue] = useState<TaskCommentType>(comment);
	// Режим отображения комментария
	const [view, setView] = useState<'default' | 'edit'>('default');

	// Включение режима редактирования
	const startEdit = () => {
		setView('edit');
	};
	// Отмена редактирования
	const onCancel = () => {
		setDraft(value);
		setView('default');
	};
	// Сохранение черновика
	const onSaveDraft = () => {
		if (onSave) {
			onSave(draft);
			setValue(draft);
		}
		setView('default');
	};
	// Функция для изменения текста комментария
	const onEditText = (text: string) => {
		setDraft((prev) => ({
			...prev,
			text: text,
		}));
	};
	// Функция для удаления вложения
	const onRemoveAttachment = (id: string) => {
		setDraft((prev) => ({
			...prev,
			files: prev.files?.filter((file) => file.id !== id) || [],
		}));
	};
	// Функция для добавления вложений
	const onAddAttachments = (attachments: FileDto[]) => {
		setDraft((prev) => {
			const oldFiles = prev.files || [];
			return {
				...prev,
				files: [...oldFiles, ...attachments],
			};
		});
	};

	return {
		editingActions: { onEditText, onRemoveAttachment, onAddAttachments },
		view,
		onCancel,
		onSaveDraft,
		startEdit,
		// если режим редактирования, то значением комментария является черновик
		// иначе последнее сохраненное значение
		value: view === 'edit' ? draft : value,
	};
};
