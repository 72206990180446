import classnames from 'classnames';
import React, { FC, HTMLAttributes, memo, useMemo, useState } from 'react';

import { UserDto } from '@shared/api/model/userDto';
import {
	AvatarProps,
	AvatarColors,
	TooltipsEventHandlers,
	AvatarRoundImage,
	Typo,
	tooltipEventHandlersFactory,
} from '@shared/ui';

import styles from './styles.module.scss';

interface UserInfoProps extends HTMLAttributes<HTMLElement> {
	user: UserDto;
	showEmail?: boolean;
	showJobTitle?: boolean;
	showDepartment?: boolean;
	avatarSize?: AvatarProps['size'];
	Tag?: 'div' | 'button';
	icon?: boolean;
	nameInOneLine?: boolean;
}

interface UserInfoLineProps extends HTMLAttributes<HTMLParagraphElement> {
	text?: string;
}

export const UserInfo = ({
	user,
	icon,
	showEmail,
	showJobTitle,
	showDepartment,
	avatarSize = 'md',
	Tag = 'div',
	children,
	nameInOneLine,
	className,
	...props
}: UserInfoProps) => {
	const [ellipsis, setEllipsis] = useState(false);

	const tooltipEventHandlers: TooltipsEventHandlers = useMemo(
		() => (ellipsis ? tooltipEventHandlersFactory(user.fullName!) : {}),
		[ellipsis, user.fullName]
	);

	return (
		<Tag className={classnames(styles.info, className)} {...props}>
			<AvatarRoundImage
				icon={icon}
				size={avatarSize}
				name={user.fullName ?? ''}
				color={user.avatarColor as AvatarColors}
				showFullNameOnHover={false}
				className={styles.avatar}
			/>
			<div className={styles.textWrap}>
				<Typo
					ref={(node) => {
						if (node) {
							setEllipsis(node.offsetWidth < node.scrollWidth);
						}
					}}
					design={showEmail ? 'text-13-bold' : 'text-13-medium'}
					className={classnames(nameInOneLine ? styles.nameInOneLine : styles.name)}
					{...tooltipEventHandlers}>
					{user.fullName}
				</Typo>
				{showEmail && <UserInfoEmail text={user?.email} />}
				{showJobTitle && (
					<Typo
						design="text-13-regular"
						className={classnames(styles.employee, { [styles.gap]: showDepartment })}>
						<UserInfoPragraph
							text={user?.jobTitle}
							className={classnames({ [styles.heavy]: showDepartment })}
						/>
						{showDepartment && (
							<>
								<UserInfoPragraph text={user?.department} />
								<UserInfoPragraph text={user?.location} className={styles.offset} />
							</>
						)}
					</Typo>
				)}
				{children}
			</div>
		</Tag>
	);
};

export const UserInfoEmail: FC<UserInfoLineProps> = memo(({ text }) => {
	const [ellipsis, setEllipsis] = useState(false);

	const tooltipEventHandlers: TooltipsEventHandlers = useMemo(
		() => (ellipsis && text ? tooltipEventHandlersFactory(text) : {}),
		[ellipsis, text]
	);

	if (!text) {
		return null;
	}

	return (
		<Typo
			ref={(node) => {
				if (node) {
					setEllipsis(node.offsetWidth < node.scrollWidth);
				}
			}}
			{...tooltipEventHandlers}
			className={styles.email}
			design="text-13-regular">
			{text}
		</Typo>
	);
});

export const UserInfoPragraph: FC<UserInfoLineProps> = memo(({ text, className }) => {
	const [ellipsis, setEllipsis] = useState(false);

	const tooltipEventHandlers: TooltipsEventHandlers = useMemo(
		() => (ellipsis && text ? tooltipEventHandlersFactory(text, { delayIn: 200 }) : {}),
		[ellipsis, text]
	);

	if (!text) {
		return null;
	}

	if (text.toLowerCase() === 'unknown') {
		return null;
	}

	return (
		<p
			ref={(node) => {
				if (node) {
					setEllipsis(node.offsetWidth < node.scrollWidth);
				}
			}}
			{...tooltipEventHandlers}
			className={className}>
			{text}
		</p>
	);
});
