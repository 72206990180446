import { combine, createEffect, createEvent, createStore, restore, sample, Store } from 'effector';

import { SubtaskDto } from '@shared/api';
import { CreateTaskPayload, tasksRequests } from '@shared/api';
import { TaskDto } from '@shared/api/model/taskDto';
import { isEmptyObject } from '@shared/lib/utils';
import { modalsModel, noticesModel } from '@shared/ui';

import {
	taskListModel,
	assigneeModel,
	coAssigneesModel,
	tagsModel,
	subtasksModel,
	taskFilesModel,
	datesModel,
} from '@entities/task';

const createTask = createEvent<CreateTaskPayload>();
const createTaskFx = createEffect(tasksRequests.createTask);

createTaskFx.doneData.watch((result) => {
	modalsModel.closeLastModal();
	noticesModel.add({
		type: 'success',
		text: `Задача "<b>${result.title}</b>" создана.`,
	});
});

createTaskFx.failData.watch((result) => {
	noticesModel.add({
		type: 'error',
		text: `Что-то пошло не так, попробуйте позже.`,
	});
});

const $createdTask = createStore<TaskDto | null>(null).reset(createTask);
const $createTaskError = restore<Error>(createTaskFx.failData, null).reset(createTask);

const $createTaskStatus = combine({
	pending: createTaskFx.pending,
	response: $createdTask,
	error: $createTaskError,
});

const $createTaskData = combine(
	{
		assignee: assigneeModel.$assignee.map((assignee) =>
			assignee && !isEmptyObject(assignee) ? assignee : null
		),
		coAssignees: coAssigneesModel.$coAssignees,
		endDate: datesModel.$dates.map(({ endDate }) => endDate ?? null),
		startDate: datesModel.$dates.map(({ startDate }) => startDate ?? null),
		deadlineReminderDate: datesModel.$dates.map(
			({ deadlineReminderDate }) => deadlineReminderDate ?? null
		),
		tags: tagsModel.$tags.map((tags) => tags),
		subtasks: subtasksModel.$subtasks as Store<SubtaskDto[]>,
		files: taskFilesModel.$files.map((files) => files ?? null),
	},
	({ assignee, endDate, startDate, deadlineReminderDate, files, ...values }) => ({
		assignee: assignee ?? undefined,
		endDate: endDate ?? undefined,
		startDate: startDate ?? undefined,
		deadlineReminderDate: deadlineReminderDate ?? undefined,
		files: files ?? undefined,
		...values,
	})
);

sample({
	clock: createTask,
	target: createTaskFx,
});

sample({
	clock: createTaskFx.done,
	source: taskListModel.$config,
	target: taskListModel.getTaskList,
});

sample({
	clock: createTaskFx.doneData,
	target: $createdTask,
});

export const createTaskFeature = {
	createTask,
	createTaskFx,
	$createTaskStatus,
	$createTaskData,
};
