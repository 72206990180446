import classnames from 'classnames';
import { useGate, useStore } from 'effector-react';
import React, { FC, useState } from 'react';

import { CommentType } from '@shared/api';
import { UserDto } from '@shared/api';
import { TASK_EVENTS_EMPTY } from '@shared/lib/constants';
import { Button, EmptyBanner, Select, SelectOption } from '@shared/ui';

import { taskEventsListModel } from '@entities/task';

import { TaskRow } from '../task-row';

import styles from './styles.module.scss';
import { TaskEventsGrouped, TaskEventsSkeleton } from './ui';

interface TaskEventsProps {
	taskId?: string;
	className?: string;
	viewer?: Nullable<UserDto>;
	editable?: boolean;
}

const eventsFilterOptions: SelectOption[] = [
	{ label: 'Все новости', value: 'ALL' },
	{ label: 'Комментарии', value: 'COMMENT' },
];

export const TaskEvents: FC<TaskEventsProps> = ({ taskId, className, ...props }) => {
	return (
		<div className={classnames(className, styles.taskEvents)}>
			{taskId && <TaskEventsComponent taskId={taskId} {...props} />}
			{!taskId && <TaskEventsSkeleton />}
		</div>
	);
};

export const TaskEventsComponent: FC<TaskEventsProps & { taskId: string }> = ({
	viewer,
	taskId,
	editable,
}) => {
	const [page, setPage] = useState(0);
	const [filter, setFilter] = useState<SelectOption>({ label: 'Все новости', value: 'ALL' });

	useGate(taskEventsListModel.TasksEventsListGate, {
		taskId,
		page,
		filter: filter.value,
		type: filter.value as CommentType,
	});

	const { data, grouped, pagination, complete, loading, lazyLoading } = useStore(
		taskEventsListModel.$status
	);
	const isEmpty = data.length === 0;

	if (loading) return <TaskEventsSkeleton />;

	const emptyBanner = (
		<EmptyBanner className={styles.emptyBanner}>
			{filter.value === 'COMMENT' ? TASK_EVENTS_EMPTY.comments : TASK_EVENTS_EMPTY.news}
		</EmptyBanner>
	);

	return (
		<>
			<TaskRow className={styles.head} title={() => <>Действия:</>}>
				<Select
					onChange={(option) => {
						setPage(0);
						setFilter(option);
					}}
					options={eventsFilterOptions}
					initial={filter}
					size="sm"
					placement="bottomEnd"
					portal={false}
					disabled={!editable}
				/>
			</TaskRow>

			{!isEmpty ? (
				<TaskEventsGrouped viewer={viewer} groupsByDate={grouped} editable={editable} />
			) : (
				emptyBanner
			)}

			{!complete && !loading && !isEmpty && (
				<div className={styles.loadMoreButton}>
					<Button
						size="sm"
						design="transparent"
						isLoading={lazyLoading}
						onClick={() => pagination?.nextPage && setPage(pagination?.nextPage)}>
						Показать больше
					</Button>
				</div>
			)}
		</>
	);
};
