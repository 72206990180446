import React, { useRef, MutableRefObject, useMemo } from 'react';

import { UserDto } from '@shared/api';
import { NOT_FOUND_MESSAGE } from '@shared/lib/constants';
import { useEscape, useOutsideClick } from '@shared/lib/hooks';
import { NotFoundMessage } from '@shared/ui';

import { SearchUsersWrapper, useSearchUsers } from '@entities/search-users';

import { CoAssigneesListItem } from '../co-assignees-list-item';

interface CoAssigneesListProps {
	title: string;
	coAssigneesList: UserDto[];
	closeDropdown: () => void;
	outsideClickExcludeRef: MutableRefObject<HTMLElement | null>;
	removeCoAssignee?: (user: UserDto) => void;
	checkUserHasSubtasks?: (user: UserDto) => boolean;
}

export const CoAssigneesList = ({
	title,
	coAssigneesList,
	removeCoAssignee,
	closeDropdown,
	outsideClickExcludeRef,
	checkUserHasSubtasks,
}: CoAssigneesListProps) => {
	const coAssigneesListDdRef = useRef<HTMLDivElement>(null);

	const sortedCoAssigneesList = useMemo(() => {
		const copyCoAssigneesList = [...coAssigneesList];
		return copyCoAssigneesList.sort((a, b) =>
			a?.fullName && b?.fullName && a?.fullName.toLowerCase() > b?.fullName.toLowerCase() ? 1 : -1
		);
	}, [coAssigneesList]);

	const { searchUsers, users } = useSearchUsers({
		localSearchList: sortedCoAssigneesList,
		isFullInitialLocalSearchList: true,
	});

	useEscape(
		true,
		(e) => {
			e?.stopPropagation();
			closeDropdown();
		},
		coAssigneesListDdRef as MutableRefObject<HTMLElement>
	);

	useOutsideClick([coAssigneesListDdRef, outsideClickExcludeRef], closeDropdown);

	return (
		<div ref={coAssigneesListDdRef}>
			<SearchUsersWrapper title={title} onSearch={searchUsers}>
				{users?.length ? (
					users.map((user) => (
						<CoAssigneesListItem
							key={user.id}
							user={user}
							removeAssignee={removeCoAssignee && (() => removeCoAssignee(user))}
							userHasSubtasks={!!checkUserHasSubtasks && checkUserHasSubtasks(user)}
						/>
					))
				) : (
					<NotFoundMessage>{NOT_FOUND_MESSAGE.employees}</NotFoundMessage>
				)}
			</SearchUsersWrapper>
			<div id="co-assignees-list" />
		</div>
	);
};
