import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useStore } from 'effector-react';
import { useGate } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { useState, useRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { object } from 'yup';

import { BoardNameDto, SubtaskDto } from '@shared/api';
import { setFocusToId } from '@shared/lib/utils';
import { checkEscape } from '@shared/lib/utils';
import { isEmptyObject } from '@shared/lib/utils';
import { commonValidators } from '@shared/lib/validation';
import {
	Typo,
	FormElement,
	Input,
	Button,
	Icon,
	Dropdown,
	Avatar,
	AvatarColors,
	SelectOption,
} from '@shared/ui';
import { ConfirmModalHead, ConfirmModalBody } from '@shared/ui';

import { AssigneeDropdown } from '@entities/assignee-dropdown';
import { convertToTaskModel } from '@entities/task';

import { SearchAssignee } from '@features/search-assignee';

import { TaskDates } from '@widgets/task-modal/ui/task-dates';

import { SubtaskToolsDdType } from '../subtask';

import styles from './styles.module.scss';
import { BoardsListSelector } from './ui/boards-list-selector';

interface ConvertToTaskProps {
	subtask: Partial<SubtaskDto>;
	setSubtaskToolsDd: (type: Nullable<SubtaskToolsDdType>) => void;
	taskBoard?: BoardNameDto;
}

interface FormValues {
	title: string;
}

export const withoutBoard = { label: 'Без доски', value: null };

export const ConvertToTask = ({ subtask, setSubtaskToolsDd, taskBoard }: ConvertToTaskProps) => {
	useGate(convertToTaskModel.convertToTaskLoad, {
		assignee: subtask.assignee,
		endDate: subtask.dueDate,
	});

	const [selectedBoard, setSelectedBoard] = useState<SelectOption>(() =>
		taskBoard
			? {
					label: taskBoard?.name || '',
					value: taskBoard?.id || null,
			  }
			: withoutBoard
	);

	const convertToTaskDates = useStore(convertToTaskModel.$convertToTaskDates);

	const assignee = useStore(convertToTaskModel.$assignee);
	const status = useStore(convertToTaskModel.$convertToTaskStatus);

	const addAssigneeBtnRef = useRef(null);

	const [assigneeDd, setAssigneeDd] = useState(false);
	const [addAssigneeDd, setAddAssigneeDd] = useState(false);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			title: subtask.title,
		},
		mode: 'all',
		resolver: yupResolver(object({ title: commonValidators.limitedRequiredString(500) })),
	});

	const createTask = (formData: FormValues) => {
		const board = selectedBoard.value ? { board: { id: selectedBoard.value } } : {};
		const newTaskAssignee = assignee && !isEmptyObject(assignee) ? assignee : undefined;

		convertToTaskModel.convertToTask({
			data: {
				...formData,
				...board,
				...convertToTaskDates,
				assignee: newTaskAssignee,
			},
			subtaskId: subtask.id as string,
		});
	};

	useEffect(() => {
		if (status === 'done') {
			setSubtaskToolsDd('none');
		}
	}, [setSubtaskToolsDd, status]);

	const hasAssignee = assignee && !!Object.keys(assignee).length;

	return (
		<FocusTrap
			focusTrapOptions={{
				escapeDeactivates: true,
				allowOutsideClick: true,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
			}}>
			<div
				className={styles.convertToTask}
				onKeyDown={(e) => {
					if (checkEscape(e)) {
						e.stopPropagation();
						setSubtaskToolsDd('main');
					}
				}}>
				<ConfirmModalHead
					title="Преобразование в задачу"
					size="sm"
					onClickBack={() => setSubtaskToolsDd('main')}
				/>
				<ConfirmModalBody
					isPending={status === 'pending'}
					content={
						<div className={styles.body}>
							<fieldset className={styles.fieldset}>
								<Typo className={styles.legend} design="text-13-medium" as="legend">
									Название
								</Typo>
								<FormElement error={errors?.title?.message}>
									<Controller
										name="title"
										control={control}
										render={({ field: { name, onChange, onBlur, value } }) => (
											<Input
												name={name}
												value={value}
												onChange={onChange}
												onBlur={onBlur}
												placeholder="Введите название"
											/>
										)}
									/>
								</FormElement>
							</fieldset>
							<fieldset className={styles.fieldset}>
								<div className={styles.fieldsetColumn}>
									<Typo className={styles.legend} design="text-13-medium" as="legend">
										Исполнитель
									</Typo>
									{hasAssignee && (
										<Dropdown
											placement="bottomStart"
											indent={10}
											isOpen={assigneeDd}
											openOnButtonClick={false}
											targetButton={
												<Avatar
													id="assignee-button"
													onClick={() => setAssigneeDd(true)}
													name={assignee.fullName || ''}
													color={assignee.avatarColor as AvatarColors}
												/>
											}
											dropdownData={(props, ref) => (
												<AssigneeDropdown
													user={assignee}
													removeAssignee={() => {
														convertToTaskModel.removeAssignee();
														setAssigneeDd(false);
													}}
													closeDropdown={() => {
														setAssigneeDd(false);
													}}
													editable
												/>
											)}
										/>
									)}

									{!hasAssignee && (
										<Dropdown
											placement="bottomStart"
											isOpen={addAssigneeDd}
											openOnButtonClick={false}
											closeOnClickOutside={false}
											targetButton={
												<Button
													id="convert-to-task-add-assignee-btn"
													ref={addAssigneeBtnRef}
													onClick={() => setAddAssigneeDd((prevState) => !prevState)}
													size="sm"
													design="filled"
													color="secondary"
													iconRight={<Icon id="plus" />}>
													Добавить
												</Button>
											}
											dropdownData={(props, ref) => null}
											dropdownElement={
												<SearchAssignee
													assignees={assignee}
													addAssignee={(user) => {
														convertToTaskModel.addAssignee(user);
														setAddAssigneeDd(false);
													}}
													removeAssignee={() => convertToTaskModel.removeAssignee()}
													closeDropdown={() => {
														setAddAssigneeDd(false);
														setFocusToId('convert-to-task-add-assignee-btn');
													}}
													outsideClickExcludeRef={addAssigneeBtnRef}
												/>
											}
										/>
									)}
								</div>
								<div className={styles.fieldsetColumn}>
									<Typo className={styles.legend} design="text-13-medium" as="legend">
										Срок
									</Typo>
									<TaskDates
										editable={true}
										model={convertToTaskModel.datesModel}
										portal={false}
										placement="right"
										dropdownId="convert-to-task-portal"
										maxWidthBtn={false}
									/>
								</div>
							</fieldset>
							<fieldset className={styles.fieldset}>
								<Typo className={styles.legend} design="text-13-medium" as="legend">
									Доска задач
								</Typo>
								<FormElement>
									<BoardsListSelector
										selectedBoard={selectedBoard}
										setSelectedBoard={setSelectedBoard}
										taskBoard={taskBoard}
									/>
								</FormElement>
							</fieldset>
							<div id="convert-to-task-portal" />
						</div>
					}
					onAccept={handleSubmit(createTask)}
					onDiscard={() => setSubtaskToolsDd('main')}
					acceptLabel="Преобразовать"
					acceptColor="primary"
					textAlign="left"
				/>
			</div>
		</FocusTrap>
	);
};
