import { useStore } from 'effector-react';
import React, { FC, useState, useRef, MutableRefObject, useMemo } from 'react';

import { BoardParticipantDto, UserDto } from '@shared/api/model/models';
import { useEscape, useOutsideClick } from '@shared/lib/hooks';
import { Button, Icon, Dropdown, AvatarsGroup, MoreButton, AvatarUser, Skeleton } from '@shared/ui';

import { useBoardRoles } from '@entities/board-roles';
import { boardModel } from '@entities/boards';

import { AddParticipantToBoard } from '@features/add-participant-to-board';
import { ParticipantDropdown, SelectedParticipant } from '@features/board-participant-dropdown';
import { useUserRoles } from '@features/user-roles';

import { boardParticipants } from '@widgets/board-participants-modal';

import styles from './styles.module.scss';

interface TaskBoardPageParticipantsProps {
	boardIsArchived?: boolean;
	isLoading?: boolean;
}

const initialStateParticipantDropdown = {
	open: false,
	position: 0,
};

export const TaskBoardPageParticipants: FC<TaskBoardPageParticipantsProps> = ({
	boardIsArchived,
	isLoading,
}) => {
	const { userOnBoard } = useUserRoles();

	const dropdownParticipantRef = useRef<HTMLDivElement>(null);

	const board = useStore(boardModel.$board);
	const { boardRoles } = useBoardRoles();

	const [stateParticipantDropdown, setStateParticipantDropdown] = useState(
		initialStateParticipantDropdown
	);
	const [isOpenAddParticipantsDd, setIsOpenAddParticipantsDd] = useState(false);

	const [selectedParticipant, setSelectedParticipant] = useState<SelectedParticipant>(
		{} as SelectedParticipant
	);

	const addParticipantsDdRef = useRef<HTMLDivElement>(null);
	const addParticipantsDdBtnRef = useRef<HTMLButtonElement>(null);

	useOutsideClick([addParticipantsDdRef, addParticipantsDdBtnRef], () =>
		setIsOpenAddParticipantsDd(false)
	);

	useEscape(
		true,
		() => setIsOpenAddParticipantsDd(false),
		addParticipantsDdRef as MutableRefObject<HTMLElement>
	);

	const setOpenParticipantDropdown = (event: AvatarUser, index: number) => {
		setStateParticipantDropdown({
			open: true,
			position: index,
		});
		if (!index) {
			setSelectedParticipant({ user: board?.author as UserDto, role: {}, isCurrentUser: true });
		} else {
			const participant = board?.participants?.find(
				(participant) => participant.user.id === event.id
			) as BoardParticipantDto;

			setSelectedParticipant({ ...participant });
		}
	};

	const closeDdAndSafePosition = () =>
		setStateParticipantDropdown((prevState) => ({ ...prevState, open: false }));

	useEscape(
		true,
		(e) => {
			e?.stopPropagation();
			closeDdAndSafePosition();
		},
		dropdownParticipantRef as MutableRefObject<HTMLElement>
	);

	useOutsideClick(dropdownParticipantRef, () => closeDdAndSafePosition());

	const names = board?.participants && board?.participants.map((name) => name.user);
	const namesWithAuthor =
		board?.author && names ? [{ ...board.author, isCurrentUser: true }, ...names] : names || [];

	const addParticipantDrop = useMemo(() => {
		const excludeUserIds = (board?.author ? [board.author] : []).map(({ id }) => id);
		return (
			<AddParticipantToBoard
				excludeUserIds={excludeUserIds}
				disableRolesChanging={!userOnBoard.isAuthor}
			/>
		);
	}, [board?.author, userOnBoard.isAuthor]);

	return (
		<div className={styles.participantsWrapper}>
			{!isLoading && (
				<>
					<div className={styles.participants}>
						{names && (
							<Dropdown
								ref={dropdownParticipantRef}
								isOpen={stateParticipantDropdown.open}
								openOnButtonClick={false}
								placement="bottomEnd"
								customCoordinates={{
									right: `${
										names.length > 4
											? (4 - stateParticipantDropdown.position) * 28 - 4
											: (names.length - stateParticipantDropdown.position) * 28 - 4
									}px`,
								}}
								targetButton={
									<AvatarsGroup
										size="md"
										onAvatarClick={setOpenParticipantDropdown}
										names={namesWithAuthor.slice(0, 5)}
									/>
								}
								dropdownData={(props, ref) => (
									<ParticipantDropdown
										participant={selectedParticipant}
										boardRoles={boardRoles}
										setRole={(role) => {
											boardModel.setRoleParticipant({
												participantId: selectedParticipant.id as string,
												roleId: role.role.id as string,
											});
											closeDdAndSafePosition();
										}}
										remove={() => {
											boardModel.removeParticipant(selectedParticipant.id as string);
											closeDdAndSafePosition();
										}}
										isDisabled={boardIsArchived}
										currentUserRole={userOnBoard.role}
										disableRolesChanging={!userOnBoard.isAuthor}
									/>
								)}
							/>
						)}
						{names && names.length > 4 && (
							<MoreButton onClick={boardParticipants} count={names.length - 4} />
						)}
					</div>
					{!boardIsArchived && userOnBoard.isEditor && (
						<Dropdown
							ref={addParticipantsDdRef}
							placement="bottomEnd"
							isOpen={isOpenAddParticipantsDd}
							openOnButtonClick={false}
							closeOnClickOutside={false}
							closeOnEsc
							targetButton={
								<Button
									ref={addParticipantsDdBtnRef}
									onClick={() => setIsOpenAddParticipantsDd((prevState) => !prevState)}
									iconLeft={<Icon id="people-add" />}
									color="secondary"
									disabled={boardIsArchived}>
									Пригласить
								</Button>
							}
							dropdownData={(props, ref) => null}
							dropdownElement={addParticipantDrop}
						/>
					)}
				</>
			)}
			{isLoading && (
				<>
					<Skeleton width={184} height={32} />
					<Skeleton width={143} height={32} />
				</>
			)}
		</div>
	);
};
