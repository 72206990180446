import { createEffect, createEvent, createStore, sample } from 'effector';
import { reset, status } from 'patronum';

import {
	UserDto,
	SubtaskDto,
	TaskStatusDto,
	CreateSubtaskRequest,
	UpdateSubtaskRequest,
	DeleteSubtaskRequest,
	ToggleSubtaskCompletedRequest,
	subtasks,
} from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { taskEventsListModel } from '../task-events-list';
/**
 * Events для созданных подзадач
 */
const addSubtask = createEvent<Partial<SubtaskDto>>();
const removeSubtask = createEvent<number>();
const toggleCompletedSubtask = createEvent<{
	value: boolean;
	index: number;
	status?: TaskStatusDto;
}>();
const toggleArchivedOfSubtask = createEvent<{ value: boolean; index: number }>();
const addAssigneeToSubtask = createEvent<{ value: UserDto; index: number }>();
const removeAssigneeFromSubtask = createEvent<{ value: UserDto; index: number }>();
const addDateToSubtask = createEvent<{ value: Nullable<string>; index: number }>();
const changeTitleOfSubtask = createEvent<{ value: string; index: number }>();
const resetSubtasksOnModalClose = createEvent();

/**
 * Stores для созданных подзадач
 */
const $subtasks = createStore<Partial<SubtaskDto>[]>([]).reset(resetSubtasksOnModalClose);

/**
 * Events для создаваемой подзадачи
 */
const resetCreatedSubtask = createEvent();
const addAssigneeToBeingCreatedSubtask = createEvent<UserDto>();
const removeAssigneeFromBeingCreatedSubtask = createEvent();
const addDateToBeingCreatedSubtask = createEvent<Nullable<string>>();

/**
 * Stores для создаваемой подзадачи
 */
const $createdSubtaskAssignee = createStore<Nullable<UserDto>>(null).reset([
	resetSubtasksOnModalClose,
	removeAssigneeFromBeingCreatedSubtask,
]);
const $createdSubtaskDate = createStore<Nullable<string>>(null).reset(resetSubtasksOnModalClose);

reset({
	clock: resetCreatedSubtask,
	target: [$createdSubtaskAssignee, $createdSubtaskDate],
});

/**
 * Samples для созданных подзадач
 */
sample({
	source: $subtasks,
	clock: addSubtask,
	fn: (source, clock) => [...source, clock],
	target: $subtasks,
});

sample({
	source: $subtasks,
	clock: removeSubtask,
	fn: (source, clock) => [...source.slice(0, clock), ...source.slice(clock + 1)],
	target: $subtasks,
});

sample({
	source: $subtasks,
	clock: toggleCompletedSubtask,
	fn: (source, clock) => {
		const newSubtask = { ...source[clock.index], completed: clock.value };
		if (newSubtask?.linkedTask && clock.status) {
			newSubtask.linkedTask = {
				...newSubtask.linkedTask,
				status: clock.status,
			};
		}
		return [...source.slice(0, clock.index), newSubtask, ...source.slice(clock.index + 1)];
	},
	target: $subtasks,
});

sample({
	source: $subtasks,
	clock: toggleArchivedOfSubtask,
	fn: (source, clock) => {
		const newSubtask = { ...source[clock.index] };
		if (newSubtask?.linkedTask) {
			newSubtask.linkedTask.archived = clock.value;
		}
		return [...source.slice(0, clock.index), newSubtask, ...source.slice(clock.index + 1)];
	},
	target: $subtasks,
});

sample({
	source: $subtasks,
	clock: addAssigneeToSubtask,
	fn: (source, clock) => {
		const currentSubtask = source[clock.index];

		return [
			...source.slice(0, clock.index),
			{ ...currentSubtask, assignee: { ...clock.value } },
			...source.slice(clock.index + 1),
		];
	},
	target: $subtasks,
});

sample({
	source: $subtasks,
	clock: removeAssigneeFromSubtask,
	fn: (source, clock) => {
		const currentSubtask = source[clock.index] as SubtaskDto;
		delete currentSubtask.assignee;

		return [
			...source.slice(0, clock.index),
			{ ...currentSubtask },
			...source.slice(clock.index + 1),
		];
	},
	target: $subtasks,
});

sample({
	source: $subtasks,
	clock: addDateToSubtask,
	fn: (source, clock) => {
		const currentSubtask = source[clock.index];

		return [
			...source.slice(0, clock.index),
			{ ...currentSubtask, dueDate: clock.value ?? undefined },
			...source.slice(clock.index + 1),
		];
	},
	target: $subtasks,
});

sample({
	source: $subtasks,
	clock: changeTitleOfSubtask,
	fn: (source, clock) => {
		const currentSubtask = source[clock.index];

		return [
			...source.slice(0, clock.index),
			{ ...currentSubtask, title: clock.value },
			...source.slice(clock.index + 1),
		];
	},
	target: $subtasks,
});

/**
 * Samples для создаваемой подзадачи
 */
sample({
	clock: addAssigneeToBeingCreatedSubtask,
	target: $createdSubtaskAssignee,
});

sample({
	clock: addDateToBeingCreatedSubtask,
	target: $createdSubtaskDate,
});

reset({
	clock: [addSubtask, resetSubtasksOnModalClose],
	target: [$createdSubtaskAssignee, $createdSubtaskDate],
});

/**
 * Операции с подзадачами
 */
const createSubtask = createEvent<CreateSubtaskRequest>();
const createSubtaskFx = createEffect(subtasks.createSubtask);

const updateSubtask = createEvent<UpdateSubtaskRequest>();
const updateSubtaskFx = createEffect(subtasks.putSubtask);

const deleteSubtask = createEvent<DeleteSubtaskRequest>();
const deleteSubtaskFx = createEffect(subtasks.deleteSubtask);

const toggleCompleted = createEvent<ToggleSubtaskCompletedRequest>();
const toggleCompletedFx = createEffect(subtasks.toggleSubtaskCompleted);

sample({
	clock: createSubtask,
	target: createSubtaskFx,
});

sample({
	clock: updateSubtask,
	target: updateSubtaskFx,
});

sample({
	clock: deleteSubtask,
	target: deleteSubtaskFx,
});

sample({
	clock: toggleCompleted,
	target: toggleCompletedFx,
});

sample({
	clock: createSubtaskFx.doneData,
	target: addSubtask,
});

sample({
	clock: toggleCompletedFx.doneData,
	target: taskEventsListModel.revalidate,
});

sample({
	clock: createSubtaskFx.done,
	fn: (clock) => ({
		type: 'success' as NoticeType,
		text: `Подзадача "<b>${clock.params.payload.title}</b>" добавлена.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: deleteSubtaskFx.done,
	fn: (clock) => ({
		type: 'success' as NoticeType,
		text: `Подзадача "<b>${clock.params.title}</b>" удалена.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: toggleCompletedFx.done,
	fn: (clock) => ({
		type: 'success' as NoticeType,
		text: `Статус подзадачи ${
			clock?.params?.title?.length ? `<b>${clock.params.title}</b> ` : ``
		}изменен.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: [createSubtaskFx.fail, updateSubtaskFx.fail, deleteSubtaskFx.fail, toggleCompletedFx.fail],
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

const $createSubtaskStatus = status({ effect: createSubtaskFx });
const $deleteSubtaskStatus = status({ effect: deleteSubtaskFx });

export const model = {
	addSubtask,
	removeSubtask,
	toggleCompletedSubtask,
	toggleArchivedOfSubtask,
	addAssigneeToSubtask,
	removeAssigneeFromSubtask,
	addDateToSubtask,
	changeTitleOfSubtask,
	resetSubtasksOnModalClose,
	resetCreatedSubtask,
	addAssigneeToBeingCreatedSubtask,
	removeAssigneeFromBeingCreatedSubtask,
	addDateToBeingCreatedSubtask,
	createSubtask,
	createSubtaskFx,
	updateSubtaskFx,
	deleteSubtaskFx,
	toggleCompletedFx,
	$subtasks,
	$createdSubtaskAssignee,
	$createdSubtaskDate,
	$createSubtaskStatus,
	$deleteSubtaskStatus,
};
