import { createEvent, createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';

import { TaskReassignRequestDto, taskReassignRequests } from '@shared/api';

const TaskReassignRequestGate = createGate<Nullable<string>>();

const getTaskReassignRequestById = createEvent<string>();

const getTaskReassignRequestByIdFx = createEffect(taskReassignRequests.getTaskReassignRequestById);

const $taskReassignRequest = createStore<Nullable<TaskReassignRequestDto>>(null);

sample({
	clock: TaskReassignRequestGate.open,
	filter: (clock) => Boolean(clock),
	fn: (clock) => clock ?? '',
	target: getTaskReassignRequestById,
});

sample({
	clock: TaskReassignRequestGate.state,
	filter: (clock) => !clock,
	fn: () => null,
	target: $taskReassignRequest,
});

sample({
	clock: getTaskReassignRequestById,
	target: getTaskReassignRequestByIdFx,
});

sample({
	clock: getTaskReassignRequestByIdFx.doneData,
	target: $taskReassignRequest,
});

export const model = {
	TaskReassignRequestGate,
	$taskReassignRequest,
};
