import { format } from 'date-fns';
import React, { FC } from 'react';

import styles from './styles.module.scss';

export interface DatetimeProps {
	date: string;
	dateFormat: string;
	className?: string;
}

export const Datetime: FC<DatetimeProps> = React.memo(({ date, dateFormat }) => {
	return <div className={styles.date}>{format(new Date(date), dateFormat)}</div>;
});
