import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { Typo } from '@shared/ui';

import { taskListModel, taskTagsTableColumnsModel } from '@entities/task';
import { TasksTransposedTableRowEntity } from '@entities/task/lib';

import { TaskTransposedTableColumn } from '../../ui';
import styles from '../styles.module.scss';

interface TaskTransposedTableRowProps extends TasksTransposedTableRowEntity {
	onCardClick: (id: string) => void;
}

export const TaskTransposedTableRow: FC<TaskTransposedTableRowProps> = React.memo(
	({ status, columns, onCardClick }) => {
		const counters = useStore(taskListModel.$taskListCounters);
		const countersByTags = useStore(taskListModel.$taskListCountersByTags);
		const columnsState = useStore(taskTagsTableColumnsModel.$tagsTableColumns);

		return (
			<div className={styles.taskTableRow} role="row">
				<div className={styles.taskTableRowHeader} role="rowheader">
					<Typo
						role="columnheader"
						design="text-14-bold"
						className={styles.taskTableRowHeaderStatus}>
						{status.name}
						<Typo as="span" design="text-14-medium" className={styles.taskTableRowHeaderCount}>
							{status?.type ? counters[status.type] : ''}
						</Typo>
					</Typo>
				</div>
				<div className={classnames(styles.taskTableRowGrid, styles.taskTableRowGridCompact)}>
					{columns?.map((column) => {
						const countInColumn = countersByTags[column.tag.id];
						return countInColumn > 0 ? (
							<TaskTransposedTableColumn
								key={`${status.id}-${column.tag?.id}`}
								tagId={column.tag?.id || ''}
								statusId={status.id}
								tasks={column.items}
								onCardClick={onCardClick}
								collapsed={!(column.tag?.id && columnsState[column.tag?.id])}
							/>
						) : null;
					})}
				</div>
			</div>
		);
	}
);
