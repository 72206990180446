import React, { useRef, useState, MutableRefObject } from 'react';

import { BoardParticipantDto, BoardRoleDto, BoardRoleDtoNameEnum } from '@shared/api';
import { useEscape } from '@shared/lib/hooks';
import { Button, Dropdown, Icon, UserInfo } from '@shared/ui';

import { CurrentUserDropdown } from '@entities/current-user-dropdown';
import { Participant } from '@entities/participant';

import styles from './styles.module.scss';

interface ParticipantsSearchListItemProps {
	participant: Partial<BoardParticipantDto>;
	boardRoles: BoardRoleDto[];
	setRole: (role: BoardParticipantDto) => void;
	remove: (id: string) => void;
	currentUserRole?: string;
	mode?: 'create' | 'edit';
	isDisabled?: boolean;
	disableRolesChanging?: boolean;
}

export const ParticipantsSearchListItem = ({
	participant,
	boardRoles,
	setRole,
	remove,
	currentUserRole,
	mode,
	isDisabled,
	disableRolesChanging,
}: ParticipantsSearchListItemProps) => {
	const ddBtnRef = useRef<HTMLButtonElement>(null);
	const participantRef = useRef<HTMLDivElement>(null);

	const [open, setOpen] = useState(false);

	useEscape(
		true,
		(e) => {
			if (!participant.role || currentUserRole !== BoardRoleDtoNameEnum.EDITOR) {
				e?.stopPropagation();
				setOpen(false);
			}
		},
		participantRef as MutableRefObject<HTMLElement>
	);

	const returnFocusTo = () => {
		setTimeout(() => {
			ddBtnRef.current?.focus();
		}, 10);
	};

	return (
		<div className={styles.participant} ref={participantRef}>
			<UserInfo
				user={participant.user!}
				icon={!Boolean(participant.role)}
				showJobTitle
				nameInOneLine
			/>
			<Dropdown
				portal
				dropdownId="participants-search-list"
				placement="bottomEnd"
				openOnButtonClick={false}
				isOpen={open}
				visibilityHandler={setOpen}
				onScrollCloseThreshold={20}
				targetButton={
					<Button
						ref={ddBtnRef}
						id={'participant' + participant.user?.id}
						onClick={() => setOpen((prevState) => !prevState)}
						size="sm"
						design="transparent">
						<Icon id="kebab" />
					</Button>
				}
				dropdownData={(props, ref) => (
					<>
						{participant.role &&
						(currentUserRole === BoardRoleDtoNameEnum.EDITOR ||
							mode === 'create' ||
							mode === 'edit') ? (
							<Participant
								participant={participant}
								boardRoles={boardRoles}
								setRole={(role) => {
									setOpen(false);
									setRole(role);
								}}
								remove={(id) => {
									setOpen(false);
									remove(id);
								}}
								onEscape={() => {
									setOpen(false);
									returnFocusTo();
								}}
								outsideClickExcludeRef={ddBtnRef}
								isDisabled={isDisabled}
								disableRolesChanging={disableRolesChanging}
							/>
						) : (
							<CurrentUserDropdown
								participant={participant}
								boardRoles={boardRoles}
								onEscape={() => {
									setOpen(false);
									returnFocusTo();
								}}
								outsideClickExcludeRef={ddBtnRef}
								icon={!Boolean(participant.role)}
							/>
						)}
					</>
				)}
			/>
		</div>
	);
};
