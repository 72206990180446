import { createEvent, createStore, sample } from 'effector';

import { TagDto } from '@shared/api';

import { taskListTagsModel } from '../task-list-tags';

const tagsTableColumnToggle = createEvent<TagDto['id']>();

const $tagsTableColumns = createStore<Record<string, boolean>>({});

sample({
	source: $tagsTableColumns,
	clock: taskListTagsModel.$taskListTags,
	fn: (source, tags) => {
		const currentKeys = Object.keys(source);

		const map = tags.reduce((acc, tag) => {
			if (currentKeys.includes(tag.id)) {
				acc[tag.id] = source[tag.id];
			} else {
				acc[tag.id] = true;
			}

			return acc;
		}, {} as Record<string, boolean>);

		return map;
	},
	target: $tagsTableColumns,
});

sample({
	clock: tagsTableColumnToggle,
	source: $tagsTableColumns,
	fn: (columns, toggleTagById) => {
		columns[toggleTagById] = !columns[toggleTagById];
		return { ...columns };
	},
	target: $tagsTableColumns,
});

export const model = {
	tagsTableColumnToggle,
	$tagsTableColumns,
};
