import { createEvent, createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';

import { GetBoardsShortRequest, boardsRequests, BoardShortDto } from '@shared/api';

const BoardsListGate = createGate<GetBoardsShortRequest>();

const fetchBoardsList = createEvent<GetBoardsShortRequest>();
const reset = createEvent<GetBoardsShortRequest>();

const fetchBoardsListFx = createEffect(boardsRequests.getBoardsShort);

const $boardsList = createStore<BoardShortDto[]>([]).reset(reset);

sample({
	clock: BoardsListGate.open,
	target: fetchBoardsList,
});

sample({
	clock: BoardsListGate.close,
	target: reset,
});

sample({
	clock: fetchBoardsList,
	target: fetchBoardsListFx,
});

sample({
	clock: fetchBoardsListFx.doneData,
	target: $boardsList,
});

export const model = {
	BoardsListGate,
	$boardsList,
};
