import { useStore } from 'effector-react';
import React, { FC, PropsWithChildren } from 'react';

import { ViewportProvider } from '@shared/lib/context';
import { useAccessibility } from '@shared/lib/hooks';
import { asideModel } from '@shared/model';
import { Modals, Tooltips, NotificationWrap } from '@shared/ui';
import { SvgSprite } from '@shared/ui/icons/svg-sprite';

import { Aside, NavList } from './';

export type LayoutProps = PropsWithChildren<{
	nav: NavList;
	isSimple?: boolean;
}>;

export const Layout: FC<LayoutProps> = ({ children, nav, isSimple }) => {
	const thisMouse = useAccessibility();
	const pageOffset = useStore(asideModel.$width);

	return (
		<ViewportProvider>
			<SvgSprite />
			<main className={`page__main ${thisMouse ? 'mouse' : 'keyboard'}`}>
				{isSimple ? (
					<>{children}</>
				) : (
					<>
						<Aside nav={nav} />
						<div style={{ width: `calc(100% - ${pageOffset}px)` }} className={'page__content'}>
							{children}
						</div>
					</>
				)}
			</main>
			<Tooltips />
			<Modals pageOffset={pageOffset} />
			<NotificationWrap pageOffset={isSimple ? 0 : pageOffset} />
		</ViewportProvider>
	);
};
