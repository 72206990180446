import { useStore } from 'effector-react';
import { useEffect, useRef, useState } from 'react';

import { DatesFactoryTypes } from '@entities/task';

const MESSAGE = 'Срок выполнения задачи';

export const getDatesUpdateMessage = (
	end?: string,
	localEnd?: string,
	start?: string,
	localStart?: string
) => {
	if (!end && localEnd) {
		return `${MESSAGE} добавлен`;
	}

	if (end && !localEnd) {
		return `${MESSAGE} удалён`;
	}

	if (
		(end && localEnd && end !== localEnd?.replace('.000', '')) ||
		(start && localStart && start !== localStart?.replace('.000', ''))
	) {
		return `${MESSAGE} изменён`;
	}
};

interface TaskDates {
	endDate?: string;
	startDate?: string;
	deadlineReminderDate?: string;
}

export const useDatesUpdateMessage = (model: DatesFactoryTypes, menuIsOpen: boolean) => {
	const mount = useRef(false);

	const datesStore = useStore(model.$dates);
	const [actualDates, setActualDates] = useState<TaskDates>({});

	useEffect(() => {
		if (menuIsOpen) {
			if (!mount.current) {
				mount.current = true;
				setActualDates(datesStore);
			}
		} else {
			mount.current = false;
		}
	}, [menuIsOpen, datesStore]);

	return () =>
		getDatesUpdateMessage(
			actualDates?.endDate,
			datesStore?.endDate,
			actualDates?.startDate,
			datesStore?.startDate
		);
};
