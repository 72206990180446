import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserDto } from '@shared/api';
import { viewTask } from '@shared/ui';

import { boardViewModel, toggleTagsOnTaskModel } from '@entities/boards';
import { taskListModel, taskListTagsModel } from '@entities/task';
import { TasksTransposedTableArray } from '@entities/task/lib';

import { TaskTableSkeleton, TaskTableHeaderWithShadow, TaskTransposedTableRow } from '../../ui';
import styles from '../styles.module.scss';

import { TaskTransposedTableHead } from './';

interface TaskTransposedTableProps {
	viewer: Nullable<UserDto>;
	data: Nullable<TasksTransposedTableArray>;
	isEmpty?: boolean;
	isLoading?: boolean;
	pageOffset?: number;
	headerOffset?: number;
}

export const TaskTransposedTable: FC<TaskTransposedTableProps> = React.memo(
	({ viewer, data, isLoading, isEmpty }) => {
		const navigate = useNavigate();
		const boardViewChanged = useStore(boardViewModel.$changed);
		const boardTagsChanged = useStore(taskListTagsModel.$boardTagsChanged);
		const showTagsOnTasksChanged = useStore(toggleTagsOnTaskModel.$showTagsOnTasksChanged);

		const taskCardClickHandler = (id: string, title: string = '') => {
			if (boardViewChanged || boardTagsChanged || showTagsOnTasksChanged) {
				viewTask({ id, title, viewer, mode: 'edit' });
				return;
			}
			navigate(`task/${id}`);
		};

		const counters = useStore(taskListModel.$taskListCountersByTags);

		const header = useMemo(() => {
			const rows = data ? [...data] : [];
			return (
				<>
					{rows[0]?.columns?.map(({ tag }) => {
						const countInColumn = counters[tag.id];
						return tag && countInColumn > 0 ? (
							<TaskTransposedTableHead key={tag.id} {...tag} count={countInColumn} />
						) : null;
					})}
				</>
			);
		}, [counters, data]);

		if (isLoading) return <TaskTableSkeleton />;
		if (isEmpty) return <></>;

		return (
			<div className={styles.taskTable} role="table">
				<TaskTableHeaderWithShadow content={header} />
				{data?.map((row) => (
					<TaskTransposedTableRow key={row.status.id} onCardClick={taskCardClickHandler} {...row} />
				))}
			</div>
		);
	}
);
