import { useEffect, useRef } from 'react';

export const useResizableTextarea = () => {
	const ref = useRef<HTMLTextAreaElement>(null);

	// Изменение высоты textarea по высоте содержимого
	const resize = (e: Event) => {
		if (e.target) {
			const textarea = e.target as HTMLTextAreaElement;
			textarea.style.height = '';
			textarea.style.height = textarea.scrollHeight + 'px';
		}
	};

	useEffect(() => {
		const node = ref.current;

		if (node) {
			node.addEventListener('input', resize);
			return () => node?.removeEventListener('input', resize);
		}
	}, [ref]);

	return ref;
};
