import classnames from 'classnames';
import React, { useMemo, FC, CSSProperties } from 'react';

import './Skeleton.scss';

interface SkeletonProps {
	display?: CSSProperties['display'];
	position?: CSSProperties['position'];
	width?: CSSProperties['width'];
	height?: CSSProperties['height'];
	margin?: CSSProperties['margin'];
	top?: CSSProperties['top'];
	delay?: number;
	className?: string;
	theme?: 'light';
}

export const Skeleton: FC<SkeletonProps> = ({
	display,
	position,
	width,
	height,
	margin,
	top,
	delay,
	className,
	theme,
}) => {
	const css = useMemo(
		() => ({ display, position, width, height, margin, top, animationDelay: `${delay}ms` }),
		[display, position, width, height, margin, delay, top]
	);
	return <span className={classnames(className, 'skeleton', theme)} style={css} />;
};
