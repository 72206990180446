import focusTrap from 'focus-trap-js';
import { MutableRefObject, useEffect } from 'react';

export const useFocusTrap = (ref: MutableRefObject<HTMLElement | null>) => {
	useEffect(() => {
		if (!ref.current) return;

		const handleKeyDown = (e: KeyboardEvent) => {
			const node = (ref as MutableRefObject<HTMLElement>).current;
			if (node) focusTrap(e, node);
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, [ref]);
};
