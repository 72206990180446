import {
	createEvent,
	createEffect,
	createStore,
	sample,
	restore,
	combine,
	forward,
} from 'effector';
import { createGate } from 'effector-react';

import { GetTaskByIdRequest, TagDtoTypeEnum, TaskDto, tasksRequests } from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { assigneeModel } from '../assignee';
import { coAssigneesModel } from '../co-assignees';
import { datesModel } from '../dates';
import { taskFilesModel } from '../files';
import { requireApproveModel } from '../require-approve';
import { subtasksModel } from '../subtasks';
import { tagsModel } from '../tags';

const getTaskById = createEvent<GetTaskByIdRequest>();
const getTaskByIdFx = createEffect(tasksRequests.getTaskById);
const updateTaskByIdFx = createEffect(tasksRequests.getTaskById);

const resetTask = createEvent();
const revalidateTask = createEvent();

const $task = createStore<Nullable<TaskDto>>(null).reset(resetTask);

const $taskConfig = createStore<GetTaskByIdRequest | null>(null).reset(resetTask);
const $taskError = restore<Error>(getTaskByIdFx.failData, null).reset(resetTask);

const $taskStatus = combine({
	loading: getTaskByIdFx.pending,
	data: $task,
	error: $taskError,
});

const TaskGate = createGate<GetTaskByIdRequest>();

forward({
	from: TaskGate.state,
	to: getTaskById,
});

forward({
	from: TaskGate.close,
	to: [
		resetTask,
		assigneeModel.removeAssignee,
		coAssigneesModel.resetCoAssignees,
		datesModel.resetDates,
		subtasksModel.resetSubtasksOnModalClose,
		tagsModel.resetTags,
		requireApproveModel.resetRequireApprove,
	],
});

sample({
	clock: getTaskById,
	filter: ({ id }) => !!id,
	target: [$taskConfig, getTaskByIdFx],
});

sample({
	clock: getTaskByIdFx.doneData,
	target: $task,
});

sample({
	clock: updateTaskByIdFx.doneData,
	target: $task,
});

sample({
	source: $taskConfig,
	clock: revalidateTask,
	fn: (source) => {
		return source ?? { id: '' };
	},
	target: updateTaskByIdFx,
});

sample({
	clock: [getTaskByIdFx.failData],
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

sample({
	clock: $task,
	fn: (task) => {
		return {
			endDate: task?.endDate ?? undefined,
			startDate: task?.startDate ?? undefined,
			deadlineReminderDate: task?.deadlineReminderDate ?? undefined,
		};
	},
	target: [datesModel.$dates, datesModel.$localDates],
});

sample({
	clock: $task,
	fn: (task) =>
		task?.tags
			? Array.from(task?.tags)?.filter(
					(item) => item !== null && item?.type !== TagDtoTypeEnum.SYSTEM_WITHOUT_TAG
			  )
			: [],
	target: [tagsModel.$tags],
});

sample({
	clock: $task,
	fn: (task) => task?.assignee || null,
	target: [assigneeModel.$assignee],
});

sample({
	clock: $task,
	fn: (task) => task?.coAssignees?.filter((item) => item !== null) || [],
	target: [coAssigneesModel.$coAssignees],
});

sample({
	clock: $task,
	fn: (task) => task?.files?.filter((item) => item !== null) || null,
	target: [taskFilesModel.$files],
});

sample({
	clock: $task,
	fn: (task) => task?.subtasks || [],
	target: subtasksModel.$subtasks,
});

sample({
	clock: $task,
	fn: (task) => !!task?.requireApprove,
	target: requireApproveModel.$requireApprove,
});

/*
 * Direct task store update
 */
const updateTaskStore = createEvent<Nullable<Partial<TaskDto>>>();

sample({
	clock: updateTaskStore,
	source: $task,
	filter: (_, task) => !!task,
	fn: (prev, task) => {
		return {
			...prev,
			...task,
		} as TaskDto;
	},
	target: $task,
});

/*
 * Update task approval
 */
const updateTaskApproval = createEvent<Nullable<Partial<TaskDto>>>();

sample({
	clock: updateTaskApproval,
	source: $task,
	filter: (_, task) => !!task,
	fn: (prev, task) => {
		const { status, taskApproval, requireApprove } = task as Partial<TaskDto>;
		return {
			...prev,
			status,
			taskApproval,
			requireApprove,
		} as TaskDto;
	},
	target: $task,
});

export const model = {
	TaskGate,
	$task,
	$taskStatus,
	$taskConfig,
	getTaskById,
	revalidateTask,
	updateTaskStore,
	updateTaskApproval,
};
