import { createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';

import { boardsRequests } from '@shared/api';
import { BoardRoleDto } from '@shared/api/model/boardRoleDto';

const boardRolesLoad = createGate();

const fetchBoardRolesFx = createEffect(boardsRequests.getBoardsRoles);

const $boardRoles = createStore<BoardRoleDto[]>([]);

sample({
	source: $boardRoles,
	clock: boardRolesLoad.open,
	filter: (source) => !Boolean(source.length),
	target: fetchBoardRolesFx,
});

sample({
	clock: fetchBoardRolesFx.doneData,
	target: $boardRoles,
});

export const model = {
	boardRolesLoad,
	$boardRoles,
};
