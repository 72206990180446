import { useStore } from 'effector-react';

import { TaskStatusDto, TaskStatusDtoTypeEnum } from '@shared/api';

import { subtasksModel, taskListModel, taskStatusesModel } from '@entities/task';

interface ToggleSubtaskStatusProps {
	taskId: string;
	subtaskId: string;
	subtaskTitle?: string;
	nextStatus: TaskStatusDtoTypeEnum;
	callback: (nextTaskStatus?: TaskStatusDto) => void;
	fallback: (prevTaskStatus?: TaskStatusDto) => void;
}

export const useToggleSubtaskStatus = () => {
	const { list } = useStore(taskListModel.$status);
	const statuses = useStore(taskStatusesModel.$statuses);

	const toggleSubtaskStatus = ({
		taskId,
		subtaskId,
		subtaskTitle,
		nextStatus,
		callback,
		fallback,
	}: ToggleSubtaskStatusProps) => {
		const initialTask = list.find((listItem) => listItem.id === taskId);

		const newStatusForTask = statuses.find(({ type }) => type === nextStatus);
		const prevTaskStatus = statuses.find(({ type }) => type === initialTask?.status?.type);

		/*
		 * Не обновлять task-list,
		 * если задача на другой доске
		 */
		if (initialTask) {
			taskListModel.updateTargetTask({ task: { ...initialTask, status: newStatusForTask } });
		}

		callback(newStatusForTask);

		subtasksModel
			.toggleCompletedFx({
				id: subtaskId,
				title: subtaskTitle || '',
			})
			.catch(() => {
				fallback(prevTaskStatus);
				if (initialTask) {
					taskListModel.updateTargetTask({ task: { ...initialTask } });
				}
			});
	};

	return {
		toggleSubtaskStatus,
	};
};
