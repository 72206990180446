import { USER_BOARD_EMPTY } from '@shared/lib/constants';

interface GetUserBoardEmptyMessageOptions {
	isArchive: boolean;
	isFilteredBySearch: boolean;
	isCreatedByMe: boolean;
}

export const getUserBoardEmptyMessage = ({
	isArchive,
	isFilteredBySearch,
	isCreatedByMe,
}: GetUserBoardEmptyMessageOptions) => {
	if (isArchive) {
		return isFilteredBySearch ? USER_BOARD_EMPTY.archivedWithSearch : USER_BOARD_EMPTY.archived;
	}

	return isCreatedByMe ? USER_BOARD_EMPTY.actualCreatedByMe : USER_BOARD_EMPTY.actualMy;
};
