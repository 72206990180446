import { BOARDS_LIST_EMPY } from '@shared/lib/constants';

interface GetBoardsEmptyMessageOptions {
	isArchive: boolean;
	isFilteredBySearch: boolean;
}

export const getBoardsEmptyMessage = ({
	isArchive,
	isFilteredBySearch,
}: GetBoardsEmptyMessageOptions) => {
	if (isArchive) {
		return isFilteredBySearch ? BOARDS_LIST_EMPY.archivedWithSearch : BOARDS_LIST_EMPY.archived;
	}

	return BOARDS_LIST_EMPY.actual;
};
