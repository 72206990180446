import { createEvent, createStore, sample } from 'effector';

const setRequireApprove = createEvent<boolean>();
const resetRequireApprove = createEvent();

const $requireApprove = createStore<Nullable<boolean>>(null).reset(resetRequireApprove);

sample({
	clock: setRequireApprove,
	target: $requireApprove,
});

export const model = {
	$requireApprove,
	setRequireApprove,
	resetRequireApprove,
};
