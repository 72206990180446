export const datesIsEqual = (a?: Nullable<Date>, b?: Nullable<Date>) => {
	return a?.setHours(0, 0, 0, 0) === b?.setHours(0, 0, 0, 0);
};

export const dateTimesIsEqual = (a?: Nullable<Date>, b?: Nullable<Date>) => {
	return a?.getTime() === b?.getTime();
};

export const dateIsLater = (a: Date, b: Date) => {
	return a?.getTime() > b?.getTime();
};

export const dateIsEarlier = (a: Date, b: Date) => {
	return a?.getTime() < b?.getTime();
};

export const timeIsLater = (startDate: string, endDate: string) => {
	const startDateHoursAndMinutes = startDate.split(':').map((item) => +item);
	const endDateHoursAndMinutes = endDate.split(':').map((item) => +item);

	return (
		startDateHoursAndMinutes[0] > endDateHoursAndMinutes[0] ||
		(startDateHoursAndMinutes[0] === endDateHoursAndMinutes[0] &&
			startDateHoursAndMinutes[1] > endDateHoursAndMinutes[1])
	);
};

export const timeIsEarlier = (startDate: string, endDate: string) => {
	const startDateHoursAndMinutes = startDate.split(':').map((item) => +item);
	const endDateHoursAndMinutes = endDate.split(':').map((item) => +item);
	return (
		endDateHoursAndMinutes[0] < startDateHoursAndMinutes[0] ||
		(endDateHoursAndMinutes[0] === startDateHoursAndMinutes[0] &&
			endDateHoursAndMinutes[1] < startDateHoursAndMinutes[1])
	);
};

export const withinOneDay = (dates: Nullable<Date>[] | null) => {
	if (!dates) return false;

	const [a, b] = dates;

	return datesIsEqual(a, b || a);
};
