import { useMemo } from 'react';

import { JWT, getJWT } from '@shared/lib/utils';

export const useAuth = (): Partial<JWT> => {
	let access = getJWT('access');

	if (process.env.NODE_ENV === 'development') {
		access = process.env.REACT_APP_DEV_TOKEN as string;
	}

	return useMemo<Partial<JWT>>(() => ({ access }), [access]);
};
