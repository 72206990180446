import { createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';

import { BoardDto } from '@shared/api/model/boardDto';
import { BoardParticipantDto } from '@shared/api/model/boardParticipantDto';
import { AvatarUser } from '@shared/ui';

interface SelectedParticipant extends BoardParticipantDto {
	isCurrentUser?: boolean;
}

const boardModalGate = createGate<BoardDto | null>();

const addParticipant = createEvent<BoardParticipantDto>();
const removeParticipant = createEvent<string | undefined>();
const setRoleParticipant = createEvent<BoardParticipantDto>();
const setSelectedParticipant = createEvent<AvatarUser>();
const resetSelectedParticipant = createEvent();
const resetParticipants = createEvent();

const $participants = createStore<BoardParticipantDto[]>([]).reset(resetParticipants);
const $selectedParticipant = createStore<SelectedParticipant | null>(null).reset(
	resetSelectedParticipant
);

sample({
	source: $participants,
	clock: addParticipant,
	fn: (source, clock) => [...source, { ...clock, emailNotificationEnabled: true, starred: false }],
	target: $participants,
});

sample({
	source: $participants,
	clock: removeParticipant,
	filter: (id) => Boolean(id),
	fn: (source, clock) => {
		const index = source.findIndex((item) => item.user.id === clock);
		return [...source.slice(0, index), ...source.slice(index + 1)];
	},
	target: $participants,
});

sample({
	source: $participants,
	clock: setRoleParticipant,
	fn: (source, clock) => {
		const index = source.findIndex((item) => item.user.id === clock.user.id);
		return [...source.slice(0, index), { ...source[index], ...clock }, ...source.slice(index + 1)];
	},
	target: $participants,
});

sample({
	source: $participants,
	clock: setSelectedParticipant,
	fn: (source, clock) => {
		if (clock.isCurrentUser) {
			const { isCurrentUser, ...user } = clock;
			return { user, isCurrentUser, role: {} };
		}

		const index = source.findIndex((item) => item.user.id === clock.id);
		return source[index];
	},
	target: $selectedParticipant,
});

sample({
	clock: boardModalGate.open,
	fn: (data) => data?.participants || [],
	target: $participants,
});

sample({
	clock: boardModalGate.close,
	target: resetParticipants,
});

export const model = {
	boardModalGate,
	addParticipant,
	removeParticipant,
	setRoleParticipant,
	$participants,
	$selectedParticipant,
	setSelectedParticipant,
};
