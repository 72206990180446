import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import React, { FC, KeyboardEventHandler, ReactElement, useMemo, useRef, useState } from 'react';

import { useOutsideClick } from '@shared/lib/hooks';
import { checkEscape, parentIsButton } from '@shared/lib/utils';
import {
	appTooltipsModel,
	Button,
	Dropdown,
	DropdownPlacement,
	Icon,
	Input,
	tooltipEventHandlersFactory,
	TooltipsEventHandlers,
} from '@shared/ui';

import styles from './styles.module.scss';

export interface SelectOption<T = string> {
	label: string;
	value: T | null;
}

interface SelectProps<T = string> {
	size?: 'sm' | 'md';
	initial: SelectOption<T>;
	options: SelectOption<T>[];
	onChange: (option: SelectOption<T>) => void;
	value?: SelectOption<T>;
	disabled?: boolean;
	portal?: boolean;
	portalId?: string;
	placement?: DropdownPlacement;
	additionalElement?: ReactElement;
	additionalElementAncestorValue?: T;
	closeOnAdditionalElementClick?: boolean;
	className?: string;
	maxWidth?: true;
	maxListSize?: number;
	withoutBorders?: boolean;
	withFilter?: boolean;
	filterPlaceholder?: string;
	filterEmptyBanner?: React.ReactElement;
	isLoading?: boolean;
}

interface SelectOptionElementProps {
	label: Nullable<string>;
	selected: boolean;
	onClick: () => void;
	onKeyDown?: KeyboardEventHandler;
}

interface SelectFilterProps {
	setFilter: React.Dispatch<React.SetStateAction<string>>;
	withShadow: boolean;
	placeholder?: string;
}

export const Select = React.memo(
	({
		initial,
		options,
		onChange,
		value,
		size = 'md',
		disabled,
		portal = true,
		portalId,
		placement = 'bottomStart',
		additionalElement,
		additionalElementAncestorValue,
		closeOnAdditionalElementClick = true,
		maxWidth,
		maxListSize,
		withoutBorders,
		withFilter,
		filterPlaceholder,
		filterEmptyBanner,
		className,
		isLoading,
	}: SelectProps) => {
		const [selected, setValue] = useState(initial);
		const [isOpen, setIsOpen] = useState(false);
		const [minWidth, setMinWidth] = useState<number | undefined>();
		const [withShadow, setWithShadow] = useState(false);
		const [filter, setFilter] = useState('');

		const resetFilter = () => setTimeout(() => setFilter(''), 100);

		const selectedOption = value || selected;
		const optionsList = useMemo(
			() =>
				filter.length > 0
					? options.filter(({ label }) => label.toLowerCase().startsWith(filter.toLowerCase()))
					: options,
			[options, filter]
		);

		const ddRef = useRef<HTMLDivElement | null>(null);
		const ddMenuRef = useRef<HTMLDivElement | null>(null);

		useOutsideClick([ddRef, ddMenuRef], () => {
			resetFilter();
			setIsOpen(false);
			appTooltipsModel.hideTooltip();
		});

		const dropdownElement = useMemo(
			() => (
				<FocusTrap
					focusTrapOptions={{
						initialFocus: false,
						escapeDeactivates: true,
						allowOutsideClick: true,
						checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
					}}>
					<div
						ref={ddMenuRef}
						onKeyDown={(e) => {
							e.stopPropagation();
							if (checkEscape(e)) {
								resetFilter();
								setIsOpen(false);
								appTooltipsModel.hideTooltip();
							}
						}}
						className={styles.selectDropContent}>
						{withFilter && (
							<SelectFilter
								setFilter={setFilter}
								withShadow={withShadow}
								placeholder={filterPlaceholder}
							/>
						)}
						<div
							className={classnames(styles.optionsList, {
								[styles.withoutBorders]: withoutBorders,
							})}
							style={
								maxListSize
									? { maxHeight: maxListSize * 40, minWidth, maxWidth: maxWidth && minWidth }
									: { minWidth, maxWidth: maxWidth && minWidth }
							}
							onScroll={({ target }) => {
								const scroller = target as HTMLDivElement;
								setWithShadow(scroller.scrollTop > 0);
							}}>
							{optionsList.length > 0 &&
								optionsList.map((item) => {
									return (
										<SelectOptionElement
											key={item.label + item.value}
											label={item.label}
											selected={item.value === selectedOption.value}
											onClick={() => {
												if (selectedOption.value !== item.value) {
													setValue(item);
													onChange(item);
												}
												if (additionalElement && additionalElementAncestorValue === item.value)
													return;
												resetFilter();
												setIsOpen(false);
												appTooltipsModel.hideTooltip();
											}}
										/>
									);
								})}
							{optionsList.length === 0 && withFilter && filterEmptyBanner}
							{additionalElementAncestorValue === selectedOption.value && (
								<div
									className={styles.additionalDrop}
									onClick={(e) => {
										if (parentIsButton(e.target as HTMLElement) && closeOnAdditionalElementClick) {
											resetFilter();
											setIsOpen(false);
											appTooltipsModel.hideTooltip();
										}
									}}>
									{additionalElement}
								</div>
							)}
						</div>
					</div>
				</FocusTrap>
			),
			[
				onChange,
				maxWidth,
				maxListSize,
				withoutBorders,
				withFilter,
				filterPlaceholder,
				filterEmptyBanner,
				additionalElement,
				additionalElementAncestorValue,
				closeOnAdditionalElementClick,
				optionsList,
				selectedOption.value,
				minWidth,
				withShadow,
			]
		);

		return (
			<Dropdown
				ref={ddRef}
				isOpen={isOpen}
				openOnButtonClick={false}
				closeOnClickOutside={false}
				portal={portal}
				dropdownId={portalId}
				placement={placement}
				className={classnames(className, styles.select)}
				dropdownClassName={styles.selectDrop}
				targetButton={
					<Button
						color={'secondary'}
						size={size}
						disabled={disabled}
						iconRight={
							isLoading ? (
								<Icon id={'loading'} className={styles.loadingIcon} />
							) : isOpen ? (
								<Icon id={'top'} />
							) : (
								<Icon id={'bottom'} />
							)
						}
						className={classnames(styles.button, styles[`button_${size}`])}
						onClick={() =>
							setIsOpen((prev) => {
								if (prev) {
									appTooltipsModel.hideTooltip();
								}
								return !prev;
							})
						}
						ref={(node) => {
							if (node) {
								setMinWidth(node.offsetWidth);
							}
						}}>
						{selectedOption.label}
					</Button>
				}
				dropdownData={(props, ref) => null}
				dropdownElement={dropdownElement}
			/>
		);
	}
);

export const SelectOptionElement: FC<SelectOptionElementProps> = ({
	label,
	selected,
	onClick,
	onKeyDown,
}) => {
	const [ellipsis, setEllipsis] = useState(false);

	const tooltipEventHandlers: TooltipsEventHandlers = useMemo(
		() => (ellipsis && label ? tooltipEventHandlersFactory(label, { margin: '0 12px' }) : {}),
		[ellipsis, label]
	);

	return (
		<button
			className={styles.option}
			onClick={onClick}
			onKeyDown={onKeyDown}
			{...tooltipEventHandlers}>
			<span
				className={styles.optionText}
				ref={(node) => {
					if (node) setEllipsis(node.offsetWidth < node.scrollWidth);
				}}>
				{label}
			</span>
			{selected && <Icon id={'done'} />}
		</button>
	);
};

export const SelectFilter: FC<SelectFilterProps> = ({
	withShadow,
	setFilter,
	placeholder = 'Поиск по названию',
}) => {
	const [value, setValue] = useState('');

	return (
		<div
			className={classnames(styles.filterBlock, {
				[styles.withShadow]: withShadow,
			})}>
			<div className={styles.filterBlockShadow} />
			<Input
				icon="search"
				theme="light-gray"
				placeholder={placeholder}
				value={value}
				onChange={(e) => {
					setValue(e.target.value);
					setFilter(e.target.value);
				}}
			/>
		</div>
	);
};
