import React from 'react';

import { TaskEventDto } from '@shared/api/model/models';

import { CommentUIProps } from '../../../model';
import { TaskEventFileManager } from '../../../ui';

import { ContentDefault } from './default-view';
import { ContentEdit } from './edit-view';

export interface ContentProps extends CommentUIProps {
	text?: TaskEventDto['text'];
	files?: TaskEventDto['files'];
	onSave?: () => void;
	onCancel?: () => void;
	editingActions: any;
	fileManagerAttach?: TaskEventFileManager;
}

export const Content = ({ view, ...props }: ContentProps) => {
	switch (view) {
		case 'default':
			return <ContentDefault {...props} />;
		case 'edit':
			return <ContentEdit {...props} />;
		default:
			return null;
	}
};
