import { Alignment, Side } from '@floating-ui/react-dom';
import { createEvent, createStore, sample } from 'effector';
import { CSSProperties } from 'react';

export type AppTooltipType = {
	text: string;
	element: Nullable<HTMLElement>;
	align?: Nullable<Alignment>;
	placement?: Side;
	margin?: CSSProperties['margin'];
	delayIn?: number;
};

const showTooltip = createEvent<Nullable<AppTooltipType>>();
const hideTooltip = createEvent();

const $tooltip = createStore<Nullable<AppTooltipType>>(null).reset(hideTooltip);

sample({
	clock: showTooltip,
	target: $tooltip,
});

export const model = {
	showTooltip,
	hideTooltip,
	$tooltip,
};
