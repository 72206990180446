import classnames from 'classnames';
import React, { InputHTMLAttributes, forwardRef } from 'react';

import styles from './styles.module.scss';

interface FormControlProps extends InputHTMLAttributes<HTMLInputElement> {
	type: 'radio' | 'checkbox';
}

export const FormControl = forwardRef<HTMLInputElement, FormControlProps>(
	({ type = 'checkbox', className, disabled, children, ...props }, forwardedRef) => {
		return (
			<label
				className={classnames(
					styles.formComp,
					styles[type],
					disabled && styles.disabled,
					className
				)}>
				<input {...props} disabled={disabled} type={type} ref={forwardedRef} />
				<span>{children}</span>
			</label>
		);
	}
);
