import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';

import { Button, Icon, tooltipEventHandlersFactory, TooltipsEventHandlers, Typo } from '@shared/ui';

import { boardViewModel } from '@entities/boards';

import styles from './styles.module.scss';

interface TaskBoardPageTableSettingsProps {
	onClick?: () => void;
}

export enum TableViewEnum {
	TAG = 'Метки',
	STATUS = 'Статусы',
}

export const TaskBoardPageTableSettings: FC<TaskBoardPageTableSettingsProps> = ({ onClick }) => {
	const boardView = useStore(boardViewModel.$store);

	const settingsButtonTooltipEventHandlers: TooltipsEventHandlers = useMemo(
		() => tooltipEventHandlersFactory('Поменять местами', { align: null, delayIn: 400 }),
		[]
	);

	if (!boardView?.rowType || !boardView.columnType) return null;

	return (
		<div className={styles.tableSettings}>
			<Typo className={styles.tableSettingsValue} design="text-13-medium">
				<span>Строки:</span>
				<span>{TableViewEnum[boardView.rowType]}</span>
			</Typo>
			<Button
				onClick={onClick}
				color="secondary"
				onlyIcon={<Icon id="up-n-down" className="svg-path" />}
				className={styles.tableSettingsButton}
				{...settingsButtonTooltipEventHandlers}
			/>
			<Typo className={styles.tableSettingsValue} design="text-13-medium">
				<span>Столбцы:</span>
				<span>{TableViewEnum[boardView.columnType]}</span>
			</Typo>
		</div>
	);
};
