import { MutableRefObject, useEffect } from 'react';

type UseOutsideClickOptions = {
	safe?: Array<string>;
	exclude?: Array<string>;
};

const getElement = (ref: MutableRefObject<HTMLElement | null> | HTMLElement | null) =>
	ref instanceof HTMLElement ? ref : ref?.current || null;

export const useOutsideClick = <T = HTMLElement | null>(
	ref: MutableRefObject<T> | T,
	handler: ((e?: MouseEvent | TouchEvent) => void) | undefined,
	options: UseOutsideClickOptions = {}
) => {
	const { exclude = [], safe = [] } = options;

	useEffect(() => {
		let mounted = true;
		if (!handler || !mounted) return;

		const refs = Array.isArray(ref) ? [...ref] : [ref];
		let elements = refs.map(getElement);

		let pointerDownConstraint = false;

		const onPointerDown = (event: MouseEvent | TouchEvent) => {
			elements = refs.map(getElement);
			const target = event.target as HTMLElement;
			pointerDownConstraint = elements.some((el) => {
				return el && (el === target || el.contains(target));
			});
		};

		const listener = (event: MouseEvent | TouchEvent) => {
			elements = refs.map(getElement);
			const target = event.target as HTMLElement;
			const hasExclude = exclude && exclude.some((c) => !!target.closest(c));

			if (pointerDownConstraint) return;

			if (
				elements.reduce((acc, item) => {
					acc = acc || !item || item.contains(target);
					if (hasExclude) acc = false;
					return acc;
				}, false)
			)
				return;

			if (safe && safe.some((c) => !!target.closest(c))) return;

			if (mounted) {
				handler && handler(event);
			}
		};

		document.addEventListener('mouseup', listener);
		document.addEventListener('touchend', listener);
		document.addEventListener('pointerdown', onPointerDown);

		return () => {
			mounted = false;

			document.removeEventListener('mouseup', listener);
			document.removeEventListener('touchend', listener);
			document.removeEventListener('pointerdown', onPointerDown);
		};
	}, [ref, handler, exclude, safe]);
};
