import React, { FC, Fragment, useMemo } from 'react';

import { Spacer, Skeleton } from '@shared/ui';

import styles from '../../styles.module.scss';
import stylesHead from '../task-board-column-head/styles.module.scss';

interface TaskBoardSkeletonProps {
	count?: number;
}

interface TaskBoardSkeletonColumnProps {
	index: number;
	count?: number;
}

export const TaskBoardSkeleton: FC<TaskBoardSkeletonProps> = React.memo(({ count = 5 }) => {
	const columns = useMemo(() => {
		const array = [];
		for (let i = 0; i < count; i++) {
			const count = ((i + 1) % 3) + 1;
			array.push(
				<TaskBoardSkeletonColumn key={`task-board-skeleton-column-${i}`} index={i} count={count} />
			);
		}
		return array;
	}, [count]);

	return <>{columns}</>;
});

export const TaskBoardSkeletonColumn: FC<TaskBoardSkeletonColumnProps> = React.memo(
	({ index, count = 1 }) => {
		const cards = useMemo(() => {
			const array = [];
			for (let i = 1; i <= count; i++) {
				array.push(
					<Fragment key={`task-board-skeleton-column-${index}-${i}`}>
						<Skeleton display="block" width="250px" height="140px" />
						{i < count && <Spacer value={16} />}
					</Fragment>
				);
			}
			return array;
		}, [index, count]);

		return (
			<div className={styles.column}>
				<div className={stylesHead.titleWrap}>
					<Skeleton display="block" width="100px" height="20px" />
				</div>
				<div className={styles.droppableColumnWrap}>
					<div className={styles.droppableColumn}>{cards}</div>
				</div>
			</div>
		);
	}
);
