import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState } from 'react';

import { FileDto } from '@shared/api';
import { filesModel } from '@shared/model';
import {
	Button,
	ButtonDesignType,
	ButtonSizeType,
	DropdownControlled,
	Icon,
	noticesModel,
} from '@shared/ui';

import { CreateFileLinkType, FileManagerCreateLink } from '@entities/file-manager';

import styles from './styles.module.scss';

interface FileManagerAttachProps {
	createFile: ChangeEventHandler<HTMLInputElement>;
	onFileUploaded?: (file: FileDto | FileDto[]) => void;
	full?: boolean;
	btnSize?: ButtonSizeType;
	btnDesign?: ButtonDesignType;
	className?: string;
	classNameButton?: string;
	editable?: boolean;
}

export const FileManagerAttach: FC<FileManagerAttachProps> = ({
	createFile,
	onFileUploaded,
	full,
	btnSize = 'sm',
	btnDesign = 'filled',
	className,
	classNameButton,
	editable,
}) => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [showCreateLinkModal, setShowCreateLinkModal] = useState(false);

	const statusUploading = useStore(filesModel.$status);

	useEffect(() => {
		if (statusUploading === 'done') {
			setMenuIsOpen(false);
		}
	}, [statusUploading]);

	useEffect(() => {
		if (!menuIsOpen) setShowCreateLinkModal(false);
	}, [menuIsOpen]);

	const createLink = useCallback(
		(data: CreateFileLinkType) => {
			if (!onFileUploaded) return;

			const formData = new FormData();
			formData.append(
				'fileUrls',
				new Blob([JSON.stringify([data])], {
					type: 'application/json',
				})
			);

			filesModel
				.uploadFilesOrFileLinksFx(Object(formData))
				.then((response) => {
					onFileUploaded(response);
					noticesModel.add({
						type: 'success',
						text: `Файл "<b>${response[0].name || response[0].url}</b>" прикреплен.`,
					});
					setShowCreateLinkModal(false);
				})
				.catch((error) => {
					console.warn(error);
					noticesModel.add({
						type: 'error',
						text: `Что-то пошло не так, попробуйте позже.`,
					});
				});
		},
		[onFileUploaded]
	);

	const uploading = statusUploading === 'pending';

	const buttonsList = useMemo(
		() => (
			<div className={styles.filesAttachMenu}>
				<Button
					isLabel={true}
					className={styles.actionButton}
					size="sm"
					design="transparent"
					disabled={uploading}
					iconLeft={
						uploading ? (
							<Icon id="loading" className={styles.actionButtonLoading} />
						) : (
							<Icon id="monitor" />
						)
					}
					iconRight={<input onChange={createFile} type="file" className="visually-hidden" />}>
					Ваш компьютер
				</Button>
				<Button
					className={styles.actionButton}
					size="sm"
					design="transparent"
					iconLeft={<Icon id="link" className="svg-path" />}
					onClick={() => setShowCreateLinkModal((prev) => !prev)}>
					Внешняя ссылка
				</Button>
			</div>
		),
		[createFile, uploading]
	);

	return (
		<DropdownControlled
			opened={menuIsOpen}
			setOpened={setMenuIsOpen}
			className={classnames(className, styles.filesAttach)}
			contentClassName={styles.filesAttachDrop}
			portalId="task-modal-portal"
			placement="topEnd"
			targetButton={
				<Button
					size={btnSize}
					design={btnDesign}
					color="secondary"
					onlyIcon={!full && <Icon id={'file'} />}
					iconRight={full && <Icon id={'file'} />}
					onClick={() => setMenuIsOpen((prev) => !prev)}
					className={classnames(classNameButton, styles.filesAttachBtn)}
					disabled={!editable}>
					{full ? 'Прикрепить' : null}
				</Button>
			}
			content={
				showCreateLinkModal ? (
					<FileManagerCreateLink
						onSubmit={createLink}
						onDiscard={() => setShowCreateLinkModal(false)}
						isPending={uploading}
					/>
				) : (
					buttonsList
				)
			}
		/>
	);
};
