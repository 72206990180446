import { createEvent, createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { status } from 'patronum';

import { tasksRequests, ToggleArchiveTaskPayload } from '@shared/api';
import { routerModel } from '@shared/model';
import { modalsModel, noticesModel } from '@shared/ui';

import { taskListModel } from '../task-list';

interface ToggleArchiveTaskEvent extends ToggleArchiveTaskPayload {
	title: string;
	archived?: boolean;
	callback?: () => void;
}

interface ToggleArchiveTaskGatePayload {
	pathname: string;
}

const toggleArchiveGate = createGate<ToggleArchiveTaskGatePayload>();

const resetStatus = createEvent();
const toggleArchiveTask = createEvent<ToggleArchiveTaskEvent>();
const toggleArchiveTaskFx = createEffect(({ taskId }: ToggleArchiveTaskEvent) =>
	tasksRequests.toggleArchiveTask({ taskId })
);

const doneCallbackFx = createEffect(
	(callback: ToggleArchiveTaskEvent['callback']) => callback && callback()
);

const $status = status({ effect: toggleArchiveTaskFx }).reset(resetStatus);

/**
 * Archiving or restoring a task
 */
sample({
	clock: toggleArchiveTask,
	target: toggleArchiveTaskFx,
});

/*
 * Notifications
 */
sample({
	clock: toggleArchiveTaskFx.done,
	fn: (source) => ({
		type: 'success' as const,
		text: source.params.archived
			? `Задача "<b>${source.params.title}</b>" была восстановлена.`
			: `Задача "<b>${source.params.title}</b>" перенесена в архив.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: toggleArchiveTaskFx.failData,
	fn: () => ({
		type: 'error' as const,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

/**
 * Revalidate task list
 */
sample({
	clock: toggleArchiveTaskFx.doneData,
	target: taskListModel.revalidateTaskList,
});

/**
 * Close modal
 */
sample({
	clock: toggleArchiveTaskFx.done,
	filter: (source) => !Boolean(source.params.archived),
	target: modalsModel.closeLastModal,
});

/**
 * Reset route after deleting a task
 */
sample({
	source: toggleArchiveGate.state,
	clock: toggleArchiveTaskFx.done,
	filter: (_, clock) => !Boolean(clock.params.archived),
	fn: (source) => source.pathname?.replace(/\/task\/[a-z0-9-]*$/gi, ''),
	target: routerModel.push,
});

/**
 * Done callback
 */
sample({
	clock: toggleArchiveTaskFx.done,
	filter: (clock) => Boolean(clock.params.callback),
	fn: (clock) => clock.params.callback,
	target: doneCallbackFx,
});

export const model = {
	resetStatus,
	toggleArchiveGate,
	toggleArchiveTask,
	$status,
};
