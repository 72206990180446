import { fetcher } from '../config';
import { PageableResponseUserDto } from '../model/pageableResponseUserDto';
import { UserDto } from '../model/userDto';

export type SearchApiParams = {
	page?: number;
	size?: number;
	sortDirection?: 'ASC' | 'DESC';
	sortBy?: string;
	fullName: string;
	includeCurrentUser?: boolean;
	excludeUserIds?: Array<string>;
};

const getUserById = (id: string) => fetcher<UserDto>({ url: `/users/${id}` });

const searchUsers = ({
	page = 0,
	size = 1000,
	sortDirection,
	sortBy,
	fullName,
	includeCurrentUser,
	excludeUserIds,
}: SearchApiParams) =>
	fetcher<PageableResponseUserDto>({
		method: 'POST',
		url: '/users/search',
		body: { page, size, sortDirection, sortBy, fullName, includeCurrentUser, excludeUserIds },
	});

const getCurrentUser = () => fetcher<UserDto>({ url: '/users/me' });

export const userRequests = {
	getUserById,
	searchUsers,
	getCurrentUser,
};
