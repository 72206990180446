/**
 * rpharm-todo
 * rpharm-todo documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './fileDto';
import { UserDto } from './userDto';

export interface TaskEventDto {
	id: string;
	type?: TaskEventDtoTypeEnum;
	text?: string;
	createDate?: string;
	updateDate?: string;
	author?: UserDto;
	taskId: string;
	files?: Array<FileDto>;
	tagsAddedCount?: number;
	tagsRemovedCount?: number;
}
export enum TaskEventDtoTypeEnum {
	COMMENT = 'COMMENT',
	SYSTEM_TASK_CREATED = 'SYSTEM_TASK_CREATED',
	SYSTEM_TASK_ARCHIVED = 'SYSTEM_TASK_ARCHIVED',
	SYSTEM_DESCRIPTION_CHANGED = 'SYSTEM_DESCRIPTION_CHANGED',
	SYSTEM_TITLE_CHANGED = 'SYSTEM_TITLE_CHANGED',
	SYSTEM_CO_ASSIGNEE_ADDED = 'SYSTEM_CO_ASSIGNEE_ADDED',
	SYSTEM_CO_ASSIGNEE_REMOVED = 'SYSTEM_CO_ASSIGNEE_REMOVED',
	SYSTEM_ASSIGNEE_NEW = 'SYSTEM_ASSIGNEE_NEW',
	SYSTEM_DEADLINE_DATES_CHANGED = 'SYSTEM_DEADLINE_DATES_CHANGED',
	SYSTEM_DEADLINE_DATES_ADDED = 'SYSTEM_DEADLINE_DATES_ADDED',
	SYSTEM_DEADLINE_DATES_REMOVED = 'SYSTEM_DEADLINE_DATES_REMOVED',
	SYSTEM_FILE_ADDED = 'SYSTEM_FILE_ADDED',
	SYSTEM_FILE_DELETED = 'SYSTEM_FILE_DELETED',
	SYSTEM_SUBTASK_DELETED = 'SYSTEM_SUBTASK_DELETED',
	SYSTEM_SUBTASK_ADDED = 'SYSTEM_SUBTASK_ADDED',
	SYSTEM_SUBTASK_ARCHIVED = 'SYSTEM_SUBTASK_ARCHIVED',
	SYSTEM_SUBTASK_COMPLETED_TRUE = 'SYSTEM_SUBTASK_COMPLETED_TRUE',
	SYSTEM_SUBTASK_STATUS_CHANGED = 'SYSTEM_SUBTASK_STATUS_CHANGED',
	SYSTEM_SUBTASK_CONVERTED = 'SYSTEM_SUBTASK_CONVERTED',
	SYSTEM_TAG_ADDED = 'SYSTEM_TAG_ADDED',
	SYSTEM_TAG_DELETED = 'SYSTEM_TAG_DELETED',
	SYSTEM_TAGS_CHANGED = 'SYSTEM_TAGS_CHANGED',
	SYSTEM_LINKED_SUBTASK_ADDED = 'SYSTEM_LINKED_SUBTASK_ADDED',
	SYSTEM_LINKED_SUBTASK_DETACHED = 'SYSTEM_LINKED_SUBTASK_DETACHED',
	SYSTEM_PARENT_TASK_DETACHED = 'SYSTEM_PARENT_TASK_DETACHED',
	SYSTEM_ASSIGNEE_UPDATED = 'SYSTEM_ASSIGNEE_UPDATED',
	SYSTEM_SUBTASK_TITLE_CHANGED = 'SYSTEM_SUBTASK_TITLE_CHANGED',
	SYSTEM_TASK_APPROVED = 'SYSTEM_TASK_APPROVED',
	SYSTEM_TASK_DECLINED = 'SYSTEM_TASK_DECLINED',
	SYSTEM_SUBTASK_ASSIGNEE_DELETED = 'SYSTEM_SUBTASK_ASSIGNEE_DELETED',
	SYSTEM_SUBTASK_ASSIGNEE_ADDED = 'SYSTEM_SUBTASK_ASSIGNEE_ADDED',
	SYSTEM_SUBTASK_ASSIGNEE_UPDATED = 'SYSTEM_SUBTASK_ASSIGNEE_UPDATED',
}
