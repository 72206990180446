import { createEvent, createEffect, sample, combine, createStore, restore } from 'effector';

import { CreateTaskEventRequest, TaskEventDto, tasksEvents } from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { taskCommentFilesModel } from '../task-comment-files';

/*
 * Comment model
 */
type OnSuccessCallback = (d?: TaskEventDto) => void;

type CreateTaskEvent = {
	payload: CreateTaskEventRequest;
	onSuccess: Nullable<OnSuccessCallback>;
};
type SuccessFx = { taskEvent: TaskEventDto; onSuccess: Nullable<OnSuccessCallback> };

const createTaskEvent = createEvent<CreateTaskEvent>();
const createTaskEventFx = createEffect(tasksEvents.createTaskEvent);
const createTaskEventSuccessFx = createEffect(
	({ taskEvent, onSuccess }: SuccessFx) => onSuccess && onSuccess(taskEvent)
);

const $onSuccess = createStore<Nullable<OnSuccessCallback>>(null).reset(createTaskEvent);
const $response = createStore<Nullable<TaskEventDto>>(null).reset(createTaskEvent);
const $error = restore<Error>(createTaskEventFx.failData, null).reset(createTaskEvent);
const $status = combine({
	pending: createTaskEventFx.pending,
	response: $response,
	error: $error,
});

sample({
	clock: createTaskEvent,
	fn: ({ payload }) => payload,
	target: createTaskEventFx,
});

sample({
	clock: createTaskEvent,
	fn: ({ onSuccess }) => onSuccess,
	target: $onSuccess,
});

sample({
	clock: createTaskEventFx.doneData,
	source: $onSuccess,
	fn: (onSuccess, taskEvent) => ({ onSuccess, taskEvent }),
	target: createTaskEventSuccessFx,
});

sample({
	clock: createTaskEventFx.doneData,
	target: [$response, taskCommentFilesModel.resetFiles],
});

sample({
	clock: createTaskEventFx.failData,
	target: $error,
});

sample({
	clock: createTaskEventFx.failData,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	createTaskEvent,
	$status,
};
