export const reminderOptions = [
	{
		value: null,
		label: 'Выкл',
	},
	{
		value: 15,
		label: 'За 15 минут',
	},
	{
		value: 30,
		label: 'За 30 минут',
	},
	{
		value: 60,
		label: 'За час',
	},
	{
		value: 120,
		label: 'За 2 часа',
	},
	{
		value: 1440,
		label: 'За день',
	},
	{
		value: 2880,
		label: 'За 2 дня',
	},
	{
		value: 10080,
		label: 'За неделю',
	},
];
