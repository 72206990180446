import { AxiosError } from 'axios';
import { createEffect, createEvent, restore, sample } from 'effector';
import { status } from 'patronum';

import { authRequests, CredentialsPasswordDto, SecurityTokensDto } from '@shared/api';
import { setJWT } from '@shared/lib/utils';
import { noticesModel } from '@shared/ui';

const login = createEvent<CredentialsPasswordDto>();
const loginFx = createEffect<CredentialsPasswordDto, SecurityTokensDto, AxiosError>(
	authRequests.devLogin
);

const $error = restore(loginFx.failData, null).reset(login);

const $status = status({ effect: loginFx });

loginFx.doneData.watch(({ accessToken, refreshToken }) => {
	setJWT({
		access: accessToken?.token,
		refresh: refreshToken?.token,
		accessExpiration: accessToken?.expirationDate,
		refreshExpiration: refreshToken?.expirationDate,
	});
	window.location.href = '/';
});

sample({
	clock: login,
	target: loginFx,
});

sample({
	clock: loginFx.failData,
	filter: (source) => source.response?.status !== 400,
	fn: () => ({
		type: 'error' as const,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	login,
	$status,
	$error,
};
