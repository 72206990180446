import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { object } from 'yup';

import { helpersStyles } from '@shared/lib/styles';
import { checkEscape } from '@shared/lib/utils';
import { commonValidators } from '@shared/lib/validation';
import { Button, ConfirmModalHead, FormElement, Input, Typo } from '@shared/ui';

import styles from './styles.module.scss';

export type CreateFileLinkType = {
	url: string;
	name?: string;
};

interface FileManagerCreateLinkProps {
	onDiscard?: () => void;
	onSubmit?: (data: CreateFileLinkType) => void;
	isPending?: boolean;
}

export const FileManagerCreateLink: FC<FileManagerCreateLinkProps> = ({
	onDiscard,
	onSubmit,
	isPending,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<CreateFileLinkType>({
		defaultValues: {
			name: '',
			url: '',
		},
		resolver: yupResolver(
			object({ url: commonValidators.requiredUrl, name: commonValidators.string255 })
		),
		mode: 'all',
	});

	return (
		<FocusTrap
			focusTrapOptions={{
				escapeDeactivates: true,
				allowOutsideClick: true,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
			}}>
			<div
				className={styles.box}
				onKeyDown={(e) => {
					if (checkEscape(e)) {
						e.stopPropagation();
						onDiscard && onDiscard();
					}
				}}>
				<ConfirmModalHead title="Прикрепить ссылку" onClickBack={onDiscard} />
				<div className={styles.body}>
					<fieldset className={styles.fieldset}>
						<Typo className={styles.legend} design="text-13-medium" as="legend">
							Ссылка
						</Typo>
						<FormElement error={errors?.url?.message}>
							<Input placeholder="Введите ссылку" {...register('url')} />
						</FormElement>
					</fieldset>
					<fieldset className={styles.fieldset}>
						<Typo className={styles.legend} design="text-13-medium" as="legend">
							Название ссылки (необязательно)
						</Typo>
						<FormElement>
							<Input placeholder="Введите название" {...register('name')} />
						</FormElement>
					</fieldset>
				</div>
				<div className={classnames(styles.buttons, helpersStyles.buttonsPanel)}>
					<Button
						type="button"
						onClick={onDiscard}
						size="md"
						design="filled"
						color="secondary"
						className={helpersStyles.buttonsPanelItem}>
						Отменить
					</Button>
					<Button
						type="button"
						size="md"
						design="filled"
						color="primary"
						disabled={!isValid}
						isLoading={isPending}
						onClick={onSubmit && handleSubmit(onSubmit)}
						className={helpersStyles.buttonsPanelItem}>
						Прикрепить
					</Button>
				</div>
			</div>
		</FocusTrap>
	);
};
