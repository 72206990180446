import { combine, createEffect, createEvent, createStore, sample } from 'effector';
import { condition } from 'patronum';

import { tasksRequests, ToggleTaskSubscribeToChangesRequest } from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { taskModel } from '@entities/task/model/task';
import { taskListModel } from '@entities/task/model/task-list';

interface ToggleTaskSubscribeToChangesArgs extends ToggleTaskSubscribeToChangesRequest {
	revalidateList?: boolean;
}

const toggleTaskSubscribeToChanges = createEvent<ToggleTaskSubscribeToChangesArgs>();
const toggleTaskSubscribeToChangesFx = createEffect(tasksRequests.toggleTaskSubscribeToChanges);

const $config = createStore<Nullable<ToggleTaskSubscribeToChangesRequest>>(null).reset(
	toggleTaskSubscribeToChanges
);
const $error = createStore<Nullable<Error>>(null).reset(toggleTaskSubscribeToChanges);
const $revalidateList = createStore(false).reset(toggleTaskSubscribeToChanges);

const $toggleTaskSubscribeToChangesStatus = combine({
	pending: combine($config, toggleTaskSubscribeToChangesFx.pending, (config, pending) =>
		pending && config?.taskId ? config.taskId : false
	),
	error: $error,
});

sample({
	clock: toggleTaskSubscribeToChanges,
	fn: ({ revalidateList }) => !!revalidateList,
	target: $revalidateList,
});

sample({
	clock: toggleTaskSubscribeToChanges,
	fn: ({ taskId }) => ({ taskId }),
	target: [$config, toggleTaskSubscribeToChangesFx],
});

const revalidateTaskList = createEvent();
const mutateCurrentTask = createEvent();

sample({
	clock: [toggleTaskSubscribeToChangesFx.done, toggleTaskSubscribeToChangesFx.fail],
	target: mutateCurrentTask,
});

condition({
	source: toggleTaskSubscribeToChangesFx.done,
	if: $revalidateList,
	then: revalidateTaskList,
});

sample({
	clock: revalidateTaskList,
	source: taskListModel.$config,
	target: taskListModel.getTaskList,
});

sample({
	clock: mutateCurrentTask,
	target: taskModel.revalidateTask,
});

sample({
	clock: toggleTaskSubscribeToChangesFx.failData,
	target: $error,
});

sample({
	clock: toggleTaskSubscribeToChangesFx.failData,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	toggleTaskSubscribeToChanges: toggleTaskSubscribeToChanges,
	toggleTaskSubscribeToChangesFx,
	$toggleTaskSubscribeToChangesStatus,
};
