import classnames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

import { Icon } from '@shared/ui';
import { IconId } from '@shared/ui/icons/svg-sprite';

import styles from './styles.module.scss';

export interface InputInterface extends InputHTMLAttributes<HTMLInputElement> {
	error?: string;
	isLoading?: boolean;
	icon?: IconId;
	theme?: 'light' | 'dark' | 'light-gray';
	paddingSize?: 'small' | 'normal';
}

export * from './input-multi';

export const Input = React.forwardRef<HTMLInputElement, InputInterface>(
	(
		{ type = 'text', className, icon, error, isLoading, theme, paddingSize = 'normal', ...props },
		ref
	) => {
		const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
			props?.onFocus && props.onFocus(e);
		};

		const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
			props?.onBlur && props.onBlur(e);
		};

		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			props?.onChange && props.onChange(e);
		};

		return (
			<label
				className={classnames(
					className,
					styles.input,
					theme && styles[theme],
					icon && styles.withIcon,
					error && styles.error
				)}>
				{icon && <Icon id={icon} />}
				{type !== 'file' && (
					<input
						{...props}
						ref={ref}
						type={type}
						className={classnames(className, paddingSize && styles[paddingSize])}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onChange={handleChange}
					/>
				)}
				{error && <small>{error}</small>}
			</label>
		);
	}
);
