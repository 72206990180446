import classnames from 'classnames';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { useRef, useState } from 'react';

import { NOT_FOUND_MESSAGE } from '@shared/lib/constants';
import { Input, NotFoundMessage, Scroller } from '@shared/ui';

import { useBoardRoles } from '@entities/board-roles';
import { boardModel } from '@entities/boards';
import { useSearchUsers } from '@entities/search-users';

import { AddParticipantListItem } from '../add-participant-list-item';

import styles from './styles.module.scss';

interface AddParticipantToBoardProps {
	excludeUserIds?: string[];
	disableRolesChanging?: boolean;
}

export const AddParticipantToBoard = React.memo(
	({ excludeUserIds, disableRolesChanging }: AddParticipantToBoardProps) => {
		const addParticipantDropdownRef = useRef(null);
		const { searchUsers, users } = useSearchUsers({
			excludeUserIds,
		});
		const { boardRoles } = useBoardRoles();

		const addParticipantLoading = useStore(boardModel.$addParticipantStatus) === 'pending';
		const removeParticipantLoading = useStore(boardModel.$removeParticipantStatus) === 'pending';
		const setRoleParticipantStatus = useStore(boardModel.$setRoleParticipantStatus) === 'pending';

		const [searchInputShadow, setSearchInputShadow] = useState(false);
		const [activeUserId, setActiveUserId] = useState<Nullable<string>>(null);

		return (
			<FocusTrap
				focusTrapOptions={{
					escapeDeactivates: true,
					allowOutsideClick: true,
					checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
				}}>
				<div className={styles.addParticipantDropdown} ref={addParticipantDropdownRef}>
					<div
						className={classnames(styles.searchInput, {
							[styles.withShadow]: searchInputShadow && users,
						})}>
						<Input
							onChange={searchUsers}
							placeholder="Поиск по имени"
							icon="search"
							theme="light-gray"
						/>
					</div>
					{users && (
						<Scroller
							axis="y"
							className={classnames(styles.scroll, 'scrollbar-container')}
							onScroll={(_, y) => {
								setSearchInputShadow(y > 0);
							}}>
							{users?.length ? (
								<ul className={styles.list}>
									{users?.map((user) => (
										<AddParticipantListItem
											user={user}
											boardRoles={boardRoles}
											setActiveUserId={setActiveUserId}
											isLoading={
												user.id === activeUserId &&
												(addParticipantLoading ||
													removeParticipantLoading ||
													setRoleParticipantStatus)
											}
											disableRolesChanging={disableRolesChanging}
											key={user.id}
										/>
									))}
								</ul>
							) : (
								<NotFoundMessage>{NOT_FOUND_MESSAGE.employees}</NotFoundMessage>
							)}
						</Scroller>
					)}
					<div id="add-participant-to-board" />
				</div>
			</FocusTrap>
		);
	}
);
