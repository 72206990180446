import { combine, createEvent, createStore, sample } from 'effector';

const setBoardScrollY = createEvent<number>();
const setBoardScrollX = createEvent<number>();

const $boardScrollY = createStore(0);
const $boardScrollX = createStore(0);

const $boardScroll = combine({
	x: $boardScrollX,
	y: $boardScrollY,
});

sample({
	clock: setBoardScrollY,
	target: $boardScrollY,
});

sample({
	clock: setBoardScrollX,
	target: $boardScrollX,
});

export const boardScrollModel = {
	$boardScroll,
	$boardScrollY,
	$boardScrollX,
	setBoardScrollY,
	setBoardScrollX,
};
