import { fetcher } from '@shared/api/config';
import { BoardViewDto } from '@shared/api/model/models';

export interface PutBoardViewRequest {
	payload: BoardViewDto;
}

const putBoardView = ({ payload }: PutBoardViewRequest) =>
	fetcher<BoardViewDto>({
		url: `/board-views/${payload.id}`,
		method: 'PUT',
		body: payload,
	});

export interface ToggleTagsOnTasksRequest {
	boardId: string;
}

const toggleTagsOnTasks = ({ boardId }: ToggleTagsOnTasksRequest) =>
	fetcher<BoardViewDto>({
		url: `/board-views/${boardId}/show-tags-on-tasks`,
		method: 'PUT',
	});

export const boardViewsRequests = {
	putBoardView,
	toggleTagsOnTasks,
};
