import { createEvent, createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { Transition } from 'history';
import { status, reset } from 'patronum';

import { TagDto, tagsRequests, GetTagListRequest } from '@shared/api';
import { modalsModel, noticesModel } from '@shared/ui';

interface PageTagsGateParams extends Omit<GetTagListRequest, 'search'> {}

const PageTagsGate = createGate<PageTagsGateParams>();

const getTaskListTags = createEvent<PageTagsGateParams>();
const changeStateTag = createEvent<TagDto>();
const resetTagsChanged = createEvent();
const saveBoardTags = createEvent<Nullable<Transition['retry']>>();
const setActiveTags = createEvent();
const addTagToList = createEvent<TagDto>();
const removeTagFromList = createEvent<TagDto>();
const resetAllStores = createEvent();

const getTaskListTagsFx = createEffect(tagsRequests.getTagList);
const retryRouteFx = createEffect(
	(retry: Nullable<Transition['retry']>) => retry && queueMicrotask(retry)
);
const setActiveTagsFx = createEffect(tagsRequests.setActiveTags);

const $taskListTags = createStore<TagDto[]>([]);
const $taskListTagsForCompare = createStore<TagDto[]>([]);
const $boardTagsChanged = createStore(false).reset(resetTagsChanged);
const $retryRoute = createStore<Nullable<Transition['retry']>>(null);
const $setActiveTagsStatus = status({ effect: setActiveTagsFx });

/**
 * Get page tags list
 */
sample({
	clock: PageTagsGate.open,
	target: getTaskListTags,
});

sample({
	clock: getTaskListTags,
	target: getTaskListTagsFx,
});

sample({
	clock: getTaskListTagsFx.doneData,
	target: [$taskListTags, $taskListTagsForCompare],
});

/**
 * Change tag state
 */
sample({
	source: $taskListTags,
	clock: changeStateTag,
	fn: (source, clock) => source.map((tag) => (tag.id === clock.id ? clock : tag)),
	target: $taskListTags,
});

/**
 * Set boardTagsChanged
 */
sample({
	source: { initialState: $taskListTagsForCompare, changedState: $taskListTags },
	clock: changeStateTag,
	fn: ({ initialState, changedState }) =>
		JSON.stringify(initialState) !== JSON.stringify(changedState),
	target: $boardTagsChanged,
});

/**
 * Set $retryRoute and save tags
 */
sample({
	clock: saveBoardTags,
	fn: (source) => source,
	target: [$retryRoute, setActiveTags],
});

/**
 * Save tags new state
 */
sample({
	source: { initialTags: $taskListTagsForCompare, changedTags: $taskListTags },
	clock: setActiveTags,
	fn: ({ initialTags, changedTags }) =>
		changedTags
			.filter((tag, i) => tag.active !== initialTags[i].active)
			.map((tag) => ({ id: tag.id, active: tag.active })),
	target: setActiveTagsFx,
});

/**
 * Revalidate PageTagsList after save tags
 */
sample({
	source: PageTagsGate.state,
	clock: setActiveTagsFx.doneData,
	fn: (source) => source,
	target: getTaskListTags,
});

/**
 * Go forward after request
 */
sample({
	source: $retryRoute,
	clock: setActiveTagsFx.doneData,
	fn: (source) => source,
	target: [retryRouteFx, modalsModel.closeLastModal],
});

/**
 * Notifications
 */
sample({
	clock: setActiveTagsFx.failData,
	fn: () => ({
		type: 'error' as const,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

/**
 * Add tag to taskListTags
 */
sample({
	source: $taskListTags,
	clock: addTagToList,
	fn: (source, clock) => {
		const tagExist = source.some((tag) => tag.id === clock.id);

		if (tagExist) {
			return source.map((tag) => (tag.id === clock.id ? clock : tag));
		}

		return [...source, clock].sort((a, b) =>
			a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
		);
	},
	target: $taskListTags,
});

/**
 * Remove tag from taskListTags
 */
sample({
	source: $taskListTags,
	clock: removeTagFromList,
	fn: (source, clock) => source.filter((tag) => tag.id !== clock.id),
	target: $taskListTags,
});

/**
 * Add tag to taskListTagsForCompare
 */
sample({
	source: $taskListTagsForCompare,
	clock: addTagToList,
	fn: (source, clock) => {
		const tagExist = source.some((tag) => tag.id === clock.id);

		if (tagExist) {
			return source.map((tag) => (tag.id === clock.id ? clock : tag));
		}

		return [...source, clock].sort((a, b) =>
			a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
		);
	},
	target: $taskListTagsForCompare,
});

/**
 * Remove tag from taskListTagsForCompare
 */
sample({
	source: $taskListTagsForCompare,
	clock: removeTagFromList,
	fn: (source, clock) => source.filter((tag) => tag.id !== clock.id),
	target: $taskListTagsForCompare,
});

/**
 * Reset states
 */
reset({
	clock: setActiveTagsFx.doneData,
	target: $boardTagsChanged,
});

reset({
	clock: [PageTagsGate.close, resetAllStores],
	target: [
		$taskListTags,
		$taskListTagsForCompare,
		$retryRoute,
		$boardTagsChanged,
		$setActiveTagsStatus,
	],
});

export const model = {
	PageTagsGate,
	changeStateTag,
	$taskListTags,
	addTagToList,
	removeTagFromList,
	getTaskListTagsFx,
	$boardTagsChanged,
	resetTagsChanged,
	saveBoardTags,
	setActiveTags,
	$setActiveTagsStatus,
	resetAllStores,
};
