import { createEvent, createStore, sample } from 'effector';

import { NoticeType } from '@shared/ui';

export type Notice = { text: string; type?: NoticeType };

const $notices = createStore<(Notice & { id: string })[]>([]);
const add = createEvent<Notice>();
const remove = createEvent<string>();

sample({
	source: $notices,
	clock: add,
	fn: (notices, newNotice) => [...notices, { ...newNotice, id: Date.now().toString() }],
	target: $notices,
});

sample({
	source: $notices,
	clock: remove,
	fn: (notices, id) => notices.filter((notice) => notice.id !== id),
	target: $notices,
});

export const noticesModel = {
	$notices,
	add,
	remove,
};
