import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { useState, useRef, useEffect } from 'react';

import { UserDto } from '@shared/api';
import { NOT_FOUND_MESSAGE } from '@shared/lib/constants';
import { useOutsideClick } from '@shared/lib/hooks';
import { UserInfo } from '@shared/ui';
import {
	Typo,
	NotFoundMessage,
	ConfirmModalHead,
	ConfirmModalBody,
	Avatar,
	AvatarColors,
	Dropdown,
} from '@shared/ui';

import { AssigneeDropdown } from '@entities/assignee-dropdown';
import { useSearchUsers } from '@entities/search-users';
import { SearchUsersDropdownWrapper } from '@entities/search-users/ui/search-users-dropdown-wrapper';
import { reassignTaskModel, taskModel } from '@entities/task';

import styles from './styles.module.scss';

interface DropdownTransferTaskProps {
	onDiscard: () => void;
}

export const DropdownTransferTask = ({ onDiscard }: DropdownTransferTaskProps) => {
	const task = useStore(taskModel.$task);

	const isPending = useStore(reassignTaskModel.$status) === 'pending';

	const { searchUsers, users, debounced, setSearch } = useSearchUsers();

	const [selectedUser, setSelectedUser] = useState<Nullable<UserDto>>(null);
	const ddUserRef = useRef(null);
	const resolveRef = useRef<(value: void | PromiseLike<void>) => void>();
	const [ddUser, setDdUser] = useState(false);

	useOutsideClick(ddUserRef, () => setDdUser(false));

	const reassignTask = () => {
		if (task?.id && selectedUser?.id) {
			reassignTaskModel.reassignTask({ taskId: task?.id, user: selectedUser });
		}
	};

	useEffect(() => {
		if (users?.length) {
			resolveRef.current && resolveRef.current();
		}
	}, [users]);

	return (
		<FocusTrap
			focusTrapOptions={{
				allowOutsideClick: true,
				initialFocus: false,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
			}}>
			<div className={styles.transferTask}>
				<ConfirmModalHead title="Передача задачи" size="sm" onClickBack={onDiscard} />
				<ConfirmModalBody
					content={
						<>
							<Typo className={styles.title} design="text-13-medium">
								Сотрудник
							</Typo>
							{selectedUser ? (
								<Dropdown
									ref={ddUserRef}
									placement="bottomStart"
									isOpen={ddUser}
									openOnButtonClick={false}
									targetButton={
										<Avatar
											className={styles.avatar}
											name={selectedUser.fullName ?? ''}
											color={selectedUser.avatarColor as AvatarColors}
											onClick={() => setDdUser(true)}
											type="withNameBG"
										/>
									}
									dropdownData={(props, ref) => (
										<AssigneeDropdown
											user={selectedUser}
											removeAssignee={() => {
												setSelectedUser(null);
												setDdUser(false);
											}}
											closeDropdown={() => setDdUser(false)}
											editable
										/>
									)}
								/>
							) : (
								<SearchUsersDropdownWrapper
									onSearch={searchUsers}
									debounced={debounced}
									className={styles.searchInput}
									theme="light-gray">
									<FocusTrap
										focusTrapOptions={{
											allowOutsideClick: true,
											initialFocus: false,
											checkCanFocusTrap: () =>
												new Promise<void>((resolve) => (resolveRef.current = resolve)),
										}}>
										{users ? (
											<ul className={styles.list}>
												{users.length ? (
													users.map((user) => (
														<li key={user.id}>
															<UserInfo
																onClick={() => {
																	setSelectedUser(user);
																	setSearch('');
																}}
																user={user}
																Tag="button"
																showJobTitle
															/>
														</li>
													))
												) : (
													<NotFoundMessage>{NOT_FOUND_MESSAGE.employees}</NotFoundMessage>
												)}
											</ul>
										) : null}
									</FocusTrap>
								</SearchUsersDropdownWrapper>
							)}
						</>
					}
					onAccept={reassignTask}
					onDiscard={onDiscard}
					acceptLabel="Передать"
					acceptColor="primary"
					textAlign="left"
					isPending={isPending}
					disabled={!selectedUser}
				/>
			</div>
		</FocusTrap>
	);
};
