import React, { FC } from 'react';

import { Button } from '@shared/ui';

import { useUserRoles } from '@features/user-roles';

import styles from './styles.module.scss';

interface TaskBoardPageArchivedBoardActionsProps {
	restoreBoard?: () => void;
	deleteBoard?: () => void;
}

export const TaskBoardPageArchivedBoardActions: FC<TaskBoardPageArchivedBoardActionsProps> = ({
	restoreBoard,
	deleteBoard,
}) => {
	const { userOnBoard } = useUserRoles();

	if (!userOnBoard.isAuthor) return null;

	return (
		<div className={styles.actionsWrapper}>
			<Button
				onClick={() => restoreBoard && restoreBoard()}
				disabled={!userOnBoard.isAuthor}
				className={styles.actionsWideButton}>
				Восстановить
			</Button>
			<Button
				onClick={() => deleteBoard && deleteBoard()}
				disabled={!userOnBoard.isAuthor}
				className={styles.actionsWideButton}
				color="secondary">
				Удалить
			</Button>
		</div>
	);
};
