import classnames from 'classnames';
import React, { FC } from 'react';

import { BoardDto, BoardPreviewDto, BoardRoleDtoNameEnum, BoardWithViewDto } from '@shared/api';
import { Skeleton } from '@shared/ui';

import {
	BoardCard,
	BoardCardFooter,
	EmptyBoardButton,
	toggleBoardStarredModel,
} from '@entities/boards';
import { useCurrentUser } from '@entities/current-user';
import { TagsManagerEntry } from '@entities/tag-manager';

import styles from './styles.module.scss';

export interface BoardsGroupProps {
	boards: BoardPreviewDto[];
	createBoard: () => void;
	isEmpty?: boolean;
	isLoading?: boolean;
	emptyBanner?: React.ReactNode;
	showEmptyBoardButton?: boolean;
	editBoard?: (board?: BoardDto | BoardWithViewDto) => void;
	removeBoard?: (board?: BoardDto | BoardWithViewDto) => void;
	restoreBoard?: (board?: BoardPreviewDto | BoardDto | BoardWithViewDto) => void;
	deleteBoard?: (board?: BoardPreviewDto | BoardDto | BoardWithViewDto) => void;
	manageTags?: () => void;
}

export const BoardsGroup: FC<BoardsGroupProps> = ({
	boards,
	createBoard,
	emptyBanner,
	isEmpty = false,
	isLoading = false,
	showEmptyBoardButton = true,
	manageTags,
	editBoard,
	removeBoard,
	restoreBoard,
	deleteBoard,
}) => {
	const { currentUser } = useCurrentUser();

	const addToFavorite = (boardId?: string) => {
		boardId && toggleBoardStarredModel.toggleBoardStarred({ boardId });
	};

	if (isEmpty && !showEmptyBoardButton) return <>{emptyBanner}</>;

	if (isLoading)
		return (
			<div className={classnames(styles.boardsGroup)}>
				<BoardsGroupSkeleton />
			</div>
		);

	return (
		<div className={classnames(styles.boardsGroup)}>
			{boards.map((board) => {
				const { id, starred, archived, archivedDate, author, currentParticipantBoardRole } = board;
				const userIsAuthor = !!currentUser?.id && currentUser.id === author?.id;
				const userIsEditor = currentParticipantBoardRole?.name === BoardRoleDtoNameEnum.EDITOR;
				return (
					<BoardCard
						key={id}
						href={`/boards/${archived ? `archive/` : ``}${id}`}
						cardFooter={
							<BoardCardFooter
								id={id}
								starred={starred}
								archived={archived}
								archivedDate={archivedDate}
								manageTags={manageTags}
								editBoard={editBoard}
								removeBoard={removeBoard}
								deleteBoard={() => deleteBoard && deleteBoard(board)}
								restoreBoard={() => restoreBoard && restoreBoard(board)}
								addToFavorite={() => addToFavorite(id)}
								viewer={currentUser}
								tagManager={
									<TagsManagerEntry boardId={id} editable={userIsAuthor || userIsEditor} />
								}
								userIsAuthor={!!currentUser && currentUser?.id === author?.id}
							/>
						}
						{...board}
					/>
				);
			})}
			{showEmptyBoardButton && <EmptyBoardButton onClick={createBoard} />}
		</div>
	);
};

export const BoardsGroupSkeleton: FC = () => {
	const mock = [0, 1, 2, 3, 4, 5];
	return (
		<>
			{mock.map((i) => (
				<Skeleton key={`board-skeleton-${i}`} display="block" height="186px" />
			))}
		</>
	);
};
