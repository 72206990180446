import IMask from 'imask/esm/imask';
import React from 'react';

import { InputMask } from '@shared/ui';

import styles from './styles.module.scss';

interface DatePickerInputProps {
	title: string;
	withTime: boolean;
	date: string;
	time: string;
	onChangeDate: (v: string) => void;
	onChangeTime: (v: string) => void;
}

const timeMaskOptions = {
	mask: 'HH:MM',
	blocks: {
		HH: {
			mask: IMask.MaskedRange,
			placeholderChar: 'HH',
			from: 0,
			to: 23,
			maxLength: 2,
		},
		MM: {
			mask: IMask.MaskedRange,
			placeholderChar: 'MM',
			from: 0,
			to: 59,
			maxLength: 2,
		},
	},
};

export const DatePickerInput = ({
	title,
	withTime,
	date,
	time,
	onChangeDate,
	onChangeTime,
}: DatePickerInputProps) => {
	return (
		<div className={styles.date}>
			<div className={styles.date_title}>{title}</div>
			<div className={styles.group_inputs}>
				<InputMask
					placeholderChar=" "
					mask={Date}
					value={date}
					onComplete={(value) => {
						if (date !== value) onChangeDate(value);
					}}
					// @ts-ignore
					max={new Date(2100, 0, 1)}
				/>
				{withTime && (
					<InputMask
						placeholderChar=" "
						value={time}
						onAccept={(value) => {
							if (time !== value) onChangeTime(value);
						}}
						{...timeMaskOptions}
					/>
				)}
			</div>
		</div>
	);
};
