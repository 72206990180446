import classNames from 'classnames';
import React, { useEffect, useRef, MutableRefObject } from 'react';

import { UserDto } from '@shared/api';
import { BoardParticipantDto } from '@shared/api/model/boardParticipantDto';
import { BoardRoleDto } from '@shared/api/model/boardRoleDto';
import { useFocusTrap, useEscape, useOutsideClick } from '@shared/lib/hooks';
import { helpersStyles } from '@shared/lib/styles';
import { Icon, Button, Role, UserInfo } from '@shared/ui';

import styles from './styles.module.scss';

interface ParticipantProps {
	participant: Partial<BoardParticipantDto>;
	boardRoles: BoardRoleDto[];
	setRole: (role: BoardParticipantDto) => void;
	remove: (id: string) => void;
	onEscape?: () => void;
	outsideClickExcludeRef?: MutableRefObject<HTMLElement | null>;
	isDisabled?: boolean;
	disableRolesChanging?: boolean;
}

export const Participant = ({
	participant,
	boardRoles,
	setRole,
	remove,
	onEscape,
	outsideClickExcludeRef,
	isDisabled,
	disableRolesChanging,
}: ParticipantProps) => {
	const participantRef = useRef<HTMLDivElement>(null);

	useFocusTrap(participantRef);

	useOutsideClick([participantRef, outsideClickExcludeRef], () => onEscape && onEscape(), {});

	useEscape(
		true,
		(e) => {
			if (onEscape) {
				e?.stopPropagation();
				onEscape();
			}
		},
		participantRef as MutableRefObject<HTMLElement>
	);

	useEffect(() => {
		setTimeout(() => {
			const role = participantRef?.current?.querySelector<HTMLButtonElement>('#participant-role');
			role?.focus();
		}, 100);
	}, []);

	const returnFocusTo = (id: string = `participant${participant.user?.id}`) => {
		setTimeout(() => {
			document.getElementById(id)?.focus();
		}, 10);
	};

	return (
		<div className={styles.participant} ref={participantRef}>
			{participant.user && (
				<UserInfo user={participant.user} showEmail showJobTitle showDepartment />
			)}
			<div className={classNames(helpersStyles.separator, styles.separator)} />
			<div className={classNames(styles.main, { [styles.disabled]: isDisabled })}>
				{boardRoles.map((role, i) => {
					const isSelected = participant.role?.name === role.name;
					if ((isDisabled || disableRolesChanging) && !isSelected) return null;
					return (
						<Role
							id={!i ? 'participant-role' : undefined}
							className={styles.role}
							description={role.description}
							isSelected={isSelected}
							onClick={() => {
								setRole({ user: participant.user as UserDto, role });
								returnFocusTo();
							}}
							key={role.id}
							disabled={isDisabled || disableRolesChanging}
						/>
					);
				})}
				{!isDisabled && (
					<Button
						onClick={() => {
							remove(participant.user?.id ?? '');
							returnFocusTo('search-input');
						}}
						className={styles.button}
						size="sm"
						design="transparent"
						iconLeft={<Icon id="remove" />}>
						Удалить
					</Button>
				)}
			</div>
		</div>
	);
};
