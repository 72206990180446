import React, { FC } from 'react';

import { TaskStatusDto } from '@shared/api';
import { Typo } from '@shared/ui';

import styles from '../styles.module.scss';

interface TaskTableHeadProps extends TaskStatusDto {
	count?: number;
}

export const TaskTableHead: FC<TaskTableHeadProps> = React.memo(({ name, count = 0 }) => {
	return (
		<Typo role="columnheader" design="text-14-bold" className={styles.taskTableHeadCell}>
			{name}
			<Typo as="span" design="text-14-medium" className={styles.taskTableHeadCellCount}>
				{count}
			</Typo>
		</Typo>
	);
});
