import { useStore, useGate } from 'effector-react';
import { useCallback } from 'react';

import { UserDto } from '@shared/api';

import { currentUserModel } from '../model';

export const useCurrentUser = () => {
	useGate(currentUserModel.currentUserLoad);

	const currentUser = useStore(currentUserModel.$currentUser);

	const userIsCurrent = useCallback(
		(user?: UserDto) => {
			return user && currentUser && user?.id === currentUser?.id;
		},
		[currentUser]
	);

	return { currentUser, userIsCurrent };
};
