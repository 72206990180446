import { useGate, useStore } from 'effector-react';
import React, { FC, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router';

import { Button, Icon, Select, SelectOption } from '@shared/ui';

import { boardsShortListModel } from '@entities/boards';
import { SearchInput } from '@entities/search-input';
import { toggleArchiveTaskModel, removeTaskModel } from '@entities/task';

import styles from './styles.module.scss';

interface TasksPageActionsProps {
	createTask: () => void;
	onFilter: (filter: SelectOption) => void;
	toArchive: string;
	onSearch: React.Dispatch<React.SetStateAction<string>>;
}

const defaultFilterOption: SelectOption[] = [
	{ label: 'Все доски', value: 'ALL' },
	{ label: 'Без доски', value: 'NO_BOARD' },
];

export const TasksPageActions: FC<TasksPageActionsProps> = ({
	createTask,
	onFilter,
	toArchive,
	onSearch,
}) => {
	const boardsShortList = useStore(boardsShortListModel.$boardsShortList);
	const toggleArchiveTaskStatus = useStore(toggleArchiveTaskModel.$status);
	const removeTaskStatus = useStore(removeTaskModel.$status);

	const { pathname } = useLocation();
	const isArchive = pathname.includes('archive');

	useGate(boardsShortListModel.BoardsShortGate, { taskArchived: isArchive, includeCounters: true });

	useEffect(() => {
		if (toggleArchiveTaskStatus === 'done' || removeTaskStatus === 'done') {
			boardsShortListModel.fetchBoardsShortListFx({ taskArchived: isArchive }).then(() => {
				toggleArchiveTaskStatus === 'done' && toggleArchiveTaskModel.resetStatus();
				removeTaskStatus === 'done' && removeTaskModel.resetStatus();
			});
		}
	}, [isArchive, removeTaskStatus, toggleArchiveTaskStatus]);

	const tasksPageFilterOptions = useMemo(
		() =>
			[
				...defaultFilterOption,
				...boardsShortList
					.filter((board) =>
						pathname.includes('created-by-me') ? board.createdByMeTasksCount : board.myTasksCount
					)
					.map((board) => ({ label: board.name, value: board.id })),
			] as SelectOption<string>[],
		[boardsShortList, pathname]
	);

	return (
		<div className={styles.actionsWrapper}>
			{isArchive && (
				<SearchInput
					className={styles.searchInput}
					onSearch={onSearch}
					icon="search"
					theme="dark"
					placeholder="Поиск по названию"
				/>
			)}
			<Select
				onChange={onFilter}
				options={tasksPageFilterOptions}
				initial={tasksPageFilterOptions[0]}
			/>
			{!isArchive && (
				<>
					<Button to={toArchive} iconLeft={<Icon id={'clock'} />} color={'secondary'}>
						Архив задач
					</Button>
					<Button onClick={createTask}>Создать задачу</Button>
				</>
			)}
		</div>
	);
};
