import { combine, createEffect, createEvent, createStore, restore, sample } from 'effector';

import { tagsRequests, DeleteTagRequest, TagDto } from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { tagListModel, tagManagerModel } from '@entities/tag-manager/model';

type DoneCallback = (tag?: TagDto) => void;
type DoneFxArgs = { cb: Nullable<DoneCallback>; tag: Nullable<TagDto> };

const deleteTag = createEvent<{ done?: DoneCallback } & DeleteTagRequest>();
const deleteTagFx = createEffect(tagsRequests.deleteTag);
const deleteTagDoneFx = createEffect(({ cb, tag }: DoneFxArgs) => cb && cb(tag || undefined));

const $response = createStore<Nullable<TagDto>>(null).reset(deleteTag);
const $error = restore<Error>(deleteTagFx.failData, null).reset(deleteTag);
const $doneCb = createStore<Nullable<DoneCallback>>(null).reset(deleteTag);

const $deleteTagStatus = combine({
	pending: deleteTagFx.pending,
	response: $response,
	error: $error,
});

sample({
	clock: deleteTag,
	fn: ({ data }) => data,
	target: [$response, deleteTagFx],
});

sample({
	clock: deleteTag,
	fn: ({ done }) => done || null,
	target: $doneCb,
});

sample({
	clock: deleteTagFx.done,
	source: combine({
		cb: $doneCb,
		tag: $response,
	}),
	target: deleteTagDoneFx,
});

sample({
	clock: deleteTagFx.doneData,
	source: $response,
	fn: (request) => ({
		type: 'success' as NoticeType,
		text: `Метка "<b>${request?.name}</b>" удалена.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: deleteTagFx.done,
	target: tagListModel.revalidateTagList,
});

sample({
	clock: deleteTagFx.done,
	fn: () => ({
		type: null,
	}),
	target: tagManagerModel.pushAction,
});

sample({
	clock: deleteTagFx.fail,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже',
	}),
	target: noticesModel.add,
});

export const model = {
	deleteTag,
	deleteTagFx,
	$deleteTagStatus,
};
