import { useEffect } from 'react';

interface UseBeforeUnloadArgs {
	when?: boolean;
	message?: string;
	callback?: () => void;
}

export const useBeforeUnload = ({
	when,
	message = 'Сохранить изменения перед выходом?',
	callback,
}: UseBeforeUnloadArgs) => {
	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			callback && setTimeout(callback, 200);
			event.preventDefault();
			event.returnValue = message;
			return message;
		};

		if (when) {
			window.addEventListener('beforeunload', handleBeforeUnload);
		}

		return () => window.removeEventListener('beforeunload', handleBeforeUnload);
	}, [when, message, callback]);
};
