export enum Colors {
	Red = '#F1CDCD',
	Orange = '#F7DDBE',
	Yellow = '#EFF0AC',
	Green = '#CCEDD2',
	Purple = '#E3D0EF',
	Pink = '#F6D6EB',
	Blue = '#C4D0FB',
	LightBlue = '#CFE6F7',
	Grey = '#DDDDE8',
}

export enum TaskStatus {
	NEW = 'Новая задача',
	IN_PROGRESS = 'В работе',
	STOPPED = 'Приостановлена',
	REVIEW = 'На согласовании',
	DONE = 'Выполнена',
}

export const MS_PER_MINUTE = 60000;

export const MAX_FILE_SIZE_50MB = 52428800;

export const URL_TASK_PATTERN = /\/task\/[\w0-9-]+/gm;

export const URL_BOARD_PATTERN = /\/boards\/(archive\/)?[\w0-9-]+/gm;

export const reassignRequestKey = 'reassign-request-id';

export const devLoginPath = '/dev-login';
export const prodLoginPath = `http://${process.env.REACT_APP_KEYCLOAK_URL}/auth/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}&response_type=code`;
