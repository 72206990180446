import {
	createEvent,
	createEffect,
	createStore,
	sample,
	restore,
	combine,
	forward,
} from 'effector';
import { createGate } from 'effector-react';

import {
	GetTasksEventsRequest,
	TaskEventPageableResponse,
	TaskEventDto,
	tasksEvents,
} from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { taskListModel } from '../task-list';

const reset = createEvent();

const addToList = createEvent<TaskEventDto>();
const updateInList = createEvent<TaskEventDto>();
const removeFromList = createEvent<Nullable<TaskEventDto>>();

const $taskEvents = createStore<TaskEventDto[]>([]).reset(reset);
const $taskEventsPageable = createStore<Record<number, TaskEventDto[]>>({ 0: [] }).reset(reset);
const $taskEventsGrouped = createStore<Map<number, TaskEventDto[]>>(new Map()).reset(reset);

const getTaskEventsList = createEvent<GetTasksEventsRequest>();
const getTaskEventsListFx = createEffect(tasksEvents.getTasksEvents);

const $config = createStore<Nullable<GetTasksEventsRequest>>(null).reset([
	getTaskEventsList,
	reset,
]);
const $error = restore<Error>(getTaskEventsListFx.failData, null).reset([getTaskEventsList, reset]);
const $pagination = createStore<Omit<TaskEventPageableResponse, 'data'>>({}).reset(reset);
const $complete = createStore<boolean>(false).reset(reset);

const $status = combine({
	loading: combine(
		$taskEvents,
		getTaskEventsListFx.pending,
		(data, pending) => pending && data.length === 0
	),
	lazyLoading: combine(
		$taskEvents,
		getTaskEventsListFx.pending,
		(data, pending) => pending && data.length > 0
	),
	data: $taskEvents,
	grouped: $taskEventsGrouped,
	error: $error,
	pagination: $pagination,
	complete: $complete,
});

/* *
 * Гейт
 */
const TasksEventsListGate = createGate<GetTasksEventsRequest>();

forward({
	from: TasksEventsListGate.state,
	to: getTaskEventsList,
});

forward({
	from: TasksEventsListGate.close,
	to: reset,
});

sample({
	clock: getTaskEventsList,
	filter: ({ page }) => page === 0,
	target: reset,
});

sample({
	clock: getTaskEventsList,
	filter: ({ taskId }) => !!taskId,
	target: [getTaskEventsListFx, $config],
});

/* *
 * Складываем респонсы в page-map
 */
sample({
	clock: getTaskEventsListFx.doneData,
	source: $taskEventsPageable,
	fn: (store, response) => {
		const map = { ...store };
		map[response?.currentPage || 0] = response?.data || [];
		return map;
	},
	target: $taskEventsPageable,
});

/*
 * Преобразуем page-map в список
 */
sample({
	clock: $taskEventsPageable,
	fn: (map) => {
		return map ? Object.values(map).reduce((acc, val) => acc.concat(val), []) : [];
	},
	target: $taskEvents,
});

/*
 * Добавляем в начало списка
 */
sample({
	clock: addToList,
	source: $taskEvents,
	fn: (list, add) => (add ? [add, ...list] : list),
	target: $taskEvents,
});

/*
 * Удаляем из списка
 */
sample({
	clock: removeFromList,
	source: $taskEvents,
	fn: (list, remove) => list.filter(({ id }) => id !== remove?.id),
	target: $taskEvents,
});

/*
 * Обновляем в списке
 */
sample({
	clock: updateInList,
	source: $taskEvents,
	fn: (list, update) => list.map((item) => (item.id === update.id ? update : item)),
	target: $taskEvents,
});

/*
 * Преобразуем в группированный список по датам
 */
sample({
	clock: $taskEvents,
	fn: (list) => {
		return list.reduce((group, event) => {
			const { createDate } = event;
			if (createDate) {
				const date = new Date(createDate).setHours(0, 0, 0, 0);
				group.set(date, [...(group.get(date) ?? []), event]);
			}
			return group;
		}, new Map<number, TaskEventDto[]>());
	},
	target: $taskEventsGrouped,
});

/*
 * Храним пагинацию отдельно
 */
sample({
	clock: getTaskEventsListFx.doneData,
	fn: ({ totalElements, currentPage, nextPage, prevPage, totalPages }) => ({
		totalElements,
		currentPage,
		nextPage,
		prevPage,
		totalPages,
	}),
	target: $pagination,
});

/*
 * Вычисляем, закончен список или нет
 */
sample({
	clock: $pagination,
	fn: ({ nextPage, totalPages }) => nextPage === totalPages,
	target: $complete,
});

/*
 * Уведомления
 */
sample({
	clock: getTaskEventsListFx.failData,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

/* *
 * Ревалидация всех страниц
 */
const revalidate = createEvent();
const revalidateFx = createEffect(async (requests: GetTasksEventsRequest[]) => {
	const response = await Promise.all(requests.map((cfg) => tasksEvents.getTasksEvents(cfg)));
	const {
		totalElements,
		currentPage,
		nextPage,
		prevPage,
		totalPages,
		totalElementsWithoutFilter,
		totalCommentsWithoutFilter,
	} = response[0];
	return {
		pagination: {
			totalElements,
			currentPage,
			nextPage,
			prevPage,
			totalPages,
			totalElementsWithoutFilter,
			totalCommentsWithoutFilter,
		},
		pageable: response.reduce((map, chunk, i) => {
			if (chunk.data) map[i] = chunk.data;
			return map;
		}, {} as Record<number, TaskEventDto[]>),
	};
});

sample({
	clock: revalidate,
	source: {
		config: $config,
		pages: $taskEventsPageable,
	},
	filter: ({ config }) => config !== null,
	fn: ({ config, pages }) =>
		Object.keys(pages).map((page) => ({ ...config, page: Number(page) } as GetTasksEventsRequest)),
	target: revalidateFx,
});

sample({
	clock: revalidateFx.doneData,
	fn: ({ pageable }) => pageable,
	target: $taskEventsPageable,
});

sample({
	clock: revalidateFx.doneData,
	fn: ({ pagination }) => pagination,
	target: $pagination,
});

/**
 * Update taskEventsAmount on preview task card
 */
sample({
	clock: revalidateFx.done,
	fn: (clock) => ({
		id: clock.params[0].taskId,
		taskEventsAmount: clock.result.pagination.totalCommentsWithoutFilter,
	}),
	target: taskListModel.updateTargetTaskData,
});

export const model = {
	TasksEventsListGate,
	$status,
	addToList,
	removeFromList,
	updateInList,
	revalidate,
};
