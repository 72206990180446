/**
 * rpharm-todo
 * rpharm-todo documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TaskApprovalDto {
	id?: string;
	status: TaskApprovalDtoStatusEnum;
	message?: string;
	active?: boolean;
	currentUserApproverOfTaskApproval?: boolean;
}
export enum TaskApprovalDtoStatusEnum {
	OPEN = 'OPEN',
	DECLINED = 'DECLINED',
	APPROVED = 'APPROVED',
}
