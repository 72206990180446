import { emptyPromise, fetcher } from '@shared/api/config';
import { TagDto } from '@shared/api/model/models';

export interface GetTagListRequest {
	boardId?: string;
	search?: string;
	showOnlyCustom?: boolean;
}

const getTagList = ({ boardId, search, showOnlyCustom }: GetTagListRequest) =>
	boardId
		? fetcher<TagDto[]>({
				url: '/tags',
				method: 'GET',
				body: {
					'board-id': boardId,
					'name-phrase': search,
					'show-only-custom': showOnlyCustom,
				},
		  })
		: emptyPromise<TagDto>();

export interface CreateTagRequest {
	boardId: string;
	data: Omit<TagDto, 'id' | 'active'>;
}

const createTag = ({ boardId, data }: CreateTagRequest) =>
	fetcher<TagDto>({
		url: `/tags?board-id=${boardId}`,
		method: 'POST',
		body: data,
	});

export interface UpdateTagRequest {
	data: TagDto;
}

const updateTag = ({ data }: UpdateTagRequest) =>
	fetcher<TagDto>({
		url: `/tags`,
		method: 'PUT',
		body: data,
	});

export interface DeleteTagRequest {
	data: TagDto;
}

const deleteTag = (tag: TagDto) =>
	fetcher<TagDto>({
		url: `/tags/${tag.id}`,
		method: 'DELETE',
	});

export interface ToggleTagActiveRequest {
	tagId: TagDto['id'];
}

const toggleTagActive = ({ tagId }: ToggleTagActiveRequest) =>
	fetcher<TagDto>({
		url: `/tags/${tagId}`,
		method: 'PUT',
	});

export interface SetActiveTagsRequest {
	id: TagDto['id'];
	active: TagDto['active'];
}

const setActiveTags = (data: SetActiveTagsRequest[]) =>
	fetcher<TagDto>({
		url: '/tags/active',
		method: 'PUT',
		body: data,
	});

export const tagsRequests = {
	getTagList,
	createTag,
	updateTag,
	deleteTag,
	toggleTagActive,
	setActiveTags,
};
