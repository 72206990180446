import { ReferenceType } from '@floating-ui/react-dom';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import React, { FC, PropsWithChildren, ReactElement, useMemo, useRef } from 'react';

import { useOutsideClick } from '@shared/lib/hooks';
import { checkEscape } from '@shared/lib/utils';
import { Dropdown, DropdownPlacement } from '@shared/ui';

import styles from './styles.module.scss';

interface DropdownControlledProps {
	opened: boolean;
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
	targetButton: ReactElement;
	content: ReactElement;
	contentStyle?: React.CSSProperties;
	contentClassName?: string;
	dropdownClassName?: string;
	className?: string;
	placement?: DropdownPlacement;
	portal?: boolean;
	portalId?: string;
	initialFocus?: false;
	returnFocusOnDeactivate?: boolean;
	withFocusTrap?: boolean;
	onClose?: () => void;
	externalReference?: Nullable<ReferenceType>;
}

export const DropdownControlled: FC<DropdownControlledProps> = ({
	opened,
	setOpened,
	targetButton,
	content,
	contentStyle,
	contentClassName,
	dropdownClassName,
	className,
	placement = 'bottomEnd',
	portal,
	portalId,
	initialFocus,
	withFocusTrap = true,
	onClose,
	externalReference,
}) => {
	const ddRef = useRef<HTMLDivElement | null>(null);
	const ddMenuRef = useRef<HTMLDivElement | null>(null);

	useOutsideClick([ddRef, ddMenuRef], () => {
		setOpened(false);
		onClose && onClose();
	});

	const dropdownElement = useMemo(
		() => (
			<DropdownElementWrap withFocusTrap={withFocusTrap} initialFocus={initialFocus}>
				<div
					ref={ddMenuRef}
					style={contentStyle}
					className={classnames(contentClassName, styles.dropBox)}
					onKeyDown={(e) => {
						e.stopPropagation();
						if (checkEscape(e)) {
							setOpened(false);
							onClose && onClose();
						}
					}}>
					{content}
				</div>
			</DropdownElementWrap>
		),
		[content, contentStyle, contentClassName, initialFocus, onClose, setOpened, withFocusTrap]
	);

	return (
		<Dropdown
			ref={ddRef}
			className={className}
			isOpen={opened}
			placement={placement}
			openOnButtonClick={false}
			closeOnClickOutside={false}
			portal={portal}
			dropdownId={portalId}
			dropdownClassName={dropdownClassName}
			dropdownElement={dropdownElement}
			dropdownData={(props, ref) => null}
			targetButton={targetButton}
			externalReference={externalReference}
		/>
	);
};

interface DropdownElementWrapProps {
	withFocusTrap?: boolean;
	initialFocus?: false;
	returnFocusOnDeactivate?: boolean;
}

export const DropdownElementWrap: FC<PropsWithChildren<DropdownElementWrapProps>> = ({
	withFocusTrap,
	initialFocus,
	returnFocusOnDeactivate,
	children,
}) => {
	return withFocusTrap ? (
		<FocusTrap
			focusTrapOptions={{
				initialFocus,
				returnFocusOnDeactivate,
				escapeDeactivates: true,
				allowOutsideClick: true,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 200)),
			}}>
			{children}
		</FocusTrap>
	) : (
		<>{children}</>
	);
};
