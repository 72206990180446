import { combine, createEffect, createEvent, createStore, restore, sample } from 'effector';

import { CreateBoardRequest, boardsRequests, BoardDto } from '@shared/api';
import { modalsModel, noticesModel } from '@shared/ui';

import { boardsModel } from '@entities/boards';

const createBoard = createEvent<CreateBoardRequest>();
const createBoardFx = createEffect(boardsRequests.createBoard);

createBoardFx.doneData.watch((result) => {
	modalsModel.closeLastModal();
	noticesModel.add({
		type: 'success',
		text: `Доска задач "<b>${result.name}</b>" создана.`,
	});
});

createBoardFx.failData.watch((result) => {
	noticesModel.add({
		type: 'error',
		text: `Что-то пошло не так, попробуйте позже.`,
	});
});

const $createdBoard = createStore<BoardDto | null>(null).reset(createBoard);
const $createBoardError = restore<Error>(createBoardFx.failData, null).reset(createBoard);

const $createBoardStatus = combine({
	pending: createBoardFx.pending,
	response: $createdBoard,
	error: $createBoardError,
});

sample({
	clock: createBoard,
	target: createBoardFx,
});

sample({
	clock: createBoardFx.done,
	source: boardsModel.$boardsListConfig,
	target: boardsModel.getBoardsList,
});

sample({
	clock: createBoardFx.doneData,
	target: $createdBoard,
});

export const createBoardFeature = {
	createBoard,
	createBoardFx,
	$createBoardStatus,
};
