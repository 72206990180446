import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import { Typo } from '@shared/ui';

import styles from './styles.module.scss';

interface EditableTextProps extends Pick<HTMLAttributes<HTMLElement>, 'children' | 'className'> {
	title: FC | React.ReactElement | string;
	wide?: boolean;
}

export const TaskRow: FC<EditableTextProps> = ({ title: Title, children, className, wide }) => {
	const TitleComponent = () =>
		typeof Title === 'function' ? <Title /> : typeof Title === 'string' ? <>{Title}</> : Title;

	return (
		<div className={classnames(className, styles.row, { [styles.wide]: wide })}>
			<Typo as="h3" design="header-16-semi-bold" className={classnames(styles.col, styles.title)}>
				<TitleComponent />
			</Typo>
			<div className={classnames(styles.col, styles.left)}>{children}</div>
		</div>
	);
};
