import classNames from 'classnames';
import classnames from 'classnames';
import React, { useRef, useState, FormEvent, ReactNode } from 'react';

import { useFocusTrap } from '@shared/lib/hooks';
import { helpersStyles } from '@shared/lib/styles';
import { Typo, Input, Scroller } from '@shared/ui';

import styles from './styles.module.scss';

export interface SearchUsersWrapperProps {
	title: string;
	onSearch: (event: FormEvent<HTMLInputElement>) => void;
	children: ReactNode;
}

export const SearchUsersWrapper = ({ title, onSearch, children }: SearchUsersWrapperProps) => {
	const searchWrapperRef = useRef<HTMLDivElement>(null);
	const [searchInputShadow, setSearchInputShadow] = useState(false);

	useFocusTrap(searchWrapperRef);

	return (
		<div className={styles.search} ref={searchWrapperRef}>
			<Typo className={styles.title} design="text-14-medium">
				{title}
			</Typo>

			<div className={classNames(helpersStyles.separator, styles.separator)} />

			<div className={classNames(styles.searchInput, { [styles.withShadow]: searchInputShadow })}>
				<Input
					id="search-input"
					placeholder="Поиск по имени"
					icon="search"
					theme="light-gray"
					onChange={onSearch}
				/>
			</div>

			<Scroller
				axis="y"
				className={classnames(styles.searchList, 'scroller', 'scrollbar-container')}
				onScroll={(_, y) => {
					setSearchInputShadow(y > 0);
				}}>
				{children}
			</Scroller>
		</div>
	);
};
