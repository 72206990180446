import { useGate } from 'effector-react';
import React, { FC, HTMLAttributes } from 'react';

import { boardModel, BoardTools, BoardToolsProps } from '@entities/boards';

interface BoardToolsWrapProps extends BoardToolsProps, HTMLAttributes<HTMLElement> {
	boardId?: string;
}

export const BoardToolsWrap: FC<BoardToolsWrapProps> = React.memo(({ boardId, ...rest }) => {
	useGate(boardModel.BoardGate, {
		boardId,
	});
	return <BoardTools {...rest} />;
});
