import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';

import { useBeforeUnload, useHistoryBlocker, useAccessibility } from '@shared/lib/hooks';
import {
	Button,
	Icon,
	Skeleton,
	tooltipEventHandlersFactory,
	TooltipsEventHandlers,
} from '@shared/ui';

import { boardViewModel, toggleTagsOnTaskModel } from '@entities/boards';
import { taskListTagsModel } from '@entities/task';

import { saveBoardViewFeature } from '@features/board';
import { useUserRoles } from '@features/user-roles';

import { confirmSaveBoardData, saveBoardViewAction } from '@widgets/save-board-view-modal';

import styles from './styles.module.scss';

interface TaskBoardPageActionsProps {
	createTask: () => void;
	isLoading?: boolean;
}

export const TaskBoardPageActions: FC<TaskBoardPageActionsProps> = ({ createTask, isLoading }) => {
	const thisMouse = useAccessibility();

	const { userOnBoard } = useUserRoles();
	const boardViewChanged = useStore(boardViewModel.$changed);
	const boardTagsChanged = useStore(taskListTagsModel.$boardTagsChanged);
	const showTagsOnTasksChanged = useStore(toggleTagsOnTaskModel.$showTagsOnTasksChanged);

	const { pending: boardViewSaving } = useStore(saveBoardViewFeature.$status);
	const setActiveTagsPending = useStore(taskListTagsModel.$setActiveTagsStatus) === 'pending';
	const toggleTagsOnTaskPending =
		useStore(toggleTagsOnTaskModel.$toggleTagsOnTasksStatus) === 'pending';

	const showSaveButton =
		userOnBoard.isAuthor && (boardViewChanged || boardTagsChanged || showTagsOnTasksChanged);
	const saveButtonTooltipEventHandlers: TooltipsEventHandlers = useMemo(
		() =>
			showSaveButton
				? tooltipEventHandlersFactory(
						'Сохранить изменения для всех участников доски',
						{
							align: null,
							delayIn: 400,
						},
						!thisMouse
				  )
				: {},
		[showSaveButton, thisMouse]
	);

	useBeforeUnload({
		when: showSaveButton,
		message: 'Вы хотите сохранить изменения для всех участников доски?',
		callback: () =>
			confirmSaveBoardData({ boardViewChanged, boardTagsChanged, showTagsOnTasksChanged }),
	});

	useHistoryBlocker({
		when: showSaveButton,
		callback: (retry) =>
			confirmSaveBoardData({
				boardViewChanged,
				boardTagsChanged,
				showTagsOnTasksChanged,
				retry,
			}),
	});

	return (
		<div className={styles.actionsWrapper}>
			{!isLoading ? (
				<Button
					onClick={createTask}
					disabled={!(userOnBoard.isAuthor || userOnBoard.isEditor || userOnBoard.isViewer)}>
					Создать задачу
				</Button>
			) : (
				<Skeleton width={148} height={32} />
			)}
			{showSaveButton && (
				<Button
					color="secondary"
					iconLeft={
						boardViewSaving || setActiveTagsPending || toggleTagsOnTaskPending ? (
							<Icon id="loading" />
						) : (
							<Icon id="disk" className="svg-path" />
						)
					}
					disabled={boardViewSaving}
					className={styles.boardViewSave}
					onClick={() =>
						saveBoardViewAction({ boardViewChanged, boardTagsChanged, showTagsOnTasksChanged })
					}
					{...saveButtonTooltipEventHandlers}>
					Сохранить изменения
				</Button>
			)}
		</div>
	);
};
