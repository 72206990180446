import { useStore } from 'effector-react';
import React from 'react';

import { NoticeLabel } from '@shared/ui';

import { notificationApprovalModel } from '@entities/notifications';

import styles from '../../../../styles.module.scss';

interface ApprovalStatusLabelsProps {
	id?: string;
}

export const ApprovalStatusLabels = ({ id }: ApprovalStatusLabelsProps) => {
	const label = useStore(notificationApprovalModel.$label);

	return label && label.id === id ? (
		<NoticeLabel
			className={styles.headerNoticeLabel}
			text={label.text}
			accepted={label.accepted}
			fadeOutTimer={3000}
			fadeOutCallback={() => notificationApprovalModel.setNoticeLabel(null)}
		/>
	) : null;
};
