import { createStore, createEvent, sample } from 'effector';
import { createGate } from 'effector-react';
import { reset } from 'patronum';

import { TagDto } from '@shared/api';

import { tagListModel } from '../tag-list';

export type TagManagerActionType = 'CREATE_TAG' | 'EDIT_TAG' | 'DELETE_TAG';

export type PushTagManagerActionType = {
	type: Nullable<TagManagerActionType>;
	boardId?: Nullable<string>;
	data?: TagDto;
};

const TagManagerGate = createGate();

const pushAction = createEvent<PushTagManagerActionType>();

const $action = createStore<Nullable<TagManagerActionType>>(null).reset(pushAction);
const $boardId = createStore<Nullable<string>>(null).reset(pushAction);
const $currentTag = createStore<Nullable<TagDto>>(null).reset(pushAction);

sample({
	clock: pushAction,
	fn: ({ type }) => type || null,
	target: $action,
});

sample({
	clock: pushAction,
	fn: ({ boardId }) => boardId || null,
	target: $boardId,
});

sample({
	clock: pushAction,
	fn: ({ data }) => data || null,
	target: $currentTag,
});

sample({
	clock: pushAction,
	filter: ({ type }) => type !== 'CREATE_TAG' && type !== null,
	target: tagListModel.resetSearch,
});

reset({
	clock: TagManagerGate.close,
	target: [$action, $boardId, $currentTag],
});

export const model = {
	TagManagerGate,
	$action,
	$currentTag,
	pushAction,
};
