import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { FC } from 'react';

import { TagDto } from '@shared/api';
import { checkEscape } from '@shared/lib/utils';
import { ConfirmModalBody, ConfirmModalHead } from '@shared/ui';

import { deleteTagModel } from '@entities/tag-manager';

import styles from './styles.module.scss';

interface TagsManagerRemoveProps {
	tag: Nullable<TagDto>;
	onDiscard: () => void;
	onAccept?: () => void;
	onUpdateList?: () => void;
}

export const TagsManagerRemove: FC<TagsManagerRemoveProps> = ({
	tag,
	onAccept,
	onDiscard,
	onUpdateList,
}) => {
	const deleteTagHandler = () => {
		if (!tag) return;
		deleteTagModel.deleteTag({
			data: tag,
			done: onUpdateList,
		});
		onAccept && onAccept();
	};

	const { pending } = useStore(deleteTagModel.$deleteTagStatus);

	return (
		<FocusTrap
			focusTrapOptions={{
				escapeDeactivates: true,
				allowOutsideClick: true,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
			}}>
			<div
				className={styles.box}
				onKeyDown={(e) => {
					if (checkEscape(e)) {
						e.stopPropagation();
						onDiscard && onDiscard();
					}
				}}>
				<ConfirmModalHead title="Удаление метки" size="sm" onClickBack={onDiscard} />
				<ConfirmModalBody
					content={
						<>
							Метка <b>{tag?.name}</b> будет удалена со всех карточек доски.
						</>
					}
					onAccept={deleteTagHandler}
					onDiscard={onDiscard}
					acceptLabel="Удалить"
					acceptColor="danger"
					textAlign="left"
					isPending={pending}
				/>
			</div>
		</FocusTrap>
	);
};
