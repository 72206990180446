import classnames from 'classnames';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { FC, HTMLAttributes, RefObject, useEffect, useRef } from 'react';

import { BoardDto, BoardPreviewDto, BoardWithViewDto } from '@shared/api';
import { Button, Icon } from '@shared/ui';

import { boardModel } from '@entities/boards';

import styles from '../styles.module.scss';

export interface BoardArchiveToolsProps extends HTMLAttributes<HTMLElement> {
	restoreBoard?: (board?: BoardPreviewDto | BoardDto | BoardWithViewDto) => void;
	deleteBoard?: (board?: BoardPreviewDto | BoardDto | BoardWithViewDto) => void;
	parentBtnRef?: RefObject<HTMLButtonElement>;
	closeDropdown?: () => void;
}

export const BoardArchiveTools: FC<BoardArchiveToolsProps> = React.memo(
	({ deleteBoard, restoreBoard, parentBtnRef, closeDropdown }) => {
		const boardData = useStore(boardModel.$board);

		const timer = useRef(0);

		useEffect(() => {
			window.clearTimeout(timer.current);
			const parentBtn = parentBtnRef?.current;
			return () => {
				timer.current = window.setTimeout(() => {
					parentBtn?.focus();
				}, 100);
			};
		}, [parentBtnRef]);

		const restoreBoardHandler = () => {
			if (!boardData) return;
			closeDropdown && closeDropdown();
			restoreBoard && restoreBoard(boardData);
		};

		const deleteBoardHandler = () => {
			if (!boardData) return;
			closeDropdown && closeDropdown();
			deleteBoard && deleteBoard(boardData);
		};

		return (
			<FocusTrap
				focusTrapOptions={{
					escapeDeactivates: true,
					allowOutsideClick: true,
					checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
				}}>
				<div className={styles.wrap}>
					<div className={classnames(styles.group)}>
						<Button
							className={styles.actionButton}
							onClick={restoreBoardHandler}
							size="sm"
							design="transparent"
							iconLeft={<Icon id="edit" />}>
							Восстановить доску
						</Button>
						<Button
							className={styles.actionButton}
							onClick={deleteBoardHandler}
							size="sm"
							design="transparent"
							iconLeft={<Icon id="trash" style={{ fill: 'currentColor', stroke: 'none' }} />}>
							Удалить доску
						</Button>
					</div>
				</div>
			</FocusTrap>
		);
	}
);
