import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { object } from 'yup';

import { RegisterWithPasswordDto } from '@shared/api';
import { devLoginPath } from '@shared/lib/constants';
import { resetJWT } from '@shared/lib/utils';
import { commonValidators } from '@shared/lib/validation';
import { Input, Button } from '@shared/ui';

import { devSignUpModel } from '@entities/auth';

import styles from './styles.module.scss';

export const DevSignUp: FC = () => {
	const navigate = useNavigate();

	const isLoading = useStore(devSignUpModel.$status) === 'pending';
	const error = useStore(devSignUpModel.$error);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm<RegisterWithPasswordDto>({
		defaultValues: {
			email: '',
			password: '',
			fullName: '',
			jobTitle: '',
			location: '',
			department: '',
		},
		resolver: yupResolver(
			object({
				email: commonValidators.requiredEmail,
				password: commonValidators.requiredString,
				fullName: commonValidators.requiredString,
				jobTitle: commonValidators.requiredString,
				location: commonValidators.requiredString,
				department: commonValidators.requiredString,
			})
		),
	});

	useEffect(() => {
		resetJWT();
	}, []);

	useEffect(() => {
		if (error?.response?.status === 400) {
			setError('email', { message: 'Пользователь с таким email существует' });
		}
	}, [error?.response?.status, setError]);

	const submit = (credentials: RegisterWithPasswordDto) => {
		devSignUpModel.signUp(credentials);
	};

	return (
		<div className={styles.signup}>
			<form className={styles.form} onSubmit={handleSubmit(submit)}>
				<Input
					type="text"
					{...register('fullName')}
					placeholder="ФИО"
					error={errors.fullName?.message}
				/>
				<Input
					type="email"
					{...register('email')}
					placeholder="E-mail"
					error={errors.email?.message}
				/>
				<Input
					type="text"
					{...register('jobTitle')}
					placeholder="Должность"
					error={errors.jobTitle?.message}
				/>
				<Input
					type="text"
					{...register('department')}
					placeholder="Департамент"
					error={errors.department?.message}
				/>
				<Input
					type="text"
					{...register('location')}
					placeholder="Расположение"
					error={errors.location?.message}
				/>
				<Input
					{...register('password')}
					type="password"
					placeholder="Пароль"
					error={errors.password?.message}
				/>
				<div className={styles.buttons}>
					<Button className={styles.button} type="submit" isLoading={isLoading}>
						Зарегистрироваться
					</Button>
					<Button className={styles.button} onClick={() => navigate(devLoginPath)}>
						Войти
					</Button>
				</div>
			</form>
		</div>
	);
};
