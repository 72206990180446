import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import classnames from 'classnames';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { object } from 'yup';

import { TagDto } from '@shared/api';
import { Colors } from '@shared/lib/constants';
import { helpersStyles } from '@shared/lib/styles';
import { checkEscape } from '@shared/lib/utils';
import { commonValidators } from '@shared/lib/validation';
import { Button, ConfirmModalHead, FormElement, Input, RoundColor, Typo } from '@shared/ui';

import { createTagModel, tagListModel, updateTagModel } from '@entities/tag-manager';

import styles from './styles.module.scss';

export type TagsManagerCreateMode = 'create' | 'edit';

interface TagsManagerCreateProps {
	boardId?: string;
	tag?: Nullable<TagDto>;
	mode?: TagsManagerCreateMode;
	onDiscard?: () => void;
	onUpdateList?: (tag: TagDto | undefined, isNew: boolean) => void;
}

type CreateTagDto = Omit<TagDto, 'id' | 'ative'>;

export const TagsManagerCreate: FC<TagsManagerCreateProps> = ({
	boardId,
	tag,
	mode = 'create',
	onDiscard,
	onUpdateList,
}) => {
	const title = mode === 'edit' ? 'Изменить метку' : 'Создать метку';
	const buttonLabel = mode === 'edit' ? 'Сохранить' : 'Создать';

	const {
		data: filteredTagList,
		full: tagList,
		search: savedSearchString,
	} = useStore(tagListModel.$tagListStatus);

	const getInitialName = () => {
		if (mode === 'create' && savedSearchString && filteredTagList.length === 0) {
			return savedSearchString ?? '';
		}

		return tag?.name ?? '';
	};

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isValid },
	} = useForm<CreateTagDto>({
		defaultValues: {
			name: getInitialName(),
			color: tag?.color ?? '#F1CDCD',
		},
		resolver: yupResolver(
			object({ name: commonValidators.noDuplicateTagName(tagList, tag?.name) })
		),
		mode: 'all',
	});

	const name = watch('name');
	const color = watch('color');
	const allowSave =
		mode === 'create' ||
		((name.toLowerCase().trim() !== tag?.name.toLowerCase().trim() || color !== tag?.color) &&
			isValid);
	const allowCreate = mode === 'create' ? isValid : true;

	const createTag = (data: CreateTagDto) => {
		if (!boardId) return;
		createTagModel.createTag({
			data: {
				boardId,
				data,
			},
			done: (tag) => onUpdateList && onUpdateList(tag, true),
		});
	};

	const updateTag = (data: CreateTagDto) => {
		if (!tag) return;
		updateTagModel.updateTag({
			data: {
				data: {
					...tag,
					...data,
				},
			},
			done: (tag) => onUpdateList && onUpdateList(tag, false),
		});
	};

	const { pending: createPending } = useStore(createTagModel.$createTagStatus);
	const { pending: updatePending } = useStore(updateTagModel.$updateTagStatus);

	return (
		<FocusTrap
			focusTrapOptions={{
				escapeDeactivates: true,
				allowOutsideClick: true,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
			}}>
			<div
				className={styles.box}
				onKeyDown={(e) => {
					if (checkEscape(e)) {
						e.stopPropagation();
						onDiscard && onDiscard();
					}
				}}>
				<ConfirmModalHead title={title} onClickBack={onDiscard} />
				<div className={styles.body}>
					<fieldset className={styles.fieldset}>
						<Typo className={styles.legend} design="text-13-medium" as="legend">
							Название
						</Typo>
						<FormElement error={errors?.name?.message}>
							<Input placeholder="Введите название" {...register('name')} />
						</FormElement>
					</fieldset>
					<fieldset className={styles.fieldset}>
						<Typo className={styles.legend} design="text-13-medium" as="legend">
							Цвет
						</Typo>
						<div className={styles.colorsList}>
							{Object.values(Colors).map((colorItem) => (
								<RoundColor
									key={colorItem}
									color={colorItem}
									value={colorItem}
									{...register('color')}
								/>
							))}
						</div>
					</fieldset>
				</div>
				<div className={classnames(styles.buttons, helpersStyles.buttonsPanel)}>
					<Button
						type="button"
						onClick={onDiscard}
						size="md"
						design="filled"
						color="secondary"
						className={helpersStyles.buttonsPanelItem}>
						Отменить
					</Button>
					<Button
						type="button"
						size="md"
						design="filled"
						color="primary"
						disabled={!allowCreate || !allowSave}
						isLoading={createPending || updatePending}
						onClick={mode === 'edit' ? handleSubmit(updateTag) : handleSubmit(createTag)}
						className={helpersStyles.buttonsPanelItem}>
						{buttonLabel}
					</Button>
				</div>
			</div>
		</FocusTrap>
	);
};
