const getTagsChangedEndWord = (counter: number) => {
	if (counter === 1) {
		return 'метку';
	}

	const word = `${counter} мет`;

	const counterString = counter.toString();
	const lastSymbol = counterString.slice(-1);
	const twoLastSymbols = counterString.slice(-2);

	if (lastSymbol === '1' && twoLastSymbols !== '11') {
		return `${word}ку`;
	}

	if (
		(lastSymbol === '2' || lastSymbol === '3' || lastSymbol === '4') &&
		twoLastSymbols !== '12' &&
		twoLastSymbols !== '13' &&
		twoLastSymbols !== '14'
	) {
		return `${word}ки`;
	}

	return `${word}ок`;
};

export const getTagsChangedText = (
	text: string | undefined,
	tagsAddedCount: number | undefined,
	tagsRemovedCount: number | undefined
) => {
	if (tagsRemovedCount !== undefined && tagsAddedCount !== undefined) {
		if (tagsAddedCount === 0 && tagsRemovedCount > 0) {
			return `удалил(а) ${getTagsChangedEndWord(tagsRemovedCount)}.`;
		}

		if (tagsRemovedCount === 0 && tagsAddedCount > 0) {
			return `добавил(а) ${getTagsChangedEndWord(tagsAddedCount)}.`;
		}

		if (tagsAddedCount > 0 && tagsRemovedCount > 0) {
			return `добавил(а) ${getTagsChangedEndWord(
				tagsAddedCount
			)} и удалил(а) ${getTagsChangedEndWord(tagsRemovedCount)}.`;
		}
	}

	return text;
};
