import { useStore } from 'effector-react';
import { useMemo } from 'react';

import { BoardRoleDtoNameEnum } from '@shared/api';

import { boardModel } from '@entities/boards';
import { currentUserModel } from '@entities/current-user';
import { taskModel } from '@entities/task';

export const useUserRoles = () => {
	const user = useStore(currentUserModel.$currentUser);
	const board = useStore(boardModel.$board);
	const task = useStore(taskModel.$task);

	const userOnBoard = useMemo(() => {
		const boardAuthor = board?.author;
		const boardParticipants = board?.participants;
		const boardRole = board?.currentParticipantBoardRole;

		const isAuthor = boardAuthor?.id === user?.id;
		const isParticipant =
			!!boardParticipants?.find(({ user: { id } }) => id === user?.id) || isAuthor;

		const isEditor = boardRole?.name === BoardRoleDtoNameEnum.EDITOR;
		const isViewer = boardRole?.name === BoardRoleDtoNameEnum.VIEWER;

		const hasFullAccess = isAuthor || isEditor;

		return {
			role: boardRole?.name,
			isAuthor,
			isEditor,
			isViewer,
			isParticipant,
			hasFullAccess,
		};
	}, [user, board]);

	const userOnTask = useMemo(() => {
		const isAuthor = task?.author && task?.author?.id === user?.id;
		const isAssignee = task?.assignee && task?.assignee?.id === user?.id;
		const isCoAssignee = task?.coAssignees?.some((coAssignee) => coAssignee.id === user?.id);

		return {
			isAuthor,
			isAssignee,
			isCoAssignee,
		};
	}, [user, task]);

	const isTaskOrBoardAuthor = userOnTask.isAuthor || userOnBoard.isAuthor;

	return {
		user,
		userOnBoard,
		userOnTask,
		isTaskOrBoardAuthor,
	};
};
