import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import { IconId } from '@shared/ui/icons/svg-sprite';

import styles from './Icon.module.scss';

export interface IconInterface extends HTMLAttributes<SVGElement> {
	id: IconId;
}

export const Icon: FC<IconInterface> = React.memo(({ id, style, className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMid meet"
			className={classnames(className, styles.svg)}
			aria-hidden="true"
			style={style}>
			<use xlinkHref={`#${id}`} />
		</svg>
	);
});
