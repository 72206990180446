import { createEvent, createEffect, createStore, sample, combine } from 'effector';
import { createGate } from 'effector-react';
import { status, reset } from 'patronum';

import { tasksRequests, ConvertToTaskPayload } from '@shared/api';
import { UserDto } from '@shared/api';
import { noticesModel } from '@shared/ui';

import { datesFactory } from '../dates-factory';
import { taskModel } from '../task';
import { taskEventsListModel } from '../task-events-list';
import { taskListModel } from '../task-list';

interface ConvertToTaskLoad {
	assignee?: Nullable<UserDto>;
	endDate?: string;
}

const convertToTaskLoad = createGate<ConvertToTaskLoad>();

const addAssignee = createEvent<UserDto>();
const removeAssignee = createEvent();

const convertToTask = createEvent<ConvertToTaskPayload>();
const convertToTaskFx = createEffect(tasksRequests.convertToTask);

const $assignee = createStore<Nullable<UserDto>>(null).reset(removeAssignee);
const $convertToTaskStatus = status({ effect: convertToTaskFx });

sample({
	clock: addAssignee,
	target: $assignee,
});

sample({
	clock: convertToTask,
	target: convertToTaskFx,
});

sample({
	clock: convertToTaskFx.doneData,
	target: [
		taskModel.revalidateTask,
		taskListModel.revalidateTaskList,
		taskEventsListModel.revalidate,
	],
});

/*
 * Dates of convert subtask
 */
const datesModel = datesFactory();

const $convertToTaskDates = combine(
	{
		endDate: datesModel.$dates.map(({ endDate }) => endDate ?? null),
		startDate: datesModel.$dates.map(({ startDate }) => startDate ?? null),
		deadlineReminderDate: datesModel.$dates.map(
			({ deadlineReminderDate }) => deadlineReminderDate ?? null
		),
	},
	({ endDate, startDate, deadlineReminderDate }) => ({
		endDate: endDate ?? undefined,
		startDate: startDate ?? undefined,
		deadlineReminderDate: deadlineReminderDate ?? undefined,
	})
);

/*
 * Set initial state from subtask
 */
sample({
	clock: convertToTaskLoad.open,
	filter: ({ endDate }) => Boolean(endDate),
	fn: ({ endDate }) => ({ endDate }),
	target: datesModel.setDates,
});

sample({
	clock: convertToTaskLoad.open,
	filter: ({ assignee }) => Boolean(assignee),
	fn: ({ assignee }) => assignee as UserDto,
	target: $assignee,
});

/*
 * Notifications
 */
sample({
	clock: convertToTaskFx.doneData,
	fn: (source) => ({
		type: 'success' as const,
		text: `Подзадача "<b>${source.title}</b>" преобразована в задачу.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: convertToTaskFx.failData,
	fn: () => ({
		type: 'error' as const,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

/**
 * Reset all stores on unmount
 */
reset({
	clock: convertToTaskLoad.close,
	target: [$assignee, $convertToTaskStatus, datesModel.$dates],
});

export const model = {
	convertToTaskLoad,
	datesModel,
	addAssignee,
	removeAssignee,
	convertToTask,
	$assignee,
	$convertToTaskStatus,
	$convertToTaskDates,
};
