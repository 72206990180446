import classNames from 'classnames';
import React, { FC, forwardRef } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';

import { helpersStyles } from '@shared/lib/styles';
import { Skeleton } from '@shared/ui';

import styles from './styles.module.scss';

type TextSizeType = 'header-24-bold' | 'text-13-regular';

type TextareaDesignType = 'without-border' | 'with-border';

interface ModalTextareaProps extends TextareaAutosizeProps {
	size?: TextSizeType;
	separator?: boolean;
	error?: string;
	design?: TextareaDesignType;
}

export const ModalTextarea = forwardRef<HTMLTextAreaElement | null, ModalTextareaProps>(
	(
		{
			size = 'header-24-bold',
			separator = true,
			error,
			design = 'without-border',
			className,
			value,
			...props
		},
		ref
	) => {
		return (
			<div className={styles.textareaWrapper}>
				<TextareaAutosize
					ref={ref}
					className={classNames(
						styles.modalTextarea,
						styles[size],
						styles[design],
						error && styles.errorTextarea,
						className
					)}
					value={value || ''}
					{...props}
				/>
				{separator && (
					<div
						className={classNames(
							helpersStyles.separator,
							styles.separator,
							error && styles.separatorError
						)}
					/>
				)}
				{error && <div className={classNames(styles.error, styles[`error-${size}`])}>{error}</div>}
			</div>
		);
	}
);

export const ModalTextareaSkeleton: FC<Pick<ModalTextareaProps, 'separator'>> = ({
	separator = true,
}) => {
	return (
		<div className={styles.textareaWrapper} style={{ paddingTop: 4 }}>
			<Skeleton width={300} height={24} margin="0 0 19px" />
			{separator && <div className={classNames(helpersStyles.separator, styles.separator)} />}
		</div>
	);
};
