import { combine, createEffect, createEvent, createStore, sample } from 'effector';

import { ToggleBoardArchivedRequest, boardsRequests, BoardPreviewDto } from '@shared/api';
import { routerModel } from '@shared/model';
import { modalsModel, noticesModel } from '@shared/ui';

import { boardsModel } from '@entities/boards';

const toggleBoardArchived = createEvent<ToggleBoardArchivedRequest>();
const toggleBoardArchivedFx = createEffect(boardsRequests.toggleBoardArchived);

toggleBoardArchivedFx.doneData.watch((result) => {
	modalsModel.closeLastModal();
	routerModel.push(`/boards`);
	noticesModel.add({
		type: 'success',
		text: result.archived
			? `Доска задач "<b>${result.name}</b>" перенесена в архив.`
			: `Доска задач "<b>${result.name}</b>" была восстановлена.`,
	});
});

toggleBoardArchivedFx.failData.watch((result) => {
	modalsModel.closeLastModal();
	noticesModel.add({
		type: 'error',
		text: `Что-то пошло не так, попробуйте позже.`,
	});
});

const $config = createStore<ToggleBoardArchivedRequest | null>(null).reset(toggleBoardArchived);
const $board = createStore<BoardPreviewDto | null>(null).reset(toggleBoardArchived);
const $error = createStore<Error | null>(null).reset(toggleBoardArchived);

const $toggleBoardArchivedStatus = combine({
	pending: toggleBoardArchivedFx.pending,
	data: $board,
	error: $error,
});

sample({
	clock: toggleBoardArchived,
	target: [$config, toggleBoardArchivedFx],
});

sample({
	clock: toggleBoardArchivedFx.done,
	source: boardsModel.$boardsListConfig,
	target: boardsModel.getBoardsList,
});

sample({
	clock: toggleBoardArchivedFx.failData,
	target: $error,
});

sample({
	clock: toggleBoardArchivedFx.doneData,
	target: $board,
});

export const model = {
	toggleBoardArchived,
	toggleBoardArchivedFx,
	$toggleBoardArchivedStatus,
};
