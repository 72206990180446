import classNames from 'classnames';
import React, { useEffect, forwardRef, HTMLAttributes, MutableRefObject, useRef } from 'react';

import { BoardRoleDto } from '@shared/api/model/boardRoleDto';
import { useEscape, useFocusTrap, useOutsideClick } from '@shared/lib/hooks';
import { helpersStyles } from '@shared/lib/styles';
import { Button, Icon } from '@shared/ui';
import { Role } from '@shared/ui';

import styles from './styles.module.scss';

export interface RolesProps extends HTMLAttributes<HTMLDivElement> {
	setRole: (role: BoardRoleDto) => void;
	removeParticipant: () => void;
	boardRoles: BoardRoleDto[];
	selectedRole?: string;
	focusOnMount?: boolean;
	onEscape?: () => void;
	outsideClickExcludeRef?: MutableRefObject<HTMLElement | null>;
	disableRolesChanging?: boolean;
}

export const Roles = forwardRef<HTMLDivElement, RolesProps>(
	(
		{
			setRole,
			removeParticipant,
			boardRoles,
			selectedRole,
			focusOnMount,
			onEscape,
			outsideClickExcludeRef,
			onKeyDownCapture,
			disableRolesChanging,
		},
		ref
	) => {
		const rolesRef = useRef<HTMLDivElement>(null);

		useFocusTrap(rolesRef);

		useOutsideClick([rolesRef, outsideClickExcludeRef], () => onEscape && onEscape(), {});

		useEffect(() => {
			if (!focusOnMount) return;

			setTimeout(() => {
				const role = document.getElementById('participant-role');
				role?.focus();
			}, 100);
		}, [focusOnMount]);

		useEscape(
			true,
			(e) => {
				if (onEscape) {
					e?.stopPropagation();
					onEscape();
				}
			},
			rolesRef as MutableRefObject<HTMLElement>
		);

		return (
			<div ref={ref} className={styles.roles} onKeyDownCapture={onKeyDownCapture}>
				<div ref={rolesRef}>
					{boardRoles.map((role, i) => {
						const isSelected = selectedRole === role.name;

						if (disableRolesChanging && !isSelected) {
							return null;
						}

						return (
							<Role
								id={!i ? 'participant-role' : undefined}
								className={styles.role}
								description={role.description}
								isSelected={isSelected}
								onClick={() => setRole(role)}
								disabled={disableRolesChanging}
								key={role.id}
							/>
						);
					})}
					<div className={classNames(helpersStyles.separator, styles.separator)} />
					<Button
						onClick={removeParticipant}
						className={styles.button}
						size="sm"
						design="transparent"
						iconLeft={<Icon id="remove" />}>
						Удалить
					</Button>
				</div>
			</div>
		);
	}
);
