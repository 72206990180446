import { createEffect, createEvent, createStore, sample } from 'effector';
import { NavigateFunction } from 'react-router-dom';

const $routerHistory = createStore<NavigateFunction | null>(null);
const setHistory = createEvent<NavigateFunction>();

sample({
	clock: setHistory,
	target: $routerHistory,
});

const pushHistoryFx = createEffect(
	({ path, history }: { path: string; history: NavigateFunction | null }) => {
		if (!history) {
			console.error('Init history before push');
			return;
		}

		history(path);
	}
);

const pushHistory = createEvent<string>();

sample({
	clock: pushHistory,
	source: $routerHistory,
	fn: (history, path) => ({ path, history }),
	target: pushHistoryFx,
});

export const routerModel = {
	setHistory,
	push: pushHistory,
	pushHistoryFx,
	$routerHistory,
};
