import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC, useState } from 'react';

import { TaskApprovalDto, TaskApprovalDtoStatusEnum } from '@shared/api';
import {
	Button,
	DropdownControlled,
	NotificationApprovalMessage,
	NotificationApprovalMessageData,
} from '@shared/ui';

import { notificationApprovalModel } from '@entities/notifications';
import { taskListModel, taskModel } from '@entities/task';

import styles from './styles.module.scss';

export interface ApprovalTaskButtonsProps {
	taskApproval?: TaskApprovalDto;
	id?: string;
	title?: string;
}

export const ApprovalTaskButtons: FC<ApprovalTaskButtonsProps> = ({ taskApproval, id, title }) => {
	const [approvalMessageOpen, setApprovalMessageOpen] = useState(false);
	const pending = useStore(notificationApprovalModel.$pending);

	const approveTask = () => {
		setApprovalMessageOpen(false);
		if (taskApproval) {
			notificationApprovalModel
				.approveTaskFx({
					taskApproval: {
						...taskApproval,
						status: TaskApprovalDtoStatusEnum.APPROVED,
					},
					id,
					title,
				})
				.then(() => {
					taskModel.revalidateTask();
					taskListModel.revalidateTaskList();
				});
		}
	};
	const disapproveTask = ({ message }: NotificationApprovalMessageData) => {
		setApprovalMessageOpen(false);
		if (taskApproval) {
			notificationApprovalModel
				.disapproveTaskFx({
					taskApproval: {
						...taskApproval,
						status: TaskApprovalDtoStatusEnum.DECLINED,
						message,
					},
					id,
					title,
				})
				.then(() => {
					taskModel.revalidateTask();
					taskListModel.revalidateTaskList();
				});
		}
	};

	return (
		<>
			<DropdownControlled
				opened={approvalMessageOpen}
				setOpened={setApprovalMessageOpen}
				dropdownClassName={classnames(styles.approvalDropDown, 'task-preview-approval-message')}
				portal={true}
				portalId="task-modal-portal"
				placement="bottomStart"
				targetButton={
					<Button
						size="md"
						design="filled"
						color="secondary"
						className={styles.button}
						onClick={() => setApprovalMessageOpen(true)}>
						В работу
					</Button>
				}
				content={
					<NotificationApprovalMessage
						onAccept={disapproveTask}
						onDiscard={() => setApprovalMessageOpen(false)}
						isPending={pending.decline}
					/>
				}
			/>
			<Button
				size="md"
				design="filled"
				color="primary"
				className={styles.button}
				onClick={approveTask}>
				Согласовать
			</Button>
		</>
	);
};
