import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { Button, EmptyBanner } from '@shared/ui';

import { Header, headerModel } from '@widgets/header';
import { PageContentWrapper } from '@widgets/page-content-wrapper';

interface ErrorPageProps {
	title?: string;
	text?: React.ReactNode;
}

export const ErrorPage: FC<ErrorPageProps> = ({ title = 'Ошибка', text }) => {
	const headerHeight = useStore(headerModel.$height);
	return (
		<div>
			<Header
				title={title}
				backUrl="/my-tasks"
				topRight={
					<Button to="/my-tasks" color="primary">
						Перейти в "Мои задачи"
					</Button>
				}
			/>
			<PageContentWrapper headerOffset={headerHeight}>
				<EmptyBanner>{text}</EmptyBanner>
			</PageContentWrapper>
		</div>
	);
};
