import React from 'react';

import { TaskPreviewDto } from '@shared/api';

import { useCurrentUser } from '@entities/current-user';
import { useToggleArchiveTask } from '@entities/task';
import { TaskCard } from '@entities/task';

import { useTaskApproval } from '@widgets/task-board';

interface TaskCardWrapperProps {
	task: TaskPreviewDto;
	onClick: (id: string, title?: string) => void;
	isDragging?: boolean;
}

export const TaskCardWrapper = React.memo<TaskCardWrapperProps>(
	({ task, onClick }) => {
		const { id, title, taskApproval } = task;
		const { currentUser } = useCurrentUser();
		const { activeTask, toggleArchiveTask, loading: archivePending } = useToggleArchiveTask();
		const {
			approveTask,
			disapproveTask,
			activeApprovalTask,
			setActiveApprovalTask,
			pending: approvalPending,
		} = useTaskApproval();

		if (!id) return null;

		return (
			<TaskCard
				{...task}
				id={id}
				onClick={() => onClick(id, title)}
				archiveTask={() => toggleArchiveTask(task)}
				toggleArchiveLoading={archivePending && activeTask === id}
				approveTask={async () => {
					const payload = { id, taskApproval, title };
					return approveTask(payload).then((response) => {
						setActiveApprovalTask(null);
						return response;
					});
				}}
				disapproveTask={async (data) => {
					const payload = { id, taskApproval: taskApproval && { ...taskApproval, ...data }, title };
					return disapproveTask(payload).then((response) => {
						setActiveApprovalTask(null);
						return response;
					});
				}}
				approveTaskPending={activeApprovalTask === id && approvalPending.approve}
				disapproveTaskPending={activeApprovalTask === id && approvalPending.decline}
				currentUser={currentUser}
			/>
		);
	},
	(_, { isDragging }) => {
		return !!isDragging;
	}
);
