export const BOARDS_LIST_SELECTOR_EMPTY =
	'Не найдено ни одной доски. Проверьте правильность написания или измените запрос.';

export const BOARDS_LIST_EMPY = {
	actual: 'Список досок задач пуст.',
	archived: 'Архив досок задач пуст.',
	archivedWithSearch: 'Досок с таким названием не найдено.',
};

export const BOARD_EMPTY = {
	actual: 'Список актуальных задач на данной доске пуст.',
	archived: 'Список архивных задач на данной доске пуст.',
	withView: 'По заданным параметрам отсутствуют задачи. Проверьте настройки отображения.',
	archivedWithSearch: 'Задач с таким названием не найдено.',
};

export const USER_BOARD_EMPTY = {
	actualMy: 'Список задач, которые нужно выполнить, на данный момент пуст.',
	actualCreatedByMe: 'Список созданных вами задач пуст.',
	archived: 'Список архивных задач на данный момент пуст.',
	archivedWithSearch: 'Задач с таким названием не найдено.',
};

export const TASK_EVENTS_EMPTY = {
	news: 'Список новостей пуст.',
	comments: 'Список комментариев пуст.',
};

export const NOTIFICATIONS_EMPTY = 'Список уведомлений пуст.';

export const TAGS_EMPTY = {
	list: 'Список меток пуст',
	listWithSearch: 'Метки с таким названием не существует',
};

export const NOT_FOUND_MESSAGE = {
	default: 'Ничего не найдено. Проверьте правильность написания или измените запрос.',
	employees:
		'Не найдено ни одного сотрудника. Проверьте правильность написания. Возможно, сотрудник еще не зарегистрирован в системе.',
};
