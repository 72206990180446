import React, { FC } from 'react';

export type IconId =
	| 'clock'
	| 'file'
	| 'message'
	| 'plus'
	| 'kebab'
	| 'calendar'
	| 'done'
	| 'frames'
	| 'layers'
	| 'close'
	| 'bottom'
	| 'top'
	| 'left'
	| 'right'
	| 'remove'
	| 'search'
	| 'send'
	| 'edit'
	| 'people'
	| 'people-add'
	| 'monitor'
	| 'link'
	| 'arrow-top-right'
	| 'save'
	| 'disk'
	| 'reorganize'
	| 'bell'
	| 'link-external'
	| 'arrow-bottom'
	| 'arrow-top'
	| 'arrow-right'
	| 'arrow-left'
	| 'chevron-left'
	| 'chevron-right'
	| 'chevron-up'
	| 'star'
	| 'resize'
	| 'loading'
	| 'label'
	| 'tag'
	| 'trash'
	| 'download'
	| 'rotate'
	| 'up-n-down'
	| 'check'
	| 'linked-task';

export const SvgSprite: FC = () => {
	return (
		<svg style={{ display: 'none' }} xmlns="http://www.w3.org/2000/svg">
			<symbol viewBox="0 0 19.9 18.7" id="clock">
				<path d="M9.9,17.7c4.6,0,8.3-3.7,8.3-8.3s-3.7-8.3-8.3-8.3c-4.6,0-8.3,3.7-8.3,8.3S5.3,17.7,9.9,17.7z" />
				<path d="M9.9,4.4v5l3.3,1.7" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="file">
				<path d="M18,8.9l-7.7,7.7C9.4,17.5,8.1,18,6.8,18c-1.3,0-2.6-0.5-3.5-1.5c-0.9-0.9-1.5-2.2-1.5-3.5 c0-1.3,0.5-2.6,1.5-3.5l7.7-7.7c0.6-0.6,1.5-1,2.4-1c0.9,0,1.7,0.4,2.4,1c0.6,0.6,1,1.5,1,2.4c0,0.9-0.4,1.7-1,2.4L8,14.2 c-0.3,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.4,0.2-0.9,0.5-1.2l7.1-7.1" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="message">
				<path d="M16.2,12c-0.6,1.2-1.5,2.2-2.6,2.9c-1.1,0.7-2.4,1.1-3.7,1.1c-1.1,0-2.2-0.3-3.2-0.8L2,16.7l1.8-4.8 	c-0.5-1-0.9-2-0.9-3.1c0-1.3,0.4-2.6,1.1-3.7c0.7-1.1,1.7-2,2.9-2.6C8,1.7,9,1.7,10.3,1.7c1.7,0.1,3.4,0.8,4.6,2.1 c1.2,1.2,2,2.9,2.1,4.6C17,9.7,17,10.7,16.2,12z" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="plus">
				<path d="M9.9,3.6v11.7" />
				<path d="M4.1,9.4h11.7" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="kebab">
				<path d="M9.9,10.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8C9.5,8.6,9.1,9,9.1,9.4C9.1,9.9,9.5,10.2,9.9,10.2z" />
				<path d="M9.9,3.6c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8C9.1,3.2,9.5,3.6,9.9,3.6z" />
				<path d="M9.9,16.9c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8C9.1,16.5,9.5,16.9,9.9,16.9z" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="calendar">
				<path d="M15.8,2.7H4.1c-0.9,0-1.7,0.7-1.7,1.7v11.7c0,0.9,0.7,1.7,1.7,1.7h11.7c0.9,0,1.7-0.7,1.7-1.7V4.4 C17.4,3.5,16.7,2.7,15.8,2.7z" />
				<path d="M13.2,1.1v3.3" />
				<path d="M6.6,1.1v3.3" />
				<path d="M2.4,7.7h15" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="done">
				<path d="M16.6,4.8L7.4,14L3.3,9.8" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="frames">
				<path d="M8.3,1.9H2.4v8.3h5.8V1.9z" />
				<path d="M17.4,1.9h-5.8v3.3h5.8V1.9z" />
				<path d="M17.4,8.6h-5.8v8.3h5.8V8.6z" />
				<path d="M8.3,13.6H2.4v3.3h5.8V13.6z" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="layers">
				<path d="M9.9,1.1L1.6,5.2l8.3,4.2l8.3-4.2L9.9,1.1z" />
				<path d="M1.6,13.6l8.3,4.2l8.3-4.2" />
				<path d="M1.6,9.4l8.3,4.2l8.3-4.2" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="close">
				<path d="M14.9,4.4l-10,10" />
				<path d="M4.9,4.4l10,10" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="bottom">
				<path d="M4.9,6.9l5,5l5-5" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="top">
				<path d="M14.9,11.9l-5-5l-5,5" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="left">
				<path d="M12.4,14.4l-5-5l5-5" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="right">
				<path d="M7.4,14.4l5-5l-5-5" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="remove">
				<path d="M2.4,4.4h1.7h13.3" />
				<path d="M6.6,4.4V2.7c0-0.4,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5h3.3c0.4,0,0.9,0.2,1.2,0.5 c0.3,0.3,0.5,0.7,0.5,1.2v1.7 M15.8,4.4v11.7c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5H5.8c-0.4,0-0.9-0.2-1.2-0.5 c-0.3-0.3-0.5-0.7-0.5-1.2V4.4H15.8z" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="search">
				<path d="M9.1,15.2c3.7,0,6.7-3,6.7-6.7c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7C2.4,12.3,5.4,15.2,9.1,15.2z" />
				<path d="M17.4,16.9l-3.6-3.6" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="send">
				<path d="M18.3,1.1l-9.2,9.2" />
				<path d="M18.3,1.1l-5.8,16.7l-3.3-7.5L1.6,6.9L18.3,1.1z" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="edit">
				<path d="M9.9,16.5h7.5" />
				<path d="M13.7,2.8c0.3-0.3,0.8-0.5,1.2-0.5c0.2,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.6 c0.1,0.2,0.1,0.4,0.1,0.7s0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.6L5.8,15.7l-3.3,0.8l0.8-3.3L13.7,2.8z" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="people">
				<path d="M16.6,16.9v-1.7c0-0.9-0.4-1.7-1-2.4s-1.5-1-2.4-1H6.6c-0.9,0-1.7,0.4-2.4,1s-1,1.5-1,2.4v1.7" />
				<path d="M9.9,8.6c1.8,0,3.3-1.5,3.3-3.3s-1.5-3.3-3.3-3.3S6.6,3.4,6.6,5.2S8.1,8.6,9.9,8.6z" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="people-add">
				<path d="M13.2,16.9v-1.7c0-0.9-0.4-1.7-1-2.4s-1.5-1-2.4-1H4.1c-0.9,0-1.7,0.4-2.4,1s-1,1.5-1,2.4v1.7" />
				<path d="M7,8.6c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3S3.7,3.4,3.7,5.2C3.7,7.1,5.2,8.6,7,8.6z" />
				<path d="M16.6,6.1v5" />
				<path d="M19.1,8.6h-5" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="monitor">
				<path d="M16.6,1.9H3.3c-0.9,0-1.7,0.7-1.7,1.7v8.3c0,0.9,0.7,1.7,1.7,1.7h13.3c0.9,0,1.7-0.7,1.7-1.7V3.6 C18.3,2.7,17.5,1.9,16.6,1.9z" />
				<path d="M6.6,16.9h6.7" />
				<path d="M9.9,13.6v3.3" />
			</symbol>
			<symbol id="link" viewBox="0 0 12 12">
				<path
					d="M8.97 1a3 3 0 00-2.11.842l-.865.86a.5.5 0 00.705.71l.857-.853a2 2 0 012.829 2.827L8.889 6.883a2 2 0 01-3.016-.216.5.5 0 00-.8.6 3 3 0 004.523.323l1.5-1.5.006-.006A3 3 0 008.971 1zM5.685 4.473a3 3 0 00-2.336.87l-1.5 1.5-.006.007a3 3 0 004.242 4.242l.861-.861a.5.5 0 10-.707-.707l-.852.852A2 2 0 012.56 7.548L4.055 6.05a2 2 0 013.016.216.5.5 0 10.801-.6 3 3 0 00-2.188-1.194z"
					fillRule="evenodd"
				/>
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="arrow-top-right">
				<path d="M5.8,13.6l8.3-8.3" />
				<path d="M5.8,5.2h8.3v8.3" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="save">
				<path d="M15.8,16.9H4.1c-0.4,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2V3.6c0-0.4,0.2-0.9,0.5-1.2 c0.3-0.3,0.7-0.5,1.2-0.5h9.2l4.2,4.2v9.2c0,0.4-0.2,0.9-0.5,1.2C16.6,16.7,16.2,16.9,15.8,16.9z" />
				<path d="M14.1,16.9v-6.7H5.8v6.7" />
				<path d="M5.8,1.9v4.2h6.7" />
			</symbol>
			<symbol id="disk" viewBox="0 0 16 16">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.333 2.5a.833.833 0 00-.833.833v9.334a.833.833 0 00.833.833h.835V8.666a.5.5 0 01.5-.5h6.667a.5.5 0 01.5.5V13.5h.832a.833.833 0 00.833-.833V5.54L10.46 2.5H5.168v2.333h4.833a.5.5 0 010 1H4.668a.5.5 0 01-.5-.5V2.5h-.835zm1.335-1H3.333A1.833 1.833 0 001.5 3.333v9.334A1.833 1.833 0 003.333 14.5h9.334a1.833 1.833 0 001.833-1.833V5.333a.5.5 0 00-.146-.353L11.02 1.646a.5.5 0 00-.353-.146H4.668zm6.167 12H5.168V9.166h5.667V13.5z"
				/>
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="reorganize">
				<path d="M14.1,15.7V6.1V3.2" />
				<path d="M11.2,6.1l2.9-2.9L17,6.1" />
				<path d="M5.7,3.2v12.5" />
				<path d="M8.7,12.7l-2.9,2.9l-2.9-2.9" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="bell">
				<path d="M14.9,6.1c0-1.3-0.5-2.6-1.5-3.5c-0.9-0.9-2.2-1.5-3.5-1.5S7.3,1.6,6.4,2.5C5.4,3.5,4.9,4.8,4.9,6.1 c0,5.8-2.5,7.5-2.5,7.5h15C17.4,13.6,14.9,11.9,14.9,6.1z" />
				<path d="M11.4,16.9c-0.1,0.3-0.4,0.5-0.6,0.6s-0.5,0.2-0.8,0.2s-0.6-0.1-0.8-0.2s-0.5-0.4-0.6-0.6" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="link-external">
				<path d="M14.9,10.2v5c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5H4.1c-0.4,0-0.9-0.2-1.2-0.5 c-0.3-0.3-0.5-0.7-0.5-1.2V6.1c0-0.4,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5h5" />
				<path d="M12.4,1.9h5v5" />
				<path d="M8.2,11.1l9.2-9.2" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="arrow-bottom">
				<path d="M9.9,3.6v11.7" />
				<path d="M15.8,9.4l-5.8,5.8L4.1,9.4" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="arrow-top">
				<path d="M9.9,15.2V3.6" />
				<path d="M4.1,9.4l5.8-5.8l5.8,5.8" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="arrow-right">
				<path d="M4.1,9.4h11.7" />
				<path d="M9.9,3.6l5.8,5.8l-5.8,5.8" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="arrow-left">
				<path d="M15.8,9.4H4.1" />
				<path d="M9.9,15.2L4.1,9.4l5.8-5.8" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="star">
				<path d="M9.9,1.5l2.6,5.2l5.8,0.8l-4.2,4.1l1,5.7l-5.1-2.7l-5.2,2.7l1-5.7L1.6,7.5l5.8-0.8L9.9,1.5z" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="resize">
				<path d="M11.3,9.9h5.1c0.4,0,0.7,0.3,0.7,0.7v5.1c0,0.4-0.3,0.7-0.7,0.7h-5.1c-0.4,0-0.7-0.3-0.7-0.7v-5.1 C10.5,10.2,10.9,9.9,11.3,9.9z" />
				<path d="M4.9,16.4V6.3c0,0,0-0.8,0.5-1.4c0.5-0.6,1.5-0.6,1.5-0.6l10.1,0" />
				<polyline points="2.4,14.3 4.9,16.9 7.5,14.3 	" />
				<polyline points="15,6.8 17.4,4.3 15.1,2 	" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="label">
				<path d="M2.1,4.2C2.2,4.1,2.3,4,2.3,4h10.6l4.8,4.8c0,0,0.2,0.2,0.2,0.4c0,0.1-0.1,0.4-0.1,0.4l-4.8,5.2H2.8 c0,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.7-0.2-0.7V4.4C1.9,4.4,2,4.3,2.1,4.2z" />
				<circle cx="12.6" cy="9.4" r="0.3" />
			</symbol>
			<symbol viewBox="0 0 19.9 18.7" id="loading">
				<circle style={{ opacity: 0.3 }} strokeWidth={2} className="st6" cx="10" cy="9.4" r="5.7" />
				<path
					strokeWidth={2}
					className="st7"
					d="M10,3.7c0.9,0,1.9,0.2,2.7,0.7c0.8,0.5,1.5,1.1,2.1,1.9c0.5,0.8,0.8,1.7,0.9,2.7c0.1,0.9-0.1,1.9-0.5,2.8 c-0.4,0.9-1,1.6-1.8,2.2c-0.8,0.6-1.6,0.9-2.6,1.1c-0.9,0.1-1.9,0-2.8-0.3c-0.9-0.3-1.7-0.9-2.3-1.6"
				/>
			</symbol>
			<symbol id="tag" viewBox="0 0 12 12">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2 2.25a1.5 1.5 0 00-1.5 1.5v4.5A1.5 1.5 0 002 9.75h5.336a1.5 1.5 0 001.06-.44l2.25-2.25a1.5 1.5 0 000-2.12l-2.25-2.25a1.5 1.5 0 00-1.06-.44H2zm-.5 1.5a.5.5 0 01.5-.5h5.336a.5.5 0 01.353.146l2.25 2.25a.5.5 0 010 .708l-2.25 2.25a.5.5 0 01-.353.146H2a.5.5 0 01-.5-.5v-4.5zm6.536 2.812A.75.75 0 006.976 5.5l-.004.003a.75.75 0 001.06 1.061l.004-.003z"
				/>
			</symbol>
			<symbol id="trash" viewBox="0 0 12 12">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.646 1.646A.5.5 0 015 1.5h2a.5.5 0 01.5.5v.5h-3V2a.5.5 0 01.146-.354zM3.5 2.5V2A1.5 1.5 0 015 .5h2A1.5 1.5 0 018.5 2v.5h2a.5.5 0 010 1H10V10a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 012 10V3.5h-.5a.5.5 0 010-1h2zM3 10V3.5h6V10a.5.5 0 01-.5.5h-5A.5.5 0 013 10z"
				/>
			</symbol>
			<symbol id="chevron-left" viewBox="0 0 16 16">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.354 3.646a.5.5 0 010 .708L6.707 8l3.647 3.646a.5.5 0 01-.708.708l-4-4a.5.5 0 010-.708l4-4a.5.5 0 01.708 0z"
				/>
			</symbol>
			<symbol id="chevron-right" viewBox="0 0 12 12">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.146 2.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 11-.708-.708L6.793 6 4.146 3.354a.5.5 0 010-.708z"
				/>
			</symbol>
			<symbol id="chevron-up" viewBox="0 0 12 12">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.646 4.146a.5.5 0 01.708 0l3 3a.5.5 0 11-.708.708L6 5.207 3.354 7.854a.5.5 0 11-.708-.708l3-3z"
				/>
			</symbol>
			<symbol id="download" viewBox="0 0 12 12">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.5 1.444a.5.5 0 00-1 0v4.849L3.854 4.646a.5.5 0 10-.708.708l2.5 2.5a.5.5 0 00.708 0l2.5-2.5a.5.5 0 10-.708-.708L6.5 6.293V1.444zM1.5 7a.5.5 0 01.5.5v2a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2A1.5 1.5 0 019.5 11h-7A1.5 1.5 0 011 9.5v-2a.5.5 0 01.5-.5z"
				/>
			</symbol>
			<symbol id="rotate" viewBox="0 0 16 16">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.592 3.11L12.238.755l-.707.707 1.147 1.146H5.885a2.5 2.5 0 00-2.5 2.5v6.793l-1.147-1.146-.707.707 2.354 2.354 2.353-2.354-.707-.707-1.146 1.146V5.11a1.5 1.5 0 011.5-1.5h6.793L11.53 4.756l.707.707 2.354-2.354zM9.259 8.005h3.052c.262 0 .474.212.474.474v3.052a.474.474 0 01-.474.474H9.26a.474.474 0 01-.474-.474V8.48c0-.262.212-.474.474-.474zm-1.474.474c0-.814.66-1.474 1.474-1.474h3.052c.814 0 1.474.66 1.474 1.474v3.052c0 .814-.66 1.474-1.474 1.474H9.26c-.814 0-1.474-.66-1.474-1.474V8.48z"
				/>
			</symbol>
			<symbol id="up-n-down" viewBox="0 0 16 16">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.958 2.669a.498.498 0 01.73-.022l2.332 2.333a.5.5 0 11-.707.707l-1.481-1.481V13a.5.5 0 11-1 0V4.208L9.354 5.687a.5.5 0 11-.708-.707l2.312-2.311zM4.668 2.5a.5.5 0 01.5.5v8.79l1.477-1.477a.5.5 0 11.707.707l-2.298 2.298a.499.499 0 01-.742.035L1.978 11.02a.5.5 0 01.708-.707l1.482 1.482V3a.5.5 0 01.5-.5z"
				/>
			</symbol>
			<symbol id="check" viewBox="0 0 12 12">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.354 2.646a.5.5 0 010 .708l-5.5 5.5a.5.5 0 01-.708 0l-2.5-2.5a.5.5 0 11.708-.708L4.5 7.793l5.146-5.147a.5.5 0 01.708 0z"
				/>
			</symbol>
			<symbol id="linked-task" viewBox="0 0 16 16">
				<path
					d="M9.99935 4.6665H11.9994C12.4371 4.6665 12.8705 4.75272 13.275 4.92024C13.6794 5.08775 14.0468 5.33329 14.3564 5.64281C14.6659 5.95234 14.9114 6.31981 15.0789 6.72423C15.2465 7.12864 15.3327 7.5621 15.3327 7.99984C15.3327 8.43758 15.2465 8.87103 15.0789 9.27545C14.9114 9.67987 14.6659 10.0473 14.3564 10.3569C14.0468 10.6664 13.6794 10.9119 13.275 11.0794C12.8705 11.247 12.4371 11.3332 11.9994 11.3332H9.99935M5.99935 11.3332H3.99935C3.56161 11.3332 3.12816 11.247 2.72374 11.0794C2.31932 10.9119 1.95186 10.6664 1.64233 10.3569C1.01721 9.73174 0.666016 8.88389 0.666016 7.99984C0.666016 7.11578 1.01721 6.26794 1.64233 5.64281C2.26745 5.01769 3.11529 4.6665 3.99935 4.6665H5.99935"
					stroke="#489654"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.33398 8H10.6673"
					stroke="#489654"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</symbol>
		</svg>
	);
};
