import classnames from 'classnames';
import React, { FC, useState } from 'react';

import { FileDto } from '@shared/api';
import { useDownloadFile } from '@shared/lib/hooks';
import { limitStringLength } from '@shared/lib/utils';
import { Icon } from '@shared/ui';

import styles from './styles.module.scss';

/* *
 * @asCard: если true, отображается в виде карточки
 */
export interface AttachmentProps extends FileDto {
	size?: string;
	asCard?: boolean;
	className?: string;
}

export const Attachment: FC<AttachmentProps> = React.memo(
	({ url, size, name, asCard, id, className }) => {
		const [downloading, setDownloading] = useState(false);
		const downloadFile = useDownloadFile(setDownloading);

		const download = () => {
			if (url) {
				window.open(url, '_blank');
				return;
			}

			downloadFile(id, name);
		};

		return (
			<button
				onClick={download}
				className={classnames(styles.file, asCard && styles.file_card, className)}>
				<div className={styles.icon}>
					{downloading ? <Icon id="loading" className="svg-spin" /> : <Icon id="file" />}
				</div>
				<div className={styles.content}>
					<div className={styles.content__name}>
						{limitStringLength(name || url?.replace(/^((ftp|http|https):\/\/)/gm, ''))}
					</div>
					{size && <div className={styles.content__size}>{size}</div>}
					{url && <div className={styles.content__size}>{limitStringLength(url)}</div>}
				</div>
			</button>
		);
	}
);
