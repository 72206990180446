import { fetcher } from '@shared/api/config';
import { BoardPreviewDto, SubtaskDto } from '@shared/api/model/models';

export type CreateSubtaskRequest = {
	taskId: SubtaskDto['id'];
	payload: Partial<SubtaskDto>;
};

const createSubtask = ({ taskId, payload }: CreateSubtaskRequest) =>
	fetcher<SubtaskDto>({
		url: `/subtasks?task-id=${taskId}`,
		method: 'POST',
		body: payload,
	});

export type UpdateSubtaskRequest = SubtaskDto;

const putSubtask = (payload: UpdateSubtaskRequest) =>
	fetcher<SubtaskDto>({
		url: `/subtasks`,
		method: 'PUT',
		body: payload,
	});

export type DeleteSubtaskRequest = SubtaskDto;

const deleteSubtask = ({ id }: DeleteSubtaskRequest) =>
	fetcher({
		url: `/subtasks/${id}`,
		method: 'DELETE',
	});

export type DetachLinkedTaskRequest = {
	id: SubtaskDto['id'];
};

const detachLinkedTask = ({ id }: DetachLinkedTaskRequest) =>
	fetcher({
		url: `/subtasks/${id}/detach`,
		method: 'POST',
	});

export type ToggleSubtaskCompletedRequest = Pick<SubtaskDto, 'id' | 'title'>;

const toggleSubtaskCompleted = ({ id }: ToggleSubtaskCompletedRequest) =>
	fetcher<BoardPreviewDto>({
		url: `/subtasks/${id}/completed`,
		method: 'PUT',
	});

export const subtasks = {
	createSubtask,
	putSubtask,
	deleteSubtask,
	detachLinkedTask,
	toggleSubtaskCompleted,
};
