import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useRef, useState, useCallback } from 'react';

import { TaskDto } from '@shared/api';
import { dateToISO } from '@shared/lib/utils';
import { Button, DropdownPlacement, DropdownControlled, Icon, Tag } from '@shared/ui';

import { DatesFactoryTypes, RemoveDropdown } from '@entities/task';

import { DatePickerBoard } from '@features/datepicker-board';

import { getDateForTag, useShouldUpdate } from '@widgets/task-modal/lib';

import { useDatesUpdateMessage } from './lib';
import styles from './styles.module.scss';

type TaskDateActionType = 'edit' | 'remove' | 'none';

interface TaskDatesProps extends Pick<TaskDto, 'startDate' | 'endDate' | 'deadlineReminderDate'> {
	model: DatesFactoryTypes;
	placement: DropdownPlacement;
	dropdownId?: string;
	portal?: boolean;
	loading?: boolean;
	maxWidthBtn?: boolean;
	editable?: boolean;
	onUpdate?: (message?: string) => void;
}

export const TaskDates: FC<TaskDatesProps> = ({
	model,
	dropdownId,
	placement,
	portal = true,
	loading,
	maxWidthBtn,
	editable,
	onUpdate,
}) => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [taskDateAction, setTaskDateAction] = useState<TaskDateActionType>('none');

	const handleClick = () => {
		setMenuIsOpen((prev) => !prev);
		setTaskDateAction('edit');
	};

	const handleChange = useCallback(
		(startDate: Date | null, endDate: Date | null, reminderDate: Date | null) => {
			model.setLocalDates({
				startDate: dateToISO(startDate) ?? undefined,
				endDate: dateToISO(endDate) ?? undefined,
				deadlineReminderDate: dateToISO(reminderDate) ?? undefined,
			});
		},
		[model]
	);

	const datesStore = useStore(model.$dates);

	const end = datesStore?.endDate;
	const start = datesStore?.startDate;

	const mount = useRef(false);
	const getMessage = useDatesUpdateMessage(model, menuIsOpen);

	const setShouldUpdate = useShouldUpdate(
		{
			editable,
			onUpdate: () => {
				const message = getMessage();
				onUpdate && onUpdate(message);
			},
		},
		datesStore
	);

	const closeDropdown = () => {
		setMenuIsOpen(false);
	};

	const updateTaskDates = () => {
		model.setDatesFromLocal();
		setShouldUpdate(true);
		closeDropdown();
	};

	const openRemoveTaskDatesDropdown = () => {
		setMenuIsOpen(true);
		setTaskDateAction('remove');
	};

	const removeTaskDates = () => {
		model.setLocalDates({
			startDate: undefined,
			endDate: undefined,
			deadlineReminderDate: undefined,
		});
		model.setDatesFromLocal();
		setShouldUpdate(true);
		closeDropdown();
	};

	useEffect(() => {
		if (!menuIsOpen) {
			if (!mount.current) {
				mount.current = true;
				return;
			}
			setTaskDateAction('none');
		}
	}, [menuIsOpen]);

	return (
		<DropdownControlled
			opened={menuIsOpen}
			setOpened={setMenuIsOpen}
			dropdownClassName={styles.drop}
			contentClassName={classNames(
				styles.dropBox,
				taskDateAction === 'remove' && styles.dropBoxRemoveDate
			)}
			initialFocus={false}
			portal={portal}
			portalId={dropdownId}
			placement={placement}
			targetButton={
				<>
					{end && (
						<Tag
							className={classNames(
								editable && styles.editableDateTag,
								maxWidthBtn && styles.maxWidthBtn
							)}
							onClick={editable ? handleClick : undefined}
							onCancel={editable ? openRemoveTaskDatesDropdown : undefined}
							disabled={!editable}
							editable={editable}>
							<Icon id="clock" />
							{start && start !== end && (
								<>
									{getDateForTag(start)}
									<Icon id="arrow-right" />
								</>
							)}
							{getDateForTag(end)}
						</Tag>
					)}
					{!end && (
						<Button
							size="sm"
							design="filled"
							color="secondary"
							iconRight={<Icon id="calendar" />}
							disabled={!editable}
							onClick={editable ? handleClick : undefined}>
							Без даты
						</Button>
					)}
				</>
			}
			content={
				<>
					{!loading && editable && (
						<>
							{taskDateAction === 'edit' && (
								<DatePickerBoard
									initialEndDate={datesStore?.endDate}
									initialStartDate={datesStore?.startDate}
									initialReminderDate={datesStore?.deadlineReminderDate}
									onChange={handleChange}
									onDiscard={closeDropdown}
									onAccept={updateTaskDates}
								/>
							)}

							{taskDateAction === 'remove' && (
								<RemoveDropdown
									target="date"
									onAccept={removeTaskDates}
									onDiscard={closeDropdown}
									isPending={false}
								/>
							)}
						</>
					)}
				</>
			}
		/>
	);
};
