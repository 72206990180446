import React, { FC } from 'react';

import { FileDto, TaskEventDto, TaskEventDtoTypeEnum, UserDto } from '@shared/api';
import { getTagsChangedText } from '@shared/lib/utils';

import { TaskCommentType } from '../model';

import { Comment } from './comment';
import { SystemEvent } from './system-event';

export type TaskEventFileManager = FC<{
	onCreateFile: React.ChangeEventHandler;
	onFileUploaded: (file: FileDto | FileDto[]) => void;
}>;

export interface TaskEventProps extends TaskEventDto {
	onRemove?: () => void;
	onSave?: (comment: TaskCommentType) => void;
	viewer?: Nullable<UserDto>;
	className?: string;
	fileManagerAttach?: TaskEventFileManager;
	editable?: boolean;
}

export const TaskEvent: FC<TaskEventProps> = React.memo(
	({ viewer, type, tagsAddedCount, tagsRemovedCount, ...props }) => {
		switch (true) {
			case type?.includes('COMMENT'):
				return <Comment viewer={viewer} {...props} />;
			case type?.includes('SYSTEM'): {
				const text =
					type === TaskEventDtoTypeEnum.SYSTEM_TAGS_CHANGED
						? getTagsChangedText(props.text, tagsAddedCount, tagsRemovedCount)
						: props.text;

				return <SystemEvent {...props} text={text} />;
			}
			default:
				return null;
		}
	}
);
