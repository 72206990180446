import { emptyPromise, fetcher, nullablePromise } from '@shared/api/config';
import {
	TaskPreviewDto,
	TaskDto,
	TaskPreviewForStatusDto,
	TaskPositionDto,
	TaskStatusDto,
	BoardViewDtoTypeEnum,
	BoardViewDtoRowTypeEnum,
} from '@shared/api/model/models';

const getTasksStatuses = () =>
	fetcher<TaskStatusDto[]>({
		url: '/task-statuses',
		method: 'GET',
	});

export type FilterType = 'ALL' | 'NO_BOARD';

export interface GetTasksList {
	url?: string;
	boardId?: string;
	archived?: boolean;
	name?: string;
	view?: Nullable<keyof typeof BoardViewDtoTypeEnum>;
	viewRowType?: keyof typeof BoardViewDtoRowTypeEnum;
	filterType?: FilterType;
}

const getTasksList = ({
	url = '/tasks',
	boardId,
	archived = false,
	name,
	filterType,
}: GetTasksList) =>
	boardId || url
		? fetcher<TaskPreviewDto[] | TaskPreviewForStatusDto[]>({
				url,
				method: 'GET',
				body: {
					name,
					archived,
					'board-id': boardId,
					'filter-type': filterType,
				},
		  })
		: emptyPromise<TaskPreviewDto>();

export interface CreateTaskPayload {
	data: Partial<TaskDto> & {
		title: string;
	};
	boardId?: string;
	archived?: boolean;
}

const createTask = ({ data, boardId }: CreateTaskPayload) =>
	fetcher<TaskDto>({
		url: `/tasks${boardId ? `?board-id=${boardId}` : ``}`,
		method: 'POST',
		body: data,
	});

export interface UpdateTaskStatusPayload {
	taskId?: string;
	data: TaskPositionDto;
}

const updateTaskStatus = ({ taskId, data }: UpdateTaskStatusPayload) =>
	fetcher<any>({
		url: `/tasks/${taskId}/status`,
		method: 'PUT',
		body: data,
	});

export interface GetTaskByIdRequest {
	id?: string | false;
}

const getTaskById = ({ id }: GetTaskByIdRequest) =>
	id
		? fetcher<TaskDto>({
				url: `/tasks/${id}`,
		  })
		: nullablePromise();

export interface UpdateTaskPayload extends Partial<TaskDto> {}

const updateTask = (data: UpdateTaskPayload) =>
	fetcher<TaskDto>({
		url: `/tasks`,
		method: 'PUT',
		body: data,
	});

export interface ToggleTaskSubscribeToChangesRequest {
	taskId: string;
}

const toggleTaskSubscribeToChanges = ({ taskId }: ToggleTaskSubscribeToChangesRequest) =>
	fetcher({
		url: `/tasks/${taskId}/subscribe-to-changes`,
		method: 'PUT',
	});

export interface ConvertToTaskPayload {
	data: Partial<TaskDto> & {
		title: string;
	};
	subtaskId: string;
}

const convertToTask = ({ data, subtaskId }: ConvertToTaskPayload) =>
	fetcher<TaskDto>({
		url: `/tasks/convert?subtask-id=${subtaskId}`,
		method: 'POST',
		body: data,
	});

export interface ToggleArchiveTaskPayload {
	taskId: string;
}

const toggleArchiveTask = ({ taskId }: ToggleArchiveTaskPayload) =>
	fetcher<TaskDto>({
		url: `/tasks/archived/${taskId}`,
		method: 'PUT',
	});

export interface RemoveTaskPayload {
	taskId: string;
}

const removeTask = ({ taskId }: RemoveTaskPayload) =>
	fetcher<TaskDto>({
		url: `/tasks/${taskId}`,
		method: 'DELETE',
	});

export interface ReassignTaskPayload {
	taskId: string;
	targetUserId: string;
}

const reassignTask = ({ taskId, targetUserId }: ReassignTaskPayload) =>
	fetcher<TaskDto>({
		url: `/tasks/${taskId}/reassign-request`,
		method: 'POST',
		body: {
			targetUserId,
		},
	});

export const tasksRequests = {
	getTasksStatuses,
	getTasksList,
	createTask,
	updateTaskStatus,
	getTaskById,
	updateTask,
	toggleTaskSubscribeToChanges,
	convertToTask,
	toggleArchiveTask,
	removeTask,
	reassignTask,
};
