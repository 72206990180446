import classnames from 'classnames';
import React, { HTMLAttributes } from 'react';

import { Icon } from '@shared/ui';

import styles from './styles.module.scss';

export interface RoundColorProps extends HTMLAttributes<HTMLInputElement> {
	color?: string;
	name?: string;
	value?: string;
}

export const RoundColor = React.forwardRef<HTMLInputElement, RoundColorProps>(
	({ color, ...props }, ref) => {
		return (
			<label className={classnames(styles.control)} style={{ color }}>
				<input ref={ref} type="radio" className="visually-hidden" {...props} />
				<span className={styles.frame} />
				<span className={styles.checkbox}>
					<Icon id="done" />
				</span>
			</label>
		);
	}
);
