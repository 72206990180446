import { createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';

import {
	BoardViewDto,
	BoardViewDtoColumnTypeEnum,
	BoardViewDtoRowTypeEnum,
	BoardViewDtoTypeEnum,
	TagDto,
} from '@shared/api';

interface BoardViewColumnType {
	id: TagDto['id'];
	expanded: boolean;
}

const setBoardView = createEvent<Nullable<BoardViewDto>>();
const setCurrentBoardView = createEvent<Nullable<BoardViewDto>>();
const setBoardViewColumns = createEvent<BoardViewColumnType[]>();
const setStoreToCurrent = createEvent();
const setCurrentToStore = createEvent();
const resetAllStores = createEvent();

const $store = createStore<Nullable<BoardViewDto>>(null);
const $current = createStore<Nullable<BoardViewDto>>(null);
const $changed = createStore(false);

const $columns = createStore<BoardViewColumnType[]>([]);

sample({
	clock: setBoardView,
	fn: (view) =>
		view
			? {
					...view,
					rowType: (view?.rowType || null) as BoardViewDtoRowTypeEnum,
					columnType: (view?.columnType || null) as BoardViewDtoColumnTypeEnum,
			  }
			: null,
	target: $store,
});

sample({
	clock: setCurrentBoardView,
	target: $current,
});

sample({
	clock: setCurrentBoardView,
	target: $store,
});

sample({
	clock: [setBoardView, setCurrentBoardView],
	source: {
		base: $current,
		local: $store,
	},
	fn: ({ base, local }) => {
		if (!base || !local) return false;

		const compareType = base.type !== local.type;
		const compareRowType = base.rowType !== local.rowType;
		const compareColType = base.columnType !== local.columnType;

		return local.type === BoardViewDtoTypeEnum.CLASSIC
			? compareType
			: compareType || compareRowType || compareColType;
	},
	target: $changed,
});

sample({
	clock: setStoreToCurrent,
	source: $store,
	target: [$current, setCurrentBoardView],
});

sample({
	clock: setCurrentToStore,
	source: $current,
	target: [$store, setBoardView],
});

/**
 * Reset all stores
 */
reset({
	clock: resetAllStores,
	target: [$store, $current, $changed, $columns],
});

export const model = {
	setBoardView,
	setCurrentBoardView,
	setBoardViewColumns,
	setStoreToCurrent,
	setCurrentToStore,
	resetAllStores,
	$store,
	$changed,
	$columns,
};
