import { useStore } from 'effector-react';
import React, { useState, FormEvent, useMemo } from 'react';

import { BoardParticipantDto } from '@shared/api/model/boardParticipantDto';
import { NOT_FOUND_MESSAGE } from '@shared/lib/constants';
import { useDebounce } from '@shared/lib/hooks';
import { NotFoundMessage } from '@shared/ui';

import { boardRolesModel } from '@entities/board-roles';
import { useCurrentUser } from '@entities/current-user';
import { SearchUsersWrapper } from '@entities/search-users';

import { ParticipantsSearchListItem } from '../participants-search-list-item';

export interface ParticipantsSearchListProps {
	title: string;
	participants: Partial<BoardParticipantDto>[];
	setRole: (role: BoardParticipantDto, participantId?: string) => void;
	remove: (userId: string, participantId?: string) => void;
	currentUserRole?: string;
	mode?: 'create' | 'edit';
	isDisabled?: boolean;
	disableRolesChanging?: boolean;
}

export const ParticipantsSearchList = ({
	title,
	participants,
	setRole,
	remove,
	currentUserRole,
	mode,
	isDisabled,
	disableRolesChanging,
}: ParticipantsSearchListProps) => {
	const boardRoles = useStore(boardRolesModel.$boardRoles);
	const { currentUser } = useCurrentUser();
	const [search, setSearch] = useState('');
	const debounced = useDebounce(search);

	const handleChangeSearch = (event: FormEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		setSearch(target.value);
	};

	const searchParticipants = useMemo(() => {
		if (debounced.length > 2) {
			return participants.filter((participant) =>
				participant.user?.fullName?.toLowerCase().includes(debounced.toLowerCase())
			);
		}

		return participants;
	}, [debounced, participants]);

	return (
		<>
			<SearchUsersWrapper title={title} onSearch={handleChangeSearch}>
				{searchParticipants.length ? (
					searchParticipants.map((participant) => (
						<ParticipantsSearchListItem
							participant={participant}
							boardRoles={boardRoles}
							setRole={(role) => setRole(role, participant.id)}
							remove={(userId) => remove(userId, participant.id)}
							key={participant.user?.id}
							currentUserRole={currentUserRole}
							mode={mode}
							isDisabled={isDisabled || currentUser?.id === participant.user?.id}
							disableRolesChanging={disableRolesChanging}
						/>
					))
				) : (
					<NotFoundMessage>{NOT_FOUND_MESSAGE.employees}</NotFoundMessage>
				)}
			</SearchUsersWrapper>
			<div id="participants-search-list" />
		</>
	);
};
