/**
 * rpharm-todo
 * rpharm-todo documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface FileDto {
	id?: string;
	name?: string;
	url?: string;
	size?: string;
	type?: FileDtoTypeEnum;
}
export enum FileDtoTypeEnum {
	FILE = 'FILE',
	LINK = 'LINK',
}
