import classNames from 'classnames';
import { useStore } from 'effector-react';
import React from 'react';

import { BoardRoleDto, BoardRoleDtoNameEnum, UserDto } from '@shared/api';
import { Icon, Button, Typo, UserInfo } from '@shared/ui';

import { boardRolesModel } from '@entities/board-roles';

import { participantsModel } from '@widgets/board-modal/model';

import { ParticipantsListItemTools } from '../participants-list-item-tools';

import styles from './styles.module.scss';

interface SearchListItemProps {
	participant: UserDto;
	id?: string;
}

export const ParticipantsListItem = React.memo(({ participant, id }: SearchListItemProps) => {
	const participants = useStore(participantsModel.$participants);
	const boardRoles = useStore(boardRolesModel.$boardRoles);

	const viewer = boardRoles.find(
		(role) => role.name === BoardRoleDtoNameEnum.VIEWER
	) as BoardRoleDto;

	const isParticipantAddedToBoard = participants.find((item) => item.user.id === participant.id);

	const sendFocusTo = (selector: string) => {
		setTimeout(() => {
			const row = id ? document.getElementById(id) : null;
			const btn = row?.querySelector<HTMLButtonElement>(selector);
			btn?.focus();
		}, 10);
	};

	return (
		<div id={id} className={styles.listItem}>
			<UserInfo user={participant} showJobTitle>
				{isParticipantAddedToBoard?.role.name && (
					<Typo className={styles.roleName} design="text-13-regular">
						{isParticipantAddedToBoard.role.description}
					</Typo>
				)}
			</UserInfo>
			{!isParticipantAddedToBoard ? (
				<Button
					onClick={() => {
						participantsModel.addParticipant({ user: participant, role: viewer });
						sendFocusTo('.tools-btn-focus-target');
					}}
					className={classNames(styles.button, 'add-btn-focus-target')}
					size="sm"
					design="transparent"
					iconRight={<Icon id="plus" />}>
					Добавить
				</Button>
			) : (
				<ParticipantsListItemTools
					participant={participant}
					backFocus={(selector) => sendFocusTo(selector)}
				/>
			)}
		</div>
	);
});
