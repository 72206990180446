import React, { FC } from 'react';

import { Typo } from '@shared/ui';

import styles from './styles.module.scss';

interface FormErrorProps {
	error?: string;
}

export const FormError: FC<FormErrorProps> = ({ error }) => {
	return error ? (
		<Typo className={styles.error} design="text-11-regular">
			{error}
		</Typo>
	) : null;
};
