import React, { FC, memo, useMemo, useState } from 'react';

import { FileDto, FileDtoTypeEnum } from '@shared/api';
import { useDownloadFile } from '@shared/lib/hooks';
import { Button, Icon, Typo } from '@shared/ui';

import styles from './styles.module.scss';

interface FileManagerListItemProps {
	file: FileDto;
	onRemove?: () => void;
	editable?: boolean;
}

export const FileManagerListItem: FC<FileManagerListItemProps> = memo(
	({ file, onRemove, editable }) => {
		const name = useMemo(() => {
			switch (file.type) {
				case FileDtoTypeEnum.LINK:
					return (
						<>
							<span className={styles.name}>
								{file.name || file.url?.replace(/^((ftp|http|https):\/\/)/gm, '')}
							</span>
							<span className={styles.suffix}>(ссылка)</span>
						</>
					);
				case FileDtoTypeEnum.FILE:
				default:
					return <span className={styles.name}>{file.name}</span>;
			}
		}, [file.type, file.name, file.url]);

		const [downloading, setDownloading] = useState(false);
		const handleDownload = useDownloadFile(setDownloading);

		return (
			<Typo className={styles.item} design="text-13-medium">
				<div className={styles.text}>{name}</div>
				<div className={styles.tools}>
					{file.type === FileDtoTypeEnum.LINK && (
						<Button
							size="sm"
							design="transparent"
							color="secondary"
							onlyIcon={<Icon id="arrow-right" />}
							className={styles.toolsBtn}
							href={file.url}
							target="_blank"
							aria-label="Перейти к файлу"
						/>
					)}
					{file.type === FileDtoTypeEnum.FILE && (
						<Button
							size="sm"
							design="transparent"
							color="secondary"
							onlyIcon={<Icon id="download" className="svg-path" />}
							className={styles.toolsBtn}
							onClick={() => handleDownload(file.id, file.name)}
							isLoading={downloading}
							aria-label="Скачать файл"
						/>
					)}
					{editable && (
						<Button
							size="sm"
							design="transparent"
							color="secondary"
							onlyIcon={<Icon id="trash" className="svg-path" />}
							className={styles.toolsBtn}
							onClick={onRemove}
							aria-label="Удалить файл"
						/>
					)}
				</div>
			</Typo>
		);
	}
);
