import { useCallback, useEffect, useState } from 'react';

interface UseShouldUpdateArgs {
	editable?: boolean;
	onUpdate?: () => void;
}

export const useShouldUpdate = ({ editable, onUpdate }: UseShouldUpdateArgs, deps: any) => {
	const saveChanges = useCallback(() => {
		if (editable && onUpdate) onUpdate();
	}, [editable, onUpdate]);

	const [shouldUpdate, setShouldUpdate] = useState(false);

	useEffect(() => {
		if (shouldUpdate) {
			saveChanges();
			setShouldUpdate(false);
		}
	}, [saveChanges, shouldUpdate, deps]);

	return setShouldUpdate;
};
