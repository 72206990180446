import React from 'react';

import { Button, Icon } from '@shared/ui';

import styles from '../styles.module.scss';

export interface AttachmentEditWrapperProps {
	children: React.ReactNode;
	onRemove: () => void;
}
export const AttachmentEditWrapper = ({ children, onRemove }: AttachmentEditWrapperProps) => {
	return (
		<div className={styles.attachmentEditWrap}>
			{children}
			<Button onClick={onRemove} size={'sm'} color={'secondary'} onlyIcon={<Icon id={'close'} />} />
		</div>
	);
};
