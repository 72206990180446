import classnames from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Icon, Tag, tooltipEventHandlersFactory, TooltipsEventHandlers } from '@shared/ui';

import { TasksTableRowEntity } from '@entities/task/lib';

import { TaskTableColumn } from '../../ui';
import styles from '../styles.module.scss';

interface TaskTableRowProps extends TasksTableRowEntity {
	onCardClick: (id: string) => void;
}

export const TaskTableRow: FC<TaskTableRowProps> = React.memo(({ tag, columns, onCardClick }) => {
	const [expanded, setExpanded] = useState(true);
	const count = useMemo(() => columns.map(({ items }) => items).flat().length, [columns]);

	const [hasEllipsis, setHasEllipsis] = useState(false);

	const tooltipEventHandlers: TooltipsEventHandlers = useMemo(
		() => (hasEllipsis ? tooltipEventHandlersFactory(tag.name) : {}),
		[hasEllipsis, tag.name]
	);

	if (count === 0) return null;

	return (
		<div className={styles.taskTableRow} role="row">
			<div className={styles.taskTableRowHeader} role="rowheader">
				<Tag
					{...tag}
					{...tooltipEventHandlers}
					className={classnames(styles.taskTableTag, { [styles.expanded]: expanded })}
					onClick={() => setExpanded((prev) => !prev)}>
					<span className={styles.tagText}>
						<span
							className={styles.tagName}
							ref={(node) => {
								if (node) setHasEllipsis(node.offsetWidth < node.scrollWidth);
							}}>
							{tag.name}
						</span>
					</span>
					<Icon id="chevron-up" className="svg-path" />
				</Tag>
			</div>
			<AnimateHeight
				className={styles.taskTableRowSpoiler}
				contentClassName={styles.taskTableRowGrid}
				height={expanded ? 'auto' : 0}>
				{columns?.map((column) => (
					<TaskTableColumn
						key={`${tag.id}-${column.status?.id}`}
						tagId={tag.id}
						statusId={column.status?.id}
						tasks={column.items}
						onCardClick={onCardClick}
					/>
				))}
			</AnimateHeight>
		</div>
	);
});
