import { fetcher } from '@shared/api/config';
import { TaskEventDto, PageableResponseNotificationDto } from '@shared/api/model/models';

export interface GetNotificationsRequest {
	page: number;
	size?: number;
}

const getNotifications = ({ page = 0, size = 10 }: GetNotificationsRequest) =>
	fetcher<PageableResponseNotificationDto>({
		url: `/notifications/my`,
		method: 'GET',
		body: {
			page,
			size,
		},
	});

const getNotificationsHasUnread = () =>
	fetcher<boolean>({
		url: `/notifications/has-unread`,
		method: 'GET',
	});

const setNotificationsAsRead = () =>
	fetcher<TaskEventDto>({
		url: `/notifications/read`,
		method: 'PUT',
	});

const setNotificationsAsHidden = () =>
	fetcher({
		url: `/notifications/my/all`,
		method: 'DELETE',
	});

export const notificationsRequests = {
	getNotifications,
	getNotificationsHasUnread,
	setNotificationsAsRead,
	setNotificationsAsHidden,
};
