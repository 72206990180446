import { format, isValid } from 'date-fns';

import { getNumEndings } from '@shared/lib/utils';

const NOW = 'Только что';
const MINUTES: [string, string, string] = ['минуту', 'минуты', 'минут'];
const MINUTES_SUFFIX = 'назад';

export const getDateText = (isoDateString?: string) => {
	if (!isoDateString) return undefined;

	const date = new Date(isoDateString);

	if (!isValid(date)) return undefined;

	const now = new Date();

	const diff = Math.abs(date.getTime() - now.getTime());
	const diffMin = diff / 60000;

	if (diffMin <= 1) {
		return NOW;
	}
	if (diffMin > 1 && diffMin <= 60) {
		return `${Math.round(diffMin)} ${getNumEndings(
			Math.round(diffMin),
			MINUTES
		)} ${MINUTES_SUFFIX}`;
	}

	return format(date, 'dd.MM.yyyy HH:mm');
};
