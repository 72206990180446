import { useGate, useStore, useList } from 'effector-react';
import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NOTIFICATIONS_EMPTY } from '@shared/lib/constants';
import { asideModel } from '@shared/model';
import { Button, ConfirmModalHead, EmptyBanner, modalsModel, Typo, BaseModal } from '@shared/ui';

import { notificationsDeleteModel, notificationsListModel } from '@entities/notifications';

import styles from './styles.module.scss';
import { NotificationWrapper } from './ui/notification-wrapper';
import { NotificationsClearAll } from './ui/notifications-clear-all';
import { NotificationsSkeleton } from './ui/notifications-skeleton';

const Component: FC = React.memo(() => {
	const navigate = useNavigate();
	const pageOffset = useStore(asideModel.$width);
	const { pathname } = useLocation();

	const [page, setPage] = useState(0);

	useGate(notificationsListModel.NotificationListGate, {
		size: 20,
		page,
	});

	const {
		data: list,
		pagination,
		complete,
		loading,
		lazyLoading,
	} = useStore(notificationsListModel.$status);
	const notEmpty = list && list.length > 0;

	const closeModal = () => {
		modalsModel.closeSubModal();
		modalsModel.closeLastModal();
		if (pathname.includes('/notifications')) {
			navigate('/my-tasks');
		}
	};

	const clearAll = () => notificationsDeleteModel.markAllAsHidden();
	const clearAllPending = useStore(notificationsDeleteModel.pending);

	const notificationList = useList(notificationsListModel.$list, (notification) => (
		<NotificationWrapper key={notification.id} notification={notification} />
	));

	return (
		<BaseModal
			design="md"
			size={590}
			left={true}
			showCloseBtn={false}
			closeModalOnEscape={true}
			closeModalOnOutsideClick={true}
			closeModalNavigate={closeModal}
			classNameBox={styles.modal}
			pageOffset={pageOffset}
			header={<ConfirmModalHead title="Уведомления" onClickClose={closeModal} />}>
			<Typo design="text-13-regular" className={styles.body}>
				{!loading && (
					<>
						{notEmpty ? (
							<>
								<div className={styles.buttonsBlock}>
									<NotificationsClearAll onAccept={clearAll} isPending={clearAllPending} />
								</div>

								{notificationList}

								{!complete && (
									<div className={styles.buttonsBlock}>
										<Button
											size="sm"
											design="transparent"
											isLoading={lazyLoading}
											onClick={() => pagination?.nextPage && setPage(pagination?.nextPage)}>
											Показать больше
										</Button>
									</div>
								)}
							</>
						) : (
							<EmptyBanner className={styles.emptyBanner}>{NOTIFICATIONS_EMPTY}</EmptyBanner>
						)}
					</>
				)}
				{loading && <NotificationsSkeleton />}
			</Typo>
			<div id="notifications-modal-portal" />
		</BaseModal>
	);
});

export const NotificationsModal = {
	name: 'NOTIFICATIONS_MODAL',
	Component,
};
