import React, { FC } from 'react';

import {
	NotificationDto,
	NotificationDtoTypeEnum,
	TaskApprovalDto,
	TaskApprovalDtoStatusEnum,
	TaskReassignRequestDtoStatusEnum,
} from '@shared/api';
import { modalsModel, viewTaskSubmodal } from '@shared/ui';

import { BoardCard, BoardCardFooter } from '@entities/boards';
import { useCurrentUser } from '@entities/current-user';
import {
	notificationApprovalModel,
	NotificationCard,
	notificationReassignModel,
} from '@entities/notifications';
import { TaskCard, taskListModel, taskModel } from '@entities/task';

interface NotificationWrapperProps {
	notification: NotificationDto;
}

export const NotificationWrapper: FC<NotificationWrapperProps> = React.memo(({ notification }) => {
	const { currentUser } = useCurrentUser();
	const boardIsDisabled =
		notification.type === NotificationDtoTypeEnum.BOARD_KICK ||
		notification.type === NotificationDtoTypeEnum.BOARD_KICK_PRIVATE;

	const isReassignRequest =
		notification?.type === NotificationDtoTypeEnum.TASK_REASSIGN_OPEN ||
		notification?.type === NotificationDtoTypeEnum.TASK_REASSIGN_ACCEPTED ||
		notification?.type === NotificationDtoTypeEnum.TASK_REASSIGN_DECLINED;

	const acceptReassign = () => {
		if (notification?.taskReassignRequest) {
			notificationReassignModel.setNotification(notification);
			notificationReassignModel
				.acceptReassignFx({
					taskReassignRequest: {
						...notification?.taskReassignRequest,
						status: TaskReassignRequestDtoStatusEnum.ACCEPTED,
					},
					taskId: notification.task?.id,
				})
				.then(() => {
					modalsModel.closeSubModal();
					taskModel.revalidateTask();
				});
		}
	};

	const declineReassign = () => {
		if (notification?.taskReassignRequest)
			notificationReassignModel.declineReassign({
				taskReassignRequest: {
					...notification?.taskReassignRequest,
					status: TaskReassignRequestDtoStatusEnum.DECLINED,
				},
				notification,
				taskId: notification.task?.id,
			});
	};

	const retryReassign = () => {
		if (notification?.taskReassignRequest)
			notificationReassignModel.retryReassign({
				taskReassignRequest: notification?.taskReassignRequest,
				notification,
			});
	};

	const approveTask = async () => {
		if (notification?.taskApproval) {
			notificationApprovalModel.setNotification(notification);
			return notificationApprovalModel
				.approveTaskFx({
					taskApproval: {
						...notification?.taskApproval,
						status: TaskApprovalDtoStatusEnum.APPROVED,
					},
					id: notification.task?.id,
					title: notification.task?.title,
				})
				.then((response) => {
					taskModel.revalidateTask();
					taskListModel.revalidateTaskList();
					return response;
				});
		}
		return null;
	};

	const disapproveTask = async (data: Partial<TaskApprovalDto>) => {
		if (notification?.taskApproval) {
			notificationApprovalModel.setNotification(notification);
			return notificationApprovalModel
				.disapproveTaskFx({
					taskApproval: {
						...notification?.taskApproval,
						...data,
						status: TaskApprovalDtoStatusEnum.DECLINED,
					},
					id: notification.task?.id,
					title: notification.task?.title,
				})
				.then((response) => {
					taskModel.revalidateTask();
					taskListModel.revalidateTaskList();
					return response;
				});
		}
		return null;
	};

	return (
		<NotificationCard
			key={notification.id}
			notification={notification}
			viewer={currentUser}
			entity={
				<>
					{notification?.task?.id && (
						<TaskCard
							{...notification.task}
							id={notification.task.id}
							onClick={() => {
								notificationReassignModel.reset();

								if (isReassignRequest) {
									notificationReassignModel.setNotification(notification);
								}

								if (notification?.task?.id) {
									viewTaskSubmodal({
										id: notification.task.id,
										title: notification.task.title || '',
										viewer: currentUser,
										mode: 'edit',
										leftOffset: 590,
									});
								}
							}}
							isMock={true}
						/>
					)}
					{!notification?.task?.id && notification?.board?.id && (
						<BoardCard
							onClick={() => modalsModel.closeLastModal()}
							isDisabled={boardIsDisabled}
							href={`/boards/${notification?.board?.id}`}
							cardFooter={
								<BoardCardFooter
									id={notification.board.id}
									starred={notification.board.starred}
									archived={notification.board.archived}
									archivedDate={notification.board.archivedDate}
									viewer={currentUser}
									isDisabled={true}
								/>
							}
							{...notification.board}
						/>
					)}
				</>
			}
			acceptReassign={acceptReassign}
			declineReassign={declineReassign}
			retryReassign={retryReassign}
			approveTask={approveTask}
			disapproveTask={disapproveTask}
		/>
	);
});
