import classnames from 'classnames';
import React, { HTMLAttributes, PropsWithChildren, useCallback, useMemo } from 'react';

import { Colors } from '@shared/lib/constants';
import { checkSpace, kebabize } from '@shared/lib/utils';
import { Icon } from '@shared/ui';

import styles from './styles.module.scss';

export type TagColorType = keyof typeof Colors;

export interface TextLabelInterface extends HTMLAttributes<HTMLElement> {
	color?: TagColorType | string;
	onCancel?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	editable?: boolean;
	hoverable?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	maxWidth?: boolean;
	className?: string;
}

export const Tag = React.memo(
	React.forwardRef<HTMLSpanElement, PropsWithChildren<TextLabelInterface>>(
		(
			{
				color,
				onCancel,
				onClick,
				editable = false,
				hoverable,
				disabled,
				onMouseEnter,
				onMouseLeave,
				onFocus,
				onBlur,
				isLoading,
				maxWidth = false,
				className,
				children,
			},
			ref
		) => {
			const handlers = { onMouseEnter, onMouseLeave, onFocus, onBlur };
			const handleClick = useCallback(
				(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
					const target = e.target as HTMLElement;
					const isCross = target.closest(`.${styles.cross}`);

					if (isCross && editable) {
						onCancel && onCancel(e);
					} else {
						onClick && onClick(e);
					}
				},
				[editable, onCancel, onClick]
			);

			const tagColor = useMemo(() => {
				const colorClass =
					color &&
					color.startsWith('#') &&
					Object.keys(Colors).find((key) => Colors[key as keyof typeof Colors] === color);
				return colorClass ? kebabize(colorClass) : undefined;
			}, [color]);

			return (editable && !!onCancel) || !!onClick ? (
				<button
					type="button"
					disabled={disabled}
					tabIndex={hoverable ? 0 : -1}
					onClick={handleClick}
					className={classnames(
						className,
						styles.tag,
						tagColor && styles[tagColor],
						editable && !hoverable ? styles.noHoverEffect : styles.hoverEffect
					)}
					{...handlers}>
					<span className={styles.text} ref={ref}>
						{children}
					</span>
					{editable && !isLoading && (
						<span
							className={styles.cross}
							tabIndex={0}
							onKeyDown={(e) => {
								if (editable && checkSpace(e)) {
									e.preventDefault();
									onCancel && onCancel();
								}
							}}>
							<Icon id="close" />
						</span>
					)}
					{isLoading && (
						<span className={styles.loading}>
							<Icon id="loading" />
						</span>
					)}
				</button>
			) : (
				<span
					className={classnames(
						className,
						styles.tag,
						maxWidth && styles.maxWidth,
						tagColor && styles[tagColor],
						editable ? styles.hoverEffect : styles.noHoverEffect
					)}
					{...handlers}>
					<span className={styles.text} ref={ref}>
						{children}
					</span>
				</span>
			);
		}
	)
);
