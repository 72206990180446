import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import { ConfirmModalBody, ConfirmModalHead, FormElement, InputMulti, Typo } from '@shared/ui';

import styles from './styles.module.scss';

export interface NotificationApprovalMessageProps {
	onAccept?: (data: NotificationApprovalMessageData) => void;
	onDiscard?: () => void;
	isPending?: boolean;
}

export interface NotificationApprovalMessageData {
	message?: string;
}

export const NotificationApprovalMessage: FC<NotificationApprovalMessageProps> = ({
	onAccept,
	onDiscard,
	isPending,
}) => {
	const { register, handleSubmit } = useForm<NotificationApprovalMessageData>({
		defaultValues: {
			message: '',
		},
		mode: 'all',
	});

	const acceptHandler = () => {
		onAccept && handleSubmit(onAccept)();
	};
	const discardHandler = () => {
		onDiscard && onDiscard();
	};

	return (
		<div className={styles.approvalMessage}>
			<ConfirmModalHead title="Вернуть задачу в работу?" onClickClose={onDiscard} />
			<ConfirmModalBody
				onAccept={acceptHandler}
				onDiscard={discardHandler}
				acceptLabel="Вернуть в работу"
				acceptColor="primary"
				textAlign="left"
				isPending={isPending}
				content={
					<>
						<label className={styles.fieldset}>
							<Typo className={styles.legend} design="text-13-medium">
								Сообщение (необязательно)
							</Typo>
							<FormElement>
								<InputMulti
									minHeight={96}
									maxHeight="40vh"
									placeholder="Введите сообщение"
									{...register('message')}
								/>
							</FormElement>
						</label>
					</>
				}
			/>
		</div>
	);
};

export const NotificationApprovalMessageRead: FC<
	NotificationApprovalMessageProps & NotificationApprovalMessageData
> = ({ message, onDiscard }) => {
	return (
		<div className={styles.approvalMessageRead}>
			<ConfirmModalHead title="Сообщение" onClickClose={onDiscard} />
			<Typo className={styles.approvalMessageReadText} design="text-13-regular">
				{message}
			</Typo>
		</div>
	);
};
