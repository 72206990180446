import { BOARD_EMPTY } from '@shared/lib/constants';

interface GetEmptyMessageOptions {
	isArchive: boolean;
	isFilteredByView: boolean;
	isFilteredBySearch: boolean;
}

export const getEmptyMessage = ({
	isArchive,
	isFilteredByView,
	isFilteredBySearch,
}: GetEmptyMessageOptions) => {
	if (isArchive) {
		return isFilteredBySearch ? BOARD_EMPTY.archivedWithSearch : BOARD_EMPTY.archived;
	}

	if (isFilteredByView) {
		return BOARD_EMPTY.withView;
	}

	return BOARD_EMPTY.actual;
};
