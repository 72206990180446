import classNames from 'classnames';
import { useStore } from 'effector-react';
import { FocusTargetOrFalse } from 'focus-trap';
import FocusTrap from 'focus-trap-react';
import React, {
	FC,
	PropsWithChildren,
	useEffect,
	useRef,
	useState,
	ReactNode,
	UIEvent,
	useCallback,
} from 'react';

import { useEscape, useOutsideClick, useResizeObserver } from '@shared/lib/hooks';
import { Button, Icon, modalsModel } from '@shared/ui';

import styles from './styles.module.scss';

export interface BaseModalProps {
	design?: 'sm' | 'md';
	/**
	 * modal width
	 */
	size?: number;
	className?: string;
	classNameBox?: string;
	showCloseBtn?: boolean;
	/**
	 * left position for notification modal
	 */
	left?: boolean;
	pageOffset?: number;
	/**
	 * header and footer props for their fixed position
	 */
	header?: ReactNode;
	footer?: ReactNode;
	closeModalOnEscape?: boolean;
	closeModalOnOutsideClick?: boolean;
	closeModalNavigate?: () => void;
	transparentBackground?: boolean;
	isSubmodal?: boolean;
	initialFocus?: FocusTargetOrFalse;
}

export const BaseModal: FC<PropsWithChildren<BaseModalProps>> = ({
	design = 'md',
	size = 560,
	className,
	classNameBox,
	showCloseBtn = true,
	left = false,
	pageOffset = 0,
	children,
	header,
	footer,
	closeModalOnEscape,
	closeModalOnOutsideClick,
	closeModalNavigate,
	transparentBackground,
	isSubmodal,
	initialFocus,
}) => {
	const isPushModal = useStore(modalsModel.$isPush);
	const openedModals = useStore(modalsModel.$modals).length;

	const boxRef = useRef<HTMLDivElement | null>(null);
	const [leftOffset, setLeftOffset] = useState(0);
	const [headerShadow, setHeaderShadow] = useState(false);

	const closeModal = () => {
		if (isSubmodal) {
			modalsModel.closeSubModal();
			return;
		}
		closeModalNavigate && closeModalNavigate();
		modalsModel.closeLastModal();
	};

	useEffect(() => {
		if (left) {
			setLeftOffset(pageOffset + 24);
		}
	}, [left, pageOffset]);

	useEscape(true, () => closeModalOnEscape && closeModal());

	useOutsideClick<HTMLDivElement | null>(boxRef, () => closeModalOnOutsideClick && closeModal(), {
		safe: ['.modal-box'],
	});

	const wrapRef = useRef<Nullable<HTMLDivElement>>(null);
	const [alignInverse, setAlignInverse] = useState(false);

	useResizeObserver({
		ref: wrapRef,
		callback: useCallback((entries) => {
			const wrap = entries[0].target as HTMLElement;
			const box = wrap.querySelector(`.${styles.box}`);
			setAlignInverse(!!box && wrap.offsetWidth < box.scrollWidth);
		}, []),
	});

	const handleHeaderShadow = (event: UIEvent<HTMLDivElement>) => {
		const scroller = event.target as HTMLDivElement;
		setHeaderShadow(scroller.scrollTop > 50);
	};

	return (
		<div
			role="dialog"
			aria-modal="true"
			aria-label="Модальное окно"
			style={{
				left: isSubmodal ? leftOffset : undefined,
			}}
			className={classNames(className, styles.modal, {
				[styles.leftPosition]: left,
				[styles.isSubmodal]: isSubmodal,
			})}>
			{!isSubmodal && (
				<div
					className={classNames(
						styles.overlay,
						isPushModal && openedModals === 1 && styles.overlayAnimation
					)}
				/>
			)}
			<div
				style={{
					paddingLeft: !isSubmodal ? leftOffset : undefined,
				}}
				className={classNames(styles.scroller, 'scrollbar-container')}
				onScroll={(event) => handleHeaderShadow(event)}>
				<div ref={wrapRef} className={classNames(styles.wrap, { [styles.inverse]: alignInverse })}>
					<FocusTrap
						focusTrapOptions={{
							initialFocus,
							escapeDeactivates: false,
							allowOutsideClick: true,
							checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
						}}>
						<div
							style={{ width: size }}
							ref={boxRef}
							className={classNames(
								classNameBox,
								styles.box,
								isPushModal && openedModals === 1 && styles.boxAnimation,
								styles[design],
								transparentBackground && styles.transparentBackground,
								'modal-box'
							)}>
							<div className={classNames(styles.header, { [styles.headerShadow]: headerShadow })}>
								{header}
								{showCloseBtn && (
									<>
										{design === 'sm' ? (
											<Button
												className={styles.close}
												onlyIcon={<Icon id="close" />}
												design="transparent"
												onClick={closeModal}
											/>
										) : (
											<Button
												design="filled"
												color="secondary"
												className={styles.close}
												onlyIcon={<Icon id="close" />}
												onClick={closeModal}
											/>
										)}
									</>
								)}
							</div>
							{children}
							{footer}
						</div>
					</FocusTrap>
				</div>
			</div>
		</div>
	);
};
