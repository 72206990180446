import type React from 'react';

import { appTooltipsModel } from '@shared/ui';
import { AppTooltipType } from '@shared/ui/molecules/tooltips/model/model';

export const tooltipEventHandlersFactory = (
	text: string,
	options?: Omit<AppTooltipType, 'text' | 'element'>,
	showTooltipOnFocus: boolean = true
) => {
	const { margin, delayIn, align = 'start', placement } = options || {};

	return {
		onMouseEnter: (e: React.MouseEvent<HTMLElement>) => {
			appTooltipsModel.showTooltip({
				text,
				element: e.target as HTMLElement,
				margin,
				align,
				placement,
				delayIn,
			});
		},
		onFocus: showTooltipOnFocus
			? (e: React.FocusEvent<HTMLElement>) => {
					appTooltipsModel.showTooltip({
						text,
						element: e.target as HTMLElement,
						margin,
						align,
						placement,
						delayIn: 200,
					});
			  }
			: undefined,
		onMouseLeave: () => {
			appTooltipsModel.hideTooltip();
		},
		onBlur: () => {
			appTooltipsModel.hideTooltip();
		},
	};
};
