import { useStore, useGate } from 'effector-react';

import { boardRolesModel } from '../model';

export const useBoardRoles = () => {
	useGate(boardRolesModel.boardRolesLoad);

	const boardRoles = useStore(boardRolesModel.$boardRoles);

	return { boardRoles };
};
