import { useStore } from 'effector-react';
import React, { FC, useRef, useState, useEffect } from 'react';

import { UserDto } from '@shared/api';
import { useOutsideClick } from '@shared/lib/hooks';
import { checkEscape } from '@shared/lib/utils';
import { Button, Dropdown, Icon, Skeleton } from '@shared/ui';

import { useToggleArchiveTask, reassignTaskModel, RemoveDropdown } from '@entities/task';

import { DropdownTransferTask } from '../dropdown-transfer-task';
import { TaskTools } from '../task-tools';

import styles from './styles.module.scss';

interface TaskToolsMenuProps {
	taskId?: string;
	title?: string;
	viewer?: Nullable<UserDto>;
	isLoading?: boolean;
	closeModalNavigate: () => void;
}

export type TaskToolsDdType = 'main' | 'remove' | 'transfer' | 'none';

export const TaskToolsMenu: FC<TaskToolsMenuProps> = ({
	taskId,
	title,
	viewer,
	isLoading,
	closeModalNavigate,
}) => {
	const [taskToolsDd, setTaskToolsDd] = useState<TaskToolsDdType>('none');

	const reassignTaskStatus = useStore(reassignTaskModel.$status);

	const { toggleArchiveTask, loading } = useToggleArchiveTask();

	const ddBtnRef = useRef<HTMLButtonElement | null>(null);
	const ddMenuRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (reassignTaskStatus === 'done') {
			setTaskToolsDd('none');
		}
	}, [reassignTaskStatus]);

	const handleAccept = () => {
		toggleArchiveTask({ id: taskId, title: title }, () => {
			setTaskToolsDd('none');
			closeModalNavigate();
		});
	};

	useOutsideClick([ddBtnRef, ddMenuRef], () => {
		setTaskToolsDd('none');
	});

	return (
		<>
			{!isLoading && (
				<Dropdown
					placement="bottomEnd"
					isOpen={taskToolsDd !== 'none'}
					openOnButtonClick={false}
					closeOnClickOutside={false}
					portal={false}
					targetButton={
						<Button
							ref={ddBtnRef}
							design="filled"
							color="secondary"
							onlyIcon={<Icon id="kebab" />}
							onClick={() =>
								setTaskToolsDd((prev) => {
									if (prev === 'none') {
										return 'main';
									} else {
										return 'none';
									}
								})
							}
						/>
					}
					dropdownData={(props, ref) => null}
					dropdownElement={
						<div
							ref={ddMenuRef}
							onKeyDown={(e) => {
								e.stopPropagation();
								checkEscape(e) && setTaskToolsDd('none');
							}}>
							{taskToolsDd === 'main' && (
								<div className={styles.dropBox}>
									<TaskTools
										viewer={viewer}
										removeTask={() => setTaskToolsDd('remove')}
										transferTask={() => setTaskToolsDd('transfer')}
									/>
								</div>
							)}

							{taskToolsDd === 'remove' && (
								<RemoveDropdown
									target="task"
									taskTitle={title}
									onAccept={handleAccept}
									onDiscard={() => setTaskToolsDd('main')}
									isPending={loading}
								/>
							)}

							{taskToolsDd === 'transfer' && (
								<DropdownTransferTask onDiscard={() => setTaskToolsDd('main')} />
							)}
						</div>
					}
				/>
			)}
			{isLoading && <Skeleton width={32} height={32} />}
		</>
	);
};
