import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { useMemo, useState, useRef, useEffect, FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { object } from 'yup';

import { SubtaskDto, TaskDto, SubtaskDtoTypeEnum } from '@shared/api';
import { useOutsideClick } from '@shared/lib/hooks';
import { isEmptyObject } from '@shared/lib/utils';
import { setFocusToId, dateToISO, checkEscape } from '@shared/lib/utils';
import { commonValidators } from '@shared/lib/validation';
import { Button, Icon, ModalTextarea, Dropdown, TaskMode, noticesModel, Tag } from '@shared/ui';

import { subtasksModel, taskEventsListModel, taskModel } from '@entities/task';

import { DatePickerBoard } from '@features/datepicker-board';
import { updateTaskFeature } from '@features/task';

import { getDateForTag } from '@widgets/task-modal/lib';
import { TaskRow } from '@widgets/task-modal/ui/task-row';

import { AddAssigneeToSubtask } from '../add-assignee-to-subtask';
import { LinkedTask } from '../linked-task';
import { Subtask } from '../subtask';
import { SubtaskAssignees } from '../subtask-assignees';

import styles from './styles.module.scss';

interface SubtasksProps {
	mode?: TaskMode;
	editable?: boolean;
	task?: Nullable<TaskDto>;
	taskId?: string;
	onUpdate?: () => void;
}

interface CreateSubtaskFormValues {
	title: string;
}

export const Subtasks: FC<SubtasksProps> = React.memo(
	({ mode, editable, task, taskId, onUpdate }) => {
		const subtasks = useStore(subtasksModel.$subtasks);
		const createSubtaskStatus = useStore(subtasksModel.$createSubtaskStatus);
		const deleteSubtaskStatus = useStore(subtasksModel.$deleteSubtaskStatus);

		const createSubtaskAssignee = useStore(subtasksModel.$createdSubtaskAssignee);
		const createdSubtaskDate = useStore(subtasksModel.$createdSubtaskDate);

		const datepickerRef = useRef<HTMLDivElement>(null);
		const btnDatepickerRef = useRef(null);

		const [datepickerDd, setDatepickerDd] = useState(false);

		const [date, setDate] = useState<Nullable<string>>(null);
		const [showCreateSubtask, setShowCreateSubtask] = useState(false);

		const {
			control,
			handleSubmit,
			formState: { errors },
			reset,
		} = useForm<CreateSubtaskFormValues>({
			defaultValues: {
				title: '',
			},
			resolver: yupResolver(object({ title: commonValidators.limitedRequiredString(500) })),
		});

		useOutsideClick([datepickerRef, btnDatepickerRef], () => {
			setDatepickerDd(false);
		});

		useEffect(() => {
			if (createSubtaskStatus === 'done') {
				taskEventsListModel.revalidate();
			}
		}, [createSubtaskStatus]);

		useEffect(() => {
			if (deleteSubtaskStatus === 'done') {
				taskEventsListModel.revalidate();
			}
		}, [deleteSubtaskStatus]);

		useEffect(() => {
			if (!showCreateSubtask) {
				reset();
				subtasksModel.resetCreatedSubtask();
			}
		}, [reset, showCreateSubtask]);

		const addSubtask = ({ title }: CreateSubtaskFormValues) => {
			const payload = {
				title,
				completed: false,
				dueDate: createdSubtaskDate || '',
				assignee:
					createSubtaskAssignee && !isEmptyObject(createSubtaskAssignee)
						? createSubtaskAssignee
						: undefined,
			};

			/*
			 * В режиме редактирования задачи
			 * создаем подзадачу напрямую на бэкенде
			 */
			if (mode !== 'create' && editable && taskId) {
				subtasksModel
					.createSubtaskFx({
						payload,
						taskId,
					})
					.then((subtask) => {
						if (task) {
							const newSubtasks = [...(subtasks as SubtaskDto[]), subtask];

							updateTaskFeature.mutateTaskPreview({
								...task,
								subtasks: newSubtasks,
							});

							taskModel.updateTaskStore({ subtasks: newSubtasks });
						}

						setFocusToId('create-subtask-textarea', 10);
						reset();
						setDate(null);
					});
			}
			/*
			 * В режиме создания задачи
			 * собираем данные в сторе
			 */
			if (mode === 'create') {
				subtasksModel.addSubtask(payload);
				setFocusToId('create-subtask-textarea', 10);
				reset();
				setDate(null);
			}
		};

		const updateSubtask = useCallback(
			(subtask: SubtaskDto, message?: string) => {
				if (subtask.id) {
					subtasksModel.updateSubtaskFx(subtask).then((subtask) => {
						if (task) {
							const newSubtasks = subtasks?.map((item) =>
								item.id !== subtask?.id ? item : subtask
							) as SubtaskDto[];

							updateTaskFeature.mutateTaskPreview({
								...task,
								subtasks: newSubtasks,
							});

							taskModel.updateTaskStore({ subtasks: newSubtasks });
						}

						if (message) {
							noticesModel.add({
								type: 'success',
								text: message,
							});
						}
						taskEventsListModel.revalidate();
					});
				}
			},
			[subtasks, task]
		);

		const removeSubtask = useCallback(
			(subtask: SubtaskDto, callback: () => void) => {
				if (subtask.id) {
					subtasksModel.deleteSubtaskFx(subtask).then(() => {
						callback();

						if (task) {
							const newSubtasks = subtasks?.filter(({ id }) => id !== subtask?.id) as SubtaskDto[];

							updateTaskFeature.mutateTaskPreview({
								...task,
								subtasks: newSubtasks,
							});

							taskModel.updateTaskStore({ subtasks: newSubtasks });
						}
					});
				}
			},
			[subtasks, task]
		);

		const removeLinkedTask = useCallback((subtask: SubtaskDto, callback: () => void) => {
			if (subtask.id) {
				subtasksModel.deleteSubtaskFx(subtask).then(callback);
			}
		}, []);

		const handleClickDatepicker = () => {
			setDatepickerDd((prevState) => !prevState);
		};

		const selectDate = () => {
			subtasksModel.addDateToBeingCreatedSubtask(date);
			setDatepickerDd(false);
		};

		const removeDate = () => {
			setDate(null);
			subtasksModel.addDateToBeingCreatedSubtask(null);
		};

		const completedTasks = useMemo(
			() => subtasks.filter((subtask) => subtask.completed).length,
			[subtasks]
		);

		return (
			<>
				<TaskRow title={() => <>Подзадачи:</>} className={styles.taskRow}>
					{!!subtasks.length && (
						<div className={styles.subtasks_col}>
							<Icon id="done" />
							{completedTasks}/{subtasks.length}
						</div>
					)}
					{!subtasks.length && !showCreateSubtask && (
						<Button
							onClick={() => {
								setShowCreateSubtask(true);
								setFocusToId('create-subtask-textarea');
							}}
							size="sm"
							design="filled"
							color="secondary"
							iconRight={<Icon id="plus" />}
							disabled={!editable}>
							Добавить
						</Button>
					)}
				</TaskRow>

				{!!subtasks.length && (
					<>
						<div className={styles.subtaskProgress}>
							<span style={{ width: (completedTasks / subtasks.length) * 100 + '%' }} />
						</div>

						{subtasks.map((subtask, index) => {
							if (subtask.type === SubtaskDtoTypeEnum.LINKED_TASK) {
								return (
									<LinkedTask
										key={index}
										subtask={subtask}
										subtasks={subtasks}
										index={index}
										onRemove={removeLinkedTask}
										task={task}
									/>
								);
							}

							return (
								<Subtask
									key={index}
									index={index}
									subtask={subtask}
									subtasks={subtasks}
									editable={editable}
									task={task}
									taskBoard={task?.board?.currentParticipantBoardRole ? task?.board : undefined}
									onUpdate={updateSubtask}
									onRemove={removeSubtask}
									mode={mode}
								/>
							);
						})}
					</>
				)}

				{editable && showCreateSubtask && (
					<div className={styles.createSubtask}>
						<Controller
							name="title"
							control={control}
							render={({ field: { name, onChange, onBlur, value } }) => (
								<ModalTextarea
									id="create-subtask-textarea"
									design="with-border"
									size="text-13-regular"
									separator={false}
									className={styles.textarea}
									name={name}
									value={value}
									onChange={onChange}
									onBlur={onBlur}
									placeholder="Название подзадачи"
									error={errors.title?.message}
								/>
							)}
						/>
						<div className={styles.buttons}>
							<div className={styles.buttonsColumn}>
								<Button
									size="sm"
									design="filled"
									onClick={handleSubmit(addSubtask)}
									isLoading={createSubtaskStatus === 'pending'}>
									Добавить
								</Button>
								<Button
									size="sm"
									design="filled"
									color="secondary"
									onClick={() => setShowCreateSubtask(false)}>
									Отменить
								</Button>
							</div>
							<div className={styles.buttonsColumn}>
								{createSubtaskAssignee && (
									<SubtaskAssignees
										assignee={createSubtaskAssignee}
										removeAssignee={() => subtasksModel.removeAssigneeFromBeingCreatedSubtask()}
										editable={editable}
									/>
								)}

								<AddAssigneeToSubtask editable={editable} onUpdate={onUpdate} />

								<Dropdown
									isOpen={datepickerDd}
									openOnButtonClick={false}
									closeOnClickOutside={false}
									customCoordinates={{ top: '-250px', right: '40px' }}
									targetButton={
										<div ref={btnDatepickerRef}>
											{createdSubtaskDate && (
												<Tag
													id="add-date-to-subtask"
													className={styles.dateTag}
													editable={true}
													onClick={handleClickDatepicker}
													onCancel={removeDate}>
													<Icon id="clock" />
													{getDateForTag(createdSubtaskDate)}
												</Tag>
											)}
											{!createdSubtaskDate && (
												<Button
													id="add-date-to-subtask"
													onlyIcon={<Icon id="calendar" />}
													design="transparent"
													onClick={handleClickDatepicker}
												/>
											)}
										</div>
									}
									dropdownData={(props, ref) => null}
									dropdownElement={
										<FocusTrap
											focusTrapOptions={{
												initialFocus: false,
												escapeDeactivates: true,
												allowOutsideClick: true,
												checkCanFocusTrap: () =>
													new Promise<void>((resolve) => setTimeout(resolve, 50)),
											}}>
											<div
												ref={datepickerRef}
												className={styles.datepicker}
												onKeyDown={(e) => {
													e.stopPropagation();
													if (checkEscape(e)) {
														setDatepickerDd(false);
														setFocusToId('add-date-to-subtask');
													}
												}}>
												<DatePickerBoard
													initialEndDate={createdSubtaskDate ?? undefined}
													onChange={(_, date) => setDate(dateToISO(date))}
													onDiscard={() => setDatepickerDd(false)}
													onAccept={selectDate}
													singleSelectDate
													showReminder={false}
												/>
											</div>
										</FocusTrap>
									}
								/>
							</div>
						</div>
					</div>
				)}

				{editable && !!subtasks.length && !showCreateSubtask && (
					<Button
						id="create-new-subtask-btn"
						onClick={() => {
							setShowCreateSubtask(true);
							setFocusToId('create-subtask-textarea', 10);
						}}
						size="sm"
						design="filled"
						color="secondary"
						iconRight={<Icon id="plus" />}
						disabled={!editable}>
						Добавить
					</Button>
				)}
			</>
		);
	}
);
