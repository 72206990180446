import { MutableRefObject, useEffect, useRef } from 'react';

type UseResizeObserverArgs = {
	ref: MutableRefObject<Nullable<HTMLDivElement>>;
	callback: ResizeObserverCallback;
};

export const useResizeObserver = ({ callback, ref }: UseResizeObserverArgs) => {
	const observer = useRef<Nullable<ResizeObserver>>(null);

	useEffect(() => {
		if (!ref.current) return;

		observer.current = new ResizeObserver(callback);
		observer.current.observe(ref.current);

		return () => observer?.current?.disconnect();
	}, [callback, ref]);
};
