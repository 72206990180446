import { useStore } from 'effector-react';
import React, { useState, useRef, useMemo } from 'react';

import { UserDto } from '@shared/api';
import {
	REMOVE_COASSIGNEE_DIALOG_TEXT,
	REMOVE_COASSIGNEE_DIALOG_TITLE,
} from '@shared/lib/constants';
import { useOutsideClick } from '@shared/lib/hooks';
import { setFocusToId } from '@shared/lib/utils';
import { Dropdown, Button, Icon, AvatarsGroup, MoreButton } from '@shared/ui';

import { AssigneeDropdown } from '@entities/assignee-dropdown';
import { coAssigneesModel, assigneeModel, subtasksModel } from '@entities/task';

import { CoAssigneesList } from '@features/co-assignees-list';
import { SearchAssignee } from '@features/search-assignee';

import { useShouldUpdate } from '@widgets/task-modal/lib';

import styles from './styles.module.scss';

interface CoAssigneesProps {
	editable?: boolean;
	onUpdate?: (message?: string) => void;
}

export const CoAssignees = ({ editable, onUpdate }: CoAssigneesProps) => {
	const coAssigneeDdRef = useRef(null);
	const coAssigneeDdBtnRef = useRef(null);
	const moreButtonRef = useRef(null);
	const coAssignees = useStore(coAssigneesModel.$coAssignees);
	const assignee = useStore(assigneeModel.$assignee);
	const subtasks = useStore(subtasksModel.$subtasks);

	const [action, setAction] = useState<boolean | undefined>();

	const setShouldUpdate = useShouldUpdate(
		{
			editable,
			onUpdate: () => {
				onUpdate &&
					onUpdate(
						action === undefined ? action : action ? 'Участник добавлен' : 'Участник удален'
					);
				setAction(undefined);
			},
		},
		coAssignees
	);

	const [searchCoAssigneesDd, setSearchCoAssigneesDd] = useState(false);

	const [coAssigneeDdOpen, setCoAssigneeDdOpen] = useState(false);
	const [coAssigneeDdPosition, setCoAssigneeDdPosition] = useState(0);
	const [coAssigneeDdUser, setCoAssigneeDdUser] = useState<UserDto>();

	const [coAssigneesListDd, setCoAssigneesListDd] = useState(false);

	const excludeUserIds = useMemo(
		() => (assignee?.id === undefined ? [] : [assignee?.id]),
		[assignee]
	);

	const setOpenCoAssigneeDd = (user: UserDto, index: number) => {
		setCoAssigneeDdPosition(index);
		setCoAssigneeDdUser(user);
		setCoAssigneeDdOpen(true);
	};

	useOutsideClick(coAssigneeDdRef, () => setCoAssigneeDdOpen(false));

	return (
		<div className={styles.coAssignees}>
			<div className={styles.avatars}>
				<Dropdown
					ref={coAssigneeDdRef}
					isOpen={coAssigneeDdOpen}
					openOnButtonClick={false}
					closeOnClickOutside={false}
					placement="bottomEnd"
					indent={10}
					customCoordinates={{
						right: `${
							coAssignees.length > 4
								? (4 - coAssigneeDdPosition) * 24 - 4
								: (coAssignees.length - coAssigneeDdPosition - 1) * 24 - 4
						}px`,
					}}
					targetButton={
						<AvatarsGroup onAvatarClick={setOpenCoAssigneeDd} names={coAssignees.slice(0, 5)} />
					}
					dropdownData={(props, ref) => null}
					dropdownElement={
						coAssigneeDdUser && (
							<AssigneeDropdown
								user={coAssigneeDdUser}
								removeAssignee={(user) => {
									coAssigneesModel.removeCoAssignee(user);
									setCoAssigneeDdOpen(false);
									setAction(false);
									setShouldUpdate(true);
								}}
								removeDialogTitle={REMOVE_COASSIGNEE_DIALOG_TITLE}
								removeDialogText={REMOVE_COASSIGNEE_DIALOG_TEXT}
								closeDropdown={() => {
									setCoAssigneeDdOpen(false);
									setFocusToId(`avatarId${coAssigneeDdUser.id}`);
								}}
								userHasSubtasks={subtasks.some(
									({ assignee }) => assignee && assignee.id === coAssigneeDdUser.id
								)}
								editable={editable}
							/>
						)
					}
				/>

				{coAssignees.length > 5 && (
					<Dropdown
						isOpen={coAssigneesListDd}
						openOnButtonClick={false}
						closeOnClickOutside={false}
						customCoordinates={{ top: '-165px', right: 'calc(100% + 4px)' }}
						targetButton={
							<MoreButton
								id="co-assignees-more-button"
								ref={moreButtonRef}
								onClick={() => setCoAssigneesListDd((prevState) => !prevState)}
								count={coAssignees.length - 5}
							/>
						}
						dropdownData={(props, ref) => null}
						dropdownElement={
							<CoAssigneesList
								title="Участники"
								coAssigneesList={coAssignees}
								removeCoAssignee={
									editable
										? (user) => {
												coAssigneesModel.removeCoAssignee(user);
												setAction(false);
												setShouldUpdate(true);
										  }
										: undefined
								}
								closeDropdown={() => {
									setCoAssigneesListDd(false);
									setFocusToId('co-assignees-more-button');
								}}
								outsideClickExcludeRef={moreButtonRef}
								checkUserHasSubtasks={(user) =>
									!!subtasks?.some(({ assignee }) => assignee && assignee.id === user.id)
								}
							/>
						}
					/>
				)}
			</div>
			<Dropdown
				placement="bottomEnd"
				isOpen={editable && searchCoAssigneesDd}
				openOnButtonClick={false}
				closeOnClickOutside={false}
				targetButton={
					<Button
						ref={coAssigneeDdBtnRef}
						id="co-assignee-button"
						onClick={() => setSearchCoAssigneesDd(true)}
						size="sm"
						design="filled"
						color="secondary"
						disabled={!editable}
						onlyIcon={coAssignees.length ? <Icon id="plus" /> : undefined}
						iconRight={coAssignees.length ? undefined : <Icon id="plus" />}>
						Добавить
					</Button>
				}
				dropdownData={(props, ref) => null}
				dropdownElement={
					<SearchAssignee
						assignees={coAssignees}
						addAssignee={(user) => {
							coAssigneesModel.addCoAssignee(user);
							setAction(true);
							setShouldUpdate(true);
						}}
						removeAssignee={(user) => {
							coAssigneesModel.removeCoAssignee(user);
							setAction(false);
							setShouldUpdate(true);
						}}
						closeDropdown={() => {
							setSearchCoAssigneesDd(false);
							setFocusToId('co-assignee-button');
						}}
						excludeUserIds={excludeUserIds}
						outsideClickExcludeRef={coAssigneeDdBtnRef}
						checkUserHasSubtasks={(user) =>
							!!subtasks?.some(({ assignee }) => assignee && assignee.id === user.id)
						}
					/>
				}
			/>
		</div>
	);
};
