import { useStore } from 'effector-react';
import React, { FC, useCallback } from 'react';

import { TagDto } from '@shared/api';

import {
	tagListModel,
	TagsManagerCreate,
	TagsManagerCreateMode,
	TagsManagerList,
	TagsManagerRemove,
} from '@entities/tag-manager';
import { tagManagerModel } from '@entities/tag-manager/model';

import styles from './styles.module.scss';

interface TagsManagerEntryProps {
	boardId?: string;
	onTagClick?: (tag: TagDto) => void;
	onUpdateList?: (tag: TagDto | undefined, isNew: boolean) => void;
	onRemoveFromList?: (tag?: TagDto) => void;
	onDiscard?: () => void;
	onAccept?: () => void;
	isDirty?: boolean;
	taskTagList?: TagDto[];
	editable?: boolean;
}

export const TagsManagerEntry: FC<TagsManagerEntryProps> = ({
	boardId,
	onTagClick,
	onUpdateList,
	onRemoveFromList,
	onDiscard,
	onAccept,
	taskTagList,
	editable,
	isDirty,
}) => {
	const { TagListGate } = tagListModel;
	const { TagManagerGate } = tagManagerModel;

	const action = useStore(tagManagerModel.$action);
	const mode = action && action.split('_')[0].toLowerCase();
	const currentTag = useStore(tagManagerModel.$currentTag);
	const searchString = useStore(tagListModel.$tagListSearch);

	const createTag = () => {
		if (boardId) {
			tagManagerModel.pushAction({
				type: 'CREATE_TAG',
				boardId,
			});
		}
	};

	const editTag = (tag: TagDto) => {
		if (boardId && tag) {
			tagManagerModel.pushAction({
				type: 'EDIT_TAG',
				boardId: boardId,
				data: tag,
			});
		}
	};

	const deleteTag = (tag: TagDto) => {
		if (boardId && tag) {
			tagManagerModel.pushAction({
				type: 'DELETE_TAG',
				boardId: boardId,
				data: tag,
			});
		}
	};

	const discardAction = useCallback(() => {
		tagManagerModel.pushAction({
			type: null,
		});

		if (searchString) {
			tagListModel.resetSearch();
		}
	}, [searchString]);

	return (
		<div className={styles.wrap}>
			<TagListGate boardId={boardId} />
			<TagManagerGate />
			{editable && (action === 'CREATE_TAG' || action === 'EDIT_TAG') && (
				<TagsManagerCreate
					boardId={boardId}
					onDiscard={discardAction}
					onUpdateList={onUpdateList}
					tag={currentTag}
					mode={mode as TagsManagerCreateMode}
				/>
			)}
			{editable && action === 'DELETE_TAG' && (
				<TagsManagerRemove
					onDiscard={discardAction}
					onUpdateList={onRemoveFromList}
					tag={currentTag}
				/>
			)}
			{!action && (
				<TagsManagerList
					boardId={boardId}
					createTag={createTag}
					editTag={editTag}
					deleteTag={deleteTag}
					clickTag={onTagClick}
					onDiscard={onDiscard}
					onAccept={onAccept}
					taskTagList={taskTagList}
					editable={editable}
					isDirty={isDirty}
				/>
			)}
		</div>
	);
};
