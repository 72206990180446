import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { boardScrollModel } from '@shared/model';

import styles from './styles.module.scss';

export const HeaderShadow: FC = React.memo(() => {
	const boardScrollY = useStore(boardScrollModel.$boardScrollY);

	return (
		<div className={styles.headerShadowWrapper}>
			<div
				className={classNames(styles.headerShadow, {
					[styles.isShown]: boardScrollY > 0,
				})}
			/>
		</div>
	);
});
