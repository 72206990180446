import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { useRef, useState } from 'react';

import { BoardDto, BoardWithViewDto, UserDto } from '@shared/api';
import { useDelayUnmount, useOutsideClick } from '@shared/lib/hooks';
import { checkEscape } from '@shared/lib/utils';
import { Button, Icon, Dropdown, TooltipWrap } from '@shared/ui';

import { BoardCardProps, BoardToolsWrap, toggleBoardStarredModel } from '@entities/boards';

import styles from '../styles.module.scss';

interface BoardCardFooterProps
	extends Pick<BoardCardProps, 'id' | 'starred' | 'archived' | 'archivedDate'> {
	editBoard?: (board?: BoardDto | BoardWithViewDto) => void;
	removeBoard?: (board?: BoardDto | BoardWithViewDto) => void;
	manageTags?: () => void;
	restoreBoard?: () => void;
	deleteBoard?: () => void;
	addToFavorite?: () => void;
	viewer?: Nullable<UserDto>;
	tagManager?: React.ReactNode;
	isDisabled?: boolean;
	userIsAuthor?: boolean;
}

export const BoardCardFooter = ({ userIsAuthor, ...props }: BoardCardFooterProps) =>
	props.archived ? (
		userIsAuthor ? (
			<ArchivedFooter {...props} />
		) : null
	) : (
		<NotArchivedFooter {...props} />
	);

const NotArchivedFooter = ({
	id,
	addToFavorite,
	starred,
	viewer,
	editBoard,
	removeBoard,
	tagManager,
	isDisabled,
}: BoardCardFooterProps) => {
	const ddRef = useRef<HTMLDivElement | null>(null);
	const ddBtnRef = useRef<HTMLButtonElement | null>(null);
	const ddMenuRef = useRef<HTMLDivElement | null>(null);

	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [tagManagerActive, setTagManagerActive] = useState(false);

	const tagManagerShouldRender = useDelayUnmount(tagManagerActive, 400);
	const displayTagManager = menuIsOpen ? tagManagerActive : tagManagerShouldRender;

	const label = starred ? 'Убрать из избранного' : 'Добавить в избранное';
	const { pending } = useStore(toggleBoardStarredModel.$toggleBoardStarredStatus);

	useOutsideClick([ddBtnRef, ddMenuRef], () => {
		setMenuIsOpen(false);
		setTagManagerActive(false);
	});

	return (
		<div
			className={classnames(styles.boardTools, {
				[styles.isOpen]: menuIsOpen,
				[styles.isStarred]: starred,
			})}>
			{((isDisabled && starred) || !isDisabled) && (
				<TooltipWrap content={label} className={styles.starBtn} margin="0 0 0 -28px">
					<Button
						onClick={addToFavorite}
						isLoading={pending === id}
						className={classnames({ [styles.isActive]: starred }, styles.starButton)}
						design="transparent"
						onlyIcon={<Icon id="star" />}
						aria-label={label}
						disabled={!!isDisabled}
					/>
				</TooltipWrap>
			)}
			{!isDisabled && (
				<Dropdown
					ref={ddRef}
					className={styles.boardToolsMenu}
					placement="bottomEnd"
					portal={true}
					isOpen={menuIsOpen}
					openOnButtonClick={false}
					closeOnClickOutside={false}
					targetButton={
						<Button
							ref={ddBtnRef}
							design="transparent"
							color="secondary"
							className={styles.boardToolsMenuBtn}
							onlyIcon={<Icon id="kebab" />}
							onClick={() => setMenuIsOpen((prev) => !prev)}
						/>
					}
					dropdownData={(props, ref) => null}
					dropdownElement={
						<div
							ref={ddMenuRef}
							className={styles.boardToolsMenuDrop}
							onKeyDown={(e) => {
								if (checkEscape(e)) {
									if (tagManagerActive) setTagManagerActive(false);
									else setMenuIsOpen(false);
								}
							}}>
							{!displayTagManager && (
								<BoardToolsWrap
									boardId={id}
									viewer={viewer}
									parentBtnRef={ddBtnRef}
									editBoard={editBoard}
									removeBoard={removeBoard}
									manageTags={() => setTagManagerActive((prev) => !prev)}
									closeDropdown={() => setMenuIsOpen(false)}
								/>
							)}
							{displayTagManager && tagManager}
						</div>
					}
				/>
			)}
		</div>
	);
};

const ArchivedFooter = ({ archivedDate, deleteBoard, restoreBoard }: BoardCardFooterProps) => {
	return (
		<div tabIndex={1} aria-hidden={false} className={styles.archiveButtons}>
			{deleteBoard && (
				<Button onClick={deleteBoard} design="filled" color="secondary" type="button">
					Удалить
				</Button>
			)}
			{restoreBoard && (
				<Button onClick={restoreBoard} design="filled" color="primary" type="button">
					Восстановить
				</Button>
			)}
		</div>
	);
};
