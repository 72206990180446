import classNames from 'classnames';
import { useGate, useStore } from 'effector-react';
import React, { FC, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { BoardViewDtoRowTypeEnum, BoardViewDtoTypeEnum, BoardWithViewDto } from '@shared/api';
import { isEmptyObject } from '@shared/lib/utils';
import { asideModel } from '@shared/model';
import { EmptyBanner, modalsModel, createTask } from '@shared/ui';

import { boardModel, boardViewModel, toggleTagsOnTaskModel } from '@entities/boards';
import { useCurrentUser } from '@entities/current-user';
import { SearchInput } from '@entities/search-input';
import { taskListModel } from '@entities/task';

import {
	completelyDeleteBoard,
	moveBoardToArchive,
	restoreBoardFromArchive,
} from '@features/board';
import { removeTask } from '@features/task';
import { useUserRoles } from '@features/user-roles';

import { editBoard } from '@widgets/board-modal';
import { Header, headerModel } from '@widgets/header';
import { PageContentWrapper } from '@widgets/page-content-wrapper';
import { TaskBoard } from '@widgets/task-board';
import { TaskTable, TaskTransposedTable } from '@widgets/task-board/ui/task-table';
import { TasksGroup } from '@widgets/tasks-group';

import { getEmptyMessage } from './lib';
import styles from './styles.module.scss';
import {
	TaskBoardPageActions,
	TaskBoardPageArchivedBoardActions,
	TaskBoardPageParticipants,
	TaskBoardPageTools,
	TaskBoardPageViews,
} from './ui';

export const TaskBoardPage: FC = () => {
	const { currentUser } = useCurrentUser();
	const headerHeight = useStore(headerModel.$height);
	const pageOffset = useStore(asideModel.$width);

	const { boardId, slug, taskId } = useParams();
	const { pathname } = useLocation();

	const { userOnBoard } = useUserRoles();

	const BoardGate = boardModel.BoardGate;

	const boardData = useStore(boardModel.$board);
	const boardLoading = useStore(boardModel.$boardStatus) === 'pending';

	const isArchivedBoard = !!boardData?.archived;

	const isArchive = slug === 'archive' || pathname.includes('/boards/archive');
	const boardTaskArchive = slug === 'archive' && !pathname.includes('/boards/archive');

	const backUrl = isArchive
		? isArchivedBoard
			? `/boards/archive`
			: `/boards/${boardId}`
		: undefined;
	const title = boardData?.name || 'Доска задач';

	const [search, setSearch] = useState('');

	const boardView = useStore(boardViewModel.$store);
	const showTagsOnTasks = useStore(toggleTagsOnTaskModel.$showTagsOnTasks);

	useGate(taskListModel.TasksListGate, {
		boardId,
		archived: isArchive || isArchivedBoard,
		name: boardTaskArchive && search.length > 0 ? search : undefined,
		view: boardView?.type || null,
		viewRowType: boardView?.rowType,
	});

	const { loading, list, localList, columns, table, transposedTable } = useStore(
		taskListModel.$status
	);

	const empty =
		list.length === 0 || localList.length === 0
			? getEmptyMessage({
					isArchive: isArchive || isArchivedBoard,
					isFilteredBySearch: search.length > 0,
					isFilteredByView: list.length > 0 && localList.length === 0,
			  })
			: null;

	const ModalGate = modalsModel.ModalGate;

	return (
		<>
			<BoardGate boardId={boardId} />
			<Header
				title={boardTaskArchive ? 'Архив задач' : title}
				backUrl={backUrl}
				topRight={
					boardTaskArchive ? (
						<SearchInput
							className={styles.searchInput}
							onSearch={setSearch}
							icon="search"
							theme="dark"
							placeholder="Поиск по названию"
						/>
					) : (
						<>
							<TaskBoardPageParticipants
								boardIsArchived={isArchivedBoard}
								isLoading={boardLoading}
							/>
							{!isArchivedBoard && (
								<TaskBoardPageTools
									currentUser={currentUser}
									editBoard={(board) => editBoard(board as BoardWithViewDto, false)}
									removeBoard={moveBoardToArchive}
									restoreBoard={restoreBoardFromArchive}
									deleteBoard={(board) => completelyDeleteBoard(board as BoardWithViewDto, false)}
									boardIsArchived={isArchivedBoard}
								/>
							)}
						</>
					)
				}
				bottomRight={isArchivedBoard || boardTaskArchive ? null : <TaskBoardPageViews />}
				bottomLeft={
					isArchivedBoard ? (
						userOnBoard.isAuthor ? (
							<TaskBoardPageArchivedBoardActions
								restoreBoard={() => restoreBoardFromArchive(boardData)}
								deleteBoard={() => completelyDeleteBoard(boardData, false)}
							/>
						) : null
					) : boardTaskArchive ? null : (
						<TaskBoardPageActions createTask={createTask} isLoading={boardLoading} />
					)
				}
				isLoading={boardLoading}
			/>

			<PageContentWrapper headerOffset={headerHeight}>
				{!loading && empty ? (
					<EmptyBanner>{empty}</EmptyBanner>
				) : (
					<div
						className={classNames(
							styles.tasksWrapper,
							!isArchive && !showTagsOnTasks && boardView?.type === 'CUSTOM' && 'showTags'
						)}>
						{isArchive && !isArchivedBoard ? (
							<TasksGroup
								tasks={list}
								removeTask={removeTask}
								isLoading={loading}
								isBoardAuthor={userOnBoard.isAuthor}
								userRole={userOnBoard.role}
							/>
						) : (
							<>
								{(boardView?.type === BoardViewDtoTypeEnum.CLASSIC || isArchivedBoard) && (
									<TaskBoard
										viewer={currentUser}
										columns={columns}
										isLoading={loading}
										isEmpty={list.length === 0}
										isDisabled={isArchivedBoard}
									/>
								)}
								{boardView?.type === BoardViewDtoTypeEnum.CUSTOM &&
									boardView?.rowType === BoardViewDtoRowTypeEnum.TAG && (
										<TaskTable
											viewer={currentUser}
											data={table}
											isLoading={loading}
											isEmpty={list.length === 0}
											pageOffset={pageOffset}
											headerOffset={headerHeight}
										/>
									)}
								{boardView?.type === BoardViewDtoTypeEnum.CUSTOM &&
									boardView?.rowType === BoardViewDtoRowTypeEnum.STATUS && (
										<TaskTransposedTable
											viewer={currentUser}
											data={transposedTable}
											isLoading={loading}
											isEmpty={list.length === 0}
											pageOffset={pageOffset}
											headerOffset={headerHeight}
										/>
									)}
							</>
						)}
					</div>
				)}
			</PageContentWrapper>

			{taskId && currentUser && !isEmptyObject(currentUser) && (
				<ModalGate
					name="TASK_MODAL"
					data={{
						id: taskId,
						viewer: currentUser,
						mode: isArchive ? 'view' : 'edit',
					}}
				/>
			)}
		</>
	);
};
