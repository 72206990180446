import React, { useState } from 'react';

import { UserDto } from '@shared/api';
import {
	REMOVE_COASSIGNEE_DIALOG_TEXT,
	REMOVE_COASSIGNEE_DIALOG_TITLE,
} from '@shared/lib/constants';
import { setFocusToId } from '@shared/lib/utils';
import {
	Button,
	Icon,
	UserInfo,
	Dropdown,
	DropdownControlled,
	ConfirmModalHead,
	ConfirmModalBody,
} from '@shared/ui';

import { AssigneeDropdown } from '@entities/assignee-dropdown';

import styles from './styles.module.scss';

interface CoAssigneesListItemProps {
	user: UserDto;
	removeAssignee?: () => void;
	userHasSubtasks?: boolean;
}

export const CoAssigneesListItem = ({
	user,
	removeAssignee,
	userHasSubtasks,
}: CoAssigneesListItemProps) => {
	const [dropdown, setDropdown] = useState(false);
	const [showLockedRemoveNotification, setShowLockedRemoveNotification] = useState(false);

	return (
		<li className={styles.listItem}>
			<Dropdown
				portal
				isOpen={dropdown}
				openOnButtonClick={false}
				closeOnClickOutside={false}
				dropdownId="co-assignees-list"
				placement="bottomStart"
				targetButton={
					<UserInfo
						className={styles.userInfo}
						id={user.id}
						user={user}
						Tag="button"
						onClick={() => setDropdown((prevState) => !prevState)}
						showJobTitle
					/>
				}
				dropdownData={(props, ref) => (
					<AssigneeDropdown
						user={user}
						removeAssignee={
							removeAssignee &&
							(() => {
								removeAssignee();
								setFocusToId('search-input');
							})
						}
						closeDropdown={() => {
							setDropdown(false);
							setFocusToId(`${user.id}`);
						}}
						userHasSubtasks={userHasSubtasks}
					/>
				)}
			/>

			{removeAssignee && (
				<>
					{!userHasSubtasks && (
						<Button
							onClick={() => {
								removeAssignee();
								setFocusToId('search-input');
							}}
							className={styles.button}
							size="sm"
							design="transparent"
							iconRight={<Icon id="remove" />}>
							Удалить
						</Button>
					)}
					{userHasSubtasks && (
						<DropdownControlled
							opened={showLockedRemoveNotification}
							setOpened={setShowLockedRemoveNotification}
							dropdownClassName={styles.notification}
							contentClassName={styles.notificationContent}
							portal={true}
							portalId="co-assignees-list"
							placement="bottomEnd"
							targetButton={
								<Button
									size="sm"
									design="transparent"
									iconRight={<Icon id="remove" />}
									onClick={() => setShowLockedRemoveNotification((prev) => !prev)}
									className={styles.button}>
									Удалить
								</Button>
							}
							content={
								<>
									<ConfirmModalHead
										size="sm"
										title={REMOVE_COASSIGNEE_DIALOG_TITLE}
										onClickBack={() => setShowLockedRemoveNotification(false)}
									/>
									<ConfirmModalBody
										content={
											<>
												{REMOVE_COASSIGNEE_DIALOG_TEXT[0]} <b>{user.fullName}</b>{' '}
												{REMOVE_COASSIGNEE_DIALOG_TEXT[1]}
											</>
										}
										onDiscard={() => setShowLockedRemoveNotification(false)}
										discardLabel="Понятно"
										textAlign="left"
									/>
								</>
							}
						/>
					)}
				</>
			)}
		</li>
	);
};
