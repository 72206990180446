import { combine, createEffect, createEvent, createStore, restore, sample } from 'effector';
import { condition } from 'patronum';

import { UpdateBoardRequest, boardsRequests, BoardDto, BoardWithViewDto } from '@shared/api';
import { modalsModel, noticesModel } from '@shared/ui';

import { boardsModel, boardModel } from '@entities/boards';

interface UpdateBoardArgs {
	body: UpdateBoardRequest;
	revalidateList?: boolean;
}

const updateBoard = createEvent<UpdateBoardArgs>();
const updateBoardFx = createEffect(boardsRequests.updateBoard);

updateBoardFx.doneData.watch((result) => {
	modalsModel.closeLastModal();
	noticesModel.add({
		type: 'success',
		text: `Доска задач "<b>${result.name}</b>" изменена.`,
	});
});

updateBoardFx.failData.watch((result) => {
	noticesModel.add({
		type: 'error',
		text: `Что-то пошло не так, попробуйте позже.`,
	});
});

const $shouldRevalidate = createStore(false).reset(updateBoard);

const $updatedBoard = createStore<BoardDto | null>(null).reset(updateBoard);
const $updateBoardError = restore<Error>(updateBoardFx.failData, null).reset(updateBoard);

const $updateBoardStatus = combine({
	pending: updateBoardFx.pending,
	response: $updatedBoard,
	error: $updateBoardError,
});

sample({
	clock: updateBoardFx.doneData,
	target: $updatedBoard,
});

sample({
	clock: updateBoard,
	fn: ({ revalidateList }) => !!revalidateList,
	target: $shouldRevalidate,
});

sample({
	clock: updateBoard,
	fn: ({ body }) => body,
	target: updateBoardFx,
});

const revalidateBoardsList = createEvent<any>();
const mutateCurrentBoard = createEvent<any>();

condition({
	source: updateBoardFx.done,
	if: $shouldRevalidate,
	then: revalidateBoardsList,
	else: mutateCurrentBoard,
});

sample({
	clock: revalidateBoardsList,
	source: boardsModel.$boardsListConfig,
	target: boardsModel.getBoardsList,
});

sample({
	clock: mutateCurrentBoard,
	source: { newBoard: $updatedBoard, oldBoard: boardModel.$board },
	fn: ({ newBoard, oldBoard }) => {
		if (newBoard) {
			newBoard.participants = newBoard.participants
				? newBoard.participants.filter(({ user }) => user?.id !== newBoard?.author?.id)
				: newBoard.participants;
		}
		return {
			...oldBoard,
			...newBoard,
		} as BoardWithViewDto;
	},
	target: boardModel.$board,
});

export const updateBoardFeature = {
	updateBoard,
	updateBoardFx,
	$updateBoardStatus,
};
