import classnames from 'classnames';
import React, { FC, HTMLAttributes, useRef, useState } from 'react';

import { useDidMount } from '@shared/lib/hooks';

import styles from './styles.module.scss';

interface NotificationProps extends HTMLAttributes<HTMLElement> {
	text: string;
	type?: NoticeType;
	onClose: () => void;
}
export type NoticeType = 'success' | 'warning' | 'error';

export const Notification: FC<NotificationProps> = React.memo(
	({ text, type = 'success', onClose, ...props }) => {
		const timer = useRef<ReturnType<typeof setTimeout>>();
		const timerEffect = useRef<ReturnType<typeof setTimeout>>();

		const [active, setActive] = useState(false);

		useDidMount(() => {
			timer.current = setTimeout(onClose, 5000);

			return () => clearTimeout(timer.current);
		});

		useDidMount(() => {
			timerEffect.current = setTimeout(() => setActive(true), 100);

			return () => clearTimeout(timerEffect.current);
		});

		return (
			<div className={classnames(active && styles.active, styles.wrap)}>
				<button onClick={onClose} className={classnames(styles.notification, styles[type])}>
					<p dangerouslySetInnerHTML={{ __html: text }} />
				</button>
			</div>
		);
	}
);
