export type JWT = {
	access: string | null;
	refresh: string | null;
	accessExpiration?: string;
	refreshExpiration?: string;
};
export type JWTKey = keyof JWT;

/*
 * Пишет токены в хранилище
 */
export const setJWT = ({
	access,
	refresh,
	accessExpiration,
	refreshExpiration,
}: Partial<JWT>): void => {
	if (access) {
		localStorage.setItem('access', access);

		if (accessExpiration) {
			localStorage.setItem('accessExpiration', accessExpiration);
		}
	} else {
		localStorage.removeItem('access');
		localStorage.removeItem('accessExpiration');
		localStorage.removeItem('refreshExpiration');
	}

	if (refresh) {
		localStorage.setItem('refresh', refresh);

		if (refreshExpiration) {
			localStorage.setItem('refreshExpiration', refreshExpiration);
		}
	}
};

/*
 * Сбрасывает токены
 */
export const resetJWT = (): void => {
	localStorage.removeItem('access');
	localStorage.removeItem('refresh');
	localStorage.removeItem('accessExpiration');
	localStorage.removeItem('refreshExpiration');
};

/*
 * Получает токены из хранилища
 */
export const getJWT = (tokenName: JWTKey = 'access'): string | null => {
	const isSSR = typeof window === 'undefined';

	if (isSSR) return null;

	const token = window?.localStorage && localStorage.getItem(tokenName);
	return token || null;
};
