import { useStore } from 'effector-react';
import { isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { BoardWithViewDto, TagDto, TaskDto } from '@shared/api';
import { Button, DropdownControlled, Icon, Skeleton, Tag, TagColorType } from '@shared/ui';

import { boardModel } from '@entities/boards';
import { TagsManagerEntry } from '@entities/tag-manager';
import { taskListModel, taskModel, taskListTagsModel } from '@entities/task';
import { tagsModel } from '@entities/task/model/tags';

import styles from './styles.module.scss';

interface TaskTagsProps extends Pick<TaskDto, 'tags'> {
	board: BoardWithViewDto;
	loading?: boolean;
	editable?: boolean;
	onUpdate?: (message?: string) => void;
	tagManagerAvailable?: boolean;
}

const compareTagsByName = (tag1: TagDto, tag2: TagDto) => {
	if (tag1.name < tag2.name) {
		return -1;
	}

	if (tag1.name > tag2.name) {
		return 1;
	}

	return 0;
};

export const TaskTags: FC<TaskTagsProps> = ({
	board,
	loading,
	editable,
	onUpdate,
	tagManagerAvailable,
}) => {
	const ddBtnRef = useRef<HTMLButtonElement | null>(null);
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const [shouldUpdate, setShouldUpdate] = useState<string | undefined>();

	const { id: boardId } = board;

	const tags = useStore(tagsModel.$tags);
	const hasTags = tags.length > 0;

	const [localTags, setLocalTags] = useState<TagDto[]>(tags);

	const isDirty = !isEqual(tags.sort(compareTagsByName), localTags.sort(compareTagsByName));

	const saveChanges = useCallback(() => {
		if (editable && onUpdate) {
			onUpdate(shouldUpdate || undefined);
		}
	}, [editable, onUpdate, shouldUpdate]);

	const handleTagClick = (tag: TagDto) => {
		const includes = localTags.findIndex(({ id }) => id === tag.id) > -1;
		const newTags = includes ? localTags.filter(({ id }) => id !== tag.id) : [...localTags, tag];
		setLocalTags(newTags);
	};

	const removeTag = (tag: TagDto) => {
		tagsModel.removeTag(tag);
		setShouldUpdate(`Метка "<b>${tag.name}</b>" удалена`);
	};

	const handleDiscard = () => {
		setLocalTags(tags);
		setMenuIsOpen(false);
	};

	const handleAccept = () => {
		tagsModel.setTags(localTags);
		setMenuIsOpen(false);
		setShouldUpdate('Метки задачи изменены');
	};

	useEffect(() => {
		setLocalTags(tags);
	}, [tags]);

	useEffect(() => {
		if (!!shouldUpdate) {
			saveChanges();
			setShouldUpdate(undefined);
		}
	}, [saveChanges, shouldUpdate, tags]);

	return (
		<>
			<div className={styles.tagsWrap}>
				{hasTags && (
					<div className={styles.tagsArray}>
						{tags.map((tag) => {
							const { id, color, name } = tag;
							return (
								<Tag
									key={id}
									color={color as TagColorType}
									editable={editable}
									onCancel={editable ? () => removeTag(tag) : undefined}>
									<span>{name}</span>
								</Tag>
							);
						})}
					</div>
				)}
				{loading ? (
					<Skeleton width={24} height={24} />
				) : (
					<DropdownControlled
						opened={menuIsOpen}
						setOpened={setMenuIsOpen}
						onClose={() => setMenuIsOpen(false)}
						contentClassName={styles.tagsDrop}
						portal={true}
						portalId="task-modal-portal"
						placement="topEnd"
						targetButton={
							<Button
								size="sm"
								design="filled"
								color="secondary"
								onlyIcon={hasTags ? <Icon id={'plus'} /> : undefined}
								iconRight={!hasTags ? <Icon id={'plus'} /> : undefined}
								ref={ddBtnRef}
								onClick={() => setMenuIsOpen((prev) => !prev)}
								disabled={!editable}>
								{!hasTags ? 'Добавить' : null}
							</Button>
						}
						content={
							<TagsManagerEntry
								boardId={boardId}
								taskTagList={localTags}
								onTagClick={handleTagClick}
								editable={tagManagerAvailable}
								isDirty={isDirty}
								onUpdateList={(tag, isNew) => {
									boardModel.revalidateBoard();
									taskModel.revalidateTask();
									taskListModel.revalidateTaskList();

									if (tag) {
										tagsModel.updateTag(tag);
										taskListTagsModel.addTagToList(tag);
										if (isNew) {
											handleTagClick(tag);
										}
									}
								}}
								onRemoveFromList={(tag) => {
									boardModel.revalidateBoard();
									taskModel.revalidateTask();
									taskListModel.revalidateTaskList();

									if (tag) {
										tagsModel.removeTag(tag);
										taskListTagsModel.removeTagFromList(tag);
									}
								}}
								onDiscard={handleDiscard}
								onAccept={handleAccept}
							/>
						}
					/>
				)}
			</div>
		</>
	);
};
