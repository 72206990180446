import { AxiosError } from 'axios';
import { createEffect, createEvent, sample, restore } from 'effector';
import { status } from 'patronum';

import { authRequests, RegisterWithPasswordDto, SecurityTokensDto } from '@shared/api';
import { setJWT } from '@shared/lib/utils';
import { noticesModel } from '@shared/ui';

const signUp = createEvent<RegisterWithPasswordDto>();
const signUpFx = createEffect<RegisterWithPasswordDto, SecurityTokensDto, AxiosError>(
	authRequests.devSignUp
);

const $status = status({ effect: signUpFx });

const $error = restore(signUpFx.failData, null).reset(signUp);

signUpFx.doneData.watch(({ accessToken, refreshToken }) => {
	setJWT({
		access: accessToken?.token,
		refresh: refreshToken?.token,
		accessExpiration: accessToken?.expirationDate,
		refreshExpiration: refreshToken?.expirationDate,
	});
	window.location.href = '/';
});

sample({
	clock: signUp,
	target: signUpFx,
});

sample({
	clock: signUpFx.failData,
	filter: (source) => source.response?.status !== 400,
	fn: () => ({
		type: 'error' as const,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	signUp,
	$status,
	$error,
};
