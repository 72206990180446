import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import React, { useState, useEffect, useMemo, memo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { object } from 'yup';

import { useVirtualFileUploader } from '@shared/lib/hooks';
import { commonValidators } from '@shared/lib/validation';
import { Attachment, Button, noticesModel } from '@shared/ui';

import { ContentProps } from '../';
import { useResizableTextarea } from '../../../../lib';
import { TaskEventFileManager } from '../../../../ui';
import styles from '../styles.module.scss';

import { AttachmentEditWrapper } from './attachment-edit-wrapper';

interface ContentEditValue {
	text: string;
	fileManagerAttach?: TaskEventFileManager;
}

export const ContentEdit = ({
	text,
	files,
	onCancel,
	onSave,
	editingActions,
	fileManagerAttach,
}: ContentProps) => {
	const textareaRef = useResizableTextarea();
	const fileUploader = useVirtualFileUploader(editingActions.onAddAttachments);

	const [withFiles, setWithFiles] = useState(false);

	const {
		control,
		handleSubmit,
		trigger,
		formState: { errors },
	} = useForm<ContentEditValue>({
		defaultValues: {
			text: text ?? '',
		},
		resolver: yupResolver(
			object({
				text: commonValidators.conditionalRequiredString(withFiles),
			})
		),
		mode: 'onSubmit',
	});

	useEffect(() => {
		setWithFiles(!!files && files.length > 0);
	}, [files]);

	useEffect(() => {
		if (withFiles) trigger('text');
	}, [withFiles, trigger]);

	const handleSave = () => {
		onSave && onSave();
	};

	const error = errors.text?.message;

	const FileManager = useMemo(
		() => (fileManagerAttach ? memo(fileManagerAttach) : () => <></>),
		[fileManagerAttach]
	);

	return (
		<FocusTrap
			focusTrapOptions={{
				escapeDeactivates: true,
				allowOutsideClick: true,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
			}}>
			<div>
				<div
					className={classnames(styles.content, styles.content_edit, error && styles.contentError)}>
					<Controller
						name="text"
						control={control}
						render={({ field: { name, onChange, onBlur, value } }) => (
							<textarea
								name={name}
								ref={textareaRef}
								className={styles.textarea}
								placeholder="Напишите комментарий"
								value={value}
								onBlur={onBlur}
								onChange={(event) => {
									onChange(event);
									editingActions.onEditText(event.target.value);
								}}
							/>
						)}
					/>

					{files && files.length > 0 && (
						<div className={styles.files}>
							{files.map((file) => (
								<AttachmentEditWrapper
									onRemove={() => {
										file.id && editingActions.onRemoveAttachment(file.id);
										noticesModel.add({
											type: 'success',
											text: `Файл "<b>${file.name || file.url}</b>" удален.`,
										});
									}}
									key={'attach_' + file.id}>
									<Attachment asCard={true} {...file} />
								</AttachmentEditWrapper>
							))}
						</div>
					)}

					<div className={styles.footer}>
						<div className={styles.footer__buttons}>
							<Button size="sm" onClick={handleSubmit(handleSave)}>
								Сохранить
							</Button>
							<Button size="sm" color="secondary" onClick={onCancel}>
								Отменить
							</Button>
						</div>
						<FileManager
							onCreateFile={fileUploader.handleChange}
							onFileUploaded={editingActions.onAddAttachments}
						/>
					</div>
				</div>

				{error && <small className={styles.errorMessage}>{error}</small>}
			</div>
		</FocusTrap>
	);
};
