/**
 * rpharm-todo
 * rpharm-todo documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TagDto {
	id: string;
	name: string;
	color: string;
	type?: TagDtoTypeEnum;
	active?: boolean;
}
export enum TagDtoTypeEnum {
	CUSTOM = 'CUSTOM',
	SYSTEM_WITHOUT_TAG = 'SYSTEM_WITHOUT_TAG',
}
