import { useStore } from 'effector-react';
import React, { FC, Dispatch, SetStateAction } from 'react';
import { useMatch } from 'react-router-dom';

import { Button, Icon, Select, SelectOption } from '@shared/ui';

import { boardsModel } from '@entities/boards';
import { SearchInput } from '@entities/search-input';

import styles from './styles.module.scss';

interface BoardPageActionsProps {
	createBoard: () => void;
	onFilter: (filter: SelectOption) => void;
	onSearch: Dispatch<SetStateAction<string>>;
	showFilter?: boolean;
}

const boardPageFilterOptions: SelectOption[] = [
	{ label: 'Все доски', value: null },
	{ label: 'Только избранные', value: 'starred' },
];

export const BoardPageActions: FC<BoardPageActionsProps> = ({
	createBoard,
	onFilter,
	onSearch,
	showFilter = true,
}) => {
	const isArchive = !!useMatch('/boards/archive');
	const { loading, count } = useStore(boardsModel.$boardsListStatus);

	return (
		<div className={styles.actionsWrapper}>
			{!isArchive && (
				<>
					{showFilter && (count > 0 || loading) && (
						<Select
							onChange={onFilter}
							options={boardPageFilterOptions}
							initial={boardPageFilterOptions[0]}
							disabled={loading}
						/>
					)}
					<Button
						to={'/boards/archive'}
						iconLeft={<Icon id={'clock'} />}
						color={'secondary'}
						disabled={loading}>
						Архив досок
					</Button>
					<Button onClick={createBoard}>Создать доску</Button>
				</>
			)}
			{isArchive && (
				<SearchInput
					className={styles.searchInput}
					onSearch={onSearch}
					icon="search"
					theme="dark"
					placeholder="Поиск по названию"
				/>
			)}
		</div>
	);
};
