import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { ChangeEventHandler, FC, useState } from 'react';

import { FileDto } from '@shared/api';
import { helpersStyles } from '@shared/lib/styles';
import { filesModel } from '@shared/model';
import { Button, DropdownControlled } from '@shared/ui';

import { FileManagerListItem, FileManagerRemoveModal } from '@entities/file-manager';
import { fileManagerModel } from '@entities/file-manager/model';

import styles from './styles.module.scss';

interface FileManagerListProps {
	files: FileDto[];
	createFile?: ChangeEventHandler<HTMLInputElement>;
	onRemove?: (file: FileDto) => void;
	onRemoveAccept?: (file: FileDto) => void;
	editable?: boolean;
}

export const FileManagerList: FC<FileManagerListProps> = ({
	files,
	createFile,
	onRemove,
	onRemoveAccept,
	editable,
}) => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const uploading = useStore(filesModel.$status) === 'pending';
	const fileManagerState = useStore(fileManagerModel.$action);
	const shownRemoveModal = fileManagerState === 'REMOVE_FILE_IN_LIST';

	const discardRemove = () => {
		fileManagerModel.pushAction({
			type: null,
			payload: null,
		});
		setMenuIsOpen(true);
	};

	const acceptRemove = (file: FileDto) => {
		onRemoveAccept && onRemoveAccept(file);
		fileManagerModel.pushAction({
			type: null,
		});
		setMenuIsOpen(true);
	};

	return (
		<DropdownControlled
			opened={menuIsOpen || shownRemoveModal}
			setOpened={setMenuIsOpen}
			className={styles.filesMore}
			portalId="task-modal-portal"
			placement="bottomEnd"
			targetButton={
				<Button
					size="sm"
					design="filled"
					color="secondary"
					onClick={() => setMenuIsOpen((prev) => !prev)}
					className={styles.filesMoreBtn}>
					ещё {files.length}
				</Button>
			}
			content={
				shownRemoveModal ? (
					<FileManagerRemoveModal onAccept={acceptRemove} onDiscard={discardRemove} />
				) : (
					<>
						<div className={styles.filesListScroller}>
							{files?.map((file, i) => (
								<FileManagerListItem
									file={file}
									key={file.id}
									onRemove={() => onRemove && onRemove(file)}
									editable={editable}
								/>
							))}
						</div>
						{createFile && editable && (
							<div className={classnames(styles.filesListTools, helpersStyles.buttonsPanel)}>
								<Button
									isLabel={true}
									size="md"
									design="filled"
									color="primary"
									type="button"
									isLoading={uploading}
									className={helpersStyles.buttonsPanelItem}
									iconRight={
										<input onChange={createFile} type="file" className="visually-hidden" />
									}>
									Загрузить файл
								</Button>
							</div>
						)}
					</>
				)
			}
		/>
	);
};
