import { useGate, useStore } from 'effector-react';
import React, { FC, useState } from 'react';
import { useParams, useMatch, useLocation } from 'react-router';

import { FilterType } from '@shared/api';
import { useDebounce } from '@shared/lib/hooks';
import { isEmptyObject } from '@shared/lib/utils';
import { EmptyBanner, modalsModel, createTask } from '@shared/ui';

import { useCurrentUser } from '@entities/current-user';
import { taskListModel } from '@entities/task';

import { removeTask } from '@features/task';

import { Header, headerModel } from '@widgets/header';
import { PageContentWrapper } from '@widgets/page-content-wrapper';
import { TaskBoard } from '@widgets/task-board';
import { TasksGroup } from '@widgets/tasks-group';

import { getUserBoardEmptyMessage } from './lib';
import { TasksPageActions } from './ui';

interface TaskUserPageProps {
	title: string;
	baseRoute: string;
}

interface FilterParams {
	boardId?: string;
	filterType?: FilterType;
}

export const TaskUserPage: FC<TaskUserPageProps> = ({ title: defaultTitle, baseRoute }) => {
	const headerHeight = useStore(headerModel.$height);
	const shownNotifications = !!useMatch('/notifications');

	const { taskId } = useParams();
	const { pathname } = useLocation();
	const { currentUser } = useCurrentUser();

	const [search, setSearch] = useState('');
	const searchQuery = useDebounce(search);

	const [filterParams, setFilterParams] = useState<FilterParams>({});

	const isArchive = pathname.includes('archive');
	const title = isArchive ? 'Архив задач' : defaultTitle;

	const toArchive = `${baseRoute}/archive`;
	const toBaseRoute = isArchive ? baseRoute : undefined;

	const apiEndpoint = baseRoute.includes('created-by-me') ? '/tasks/created-by-me' : '/tasks/my';

	useGate(taskListModel.TasksListGate, {
		url: apiEndpoint,
		archived: isArchive,
		name: isArchive && searchQuery.length > 0 ? searchQuery : undefined,
		...filterParams,
	});

	const { loading, list, columns } = useStore(taskListModel.$status);

	const empty =
		list.length === 0
			? getUserBoardEmptyMessage({
					isArchive,
					isFilteredBySearch: searchQuery.length > 0,
					isCreatedByMe: baseRoute.includes('created-by-me'),
			  })
			: null;

	const ModalGate = modalsModel.ModalGate;

	return (
		<>
			<Header
				title={title}
				backUrl={toBaseRoute}
				topRight={
					<TasksPageActions
						toArchive={toArchive}
						createTask={() => createTask()}
						onFilter={({ value }) => {
							if (value) {
								value === 'ALL' || value === 'NO_BOARD'
									? setFilterParams({ filterType: value })
									: setFilterParams({ boardId: value });
							}
						}}
						onSearch={setSearch}
					/>
				}
			/>

			<PageContentWrapper headerOffset={headerHeight}>
				{!loading && empty ? (
					<EmptyBanner>{empty}</EmptyBanner>
				) : (
					<>
						{isArchive ? (
							<TasksGroup tasks={list} removeTask={removeTask} isLoading={loading} />
						) : (
							<TaskBoard
								viewer={currentUser}
								columns={columns}
								isLoading={loading}
								isEmpty={list.length === 0}
								baseRoute={baseRoute}
							/>
						)}
					</>
				)}
			</PageContentWrapper>

			{taskId && currentUser && !isEmptyObject(currentUser) && (
				<ModalGate
					name="TASK_MODAL"
					data={{
						id: taskId,
						viewer: currentUser,
						mode: isArchive ? 'view' : 'edit',
					}}
				/>
			)}
			{shownNotifications && <ModalGate name="NOTIFICATIONS_MODAL" />}
		</>
	);
};
