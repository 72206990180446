import React, { FC, useState } from 'react';

import { UserDto } from '@shared/api';
import { checkEscape } from '@shared/lib/utils';
import { Button, DropdownControlled, UserInfo } from '@shared/ui';

import styles from './styles.module.scss';

interface TaskAuthorDropDownProps {
	author?: UserDto;
}

export const TaskAuthorDropDown: FC<TaskAuthorDropDownProps> = ({ author }) => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	if (!author) return null;

	return (
		<DropdownControlled
			opened={menuIsOpen}
			setOpened={setMenuIsOpen}
			className={styles.dropDown}
			dropdownClassName={styles.dropDownBox}
			contentClassName={styles.dropDownBoxContent}
			withFocusTrap={false}
			portal={true}
			portalId="task-modal-portal"
			placement="bottomStart"
			targetButton={
				<Button
					size="sm"
					design="transparent"
					color="secondary"
					onClick={() => setMenuIsOpen((prev) => !prev)}
					onKeyDown={(e) => {
						e.stopPropagation();
						if (checkEscape(e)) setMenuIsOpen(false);
					}}
					className={styles.dropDownBtn}>
					Показать автора
				</Button>
			}
			content={<UserInfo user={author} showEmail={true} showJobTitle showDepartment />}
		/>
	);
};
