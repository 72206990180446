/**
 * rpharm-todo
 * rpharm-todo documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TaskReassignRequestDto {
	id?: string;
	status: TaskReassignRequestDtoStatusEnum;
	active?: boolean;
	currentUserConsumerOfTaskReassign?: boolean;
}
export enum TaskReassignRequestDtoStatusEnum {
	OPEN = 'OPEN',
	ACCEPTED = 'ACCEPTED',
	DECLINED = 'DECLINED',
}
