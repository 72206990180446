import { string } from 'yup';

import { TagDto } from '@shared/api';

const REQUIRED_MESSAGE = 'Это поле обязательное';

const STRING_OVERLIMIT_PREFIX = 'Превышено максимальное количество символов:';
const STRING_OVERLIMIT_MESSAGE = `${STRING_OVERLIMIT_PREFIX} 255`;
const STRING_OVERLIMIT_MESSAGE_500 = `${STRING_OVERLIMIT_PREFIX} 500`;

const URL_INVALID_MESSAGE = 'Некорректный формат ссылки';
const EMAIL_INVALID_MESSAGE = 'Некорректный формат email';
const TAG_NAME_ALREADY_EXIST = 'Метка с таким названием существует';

const requiredString = string().trim().required(REQUIRED_MESSAGE);

const requiredUrl = string()
	.trim()
	.required(REQUIRED_MESSAGE)
	.max(500, STRING_OVERLIMIT_MESSAGE_500)
	.matches(
		/^((ftp|http|https):\/\/)?(www\.)?(?!\/)[-a-zA-Zа-яА-Я0-9@:%._+~#=]{1,256}\.[a-zA-Zа-яА-Я0-9]{1,6}([-a-zA-Zа-яА-Я0-9()@:%_+.~#?&/=]*)$/gm,
		URL_INVALID_MESSAGE
	);

const string255 = string().trim().max(255, STRING_OVERLIMIT_MESSAGE);

const limitedRequiredString = (limit = 256) => {
	return string()
		.trim()
		.required(REQUIRED_MESSAGE)
		.max(limit, `${STRING_OVERLIMIT_PREFIX} ${limit}`);
};

const conditionalRequiredString = (condition: boolean) => {
	return string()
		.trim()
		.test(
			'with-file',
			REQUIRED_MESSAGE,
			(value) => condition || (!!value && value.trim().length > 0)
		);
};

const noDuplicateTagName = (tagList: TagDto[], origin?: string) => {
	return string()
		.trim()
		.test('no-duplicate-tag-name', TAG_NAME_ALREADY_EXIST, (value) => {
			return !tagList
				.filter(({ name }) => name !== origin)
				.some(({ name }) => !!value && name.toLowerCase().trim() === value.toLowerCase().trim());
		})
		.max(255, STRING_OVERLIMIT_MESSAGE)
		.required(REQUIRED_MESSAGE);
};

const requiredEmail = string().trim().required(REQUIRED_MESSAGE).email(EMAIL_INVALID_MESSAGE);

export const commonValidators = {
	requiredString,
	requiredUrl,
	string255,
	limitedRequiredString,
	conditionalRequiredString,
	noDuplicateTagName,
	requiredEmail,
};
