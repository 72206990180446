import { combine, createEffect, createEvent, createStore, sample } from 'effector';

import { ToggleBoardStarredRequest, boardsRequests, BoardPreviewDto } from '@shared/api';
import { noticesModel } from '@shared/ui';

import { boardsModel } from '@entities/boards/model';

const toggleBoardStarred = createEvent<ToggleBoardStarredRequest>();
const toggleBoardStarredFx = createEffect(boardsRequests.toggleBoardStarred);

toggleBoardStarredFx.doneData.watch((result) => {
	noticesModel.add({
		type: 'success',
		text: result.starred
			? `Доска задач "<b>${result.name}</b>" была добавлена в избранное.`
			: `Доска задач "<b>${result.name}</b>" была убрана из избранного.`,
	});
});

toggleBoardStarredFx.failData.watch((result) => {
	noticesModel.add({
		type: 'error',
		text: `Что-то пошло не так, попробуйте позже.`,
	});
});

const $config = createStore<ToggleBoardStarredRequest | null>(null).reset(toggleBoardStarred);
const $board = createStore<BoardPreviewDto | null>(null).reset(toggleBoardStarred);
const $error = createStore<Error | null>(null).reset(toggleBoardStarred);

const $toggleBoardStarredStatus = combine({
	pending: combine($config, toggleBoardStarredFx.pending, (config, pending) =>
		pending && config?.boardId ? config.boardId : false
	),
	data: $board,
	error: $error,
});

sample({
	clock: toggleBoardStarred,
	target: [$config, toggleBoardStarredFx],
});

sample({
	clock: toggleBoardStarredFx.done,
	source: boardsModel.$boardsListConfig,
	target: boardsModel.getBoardsList,
});

sample({
	clock: toggleBoardStarredFx.failData,
	target: $error,
});

sample({
	clock: toggleBoardStarredFx.doneData,
	target: $board,
});

export const model = {
	toggleBoardStarred,
	toggleBoardStarredFx,
	$toggleBoardStarredStatus,
};
