import React, { Dispatch, FC, HTMLAttributes, KeyboardEventHandler, SetStateAction } from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';

import { checkSpace } from '@shared/lib/utils';
import { Button, Icon } from '@shared/ui';

import { ru, gregorianRu, datesIsEqual } from './lib';
import styles from './styles.module.scss';

interface DatePickerProps {
	dates: Date[] | null;
	setDates: Dispatch<SetStateAction<Date[] | null>>;
	setDatesCallback?: (dates: Date[] | null) => void;
	multiple?: boolean;
}

const renderButton = (direction: 'right' | 'left', handleClick: () => void) => (
	<Button
		size="sm"
		design="filled"
		color="secondary"
		onlyIcon={<Icon id={`chevron-${direction}`} style={{ stroke: 'none', fill: 'currentColor' }} />}
		onClick={handleClick}
	/>
);

export const DatePicker: FC<DatePickerProps> = ({
	dates,
	setDates,
	setDatesCallback,
	multiple = false,
}) => {
	const settings = {
		locale: ru,
		calendar: gregorianRu,
		weekStartDayIndex: 0,
		disableMonthPicker: true,
		disableYearPicker: true,
		className: styles.calendar,
		renderButton: renderButton,
		minDate: new Date(),
	};

	const goToNextTabbableDay = (target: HTMLElement, index: number) => {
		const month = target.closest('.rmdp-day-picker');
		const nextTarget = month?.querySelector<HTMLSpanElement>(`[data-index="${index}"]`);
		if (nextTarget) nextTarget.focus();
	};

	const focusOut = (direction: number) => {
		const focusable =
			'.rmdp-day-picker, a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"]):not(.sd)';
		const focussableElements = Array.from(document.querySelectorAll<HTMLElement>(focusable));
		const picker = document.querySelector<HTMLElement>('.rmdp-day-picker');
		const pickerIndex = picker && focussableElements.indexOf(picker);

		if (pickerIndex) {
			focussableElements[pickerIndex + direction]?.focus();
		}
	};

	const keydownHandler: KeyboardEventHandler<HTMLElement> = (e) => {
		const target = e.target as HTMLElement;

		/*
		 * Работа с табуляцией
		 */
		if (target.closest('.rmdp-day-picker')) {
			if (e.key === 'Tab') {
				e.preventDefault();
				focusOut(1);
			}
			if (e.key === 'Tab' && e.shiftKey) {
				e.preventDefault();
				focusOut(-1);
			}
		}

		/*
		 * Навигация стрелками в рамках календаря
		 */
		if (e.key === 'ArrowRight') {
			goToNextTabbableDay(target, Number(target.dataset.index) + 1);
		}
		if (e.key === 'ArrowLeft') {
			goToNextTabbableDay(target, Number(target.dataset.index) - 1);
		}
		if (e.key === 'ArrowDown') {
			goToNextTabbableDay(target, Number(target.dataset.index) + 7);
		}
		if (e.key === 'ArrowUp') {
			goToNextTabbableDay(target, Number(target.dataset.index) - 7);
		}

		/*
		 * Вызов клика по пробелу
		 */
		if (checkSpace(e) && target.getAttribute('tabindex')) {
			e.preventDefault();
			target.click();
		}
	};

	return (
		<div className={styles.calendarAriaWrap} onKeyDownCapture={keydownHandler}>
			{!multiple && (
				<Calendar
					value={dates && dates[0]}
					onChange={(val: DateObject | null) => {
						setDates((prev) => {
							if (!val) return null;
							const date = val.toDate();
							const startDate = prev && prev[0] && datesIsEqual(date, prev[0]) ? null : date;
							return startDate ? [startDate] : null;
						});
					}}
					mapDays={({ date }) => {
						const today = new Date(new Date().setHours(0, 0, 0, 0));
						if (date.toDate() >= today) {
							return {
								tabIndex: 0,
								'data-index': date.day,
							};
						}
					}}
					{...settings}
				/>
			)}
			{multiple && (
				<Calendar
					range={true}
					value={dates}
					onChange={(val: DateObject[] | null) => {
						setDates((prev) => {
							if (!val) return null;
							const dates = val.map((d) => d.toDate());

							if (prev?.length === 1 && dates) {
								const isSelectedDate =
									datesIsEqual(prev[0], dates[0]) && datesIsEqual(prev[0], dates[1]);

								if (isSelectedDate) return null;
							}

							if (prev?.length === 2 && dates) {
								const isStartDate = datesIsEqual(prev[0], dates[0]);
								const isEndDate = datesIsEqual(prev[1], dates[0]);

								if (isStartDate) {
									setDatesCallback && setDatesCallback([prev[1]]);
									return [prev[1]];
								}
								if (isEndDate) {
									setDatesCallback && setDatesCallback([prev[0]]);
									return [prev[0]];
								}
							}

							setDatesCallback && setDatesCallback(dates);

							return dates;
						});
					}}
					mapDays={({ date, selectedDate, currentMonth }) => {
						const props: HTMLAttributes<HTMLSpanElement> & { 'data-index'?: number } = {};

						const today = new Date(new Date().setHours(0, 0, 0, 0));
						if (date.toDate() >= today) {
							props.tabIndex = 0;
							props['data-index'] = date.day;
						}

						if (Array.isArray(selectedDate)) {
							const point = new Date(date.toDate()).setHours(0, 0, 0, 0);
							const range = selectedDate.map((d) => d.toDate().setHours(0, 0, 0, 0));

							if (selectedDate.length === 2) {
								const classes = [];

								const firstSelectedDate = selectedDate[0].toDate();
								const secondSelectedDate = selectedDate[1].toDate();

								const nearbyDates =
									firstSelectedDate.getMonth() === secondSelectedDate.getMonth() &&
									firstSelectedDate.getFullYear() === secondSelectedDate.getFullYear() &&
									secondSelectedDate.getDate() - firstSelectedDate.getDate() === 1;

								if (point === range[0]) {
									classes.push('start');
									if (nearbyDates) {
										classes.push('nearby');
									}
								}

								if (point === range[1]) {
									classes.push('end');
								}

								if (point > range[0] && point < range[1]) {
									classes.push('inter');

									// @ts-ignore
									if (date.toDate().getDate() === currentMonth.length) classes.push('last');
									if (date.toDate().getDate() === 1) classes.push('first');
								}

								props.className = classes.join(' ');
							}

							if (selectedDate.length === 1) {
								if (point === range[0]) {
									props.className = 'single';
								}
							}
						}

						return props;
					}}
					{...settings}
				/>
			)}
		</div>
	);
};
