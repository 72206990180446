import { createEvent, createStore, sample, forward } from 'effector';
import { createGate } from 'effector-react';

interface TaskDates {
	endDate?: string;
	startDate?: string;
	deadlineReminderDate?: string;
}

export const datesFactory = () => {
	const setDates = createEvent<TaskDates>();
	const setLocalDates = createEvent<TaskDates>();
	const setDatesFromLocal = createEvent();
	const resetDates = createEvent();

	const $dates = createStore<TaskDates>({}).reset(resetDates);
	const $localDates = createStore<TaskDates>({}).reset(resetDates);

	sample({
		clock: setDates,
		source: $dates,
		fn: (source, clock) => {
			return {
				...source,
				...clock,
			};
		},
		target: $dates,
	});

	sample({
		clock: setLocalDates,
		source: $localDates,
		fn: (source, clock) => {
			return {
				...source,
				...clock,
			};
		},
		target: $localDates,
	});

	sample({
		clock: setDatesFromLocal,
		source: $localDates,
		target: $dates,
	});

	const InitialDatesGate = createGate<TaskDates>();

	forward({
		from: InitialDatesGate.open,
		to: setDates,
	});

	return {
		$dates,
		$localDates,
		setDates,
		setLocalDates,
		setDatesFromLocal,
		resetDates,
		InitialDatesGate,
	};
};

export type DatesFactoryTypes = ReturnType<typeof datesFactory>;
