import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC, useState } from 'react';

import { NotificationDtoTypeEnum, TaskApprovalDtoStatusEnum } from '@shared/api';
import {
	Button,
	DropdownControlled,
	Icon,
	NoticeLabel,
	Typo,
	NotificationApprovalMessage,
	NotificationApprovalMessageRead,
	modalsModel,
} from '@shared/ui';

import { notificationApprovalModel } from '@entities/notifications';

import { NotificationToolsProps } from '../notification-tools';
import styles from '../styles.module.scss';

export interface NotificationApprovalProps
	extends Pick<
		NotificationToolsProps,
		'notification' | 'goToTask' | 'approveTask' | 'disapproveTask' | 'viewer'
	> {}

export const NotificationApproval: FC<NotificationApprovalProps> = ({
	notification,
	approveTask,
	disapproveTask,
	goToTask,
}) => {
	const pending = useStore(notificationApprovalModel.$pending);

	const [notificationElement, setNotificationElement] = useState<Nullable<HTMLElement>>(null);
	const [messageDropOpen, setMessageDropOpen] = useState(false);
	const [messageReadDropOpen, setMessageReadDropOpen] = useState(false);

	const userIsTaskAuthor = notification?.task?.authorities?.isAuthor;
	const reviewWasFinished = notification?.type === NotificationDtoTypeEnum.REVIEW_FINISHED;

	const disapproveButton = (
		<DropdownControlled
			opened={messageDropOpen}
			setOpened={setMessageDropOpen}
			dropdownClassName={styles.createMessage}
			portal={true}
			portalId="notifications-modal-portal"
			placement="rightStart"
			externalReference={notificationElement}
			targetButton={
				<Button
					size="md"
					design="filled"
					color="secondary"
					className={styles.button}
					onClick={() => {
						modalsModel.closeSubModal();
						setMessageDropOpen((prev) => !prev);
					}}
					ref={(node) => {
						setNotificationElement(
							(node?.closest('.notification') ?? null) as Nullable<HTMLElement>
						);
					}}>
					В работу
				</Button>
			}
			content={
				<NotificationApprovalMessage
					onAccept={(data) => {
						disapproveTask &&
							disapproveTask(data).then(() => {
								setMessageDropOpen(false);
							});
					}}
					onDiscard={() => setMessageDropOpen(false)}
					isPending={pending.decline}
				/>
			}
		/>
	);

	const approveButton = (
		<Button
			size="md"
			design="filled"
			color="primary"
			className={styles.button}
			onClick={() => {
				approveTask &&
					approveTask().then(() => {
						setMessageDropOpen(false);
					});
			}}
			isLoading={pending.approve}>
			Выполнено
		</Button>
	);

	switch (notification?.taskApproval?.status) {
		case TaskApprovalDtoStatusEnum.OPEN:
			return (
				<>
					{!userIsTaskAuthor && (
						<>
							{disapproveButton}
							{approveButton}
						</>
					)}
				</>
			);
		case TaskApprovalDtoStatusEnum.APPROVED:
			return (
				<>
					{!reviewWasFinished && (
						<>
							{disapproveButton}
							<NoticeLabel text="Согласовано" className={styles.acceptNotice} accepted={true} />
						</>
					)}
					{reviewWasFinished && (
						<Button
							size="md"
							design="filled"
							color="secondary"
							className={classnames(styles.button, styles.buttonWithArrow)}
							iconRight={<Icon id="arrow-right" />}
							onClick={() => goToTask && goToTask()}>
							Перейти
						</Button>
					)}
				</>
			);
		case TaskApprovalDtoStatusEnum.DECLINED:
			return (
				<>
					{!reviewWasFinished && (
						<>
							{approveButton}
							<NoticeLabel text="Не согласовано" className={styles.acceptNotice} accepted={false} />
						</>
					)}
					{reviewWasFinished && (
						<>
							<Button
								size="md"
								design="filled"
								color="secondary"
								className={classnames(styles.button, styles.buttonWithArrow)}
								iconRight={<Icon id="arrow-right" />}
								onClick={() => goToTask && goToTask()}>
								Перейти
							</Button>
							{notification?.taskApproval?.message && (
								<DropdownControlled
									opened={messageReadDropOpen}
									setOpened={setMessageReadDropOpen}
									className={styles.buttonLinkWrap}
									dropdownClassName={styles.readMessage}
									portal={true}
									portalId="notifications-modal-portal"
									placement="rightStart"
									targetButton={
										<button
											type="button"
											className={styles.buttonLink}
											onClick={() => setMessageReadDropOpen((prev) => !prev)}>
											<Typo as="span" design="text-13-medium">
												Открыть сообщение
											</Typo>
										</button>
									}
									content={
										<NotificationApprovalMessageRead
											message={notification?.taskApproval?.message}
											onDiscard={() => setMessageReadDropOpen(false)}
										/>
									}
								/>
							)}
						</>
					)}
				</>
			);
		default:
			return null;
	}
};
