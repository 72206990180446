import { useFloating, shift, offset, Side, Alignment } from '@floating-ui/react-dom';
import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import { Tooltip } from '@shared/ui';

import styles from './styles.module.scss';

interface TooltipWrapProps extends HTMLAttributes<HTMLElement> {
	as?: 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	content: React.ReactNode;
	align?: Alignment;
	placement?: Side;
	margin?: string | number;
	tooltipClassName?: string;
	portal?: boolean;
}

export const TooltipWrap: FC<TooltipWrapProps> = ({
	as: Tag = 'div',
	content,
	align,
	placement = 'bottom',
	style,
	margin,
	tooltipClassName,
	className,
	children,
}) => {
	const { x, y, reference, floating, strategy } = useFloating({
		placement: align ? `${placement}-${align}` : placement,
		strategy: 'fixed',
		middleware: [shift({ padding: 5 }), offset(16)],
	});

	return (
		<Tag ref={reference} style={style} className={classNames(className, styles.tooltipWrap)}>
			{children}
			{content && (
				<Tooltip
					ref={floating}
					style={{
						position: strategy,
						top: y ?? 0,
						left: x ?? 0,
						margin,
					}}
					className={tooltipClassName}
					aria-hidden={true}>
					{content}
				</Tooltip>
			)}
		</Tag>
	);
};
