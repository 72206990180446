import classnames from 'classnames';
import React from 'react';

import styles from '../styles.module.scss';

export const EmptyBoardButton = ({ onClick }: { onClick: () => void }) => {
	return (
		<button onClick={onClick} className={classnames(styles.board, styles.emptyBoard)}>
			<span>Создать доску</span>
		</button>
	);
};
