import { format, isValid } from 'date-fns';

const EXPIRED = 'Просрочена';
const EXPIRES_PREFIX = 'Истекает';

export const getDeadlineText = (endDateIso?: string, createDateIso?: string) => {
	if (!endDateIso || !createDateIso) return EXPIRES_PREFIX;

	const date = new Date(endDateIso);
	const today = new Date(createDateIso);

	if (!isValid(date) || !isValid(today)) return EXPIRES_PREFIX;

	const timestamp = date.getTime();
	const dateTimestamp = new Date(date).setHours(0, 0, 0, 0);

	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);

	const timeString = format(date, 'HH:mm');
	let dateString = format(date, 'dd.MM.yyyy');

	const isToday = dateTimestamp === new Date(today).setHours(0, 0, 0, 0);
	const isTomorrow = dateTimestamp === new Date(tomorrow).setHours(0, 0, 0, 0);

	if (timestamp < today.getTime()) {
		return EXPIRED;
	}

	if (isToday) {
		dateString = 'сегодня';
	} else if (isTomorrow) {
		dateString = 'завтра';
	}

	return `${EXPIRES_PREFIX} ${dateString} в ${timeString}`;
};
