import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { RemovalWindow } from '@entities/removal-window';
import { removeTaskModel } from '@entities/task';

interface RemoveBoardModalProps {
	onAccept: () => void;
	title?: React.ReactNode;
	text?: React.ReactNode;
}

const Component: FC<RemoveBoardModalProps> = ({ title, text, onAccept }) => {
	const isPending = useStore(removeTaskModel.$status) === 'pending';

	return <RemovalWindow onAccept={onAccept} title={title} text={text} isPending={isPending} />;
};

export const RemoveTaskModal = {
	name: 'REMOVE_TASK_MODAL',
	Component,
};
