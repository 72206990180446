import classnames from 'classnames';
import React, {
	FC,
	HTMLAttributes,
	MouseEvent,
	MouseEventHandler,
	PropsWithChildren,
	ReactNode,
	useCallback,
	useMemo,
} from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@shared/ui';

import styles from './styles.module.scss';

export type ButtonSizeType = 'sm' | 'md';
export type ButtonDesignType = 'filled' | 'transparent' | 'underlined' | 'no-style';
export type ButtonColorType = 'primary' | 'secondary' | 'danger';

interface ButtonIconInterface {
	position: 'left' | 'right' | 'center';
}

export interface ButtonInterface
	extends HTMLAttributes<HTMLElement>,
		Partial<Pick<HTMLAnchorElement, 'download'>> {
	size?: ButtonSizeType;
	design?: ButtonDesignType;
	color?: ButtonColorType;
	type?: 'button' | 'submit';
	className?: string;
	to?: string;
	href?: string;
	target?: '_blank';
	onClick?: MouseEventHandler | ((e: MouseEvent<HTMLElement>) => void);
	iconLeft?: ReactNode;
	iconRight?: ReactNode;
	onlyIcon?: ReactNode;
	isLabel?: boolean;
	isLoading?: boolean;
	disabled?: boolean;
}
interface ButtonContentInterface
	extends Pick<ButtonInterface, 'onlyIcon' | 'iconLeft' | 'iconRight' | 'isLoading'> {}

const ButtonIcon: FC<PropsWithChildren<ButtonIconInterface>> = ({ position, children }) => {
	return <>{children}</>;
};

const ButtonContent: FC<PropsWithChildren<ButtonContentInterface>> = ({
	onlyIcon,
	iconLeft,
	iconRight,
	children,
}) => {
	return (
		<>
			{onlyIcon && <ButtonIcon position="center">{onlyIcon}</ButtonIcon>}
			{!onlyIcon && iconLeft && <ButtonIcon position="left">{iconLeft}</ButtonIcon>}
			{!onlyIcon && (
				<span datatype="btntext" className={styles.text}>
					{children}
				</span>
			)}
			{!onlyIcon && iconRight && <ButtonIcon position="right">{iconRight}</ButtonIcon>}
		</>
	);
};

export const Button = React.memo(
	React.forwardRef<HTMLButtonElement, PropsWithChildren<ButtonInterface>>(
		(
			{
				size = 'md',
				design = 'filled',
				color = 'primary',
				type = 'button',
				className,
				to,
				href,
				target,
				download,
				onClick,
				onlyIcon,
				iconLeft,
				iconRight,
				isLabel,
				isLoading,
				disabled,
				children,
				...props
			},
			forwardedRef
		) => {
			const content = useMemo(
				() => (
					<ButtonContent onlyIcon={onlyIcon} iconLeft={iconLeft} iconRight={iconRight}>
						{children}
					</ButtonContent>
				),
				[onlyIcon, iconLeft, iconRight, children]
			);
			const classname = useMemo(() => {
				return classnames(
					styles.btn,
					styles[size],
					styles[design],
					styles[color],
					onlyIcon && styles.onlyIcon,
					isLoading && styles.isLoading,
					disabled && styles['is-disabled'],
					className
				);
			}, [size, design, color, onlyIcon, isLoading, disabled, className]);

			const clickHandler: MouseEventHandler<HTMLElement> = useCallback(
				(e) => {
					onClick && onClick(e);
				},
				[onClick]
			);

			if (to) {
				return (
					<Link to={to} className={classname} {...props}>
						{content}
					</Link>
				);
			}

			if (href) {
				return (
					<a
						className={classname}
						onClick={clickHandler}
						href={href}
						target={target}
						rel="noreferrer noopener"
						download={download}
						{...props}>
						{content}
					</a>
				);
			}

			if (isLabel) {
				return (
					<label className={classname} {...props}>
						{isLoading && (design === 'filled' || design === 'transparent') && (
							<span className={styles.loadedIcon}>
								<Icon id="loading" />
							</span>
						)}
						{content}
					</label>
				);
			}

			return (
				<button
					ref={forwardedRef}
					onClick={clickHandler}
					aria-label="button"
					type={type}
					className={classname}
					disabled={disabled}
					{...props}>
					{isLoading && (design === 'filled' || design === 'transparent') && (
						<span className={styles.loadedIcon}>
							<Icon id="loading" />
						</span>
					)}
					{content}
				</button>
			);
		}
	)
);
