import { createEvent, createStore, sample } from 'effector';

import { TagDto } from '@shared/api';

const setTags = createEvent<TagDto[]>();
const resetTags = createEvent();

const addTag = createEvent<TagDto>();
const removeTag = createEvent<TagDto>();
const toggleTag = createEvent<TagDto>();
const updateTag = createEvent<TagDto>();

const $tags = createStore<TagDto[]>([]).reset(resetTags);

sample({
	clock: setTags,
	target: $tags,
});

sample({
	clock: addTag,
	source: $tags,
	fn: (store, add) => {
		return [...store, add];
	},
	target: $tags,
});

sample({
	clock: removeTag,
	source: $tags,
	fn: (store, remove) => {
		return store.filter(({ id }) => id !== remove.id);
	},
	target: $tags,
});

sample({
	clock: toggleTag,
	source: $tags,
	fn: (store, tag) => {
		const includes = store.findIndex(({ id }) => id === tag.id) > -1;
		return includes ? store.filter(({ id }) => id !== tag.id) : [...store, tag];
	},
	target: $tags,
});

sample({
	clock: updateTag,
	source: $tags,
	fn: (store, updateTag) => {
		return store?.map((tag) => (tag.id === updateTag.id ? updateTag : tag));
	},
	target: $tags,
});

export const model = {
	$tags,
	setTags,
	resetTags,
	addTag,
	removeTag,
	toggleTag,
	updateTag,
};
