import { combine, createEffect, createEvent, createStore, restore, sample } from 'effector';

import { DeleteBoardRequest, boardsRequests } from '@shared/api';
import { routerModel } from '@shared/model';
import { modalsModel, noticesModel } from '@shared/ui';

import { boardsModel } from '@entities/boards';

interface DeleteBoardArgs {
	body: DeleteBoardRequest;
	revalidateList?: boolean;
}

const deleteBoard = createEvent<DeleteBoardArgs>();
const deleteBoardFx = createEffect(boardsRequests.deleteBoard);

const $deletedBoard = createStore<DeleteBoardRequest | null>(null).reset(deleteBoard);
const $deleteBoardError = restore<Error>(deleteBoardFx.failData, null).reset(deleteBoard);

const $deleteBoardStatus = combine({
	pending: deleteBoardFx.pending,
	response: $deletedBoard,
	error: $deleteBoardError,
});

const $shouldRevalidate = createStore(false).reset(deleteBoard);

sample({
	clock: deleteBoard,
	fn: ({ revalidateList }) => !!revalidateList,
	target: $shouldRevalidate,
});

sample({
	clock: deleteBoard,
	fn: ({ body }) => body,
	target: [$deletedBoard, deleteBoardFx],
});

const revalidateBoardsList = createEvent();

sample({
	clock: deleteBoardFx.doneData,
	source: $shouldRevalidate,
	filter: (shouldRevalidate) => !!shouldRevalidate,
	target: revalidateBoardsList,
});

sample({
	clock: revalidateBoardsList,
	source: boardsModel.$boardsListConfig,
	target: boardsModel.getBoardsList,
});

sample({
	clock: deleteBoardFx.doneData,
	source: $deletedBoard,
	fn: (data) => ({
		type: 'success' as const,
		text: `Доска задач "<b>${data?.boardName}</b>" удалена.`,
	}),
	target: [modalsModel.closeLastModal, noticesModel.add],
});

sample({
	clock: deleteBoardFx.doneData,
	fn: () => `/boards/archive`,
	target: routerModel.push,
});

sample({
	clock: deleteBoardFx.failData,
	fn: () => ({
		type: 'error' as const,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const deleteBoardFeature = {
	deleteBoard: deleteBoard,
	deleteBoardFx,
	$deleteBoardStatus,
};
