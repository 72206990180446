import { fetcher } from '@shared/api/config';
import { TaskApprovalDto } from '@shared/api/model/models';

export interface UpdateTaskApprovalArgs extends TaskApprovalDto {}

const updateTaskApproval = (taskApproval: UpdateTaskApprovalArgs) =>
	fetcher<TaskApprovalDto>({
		url: `/task-approvals/${taskApproval.id}`,
		method: 'PUT',
		body: taskApproval,
	});

export const taskApprovalRequests = {
	updateTaskApproval,
};
