import { format } from 'date-fns';

function declension(forms: string[], val: number) {
	const cases = [2, 0, 1, 1, 1, 2];
	return forms[val % 100 > 4 && val % 100 < 20 ? 2 : cases[val % 10 < 5 ? val % 10 : 5]];
}

export const getFormattedUpdateDate = (date: string, actionName = 'Изменено') => {
	try {
		const now = new Date();
		const updateDate = new Date(date);
		const timeHasPassed = Number(now) - Number(updateDate);
		const minutesHasPassed = Math.floor(timeHasPassed / 1000 / 60);
		const hoursHasPassed = Math.floor(minutesHasPassed / 60);

		if (minutesHasPassed < 1) {
			return `${actionName} только что`;
		} else if (minutesHasPassed < 1) {
			return `${actionName} минуту назад`;
		} else if (minutesHasPassed < 60) {
			return `${actionName} 
						${minutesHasPassed} 
						${declension(['минуту', 'минуты', 'минут'], minutesHasPassed)} 
						назад`;
		} else if (hoursHasPassed < 24) {
			return `${actionName} 
						${hoursHasPassed} 
						${declension(['час', 'часа', 'часов'], hoursHasPassed)} 
						назад`;
		} else {
			return `${actionName} ${format(updateDate, 'dd.MM.yyyy')}`;
		}
	} catch (err) {
		return date;
	}
};
