import classNames from 'classnames';
import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, {
	MouseEventHandler,
	ChangeEventHandler,
	useMemo,
	useState,
	useRef,
	useEffect,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SubtaskDto, TaskStatusDtoTypeEnum, TaskDto } from '@shared/api';
import { TaskStatus } from '@shared/lib/constants';
import { useOutsideClick } from '@shared/lib/hooks';
import { toolsMenuStyles } from '@shared/lib/styles';
import { replaceBoardsUrlPattern, replaceTaskUrlPattern } from '@shared/lib/utils';
import { setFocusToId, checkEscape } from '@shared/lib/utils';
import {
	Button,
	FormControl,
	Icon,
	Tag,
	tooltipEventHandlersFactory,
	TooltipsEventHandlers,
	Dropdown,
	ConfirmModalHead,
	ConfirmModalBody,
	viewTask,
} from '@shared/ui';

import { useCurrentUser } from '@entities/current-user';
import { subtasksModel, detachLinkedTaskModel, taskListModel, taskModel } from '@entities/task';

import { updateTaskFeature, useToggleSubtaskStatus } from '@features/task';
import { useUserRoles } from '@features/user-roles';

import { getDateForTag } from '@widgets/task-modal/lib';

import { SubtaskAssignees } from '../subtask-assignees';

import styles from './styles.module.scss';

interface LinkedTaskProps {
	subtask: Partial<SubtaskDto>;
	subtasks: Partial<SubtaskDto>[];
	index: number;
	onRemove: (subtask: SubtaskDto, callback: () => void) => void;
	task?: Nullable<TaskDto>;
}

type ToolsDdType = 'main' | 'remove' | 'unlink';

export const LinkedTask = ({ subtask, subtasks, index, onRemove, task }: LinkedTaskProps) => {
	const {
		userOnBoard: { isAuthor: isParentBoardAuthor, isEditor: isParentBoardEditor },
		userOnTask: {
			isAuthor: isParentTaskAuthor,
			isAssignee: isParentTaskAssignee,
			isCoAssignee: isParentTaskCoAssignee,
		},
	} = useUserRoles();

	const { toggleSubtaskStatus } = useToggleSubtaskStatus();

	const toolsRef = useRef(null);
	const [toolsDd, setToolsDd] = useState<Nullable<ToolsDdType>>(null);

	const [subtaskIsDone, setSubtaskIsDone] = useState(
		subtask.linkedTask?.status?.type === TaskStatusDtoTypeEnum.DONE
	);

	const deleteStatus = useStore(subtasksModel.$deleteSubtaskStatus);
	const detachStatus = useStore(detachLinkedTaskModel.$status);

	useOutsideClick(toolsRef, () => setToolsDd(null));

	useEffect(() => {
		if (detachStatus === 'done') {
			setToolsDd(null);
		}
	}, [detachStatus]);

	const actualEndDate = subtask.linkedTask?.endDate || subtask.dueDate;

	const dateTooltipEventHandlers: TooltipsEventHandlers = useMemo(() => {
		const startDate = getDateForTag(subtask.linkedTask?.startDate);
		const endDate = getDateForTag(subtask.linkedTask?.endDate);
		const dateTooltipText = startDate && endDate ? `${startDate} ⟶ ${endDate}` : endDate;

		return dateTooltipText ? tooltipEventHandlersFactory(dateTooltipText!, { delayIn: 300 }) : {};
	}, [subtask.linkedTask?.endDate, subtask.linkedTask?.startDate]);

	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { currentUser: viewer } = useCurrentUser();

	const isArchiveBoard = pathname.includes('/boards/archive');
	const isArchiveTask = pathname.includes('/archive/task');

	const handleLinkedTaskClick: MouseEventHandler = (e) => {
		e.preventDefault();

		setToolsDd(null);

		const { id, board, title = '' } = subtask.linkedTask || {};
		const hasRoleOnBoardOfTask = !!board?.currentParticipantBoardRole;

		if (id) {
			/* *
			 * Сначала реплейсим id задачи роуте.
			 * Затем, если есть роль на доске подзадачи, то реплейсим:
			 * 	если роут содержит /boards/{id текущей доски}, то заменяем его на /boards/{id доски даннойподзадачи}
			 * 	если не содержит, то заменяем на /my-tasks
			 */

			/**
			 * Проверяем одного ли состояния родительская и дочерняя задачи
			 */
			const tasksOfOneState = task?.archived === subtask.linkedTask?.archived;

			const replacedTaskInPathname = replaceTaskUrlPattern(pathname, id, tasksOfOneState);

			const replacedPathname = hasRoleOnBoardOfTask
				? replaceBoardsUrlPattern(
						replacedTaskInPathname,
						hasRoleOnBoardOfTask && board?.id,
						'/my-tasks'
				  )
				: replacedTaskInPathname;

			viewTask({ id, title, viewer, mode: subtask.linkedTask?.archived ? 'view' : 'edit' });
			navigate(replacedPathname, { replace: false });
		}
	};

	const handleLinkedTaskCheckbox: ChangeEventHandler = (e) => {
		const checked = (e.target as HTMLInputElement).checked;
		const nextStatus = (checked ? 'DONE' : 'NEW') as TaskStatusDtoTypeEnum;

		toggleSubtaskStatus({
			taskId: subtask.linkedTask?.id!,
			subtaskId: subtask.id!,
			subtaskTitle: subtask.linkedTask?.title,
			nextStatus,
			callback: (newStatusForTask) => {
				setSubtaskIsDone(checked);
				subtasksModel.toggleCompletedSubtask({ value: checked, status: newStatusForTask, index });

				if (task) {
					const newSubtasks = subtasks?.map((item) =>
						item.id === subtask?.id
							? {
									...item,
									linkedTask: { ...item.linkedTask, status: newStatusForTask },
									completed: checked,
							  }
							: item
					) as SubtaskDto[];

					updateTaskFeature.mutateTaskPreview({
						...task,
						subtasks: newSubtasks,
					});

					taskModel.updateTaskStore({ subtasks: newSubtasks });
				}
			},
			fallback: (prevTaskStatus) => {
				setSubtaskIsDone(!checked);
				subtasksModel.toggleCompletedSubtask({ value: !checked, status: prevTaskStatus, index });
			},
		});
	};

	/* *
	 * Определяем, архивная или нет
	 */
	const isArchiveLinkedTask = subtask.linkedTask?.archived;

	/* *
	 * Определяем роль текущего пользователя для связанной задачи
	 */
	const { isBoardAuthor, isBoardEditor, isBoardViewer, isAuthor, isAssignee, isCoAssignee } =
		subtask.linkedTask?.authorities || {};

	/* *
	 * Если автор связанной задачи — это текущий пользователь,
	 * то он может чекнуть связанную, но только в случае, если она уже "на согласовании"
	 * Если связанная задача с флагом requireApprove имеет статус "выполнена",
	 * то чекбокс также не блокируется (можно вернуть в статус "новые")
	 */
	const requiredApprove =
		!!subtask.linkedTask?.requireApprove &&
		!(isAuthor && subtask.linkedTask?.status?.type === TaskStatusDtoTypeEnum.REVIEW) &&
		!subtaskIsDone;

	const userCanSetSubtaskStatus =
		!isArchiveBoard &&
		!isArchiveTask &&
		!isArchiveLinkedTask &&
		!requiredApprove &&
		(isBoardAuthor || isBoardEditor || isAuthor || isAssignee || isCoAssignee);

	const toolsAvailable =
		!isArchiveBoard &&
		!isArchiveTask &&
		(isParentBoardAuthor ||
			isParentBoardEditor ||
			isParentTaskAuthor ||
			isParentTaskAssignee ||
			isAuthor ||
			isAssignee ||
			isCoAssignee);

	const detachAvailable =
		isParentBoardAuthor ||
		isParentBoardEditor ||
		isParentTaskAuthor ||
		isParentTaskAssignee ||
		(isParentTaskCoAssignee && isAssignee) ||
		isAuthor;

	const editingAvailable = isBoardAuthor || isBoardEditor || isAuthor || isAssignee;

	const deletionAvailable = (isBoardAuthor || isBoardEditor || isAuthor) && !isArchiveLinkedTask;

	const linkedTaskAvailableToView =
		isAuthor || isBoardEditor || isBoardViewer || isAssignee || isCoAssignee;

	/* *
	 * Хэндлеры для тултипа чекбкоса
	 */
	const checkboxTooltipEventHandlers: TooltipsEventHandlers = useMemo(() => {
		return requiredApprove
			? tooltipEventHandlersFactory('Задача требует согласования', { delayIn: 300 })
			: {};
	}, [requiredApprove]);

	return (
		<div className={styles.linkedTask}>
			<div className={styles.leftColumn}>
				<div className={styles.checkbox} {...checkboxTooltipEventHandlers}>
					<FormControl
						type="checkbox"
						checked={subtaskIsDone}
						onChange={handleLinkedTaskCheckbox}
						disabled={!userCanSetSubtaskStatus}
					/>
				</div>
				<div className={styles.taskName}>
					<div className={styles.icon}>
						<Icon id="linked-task" />
					</div>

					<Tag className={styles.status}>
						{isArchiveLinkedTask
							? 'В архиве'
							: subtask.linkedTask?.status?.type && TaskStatus[subtask.linkedTask.status.type]}
					</Tag>

					<button
						type="button"
						className={classNames(styles.link, {
							[styles.checkboxDone]: subtaskIsDone,
							[styles.disabled]: !linkedTaskAvailableToView,
						})}
						onClick={handleLinkedTaskClick}
						disabled={!linkedTaskAvailableToView}>
						{subtask.linkedTask?.title || subtask.title}
					</button>
				</div>
			</div>

			<div className={styles.rightColumn}>
				{subtask.assignee && (
					<SubtaskAssignees
						editable={true}
						assignee={subtask.assignee}
						disabled={isArchiveLinkedTask}
					/>
				)}

				{actualEndDate && (
					<Tag
						className={classNames(styles.date, { [styles.disabled]: isArchiveLinkedTask })}
						{...dateTooltipEventHandlers}>
						<Icon id="clock" />
						{getDateForTag(actualEndDate, true)}
					</Tag>
				)}

				<Dropdown
					isOpen={!!toolsDd}
					openOnButtonClick={false}
					closeOnClickOutside={false}
					placement="bottomEnd"
					targetButton={
						<Button
							id={`subtask-tools-button${subtask.id}`}
							size="sm"
							design="filled"
							color="secondary"
							onlyIcon={<Icon id="kebab" />}
							onClick={() => setToolsDd((prev) => (prev ? null : 'main'))}
							disabled={!toolsAvailable}
						/>
					}
					dropdownData={(props, ref) => null}
					dropdownElement={
						<div ref={toolsRef} className={styles.tools}>
							{toolsDd === 'main' && (
								<div className={toolsMenuStyles.group}>
									{editingAvailable && (
										<Button
											className={toolsMenuStyles.actionButton}
											onClick={handleLinkedTaskClick}
											size="sm"
											design="transparent">
											{!isArchiveLinkedTask ? 'Редактировать задачу' : 'Посмотреть задачу'}
										</Button>
									)}

									{detachAvailable && (
										<Button
											className={toolsMenuStyles.actionButton}
											onClick={() => setToolsDd('unlink')}
											size="sm"
											design="transparent">
											Отвязать от задачи
										</Button>
									)}

									{deletionAvailable && (
										<Button
											className={toolsMenuStyles.actionButton}
											onClick={() => setToolsDd('remove')}
											size="sm"
											design="transparent"
											iconLeft={<Icon id="remove" />}>
											Удалить
										</Button>
									)}
								</div>
							)}

							{toolsDd === 'unlink' && (
								<FocusTrap
									focusTrapOptions={{
										escapeDeactivates: true,
										allowOutsideClick: true,
										checkCanFocusTrap: () =>
											new Promise<void>((resolve) => setTimeout(resolve, 50)),
									}}>
									<div
										className={styles.notification}
										onKeyDown={(e) => {
											if (checkEscape(e)) {
												e.stopPropagation();
												setFocusToId(`subtask-tools-button${subtask.id}`);
												setToolsDd(null);
											}
										}}>
										<ConfirmModalHead
											title="Удаление связи с задачей"
											size="sm"
											onClickBack={() => setToolsDd('main')}
										/>
										<ConfirmModalBody
											content={
												<>
													Подзадача <b>{subtask.title}</b> будет отвязана от родительской задачи.
												</>
											}
											onAccept={() =>
												detachLinkedTaskModel.detachLinkedTask({
													id: subtask.id!,
													title: subtask.title!,
												})
											}
											onDiscard={() => setToolsDd('main')}
											acceptLabel="Отвязать"
											acceptColor="primary"
											textAlign="left"
											isPending={detachStatus === 'pending'}
										/>
									</div>
								</FocusTrap>
							)}

							{toolsDd === 'remove' && (
								<FocusTrap
									focusTrapOptions={{
										escapeDeactivates: true,
										allowOutsideClick: true,
										checkCanFocusTrap: () =>
											new Promise<void>((resolve) => setTimeout(resolve, 50)),
									}}>
									<div
										className={styles.notification}
										onKeyDown={(e) => {
											if (checkEscape(e)) {
												e.stopPropagation();
												setFocusToId(`subtask-tools-button${subtask.id}`);
												setToolsDd(null);
											}
										}}>
										<ConfirmModalHead
											title="Удаление подзадачи"
											size="sm"
											onClickBack={() => setToolsDd('main')}
										/>
										<ConfirmModalBody
											content={
												<>
													Подзадача <b>{subtask.title}</b> будет перемещена в архив.
												</>
											}
											onAccept={() => {
												subtask?.id &&
													onRemove(subtask as SubtaskDto, () => {
														setToolsDd(null);
														subtasksModel.toggleArchivedOfSubtask({ value: true, index });
														taskListModel.revalidateTaskList();
													});
											}}
											onDiscard={() => setToolsDd('main')}
											acceptLabel="Удалить"
											acceptColor="danger"
											textAlign="left"
											isPending={deleteStatus === 'pending'}
										/>
									</div>
								</FocusTrap>
							)}
						</div>
					}
				/>
			</div>
		</div>
	);
};
