export const REMOVE_ASSIGNEE_DIALOG_TITLE = 'Удаление исполнителя';
export const REMOVE_COASSIGNEE_DIALOG_TITLE = 'Удаление участника';

export const REMOVE_ASSIGNEE_DIALOG_TEXT: [string, string] = [
	'Невозможно удалить пользователя',
	'из исполнителей задачи, так как он является исполнителем подзадачи.',
];
export const REMOVE_COASSIGNEE_DIALOG_TEXT: [string, string] = [
	'Невозможно удалить пользователя',
	'из участников задачи, так как он является исполнителем подзадачи.',
];

export const ARCHIVED_MARK = '[В архиве]';
