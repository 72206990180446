import React, { ChangeEvent, useRef } from 'react';

import { filesModel } from '@shared/model';
import { noticesModel } from '@shared/ui';

export const useVirtualFileUploader = (onUpload: (file: any) => void) => {
	const ref = useRef<HTMLInputElement>(null);

	const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		e.preventDefault();
		const file = e.target.files ? e.target.files[0] : null;
		const formData = new FormData();

		if (file) {
			formData.append('files', file);

			filesModel
				.uploadFilesOrFileLinksFx(Object(formData))
				.then((response) => {
					onUpload(response);
					noticesModel.add({
						type: 'success',
						text: `Файл "<b>${response[0].name || response[0].url}</b>" прикреплен.`,
					});
				})
				.catch((error) => console.warn(error));
		}
	};

	return {
		handleChange,
		element: <input onChange={handleChange} type={'file'} style={{ display: 'none' }} ref={ref} />,
		ref,
	};
};
