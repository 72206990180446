import React from 'react';

import { BoardDto, BoardPreviewDto, BoardWithViewDto } from '@shared/api';
import { modalsModel } from '@shared/ui';

import { boardViewModel, toggleTagsOnTaskModel } from '@entities/boards';
import { taskListTagsModel } from '@entities/task';

import { deleteBoardFeature, toggleBoardArchivedModel } from '@features/board';

export const moveBoardToArchive = (
	board?: BoardDto | BoardWithViewDto,
	hasUnsavedData?: boolean
) => {
	if (!board) return;

	modalsModel.pushModal({
		name: 'REMOVE_BOARD_MODAL',
		data: {
			id: board.id,
			title: 'Удаление доски',
			text: (
				<>
					{hasUnsavedData && <>Все несохраненные изменения будут утеряны. </>}
					Доска задач <b>{board.name}</b> и все задачи на доске будут перемещены в архив и будут
					недоступны для редактирования.
				</>
			),
			onAccept: () => {
				if (!board.id) return;

				/**
				 * Если есть какие-то несохраненные данные,
				 * то перед удалением доски сбрасываем их
				 */
				if (hasUnsavedData) {
					boardViewModel.resetAllStores();
					taskListTagsModel.resetAllStores();
					toggleTagsOnTaskModel.resetAllStores();
				}

				toggleBoardArchivedModel.toggleBoardArchived({
					boardId: board.id,
				});
			},
		},
	});
};

export const restoreBoardFromArchive = (board?: BoardPreviewDto | BoardDto | BoardWithViewDto) => {
	if (!board?.id) return;
	toggleBoardArchivedModel.toggleBoardArchived({
		boardId: board.id,
	});
};

export const completelyDeleteBoard = (
	board?: BoardPreviewDto | BoardDto | BoardWithViewDto,
	revalidateList?: boolean
) => {
	if (!board) return;
	modalsModel.pushModal({
		name: 'REMOVE_BOARD_MODAL',
		data: {
			id: board.id,
			title: 'Удаление доски задач',
			text: (
				<>
					Доска задач <b>{board.name}</b> и все задачи на доске будут безвозвратно удалены.
				</>
			),
			onAccept: () => {
				if (!board.id) return;
				deleteBoardFeature.deleteBoard({
					body: {
						boardId: board.id,
						boardName: board.name,
					},
					revalidateList: !!revalidateList,
				});
			},
		},
	});
};
