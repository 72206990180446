import classnames from 'classnames';
import React, {
	FC,
	HTMLAttributes,
	MouseEvent,
	MouseEventHandler,
	useCallback,
	useState,
} from 'react';

import { Icon } from '@shared/ui';

import styles from './styles.module.scss';

/*
showFullNameOnHover - когда имя длинное, например, "Константин Кириенко-Константинопольский"
и оно не помещается во всю строку, показываем многоточие и при ховере
можем прочесть имя в подсказке полностью

<p className={styles.promptText}>{name}</p> - если у нас используются кружочки,
 то при ховере показываем полное имя
 */

export interface AvatarProps extends HTMLAttributes<HTMLElement> {
	name: string;
	icon?: boolean;
	size?: 'sm' | 'md';
	color?: AvatarColors;
	showFullNameOnHover?: boolean;
	type?: typeChoice;
	onClick?: MouseEventHandler | ((e: MouseEvent<HTMLElement>) => void);
	mail?: string;
	status?: string;
	time?: string;
}
type typeChoice = 'onlyIcon' | 'withNameBG' | 'regular' | 'regularForComment';

export type AvatarColors =
	| 'red'
	| 'indigo'
	| 'pink'
	| 'blue'
	| 'amber'
	| 'purple'
	| 'teal'
	| 'gray'
	| 'deep-purple'
	| 'green'
	| 'blue-gray'
	| 'orange';

export const Avatar: FC<AvatarProps> = React.memo(
	({
		name,
		icon = false,
		size = 'sm',
		color = 'blue',
		showFullNameOnHover = false,
		type = 'onlyIcon',
		onClick,
		mail,
		time,
		status,
		className,
		...props
	}) => {
		///ля, гляди какой красавчик вышел! ^^

		const styleClasses = classnames(styles.avatar, styles[size], styles[type], className);

		const clickHandler: MouseEventHandler<HTMLElement> = useCallback(
			(e) => {
				onClick && onClick(e);
			},
			[onClick]
		);

		let Wrap: JSX.Element;
		let Child = (
			<>
				<AvatarRoundImage color={color} size={size} icon={icon} name={name} />
				<AvatarText
					name={name}
					time={time}
					status={status}
					mail={mail}
					showFullNameOnHover={showFullNameOnHover}
				/>
			</>
		);

		if (type === 'regular' || type === 'withNameBG') {
			onClick
				? (Wrap = (
						<button key={'ava' + name} className={styleClasses} onClick={clickHandler} {...props}>
							{Child}
						</button>
				  ))
				: (Wrap = (
						<div key={'ava' + name} className={styleClasses} {...props}>
							{Child}
						</div>
				  ));
		} else if (type === 'regularForComment') {
			Wrap = (
				<div key={'ava' + name} className={styleClasses} {...props}>
					{Child}
				</div>
			);
		} else {
			Wrap = (
				<AvatarRoundImage onClick={onClick} color={color} size={size} icon={icon} name={name} />
			);
		}

		return Wrap;
	}
);

export const AvatarRoundImage: FC<AvatarProps> = React.memo(
	({
		id,
		className,
		color = 'blue',
		size = 'sm',
		icon,
		name,
		onClick,
		showFullNameOnHover = true,
	}) => {
		const clickHandler: MouseEventHandler<HTMLElement> = useCallback(
			(e) => {
				onClick && onClick(e);
			},
			[onClick]
		);

		const nameArray = name.split(' ');
		let firstName = nameArray[0].split('')[0];
		let secondName = '';
		if (nameArray[1]) secondName = nameArray[1].split('')[0];
		const firstLetters = firstName + secondName;

		return onClick ? (
			<button
				id={id}
				key={'ava' + name}
				onClick={clickHandler}
				className={classnames(className, styles[size], styles.roundName, styles[color])}>
				<span>{firstLetters}</span>
				{icon && <Icon id={'star'} />}
				<AvatarPromptText name={name} />
			</button>
		) : (
			<div
				key={'ava' + name}
				className={classnames(className, styles[size], styles.roundName, styles[color])}>
				<span>{firstLetters}</span>
				{icon && <Icon id={'star'} />}
				{showFullNameOnHover && <AvatarPromptText name={name} />}
			</div>
		);
	}
);

export const AvatarText: FC<AvatarProps> = React.memo(
	({ showFullNameOnHover, status, mail, name, time }) => {
		return (
			<>
				<div className={styles.textWrap}>
					<p className={styles.firstLine}>
						<span className={styles.name}>{name}</span>
						{status && <>{status}</>}
						{!status && time && <span className={styles.time}>{time}</span>}
					</p>
					{mail && <p className={styles.mail}>{mail}</p>}
					{time && status && <p className={styles.time}>{time}</p>}
				</div>

				{showFullNameOnHover && <small className={styles.showFullNameOnHover}>{name}</small>}
			</>
		);
	}
);

export const AvatarPromptText: FC<Pick<AvatarProps, 'name'>> = React.memo(({ name }) => {
	const [inversePosition, setInversePosition] = useState(false);

	return (
		<p
			className={classnames(styles.promptText, { [styles.inverse]: inversePosition })}
			ref={(node) => {
				if (!inversePosition && !!node && node.getBoundingClientRect().right > window.innerWidth) {
					setInversePosition(true);
				}
			}}>
			{name}
		</p>
	);
});
