import { createEffect, createEvent, sample } from 'effector';
import { status } from 'patronum';

import { filesRequests, UploadFilesOrFileLinksRequest } from '@shared/api';

const uploadFilesOrFileLinks = createEvent<UploadFilesOrFileLinksRequest>();
const uploadFilesOrFileLinksFx = createEffect(filesRequests.uploadFilesOrFileLinks);

const $status = status({ effect: uploadFilesOrFileLinksFx });

sample({
	clock: uploadFilesOrFileLinks,
	target: uploadFilesOrFileLinksFx,
});

export const filesModel = {
	uploadFilesOrFileLinks,
	uploadFilesOrFileLinksFx,
	$status,
};
