import { fetcher } from '@shared/api/config';

import { BoardParticipantDto } from '../model/boardParticipantDto';

export interface SetRoleParticipantParams {
	participantId: string;
	roleId: string;
}

const setRoleParticipant = ({ participantId, roleId }: SetRoleParticipantParams) =>
	fetcher<BoardParticipantDto>({
		method: 'PUT',
		url: `/participants/${participantId}/role?new-role-id=${roleId}`,
	});

const removeParticipant = (participant: string) =>
	fetcher({
		method: 'DELETE',
		url: `/participants/${participant}`,
	});

export const boardParticipantsRequests = {
	setRoleParticipant,
	removeParticipant,
};
