export const getRelativePosition = (placement: string, indent: number) => {
	switch (placement) {
		case 'left':
			return {
				right: `calc(100% + ${indent}px)`,
				top: `50%`,
				transform: `translateY(-50%)`,
			};
		case 'leftEnd':
			return {
				right: `calc(100% + ${indent}px)`,
				bottom: 0,
			};
		case 'leftStart':
			return {
				right: `calc(100% + ${indent}px)`,
				top: 0,
			};
		case 'right':
			return {
				left: `calc(100% + ${indent}px)`,
				top: `50%`,
				transform: `translateY(-50%)`,
			};
		case 'rightEnd':
			return {
				left: `calc(100% + ${indent}px)`,
				bottom: 0,
			};
		case 'rightStart':
			return {
				left: `calc(100% + ${indent}px)`,
				top: 0,
			};
		case 'top':
			return {
				left: `calc(100% + ${indent}px)`,
				bottom: `calc(100% + ${indent}px)`,
				transform: `translateX(-50%)`,
			};
		case 'topEnd':
			return {
				right: 0,
				bottom: `calc(100% + ${indent}px)`,
			};
		case 'topStart':
			return {
				left: 0,
				bottom: `calc(100% + ${indent}px)`,
			};
		case 'bottom':
			return {
				left: `calc(100% + ${indent}px)`,
				top: `calc(100% + ${indent}px)`,
				transform: `translateX(-50%)`,
			};
		case 'bottomEnd':
			return {
				right: 0,
				top: `calc(100% + ${indent}px)`,
			};
		case 'bottomStart':
		default:
			return {
				left: 0,
				top: `calc(100% + ${indent}px)`,
			};
	}
};
