import classnames from 'classnames';
import React, { HTMLAttributes, LegacyRef, useRef } from 'react';

import { Typo } from '@shared/ui';

import styles from './styles.module.scss';

interface TooltipProps extends HTMLAttributes<HTMLElement> {
	theme?: 'dark' | 'light';
	isShown?: boolean;
	showWithAnimation?: boolean;
	text?: string;
}

export const Tooltip = React.forwardRef<HTMLElement, TooltipProps>(
	(
		{
			className,
			style,
			children,
			theme = 'dark',
			isShown = false,
			showWithAnimation = false,
			text,
			...rest
		},
		ref
	) => {
		const content = useRef(children);
		return (
			<div
				ref={ref as LegacyRef<HTMLDivElement>}
				style={style}
				className={classnames(className, styles.tooltip, styles[theme], {
					[styles.isShown]: isShown && !showWithAnimation,
					[styles.fadeIn]: isShown && showWithAnimation,
					[styles.showWithAnimation]: showWithAnimation,
				})}
				{...rest}>
				<Typo design="text-11-regular">{text || content.current}</Typo>
			</div>
		);
	}
);
