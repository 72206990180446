import classnames from 'classnames';
import React, { FC } from 'react';

import { TaskPreviewDto } from '@shared/api';

import { TaskCardWrapper } from '@widgets/task-board';

import styles from '../styles.module.scss';

interface TaskTableColumnProps {
	tasks?: TaskPreviewDto[];
	tagId: string;
	statusId?: string;
	onCardClick?: (id: string, title?: string) => void;
}

export const TaskTableColumn: FC<TaskTableColumnProps> = React.memo(
	({ tasks = [], tagId, statusId, onCardClick }) => {
		return (
			<div className={classnames(styles.taskTableColumn)}>
				{tasks?.map((task) => {
					const id = task?.id;
					return id ? (
						<div
							role="cell"
							key={`${tagId}-${statusId}-${id}`}
							className={styles.taskTableColumnCell}>
							{task?.id && (
								<TaskCardWrapper
									task={task}
									onClick={() => onCardClick && onCardClick(id, task?.title)}
								/>
							)}
						</div>
					) : null;
				})}
			</div>
		);
	}
);
