import classnames from 'classnames';
import React, { HTMLAttributes, PropsWithChildren } from 'react';

import styles from './styles.module.scss';

export type TypoTagnameType = keyof Omit<JSX.IntrinsicElements, keyof SVGElementTagNameMap>;

export type TypoDesignToken =
	| 'header-24-bold'
	| 'header-16-semi-bold'
	| 'text-14-bold'
	| 'text-14-medium'
	| 'text-13-bold'
	| 'text-13-medium'
	| 'text-13-regular'
	| 'text-13-medium-lined'
	| 'text-11-regular';

interface TypographyProps extends HTMLAttributes<HTMLElement> {
	as?: TypoTagnameType;
	design?: TypoDesignToken;
}

export const Typo = React.forwardRef<HTMLElement, PropsWithChildren<TypographyProps>>(
	({ as: Tag = 'div', design = 'text-13-regular', className, children, ...rest }, ref) => {
		return React.createElement(
			Tag,
			{
				ref,
				className: classnames(styles[design], className),
				...rest,
			},
			children
		);
	}
);
