import { createEvent, createEffect, sample } from 'effector';
import { status } from 'patronum';

import { subtasks, DetachLinkedTaskRequest } from '@shared/api';
import { noticesModel } from '@shared/ui';

import { taskModel } from '../task';
import { taskEventsListModel } from '../task-events-list';
import { taskListModel } from '../task-list';

interface DetachLinkedTaskEvent extends DetachLinkedTaskRequest {
	title: string;
}

const detachLinkedTask = createEvent<DetachLinkedTaskEvent>();
const detachLinkedTaskFx = createEffect(({ id }: DetachLinkedTaskEvent) =>
	subtasks.detachLinkedTask({ id })
);

const $status = status({ effect: detachLinkedTaskFx });

sample({
	clock: detachLinkedTask,
	target: detachLinkedTaskFx,
});

sample({
	clock: detachLinkedTaskFx.doneData,
	target: [
		taskModel.revalidateTask,
		taskListModel.revalidateTaskList,
		taskEventsListModel.revalidate,
	],
});

sample({
	clock: detachLinkedTaskFx.done,
	fn: (source) => ({
		type: 'success' as const,
		text: `Задача "<b>${source.params.title}</b>" отвязана.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: detachLinkedTaskFx.failData,
	fn: () => ({
		type: 'error' as const,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	detachLinkedTask,
	$status,
};
