import { createEvent, createEffect, createStore, sample, restore, combine } from 'effector';

import { DeleteTaskEventRequest, tasksEvents } from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { taskEventsListModel } from '../task-events-list';

const deleteTaskEvent = createEvent<DeleteTaskEventRequest>();
const deleteTaskEventFx = createEffect(tasksEvents.deleteTaskStatusEvent);

const $config = createStore<Nullable<DeleteTaskEventRequest>>(null).reset(deleteTaskEvent);
const $error = restore<Error>(deleteTaskEventFx.failData, null).reset(deleteTaskEvent);

const $status = combine({
	pending: deleteTaskEventFx.pending,
	data: $config,
	error: $error,
});

sample({
	clock: deleteTaskEvent,
	target: [deleteTaskEventFx, $config],
});

/*
 * Мутируем основной стор
 */
sample({
	clock: deleteTaskEventFx.doneData,
	source: $config,
	target: taskEventsListModel.removeFromList,
});

/*
 * Уведомления
 */
sample({
	clock: deleteTaskEventFx.doneData,
	fn: () => ({
		type: 'success' as NoticeType,
		text: `Комментарий удален`,
	}),
	target: noticesModel.add,
});

sample({
	clock: deleteTaskEventFx.failData,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	$status,
	deleteTaskEvent,
};
