import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { FileDto } from '@shared/api';
import { DropdownControlled } from '@shared/ui';

import { fileManagerModel } from '@entities/file-manager/model';

import { FileManagerRemoveModal } from './remove-modal';
import styles from './styles.module.scss';

interface FileManagerRemoveProps {
	removeFile?: (file: FileDto) => void;
}

export const FileManagerRemove: FC<FileManagerRemoveProps> = ({ removeFile }) => {
	const state = useStore(fileManagerModel.$action);
	const file = useStore(fileManagerModel.$file);
	const isOpened = state === 'REMOVE_FILE';

	const handleAccept = (file: FileDto) => {
		removeFile && removeFile(file);
		fileManagerModel.pushAction({
			type: null,
			payload: null,
		});
	};

	const handleDiscard = () => {
		fileManagerModel.pushAction({
			type: null,
			payload: null,
		});
	};

	return (
		<DropdownControlled
			opened={isOpened && !!file}
			setOpened={(state) => {
				if (!state) handleDiscard();
			}}
			className={styles.filesRemove}
			contentClassName={styles.filesRemoveDrop}
			portalId="task-modal-portal"
			placement="bottomEnd"
			targetButton={<></>}
			content={<FileManagerRemoveModal onAccept={handleAccept} onDiscard={handleDiscard} />}
		/>
	);
};

export { FileManagerRemoveModal };
