import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { Notification, ClientPortal } from '@shared/ui';

import { noticesModel } from '../model';

import styles from './styles.module.scss';

interface NotificationWrapProps {
	pageOffset: number;
}

export const NotificationWrap: FC<NotificationWrapProps> = ({ pageOffset }) => {
	const items = useStore(noticesModel.$notices);

	const notices = items.map((item) => (
		<Notification {...item} key={item.id} onClose={() => noticesModel.remove(item.id)} />
	));

	return (
		<ClientPortal>
			<div style={{ left: pageOffset + 'px' }} className={styles.noticeWrap}>
				{notices}
			</div>
		</ClientPortal>
	);
};
