import { formatDate } from '@shared/lib/utils';

export const getDateForTag = (d?: string, short?: boolean) => {
	if (!d) return d;

	const time = formatDate(d, 'HH:mm');

	if (short) {
		return formatDate(d, 'dd.MM.yy');
	}

	return formatDate(d, time !== '00:00' && time !== '09:00' ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy');
};
