import { createEvent, createEffect, createStore, sample, combine, forward } from 'effector';
import { createGate } from 'effector-react';

import {
	NotificationDto,
	TaskReassignRequestDto,
	TaskReassignRequestDtoStatusEnum,
	taskReassignRequests,
} from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { notificationsListModel } from '../notifications-list';

interface TaskReassignEventArgs {
	taskReassignRequest: TaskReassignRequestDto;
	notification?: Nullable<NotificationDto>;
	taskId?: string;
}

interface NoticeLabelType {
	text: string;
	accepted: boolean;
	taskId?: string;
}

const reset = createEvent();

const setNotification = createEvent<NotificationDto>();
const setNoticeLabel = createEvent<Nullable<NoticeLabelType>>();

const acceptReassign = createEvent<TaskReassignEventArgs>();
const acceptReassignFx = createEffect(({ taskReassignRequest }: TaskReassignEventArgs) =>
	taskReassignRequests.updateTaskReassignRequestStatus(taskReassignRequest)
);

const declineReassign = createEvent<TaskReassignEventArgs>();
const declineReassignFx = createEffect(({ taskReassignRequest }: TaskReassignEventArgs) =>
	taskReassignRequests.updateTaskReassignRequestStatus(taskReassignRequest)
);

const cancelReassign = createEvent<TaskReassignEventArgs>();
const cancelReassignFx = createEffect(({ taskReassignRequest }: TaskReassignEventArgs) =>
	taskReassignRequests.updateTaskReassignRequestStatus({
		...taskReassignRequest,
		status: TaskReassignRequestDtoStatusEnum.OPEN,
	})
);

const retryReassign = createEvent<TaskReassignEventArgs>();
const retryReassignFx = createEffect(({ taskReassignRequest }: TaskReassignEventArgs) =>
	taskReassignRequests.taskReassignRequestRetry({
		...taskReassignRequest,
		status: TaskReassignRequestDtoStatusEnum.OPEN,
	})
);

const $pending = combine({
	accept: acceptReassignFx.pending,
	decline: declineReassignFx.pending,
	cancel: cancelReassignFx.pending,
	retry: retryReassignFx.pending,
});

const $label = createStore<Nullable<NoticeLabelType>>(null);
const $retried = createStore<Nullable<Record<string, boolean>>>(null);
const $notification = createStore<Nullable<NotificationDto>>(null).reset(reset);

sample({
	clock: setNoticeLabel,
	target: $label,
});

sample({
	clock: setNotification,
	target: $notification,
});

sample({
	clock: [acceptReassign, declineReassign, cancelReassign, retryReassign],
	fn: ({ notification }) => notification || null,
	target: $notification,
});

sample({
	clock: acceptReassign,
	target: acceptReassignFx,
});

sample({
	clock: declineReassign,
	target: declineReassignFx,
});

sample({
	clock: cancelReassign,
	target: cancelReassignFx,
});

sample({
	clock: retryReassign,
	target: retryReassignFx,
});

sample({
	clock: [acceptReassignFx.done, declineReassignFx.done, cancelReassignFx.done],
	source: $notification,
	filter: (notification) => !!notification,
	fn: (notification, clock) => {
		return {
			...notification,
			taskReassignRequest: notification?.taskReassignRequest
				? {
						...notification.taskReassignRequest,
						status: clock.params.taskReassignRequest.status,
				  }
				: null,
		} as NotificationDto;
	},
	target: [notificationsListModel.updateInList, $notification],
});

sample({
	clock: retryReassignFx.doneData,
	source: $retried,
	filter: (_, retry) => !!retry?.id,
	fn: (source, retry) => {
		const map = { ...source };
		if (retry?.id) map[retry.id] = true;
		return map;
	},
	target: $retried,
});

sample({
	clock: acceptReassignFx.done,
	fn: (clock) => ({
		text: 'Принято',
		accepted: true,
		taskId: clock.params.taskId,
	}),
	target: setNoticeLabel,
});

sample({
	clock: declineReassignFx.done,
	fn: (clock) => ({
		text: 'Отклонено',
		accepted: false,
		taskId: clock.params.taskId,
	}),
	target: setNoticeLabel,
});

sample({
	clock: retryReassignFx.done,
	source: $notification,
	filter: (notification) => !!notification,
	fn: (source) => ({
		type: 'success' as const,
		text: `Запрос на передачу задачи был отправлен пользователю <b>${source?.sender?.fullName}</b>.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: [
		acceptReassignFx.fail,
		declineReassignFx.fail,
		cancelReassignFx.fail,
		retryReassignFx.fail,
	],
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

const NotificationGate = createGate();

forward({
	from: NotificationGate.close,
	to: reset,
});

export const model = {
	NotificationGate,
	acceptReassign,
	acceptReassignFx,
	declineReassign,
	declineReassignFx,
	cancelReassign,
	retryReassign,
	setNotification,
	setNoticeLabel,
	reset,
	$pending,
	$retried,
	$notification,
	$label,
};
