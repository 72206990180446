import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import React, { useRef, MutableRefObject, useState, useEffect } from 'react';

import { UserDto } from '@shared/api';
import { NOT_FOUND_MESSAGE } from '@shared/lib/constants';
import { useEscape, useOutsideClick } from '@shared/lib/hooks';
import { Input, NotFoundMessage, Scroller } from '@shared/ui';

import { useSearchUsers } from '@entities/search-users';

import { SearchAssigneeListItem } from '../search-assignee-list-item';

import styles from './styles.module.scss';

interface SearchAssigneeProps {
	assignees: UserDto | UserDto[] | null;
	addAssignee?: (user: UserDto) => void;
	removeAssignee?: (user: UserDto) => void;
	closeDropdown?: () => void;
	excludeUserIds?: Array<string>;
	outsideClickExcludeRef?: MutableRefObject<HTMLElement | null>;
	checkUserHasSubtasks?: (user: UserDto) => boolean;
}

const assigneeAdded = (user: UserDto, assignees: UserDto | UserDto[]) => {
	if (Array.isArray(assignees)) {
		return assignees.some((assignee) => assignee.id === user.id);
	}

	return assignees.id === user.id;
};

export const SearchAssignee = ({
	assignees,
	addAssignee,
	removeAssignee,
	closeDropdown,
	excludeUserIds,
	outsideClickExcludeRef,
	checkUserHasSubtasks,
}: SearchAssigneeProps) => {
	const searchAsigneeRef = useRef<HTMLDivElement>(null);

	const { searchUsers, users } = useSearchUsers({
		includeCurrentUser: true,
		excludeUserIds,
	});

	const [searchInputShadow, setSearchInputShadow] = useState(false);
	const [maxListItemsShow, setMaxListItemsShow] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			if (searchAsigneeRef.current) {
				const windowHeight = document.documentElement.clientHeight;
				const { bottom } = searchAsigneeRef.current.getBoundingClientRect();

				const availableHeight = windowHeight - bottom - 12;
				const countRow = Math.floor(availableHeight / 40);
				setMaxListItemsShow(countRow > 10 ? 10 : countRow);
			}
		}, 50);
	}, []);

	useEscape(
		true,
		(e) => {
			e?.stopPropagation();
			closeDropdown && closeDropdown();
		},
		searchAsigneeRef as MutableRefObject<HTMLElement>
	);

	useOutsideClick([searchAsigneeRef, outsideClickExcludeRef], closeDropdown);

	return (
		<FocusTrap
			focusTrapOptions={{
				escapeDeactivates: true,
				allowOutsideClick: true,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
			}}>
			<div className={styles.searchAsignee} ref={searchAsigneeRef}>
				<div
					className={classnames(styles.searchInputWrapper, {
						[styles.withShadow]: searchInputShadow,
					})}>
					<Input
						id="search-participants"
						placeholder="Поиск по имени"
						icon="search"
						theme="light-gray"
						className={styles.searchInput}
						onChange={searchUsers}
					/>
				</div>

				{users && (
					<Scroller
						axis="y"
						className={classnames(styles.scroll, 'scrollbar-container')}
						style={{ maxHeight: `${32 * maxListItemsShow + 8 * (maxListItemsShow - 1) + 8}px` }}
						onScroll={(_, y) => {
							setSearchInputShadow(y > 0);
						}}>
						{users?.length ? (
							<ul className={styles.list}>
								{users.map((user) => (
									<SearchAssigneeListItem
										key={user.id}
										user={user}
										addAssignee={() => addAssignee && addAssignee(user)}
										removeAssignee={() => removeAssignee && removeAssignee(user)}
										added={!!assignees && assigneeAdded(user, assignees)}
										userHasSubtasks={!!checkUserHasSubtasks && checkUserHasSubtasks(user)}
									/>
								))}
							</ul>
						) : (
							<NotFoundMessage>{NOT_FOUND_MESSAGE.employees}</NotFoundMessage>
						)}
					</Scroller>
				)}
				<div id="search-assignee-list" />
			</div>
		</FocusTrap>
	);
};
