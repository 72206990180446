import { isValid, parse } from 'date-fns';

export const checkTime = (timeString?: string | null) => {
	return timeString ? /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(timeString) : false;
};

export const checkedDate = (dateString?: string | null, timeString?: string | null) => {
	if (!dateString) return null;
	if (dateString.replace(/\s/g, '').length !== 10) return null;

	const withTime = checkTime(timeString);
	const parseDate = withTime ? `${dateString} ${timeString}` : dateString;
	const parseFormat = withTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';

	const parsed = parse(parseDate, parseFormat, new Date());

	return isValid(parsed) ? parsed : null;
};
