import classnames from 'classnames';
import React, { FC } from 'react';

import { TaskPreviewDto } from '@shared/api';

import { TaskCardWrapper } from '@widgets/task-board';

import styles from '../styles.module.scss';

interface TaskTransposedTableColumnProps {
	tasks?: Nullable<TaskPreviewDto[]>;
	tagId: string;
	statusId?: string;
	onCardClick?: (id: string, title?: string) => void;
	collapsed?: boolean;
}

export const TaskTransposedTableColumn: FC<TaskTransposedTableColumnProps> = React.memo(
	({ tasks, tagId, statusId, onCardClick, collapsed }) => {
		return (
			<div className={classnames(styles.taskTableColumn, { [styles.collapsed]: collapsed })}>
				{tasks &&
					tasks.map((task) => {
						const { id } = task;
						return id ? (
							<div
								role="cell"
								key={`${tagId}-${statusId}-${id}`}
								className={styles.taskTableColumnCell}>
								{task?.id && (
									<TaskCardWrapper
										task={task}
										onClick={() => onCardClick && onCardClick(id, task?.title)}
									/>
								)}
							</div>
						) : null;
					})}
			</div>
		);
	}
);
