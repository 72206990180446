import { useStore } from 'effector-react';
import { FormEvent, useEffect, useState, useMemo } from 'react';

import { UserDto } from '@shared/api';
import { useDebounce } from '@shared/lib/hooks';

import { searchUsersModel } from '../model';

interface useSearchUsersRarams {
	localSearchList?: UserDto[] | null;
	isFullInitialLocalSearchList?: boolean;
	includeCurrentUser?: boolean;
	excludeUserIds?: Array<string>;
}

export const useSearchUsers = (params: useSearchUsersRarams = {}) => {
	const { localSearchList, isFullInitialLocalSearchList, includeCurrentUser, excludeUserIds } =
		params;
	const globalUsers = useStore(searchUsersModel.$users);
	const status = useStore(searchUsersModel.$statusUsers);
	const [search, setSearch] = useState('');

	const debounced = useDebounce(search);

	const searchUsers = (event: FormEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		setSearch(target.value.trim());
	};

	useEffect(() => {
		!localSearchList &&
			searchUsersModel.fetchUsers({
				fullName: debounced,
				includeCurrentUser,
				excludeUserIds,
			});
	}, [debounced, excludeUserIds, includeCurrentUser, localSearchList]);

	const localUsers = useMemo(() => {
		if (debounced.length > 2 && localSearchList) {
			return localSearchList?.filter((user) =>
				user.fullName?.toLowerCase().includes(debounced.toLowerCase())
			);
		}

		return isFullInitialLocalSearchList ? localSearchList : null;
	}, [debounced, isFullInitialLocalSearchList, localSearchList]);

	const users = localSearchList ? localUsers : globalUsers?.data;

	return { searchUsers, users, debounced, status, setSearch };
};
