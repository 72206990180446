import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import { Typo } from '@shared/ui';

import styles from './styles.module.scss';

interface EmptyBannerProps extends HTMLAttributes<HTMLElement> {}

export const EmptyBanner: FC<EmptyBannerProps> = React.memo(({ className, children }) => {
	return <Typo className={classnames(className, styles.component)}>{children}</Typo>;
});
