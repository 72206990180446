import { createEvent, createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';

import { GetBoardsShortRequest, boardsRequests, BoardShortDto } from '@shared/api';

const BoardsShortGate = createGate<GetBoardsShortRequest>();

const fetchBoardsShortList = createEvent<GetBoardsShortRequest>();

const fetchBoardsShortListFx = createEffect(boardsRequests.getBoardsShort);

const $boardsShortList = createStore<BoardShortDto[]>([]);

sample({
	source: BoardsShortGate.status,
	clock: BoardsShortGate.state,
	filter: (source) => source,
	fn: (_, clock) => clock,
	target: fetchBoardsShortList,
});

sample({
	clock: fetchBoardsShortList,
	target: fetchBoardsShortListFx,
});

sample({
	clock: fetchBoardsShortListFx.doneData,
	target: $boardsShortList,
});

export const model = {
	BoardsShortGate,
	fetchBoardsShortListFx,
	$boardsShortList,
};
