import { authRequests, SecurityTokensDto } from '@shared/api';
import { getJWT, setJWT, isFirefox } from '@shared/lib/utils';

const API_URL = process.env.REACT_APP_API_URL || '';

/*
 * Отсечение слэшей
 */
export const withoutTrailingSlash = (url: string) => url.replace(/\/$/, '');
export const withoutLeadingSlash = (url: string) => url.replace(/^\//, '');

export const trimSlash = (url: string) => withoutLeadingSlash(withoutTrailingSlash(url));

/*
 * Собирает url
 */
export const concatUrls = (base: string, url: string) => {
	base = withoutTrailingSlash(base);
	url = withoutLeadingSlash(url);

	if (url.match(base)) return url;

	return `${base}/${url}`;
};

/*
 * Возвращает полный url
 */
export const getUrl = (url: string): string => {
	return `${withoutTrailingSlash(API_URL)}/${withoutLeadingSlash(url)}`;
};

/*
 * Готовит заголовки
 */
export const getHeaders = (headers: Headers): Record<string, string> => {
	if (headers === null) return {};

	let access = getJWT();

	if (process.env.NODE_ENV === 'development') {
		access = process.env.REACT_APP_DEV_TOKEN as string;

		/*
		 * Для отладки взаимодействия между разными пользователями
		 * в дев-режиме добавляем возможность авторизовываться
		 * под другим токеном для другого браузера (FireFox)
		 */
		if (isFirefox()) {
			access = process.env.REACT_APP_DEV_TOKEN_FF as string;
		}
	}

	if (access) {
		headers.set('Authorization', `Bearer ${access}`);
	}

	headers.set('content-type', `application/json`);

	return Object.fromEntries(headers.entries());
};

/*
 * Обновлет токены
 * Если запросо несколько, то новые
 * кладет в очередь и резолвит после обновления токена
 */
let refreshInProrgess = false;
let refreshWaiters: Array<(value?: unknown) => void> = [];

export const refreshToken = () => {
	if (!refreshInProrgess) {
		refreshInProrgess = true;

		return authRequests.refreshToken().then((response) => {
			const { accessToken, refreshToken } = response;

			refreshInProrgess = false;

			try {
				setJWT({
					access: accessToken?.token,
					refresh: refreshToken?.token,
					accessExpiration: accessToken?.expirationDate,
					refreshExpiration: refreshToken?.expirationDate,
				});

				refreshWaiters.forEach((resolve) => resolve());
				refreshWaiters = [];

				return response as SecurityTokensDto;
			} catch (e) {
				throw response;
			}
		});
	}

	return new Promise((resolve) => {
		refreshWaiters.push(resolve);
	});
};

export * from './jwt';
