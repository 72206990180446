import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface DropdownPortalProps {
	id?: string;
	children: ReactNode;
}

export const DropdownPortal = ({ id = 'dropdown-portal', children }: DropdownPortalProps) => {
	const root = document.getElementById(id);
	return root ? createPortal(children, root) : null;
};
