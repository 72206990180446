import { SecurityTokensDto, CredentialsPasswordDto, RegisterWithPasswordDto } from '@shared/api';
import { fetcher } from '@shared/api/config';
import { getJWT } from '@shared/lib/utils';

export const devSignUp = (credentials: RegisterWithPasswordDto) =>
	fetcher<SecurityTokensDto>({
		url: '/auth/register/password',
		method: 'POST',
		body: credentials,
	});

export const devLogin = (credentials: CredentialsPasswordDto) =>
	fetcher<SecurityTokensDto>({
		url: '/auth/login/password',
		method: 'POST',
		body: credentials,
	});

export interface LoginProps {
	code: Nullable<string>;
}

export const login = (credentials: LoginProps) =>
	fetcher<SecurityTokensDto>({
		url: '/auth/login/keycloak',
		method: 'POST',
		body: credentials,
	});

export const refreshToken = () => {
	const refresh = getJWT('refresh');

	return fetcher<SecurityTokensDto>({
		url: '/auth/login/refresh-token',
		method: 'POST',
		body: { token: refresh },
	});
};

export const authRequests = {
	devSignUp,
	devLogin,
	login,
	refreshToken,
};
