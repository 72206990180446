import { isValid, format } from 'date-fns';
import { ru } from 'date-fns/locale';

import { getNumEndings, padForNum } from '@shared/lib/utils';

type LangType = 'ru' | 'en';

type EuFormatOptions = {
	regularForComment?: boolean;
	withMonthName?: boolean;
	lang?: LangType;
};

type ReturnShortDate = {
	day?: number;
	dayFormatted?: string;
	month?: string;
	datetime?: string;
	concat?: string;
};

type ReturnedCountdown = {
	dateText: string;
	expiredDate: boolean;
};

type DateDictType = {
	months: Array<string>;
	monthsShort: Array<string>;
};

const dict: Partial<Record<LangType, DateDictType>> = {
	ru: {
		months: [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря',
		],
		monthsShort: [
			'янв.',
			'фев.',
			'мар.',
			'апр.',
			'мая',
			'июн.',
			'июл.',
			'авг.',
			'сен.',
			'окт.',
			'ноя.',
			'дек.',
		],
	},
};

export const euFormatDate = (
	inputDate: string | Date,
	{ regularForComment, withMonthName, lang = 'ru' }: EuFormatOptions
): string | undefined => {
	if (!inputDate) {
		return undefined;
	}

	const date = new Date(inputDate);
	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	const time = regularForComment
		? ', ' + padForNum(date.getHours(), '00') + ':' + padForNum(date.getMinutes(), '00')
		: '';

	return withMonthName
		? `${day} ${dict[lang]?.monthsShort[month]} ${year}`
		: `${padForNum(day, '00')}.${padForNum(month + 1, '00')}.${year}${time}`;
};

export const fullFormatDate = (
	inputDate: undefined | string,
	lang: LangType = 'ru',
	shortMonth?: boolean
): ReturnShortDate => {
	if (!inputDate) {
		return {};
	}

	const date = new Date(inputDate);
	const month = shortMonth
		? dict[lang]?.monthsShort[date.getMonth()].replace('.', '')
		: dict[lang]?.months[date.getMonth()];
	const day = date.getDate();
	const dayFormatted = padForNum(day, '00');
	const year = date.getFullYear();

	const datetime = inputDate.replace(' ', 'T');

	return {
		day,
		dayFormatted,
		month,
		datetime,
		concat: `${dayFormatted} ${month} ${year}`,
	};
};

export const shortFormatDate = (
	inputDate: undefined | string,
	lang: LangType = 'ru'
): ReturnShortDate => {
	if (!inputDate) {
		return {};
	}

	inputDate = inputDate.replace(' ', 'T');

	const date = new Date(inputDate);
	const month = dict[lang]?.monthsShort[date.getMonth()] || '';
	const day = date.getDate();
	const dayFormatted = padForNum(day, '00');

	const datetime = inputDate.replace(' ', 'T');

	return {
		day,
		dayFormatted,
		month,
		datetime,
		concat: `${dayFormatted} ${month}`,
	};
};

export const countdown = (inputDate: undefined | string): ReturnedCountdown => {
	if (!inputDate) return { dateText: '', expiredDate: false };

	const date = new Date(inputDate);
	const dateNow = new Date();

	if (!isValid(date)) return { dateText: '', expiredDate: false };

	let days = (date.getTime() - dateNow.getTime()) / (1000 * 60 * 60 * 24);
	let dateText;
	let expiredDate;

	if (days < 1 && days > 0) {
		dateText = '<1 дня';
		expiredDate = true;
	} else if (days < 0) {
		dateText = 'Просрочена';
		expiredDate = true;
	} else {
		days = Math.round(days);
		dateText = `${days} ${getNumEndings(days, ['день', 'дня', 'дней'])}`;
		expiredDate = false;
	}

	return { dateText, expiredDate };
};

export const convertHMS = (value?: number | string) => {
	if (!value) return `00:00`;

	if (typeof value === 'string') {
		value = parseInt(value, 10);
	}

	const hours = Math.floor((value % (60 * 60 * 24)) / (60 * 60));
	const minutes = Math.floor((value % (60 * 60)) / 60);
	const seconds = Math.floor(value % 60);

	return `${hours ? `${padForNum(hours, '00')}:` : ``}${padForNum(minutes, '00')}:${padForNum(
		seconds,
		'00'
	)}`;
};

export const formatDate = (date?: string, tpl = 'dd.MM.yyyy') =>
	date && new Date(date) ? format(new Date(date), tpl) : date;

export const textDate = (date?: string | number, tpl = 'd MMMM') =>
	date && new Date(date) ? format(new Date(date), tpl, { locale: ru }) : date;

export const dateToUTC = (date?: Date | null) => (date ? date.toUTCString().slice(0, -4) : null);
export const dateToISO = (date?: Date | null) => (date ? date.toISOString() : null);

export const dateWillExpireSoon = (input?: string | null) => {
	if (!input) {
		return false;
	}

	const now = new Date().getTime();
	const date = new Date(input);

	if (!isValid(date)) {
		return false;
	}

	const diff = (date.getTime() - now) / 1000;

	return diff < 5;
};
