import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { Input, InputInterface } from '@shared/ui';

interface SearchInputProps extends InputInterface {
	onSearch: Dispatch<SetStateAction<string>>;
}

export const SearchInput = ({ onSearch, ...props }: SearchInputProps) => {
	const searchInputTimerRef = useRef(0);

	useEffect(() => {
		return () => {
			onSearch('');
		};
	}, [onSearch]);

	return (
		<Input
			onChange={(event) => {
				window.clearTimeout(searchInputTimerRef.current);

				searchInputTimerRef.current = window.setTimeout(() => {
					const search = event.target.value;
					onSearch(search);
				}, 300);
			}}
			{...props}
		/>
	);
};
