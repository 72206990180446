import { useStore } from 'effector-react';
import FocusTrap from 'focus-trap-react';
import React, { FC, KeyboardEvent, useRef } from 'react';

import { UserDto } from '@shared/api/model/userDto';
import { checkEscape } from '@shared/lib/utils';
import { Button, Icon, Dropdown } from '@shared/ui';

import { boardRolesModel } from '@entities/board-roles';
import { Roles } from '@entities/roles-component';

import { participantsModel } from '@widgets/board-modal/model';

interface ParticipantsListItemToolsProps {
	participant: UserDto;
	backFocus?: (s: string) => void;
}

export const ParticipantsListItemTools: FC<ParticipantsListItemToolsProps> = React.memo(
	({ participant, backFocus }) => {
		const participants = useStore(participantsModel.$participants);
		const boardRoles = useStore(boardRolesModel.$boardRoles);

		const isParticipantAddedToBoard = participants.filter(
			(item) => item.user.id === participant.id
		)[0];

		const ddRef = useRef<HTMLDivElement>(null);
		const ddBtnRef = useRef<HTMLButtonElement>(null);
		const ddMenuRef = useRef<HTMLDivElement>(null);

		const handleKeydown = (e: KeyboardEvent) => {
			if (checkEscape(e)) {
				e?.stopPropagation();
				backFocus && backFocus('.tools-btn-focus-target');
			}
		};

		return (
			<Dropdown
				dropdownId="create-board-dropdowns"
				portal
				ref={ddRef}
				placement="bottomEnd"
				targetButton={
					<Button
						ref={ddBtnRef}
						className="tools-btn-focus-target"
						size="sm"
						design="transparent"
						onKeyDownCapture={handleKeydown}>
						<Icon id="kebab" />
					</Button>
				}
				dropdownData={({ setOpen }, ref) => {
					return (
						<FocusTrap
							focusTrapOptions={{
								initialFocus: false,
								allowOutsideClick: true,
								checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 100)),
							}}>
							<Roles
								ref={ddMenuRef}
								onKeyDownCapture={(e) => {
									if (checkEscape(e)) setOpen(false);
									handleKeydown(e);
								}}
								boardRoles={boardRoles}
								setRole={(role) => {
									participantsModel.setRoleParticipant({ user: participant, role });
									backFocus && backFocus('.tools-btn-focus-target');
								}}
								removeParticipant={() => {
									participantsModel.removeParticipant(participant.id);
									backFocus && backFocus('.add-btn-focus-target');
								}}
								selectedRole={isParticipantAddedToBoard.role.name}
							/>
						</FocusTrap>
					);
				}}
			/>
		);
	}
);
