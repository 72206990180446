import classnames from 'classnames';
import React, { FC, useState } from 'react';

import { Button, Icon, Skeleton, TooltipWrap } from '@shared/ui';

import { headerModel } from '@widgets/header';

import { HeaderShadow } from './header-shadow';
import styles from './styles.module.scss';

interface HeaderProps {
	title: string;
	backUrl?: string;
	topRight?: React.ReactNode;
	bottomLeft?: React.ReactNode;
	bottomRight?: React.ReactNode;
	isLoading?: boolean;
	withShadow?: boolean;
}

export const Header: FC<HeaderProps> = React.memo(
	({ title, backUrl, topRight, bottomLeft, bottomRight, isLoading, withShadow }) => {
		const [hasEllipsis, setHasEllipsis] = useState(false);

		return (
			<header
				ref={(node) => {
					if (node) headerModel.setHeight(node.offsetHeight);
				}}
				className={classnames(styles.header, 'page__header')}>
				{withShadow && <HeaderShadow />}
				<div className={styles.row}>
					<div className={styles.left}>
						{backUrl && (
							<Button
								to={backUrl}
								design="transparent"
								color="secondary"
								className={styles.backBtn}
								onlyIcon={<Icon id="arrow-left" />}
							/>
						)}
						<TooltipWrap
							align="start"
							content={hasEllipsis ? title : null}
							className={styles.titleWrap}
							tooltipClassName={styles.titleTooltip}>
							<h1
								ref={(node) => {
									if (node) setHasEllipsis(node.offsetWidth < node.scrollWidth);
								}}
								className={styles.title}>
								{isLoading && <Skeleton width={300} />}
								{!isLoading && title}
							</h1>
						</TooltipWrap>
					</div>
					<div className={styles.right}>{topRight}</div>
				</div>
				{(bottomLeft || bottomRight) && (
					<div className={styles.row}>
						<div className={styles.left}>{bottomLeft}</div>
						<div className={styles.right}>{bottomRight}</div>
					</div>
				)}
			</header>
		);
	}
);
