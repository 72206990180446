import classnames from 'classnames';
import { useEvent, useStore, useGate } from 'effector-react';
import React, { FC, MouseEventHandler, useCallback, useState } from 'react';

import { asideModel } from '@shared/model';
import { Button, Icon, modalsModel, tooltipEventHandlersFactory } from '@shared/ui';

import { notificationsReadedModel } from '@entities/notifications';

import styles from './styles.module.scss';

export type NavList = {
	name: string;
	path: string;
	icon?: React.ReactNode;
	isNotifications?: boolean;
}[];

interface AsideProps {
	nav: NavList;
}

export const Aside: FC<AsideProps> = React.memo(({ nav }) => {
	const [asideMinimized, setAsideMinimized] = useState(false);

	const collapse = useEvent(asideModel.collapse);
	const expand = useEvent(asideModel.expand);

	const clickHandler: MouseEventHandler<HTMLElement> = useCallback(
		(e) => {
			setAsideMinimized((asideMinimized) => !asideMinimized);

			if (!asideMinimized) collapse();
			else expand();
		},
		[asideMinimized, collapse, expand]
	);

	const openedModals = useStore(modalsModel.$modals);

	const isNotificationsLocation = (path: string) => {
		return window.location.pathname === path;
	};
	const notificationsModalIsActive = () => {
		const lastModal = [...openedModals].pop();
		return lastModal?.name === 'NOTIFICATIONS_MODAL';
	};
	const openNotificationsModal = () => {
		modalsModel.pushModal({
			name: 'NOTIFICATIONS_MODAL',
		});
	};

	const isRoot = (path: string) => {
		return (
			window.location.pathname === path ||
			(path.includes('created-by-me') && window.location.pathname === '/')
		);
	};

	const isActive = (path: string) => {
		switch (path) {
			case '/notifications':
				return window.location.pathname.includes(path) || notificationsModalIsActive();
			case '/created-by-me':
				return (
					(window.location.pathname.includes(path) || window.location.pathname === '/') &&
					!notificationsModalIsActive()
				);
			default:
				return window.location.pathname.includes(path) && !notificationsModalIsActive();
		}
	};

	useGate(notificationsReadedModel.NotificationHasUnreadGate);
	const hasUnread = useStore(notificationsReadedModel.$hasUnread);

	return (
		<nav className={classnames(styles.asideNav, asideMinimized && styles.asideMinimized)}>
			<Button
				color="secondary"
				iconLeft={<Icon id="left" />}
				onClick={clickHandler}
				className={styles.changeSizeBtn}>
				Свернуть
			</Button>
			<div className={styles.asideMenu}>
				{nav.map((item) => {
					const props =
						item.isNotifications && !isNotificationsLocation(item.path)
							? {
									onClick: openNotificationsModal,
							  }
							: { to: item.path };
					const tooltipEventHandlers = asideMinimized
						? tooltipEventHandlersFactory(item.name, {
								delayIn: 300,
								align: null,
								placement: 'right',
						  })
						: {};
					return (
						<div key={item.path} {...tooltipEventHandlers} className={styles.asideMenuItem}>
							<Button
								{...props}
								design="no-style"
								iconLeft={item.icon}
								className={classnames(styles.asideMenuButton, {
									[styles.isRoot]: isRoot(item.path),
									[styles.isActive]: isActive(item.path),
								})}>
								{item.isNotifications && hasUnread && <span className={styles.asideMenuMark} />}
								{item.name}
							</Button>
						</div>
					);
				})}
			</div>
		</nav>
	);
});
