export const getSubtaskDateUpdateMessage = (
	prev?: string | null,
	next?: string | null,
	title?: string
) => {
	const MESSAGE_PREFIX = `Срок выполнения подзадачи${title ? ` "<b>${title}</b>"` : ''}`;

	if (prev && !next) {
		return `${MESSAGE_PREFIX} удалён.`;
	}

	if (!prev && next) {
		return `${MESSAGE_PREFIX} добавлен.`;
	}

	if (prev && next && prev !== next) {
		return `${MESSAGE_PREFIX} изменен.`;
	}
};
