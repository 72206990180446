import React, { FC, useState } from 'react';

import { Button, ConfirmModalBody, ConfirmModalHead, DropdownControlled } from '@shared/ui';

import styles from '../styles.module.scss';

export interface NotificationReassignDropdownProps {
	onAccept: () => void;
	dropdownType: 'accept' | 'decline';
	dropdownTitle?: string;
	taskTitle?: string;
	isPending?: boolean;
	portalId?: string;
}

export const ReassignDropdown: FC<NotificationReassignDropdownProps> = ({
	onAccept,
	dropdownType,
	dropdownTitle = 'Новая задача',
	taskTitle,
	isPending = false,
	portalId,
}) => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	return (
		<DropdownControlled
			opened={menuIsOpen}
			setOpened={setMenuIsOpen}
			withFocusTrap={true}
			portal={!!portalId}
			portalId={portalId}
			placement="bottomStart"
			targetButton={
				<Button
					size="md"
					design="filled"
					className={styles.button}
					onClick={() => setMenuIsOpen((prev) => !prev)}
					color={dropdownType === 'accept' ? 'primary' : 'secondary'}>
					{dropdownType === 'accept' ? 'Принять' : 'Отклонить'}
				</Button>
			}
			content={
				<div className={styles.reassignConfirmDropdown}>
					<ConfirmModalHead
						title={dropdownTitle}
						size="sm"
						onClickBack={() => setMenuIsOpen(false)}
					/>
					<ConfirmModalBody
						content={
							dropdownType === 'accept' ? (
								<>
									Принять задачу <b>{taskTitle}</b>?
								</>
							) : (
								<>
									Отклонить задачу <b>{taskTitle}</b>?
								</>
							)
						}
						onAccept={() => {
							onAccept && onAccept();
							setMenuIsOpen(false);
						}}
						onDiscard={() => setMenuIsOpen(false)}
						acceptLabel={dropdownType === 'accept' ? 'Принять' : 'Отклонить'}
						acceptColor={dropdownType === 'accept' ? 'primary' : 'danger'}
						isPending={isPending}
					/>
				</div>
			}
		/>
	);
};
