import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FocusTrap from 'focus-trap-react';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { object } from 'yup';

import { SubtaskDto } from '@shared/api';
import { checkEscape } from '@shared/lib/utils';
import { commonValidators } from '@shared/lib/validation';
import { Typo, FormElement, InputMulti } from '@shared/ui';
import { ConfirmModalHead, ConfirmModalBody } from '@shared/ui';

import { SubtaskToolsDdType } from '../subtask';

import styles from './styles.module.scss';

interface SubtaskChangeTitleProps {
	subtask: Partial<SubtaskDto>;
	setSubtaskToolsDd: (type: Nullable<SubtaskToolsDdType>) => void;
	onAccept?: (title: string) => void;
}

interface FormValues {
	title: string;
}

export const SubtaskChangeTitle = ({
	subtask,
	setSubtaskToolsDd,
	onAccept,
}: SubtaskChangeTitleProps) => {
	const [prevTitle] = useState(subtask.title || '');

	const {
		watch,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			title: subtask.title,
		},
		mode: 'all',
		resolver: yupResolver(object({ title: commonValidators.limitedRequiredString(500) })),
	});

	const newTitle = watch('title');

	const changeSubtaskTitle = ({ title }: FormValues) => {
		if (onAccept) {
			onAccept(title);
		}
	};

	return (
		<FocusTrap
			focusTrapOptions={{
				escapeDeactivates: true,
				allowOutsideClick: true,
				checkCanFocusTrap: () => new Promise<void>((resolve) => setTimeout(resolve, 50)),
			}}>
			<div
				className={styles.changeSubtaskTitle}
				onKeyDown={(e) => {
					if (checkEscape(e)) {
						e.stopPropagation();
						setSubtaskToolsDd('main');
					}
				}}>
				<ConfirmModalHead
					title="Изменить название подзадачи"
					size="sm"
					onClickBack={() => setSubtaskToolsDd('main')}
				/>
				<ConfirmModalBody
					content={
						<div className={styles.body}>
							<fieldset className={styles.fieldset}>
								<Typo className={styles.legend} design="text-13-medium" as="legend">
									Название
								</Typo>
								<FormElement error={errors?.title?.message}>
									<Controller
										name="title"
										control={control}
										render={({ field: { name, onChange, onBlur, value } }) => (
											<InputMulti
												name={name}
												value={value}
												onChange={onChange}
												onBlur={onBlur}
												placeholder="Введите название"
											/>
										)}
									/>
								</FormElement>
							</fieldset>
						</div>
					}
					isPending={false}
					disabled={newTitle === prevTitle}
					onAccept={handleSubmit(changeSubtaskTitle)}
					onDiscard={() => setSubtaskToolsDd('main')}
					acceptLabel="Сохранить"
					acceptColor="primary"
					textAlign="left"
				/>
			</div>
		</FocusTrap>
	);
};
