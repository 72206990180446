import React, { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { matchRoutes, Navigate, useNavigate, useRoutes } from 'react-router-dom';

import { prodLoginPath } from '@shared/lib/constants';
import { useAuth } from '@shared/lib/hooks';
import { routerModel } from '@shared/model';
import { modalsCollection, ModalsInterface } from '@shared/ui/molecules/modals';

import { RemoveBoardModal } from '@features/board';
import { RemoveTaskModal } from '@features/task';

import { BoardModal } from '@widgets/board-modal';
import { BoardParticipantsModal } from '@widgets/board-participants-modal';
import { NotificationsModal } from '@widgets/notifications-modal';
import { SaveBoardViewModal } from '@widgets/save-board-view-modal';
import { TaskModal } from '@widgets/task-modal';

import { pagesConfig } from '@pages/pages-config';
import { routeConfig } from '@pages/router-config';
import { IRouteMatch, IRouteObject } from '@pages/router.types';
import '@shared/lib/styles/app.scss';

[
	BoardParticipantsModal,
	BoardModal,
	TaskModal,
	RemoveBoardModal,
	RemoveTaskModal,
	SaveBoardViewModal,
	NotificationsModal,
].forEach((modal: ModalsInterface) => (modalsCollection[modal.name] = modal.Component));

const useProtectedRoute = (routeConfig: RouteObjectList) => {
	const location = useLocation();

	const route = useMemo(() => {
		const result = matchRoutes(routeConfig, location.pathname) as RouteMatchList;
		return result && result[0].route;
	}, [routeConfig, location]);

	const Page = useRoutes(routeConfig);
	const Layout = route.layout;

	const { access } = useAuth();

	const layoutProps = {
		nav: pagesConfig
			.filter((page) => page.inAside)
			.map((page) => ({
				name: page.navTitle || page.title,
				path: page.path,
				icon: page.icon,
				isNotifications: page.isNotifications,
			})),
		title: pagesConfig.find((page) => page.path === route.path)?.title,
		isSimple: route.isSimple,
	};

	if (route?.private && !access && process.env.REACT_APP_STAND === 'PROD') {
		window.location.href = prodLoginPath;
	}

	return route?.private && !access
		? {
				Layout,
				Page: <Navigate to="/dev-login" state={{ from: location }} />,
				meta: {
					title: route.title,
				},
				layoutProps,
		  }
		: {
				Layout,
				Page,
				meta: {
					title: route.title,
				},
				layoutProps,
		  };
};

export const App: FC = () => {
	const { Layout, Page, meta, layoutProps } = useProtectedRoute(routeConfig);

	useEffect(() => {
		document.title = `${meta?.title?.length ? `${meta.title} — ` : ``}R-Pharm «ToDo»`;
	}, [meta?.title]);

	const navigate = useNavigate();

	useEffect(() => {
		routerModel.setHistory(navigate);
	}, [navigate]);

	return <Layout {...layoutProps}>{Page}</Layout>;
};

type RouteObjectList = Array<IRouteObject>;
type RouteMatchList = Array<IRouteMatch>;
