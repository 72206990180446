/**
 * rpharm-todo
 * rpharm-todo documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LinkedTaskDto } from './linkedTaskDto';
import { UserDto } from './userDto';

export interface SubtaskDto {
	id: string;
	title: string;
	type?: SubtaskDtoTypeEnum;
	linkedTask?: LinkedTaskDto;
	completed?: boolean;
	dueDate?: string;
	assignee?: UserDto;
}
export enum SubtaskDtoTypeEnum {
	CHECKLIST = 'CHECKLIST',
	LINKED_TASK = 'LINKED_TASK',
}
