import React, { FC, useState } from 'react';

import { Button, ConfirmModalBody, ConfirmModalHead, DropdownControlled, Icon } from '@shared/ui';

import { CommentHeaderProps } from '../';

import styles from './styles.module.scss';

export interface CommentControlsProps {
	onRemove?: CommentHeaderProps['onRemove'];
	startEdit?: CommentHeaderProps['startEdit'];
}

export const CommentControls: FC<CommentControlsProps> = React.memo(({ onRemove, startEdit }) => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	if (!startEdit && !onRemove) return null;

	return (
		<div className={styles.controlsWrap}>
			{startEdit && (
				<Button
					onClick={startEdit}
					size={'sm'}
					design={'transparent'}
					color={'secondary'}
					iconRight={<Icon id={'edit'} />}>
					Изменить
				</Button>
			)}
			{onRemove && (
				<DropdownControlled
					opened={menuIsOpen}
					setOpened={setMenuIsOpen}
					dropdownClassName={styles.confirmModal}
					portal={true}
					portalId="task-modal-portal"
					placement="bottomEnd"
					targetButton={
						<Button
							size="sm"
							design="transparent"
							color="secondary"
							iconRight={<Icon id="remove" />}
							onClick={() => setMenuIsOpen((prev) => !prev)}>
							Удалить
						</Button>
					}
					content={
						<>
							<ConfirmModalHead
								size="sm"
								title="Удаление комментария"
								onClickBack={() => setMenuIsOpen(false)}
							/>
							<ConfirmModalBody
								content="Комментарий будет удален навсегда без возможности восстановления."
								onAccept={() => {
									onRemove && onRemove();
									setMenuIsOpen(false);
								}}
								onDiscard={() => setMenuIsOpen(false)}
								acceptLabel="Удалить"
								acceptColor="danger"
								textAlign="left"
							/>
						</>
					}
				/>
			)}
		</div>
	);
});
