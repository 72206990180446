import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserDto } from '@shared/api';
import { viewTask } from '@shared/ui';

import { boardViewModel, toggleTagsOnTaskModel } from '@entities/boards';
import { taskListModel, taskListTagsModel } from '@entities/task';
import { TasksTableArray } from '@entities/task/lib';

import { TaskTableSkeleton, TaskTableHeaderWithShadow } from '../../ui';
import styles from '../styles.module.scss';

import { TaskTableHead, TaskTableRow } from './';

interface TaskTableProps {
	viewer: Nullable<UserDto>;
	data: Nullable<TasksTableArray>;
	isEmpty?: boolean;
	isLoading?: boolean;
	pageOffset?: number;
	headerOffset?: number;
}

export const TaskTable: FC<TaskTableProps> = React.memo(({ viewer, data, isLoading, isEmpty }) => {
	const navigate = useNavigate();
	const boardViewChanged = useStore(boardViewModel.$changed);
	const boardTagsChanged = useStore(taskListTagsModel.$boardTagsChanged);
	const showTagsOnTasksChanged = useStore(toggleTagsOnTaskModel.$showTagsOnTasksChanged);

	const taskCardClickHandler = (id: string, title: string = '') => {
		if (boardViewChanged || boardTagsChanged || showTagsOnTasksChanged) {
			viewTask({ id, title, viewer, mode: 'edit' });
			return;
		}
		navigate(`task/${id}`);
	};

	const counters = useStore(taskListModel.$taskListCounters);

	const header = useMemo(() => {
		const rows = data ? [...data] : [];
		return (
			<>
				{rows[0]?.columns?.map(({ status }) => {
					const count = status?.type ? counters[status?.type] : 0;
					return <TaskTableHead key={status?.type} count={count} {...status} />;
				})}
			</>
		);
	}, [counters, data]);

	if (isLoading) return <TaskTableSkeleton />;
	if (isEmpty) return <></>;

	return (
		<div className={styles.taskTable} role="table">
			<TaskTableHeaderWithShadow content={header} />
			{data?.map((row) => (
				<TaskTableRow key={row.tag.id} onCardClick={taskCardClickHandler} {...row} />
			))}
		</div>
	);
});
