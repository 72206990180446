import { combine, createEffect, createEvent, createStore, restore, sample } from 'effector';

import { tagsRequests, CreateTagRequest, TagDto } from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { tagListModel, tagManagerModel } from '@entities/tag-manager/model';

interface CreateTagProps {
	data: CreateTagRequest;
	done: (tag: TagDto) => void;
}

const createTag = createEvent<CreateTagProps>();
const createTagFx = createEffect(({ data }: CreateTagProps) => tagsRequests.createTag(data));
const createTagDoneFx = createEffect((cb: VoidCallback) => cb && cb());

const $response = createStore<Nullable<TagDto>>(null).reset(createTag);
const $error = restore<Error>(createTagFx.failData, null).reset(createTag);

const $createTagStatus = combine({
	pending: createTagFx.pending,
	response: $response,
	error: $error,
});

sample({
	clock: createTag,
	target: [createTagFx, tagListModel.resetSearch],
});

sample({
	clock: createTagFx.doneData,
	target: $response,
});

sample({
	clock: createTagFx.done,
	fn: (source) => () => source.params.done(source.result),
	target: createTagDoneFx,
});

sample({
	clock: createTagFx.doneData,
	fn: (result) => ({
		type: 'success' as NoticeType,
		text: `Метка "<b>${result.name}</b>" создана.`,
	}),
	target: noticesModel.add,
});

sample({
	clock: createTagFx.done,
	target: tagListModel.revalidateTagList,
});

sample({
	clock: createTagFx.done,
	fn: () => ({
		type: null,
	}),
	target: tagManagerModel.pushAction,
});

sample({
	clock: createTagFx.fail,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	createTag,
	createTagFx,
	$createTagStatus,
};
