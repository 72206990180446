import React, { useEffect } from 'react';

import { BoardParticipantDto, BoardRoleDto, BoardRoleDtoNameEnum } from '@shared/api';

import { useCurrentUser } from '@entities/current-user';
import { CurrentUserDropdown } from '@entities/current-user-dropdown';
import { Participant } from '@entities/participant';

export interface SelectedParticipant extends BoardParticipantDto {
	isCurrentUser?: boolean;
}

interface ParticipantDropdownProps {
	participant: SelectedParticipant | null;
	boardRoles: BoardRoleDto[];
	setRole: (role: BoardParticipantDto, participantId?: string) => void;
	remove: (id: string) => void;
	isDisabled?: boolean;
	currentUserRole?: string;
	disableRolesChanging?: boolean;
}

export const ParticipantDropdown = ({
	participant,
	boardRoles,
	setRole,
	remove,
	isDisabled,
	currentUserRole,
	disableRolesChanging,
}: ParticipantDropdownProps) => {
	const { userIsCurrent } = useCurrentUser();
	const isStarredUser = participant?.isCurrentUser;
	const isCurrentUser = userIsCurrent(participant?.user);
	const isViewer = currentUserRole === BoardRoleDtoNameEnum.VIEWER;

	useEffect(() => {
		return () => {
			const avatar = document.getElementById('avatarId' + participant?.user.id);
			avatar?.focus();
		};
	}, [participant?.user.id]);

	return (
		participant && (
			<>
				{isCurrentUser || isStarredUser || isViewer ? (
					<CurrentUserDropdown
						participant={participant}
						boardRoles={boardRoles}
						icon={participant.isCurrentUser}
					/>
				) : (
					<Participant
						participant={participant}
						boardRoles={boardRoles}
						setRole={(role) => setRole(role, participant.id)}
						remove={(id) => remove(id)}
						isDisabled={isDisabled}
						disableRolesChanging={disableRolesChanging}
					/>
				)}
			</>
		)
	);
};
