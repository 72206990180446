import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useMatch, useLocation } from 'react-router-dom';

import { CreateTaskPayload } from '@shared/api';
import { Button } from '@shared/ui';

import { createTaskFeature } from '@features/task';

export interface CreateTaskActionsProps {
	handleSubmit: UseFormHandleSubmit<CreateTaskPayload['data']>;
	onDiscard: () => void;
	disabled?: boolean;
}

export const CreateTaskActions: FC<CreateTaskActionsProps> = ({
	handleSubmit,
	onDiscard,
	disabled,
}) => {
	const { pathname } = useLocation();
	const isArchive = pathname.includes('archive');

	const matchBoard = useMatch({ path: '/boards/:boardId', end: false });
	const boardId = matchBoard?.params?.boardId;

	const { pending } = useStore(createTaskFeature.$createTaskStatus);
	const createTaskData = useStore(createTaskFeature.$createTaskData);

	const createTaskSubmit = async (data: CreateTaskPayload['data']) => {
		createTaskFeature.createTask({
			data: { ...data, ...createTaskData },
			boardId,
			archived: isArchive,
		});
	};

	return (
		<>
			<Button
				onClick={handleSubmit(createTaskSubmit)}
				disabled={disabled}
				size="md"
				design="filled"
				color="primary"
				type="button"
				isLoading={pending}>
				Создать задачу
			</Button>
			<Button onClick={onDiscard} size="md" design="filled" color="secondary" type="button">
				Отменить
			</Button>
		</>
	);
};
