import { createEvent, createEffect, createStore, sample, restore, combine } from 'effector';

import { TaskEventDto, tasksEvents, UpdateTaskEventRequest } from '@shared/api';
import { noticesModel, NoticeType } from '@shared/ui';

import { taskEventsListModel } from '../task-events-list';

const updateTaskEvent = createEvent<UpdateTaskEventRequest>();
const updateTaskEventFx = createEffect(tasksEvents.putTaskStatusEvent);

const $config = createStore<Nullable<UpdateTaskEventRequest>>(null).reset(updateTaskEvent);
const $response = createStore<Nullable<TaskEventDto>>(null).reset(updateTaskEvent);
const $error = restore<Error>(updateTaskEventFx.failData, null).reset(updateTaskEvent);

const $status = combine({
	pending: updateTaskEventFx.pending,
	data: $response,
	config: $config,
	error: $error,
});

sample({
	clock: updateTaskEvent,
	target: updateTaskEventFx,
});

/*
 * Мутируем основной стор
 */
sample({
	clock: updateTaskEventFx.doneData,
	target: taskEventsListModel.updateInList,
});

/*
 * Уведомления
 */
sample({
	clock: updateTaskEventFx.doneData,
	fn: () => ({
		type: 'success' as NoticeType,
		text: `Комментарий изменен`,
	}),
	target: noticesModel.add,
});

sample({
	clock: updateTaskEventFx.failData,
	fn: () => ({
		type: 'error' as NoticeType,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

export const model = {
	$status,
	updateTaskEvent,
};
