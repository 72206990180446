import { useStore } from 'effector-react';
import React from 'react';

import { NoticeLabel } from '@shared/ui';

import { notificationReassignModel } from '@entities/notifications';

import styles from '../../../../styles.module.scss';

interface ReassignStatusLabelsProps {
	id?: string;
}

export const ReassignStatusLabels = ({ id }: ReassignStatusLabelsProps) => {
	const label = useStore(notificationReassignModel.$label);

	return label && label.taskId === id ? (
		<NoticeLabel
			className={styles.headerNoticeLabel}
			text={label.text}
			accepted={label.accepted}
			fadeOutTimer={3000}
			fadeOutCallback={() => notificationReassignModel.setNoticeLabel(null)}
		/>
	) : null;
};
