import { NotificationDto, TaskDto, UserDto } from '@shared/api/model/models';
import { modalsModel } from '@shared/ui';

export type TaskMode = 'create' | 'edit' | 'view';

export interface TaskModalProps extends TaskDto {
	mode?: TaskMode;
	viewer?: Nullable<UserDto>;
	isSubmodal?: boolean;
	pageOffset?: number;
	leftOffset?: number;
	notification?: NotificationDto;
}

export const createTask = () => {
	modalsModel.pushModal({
		name: 'TASK_MODAL',
		data: {
			id: '',
			title: '',
			mode: 'create',
		},
	});
};

export const viewTask = (data?: TaskModalProps) => {
	modalsModel.pushModal({
		name: 'TASK_MODAL',
		data: data,
	});
};

export const viewTaskSubmodal = (data?: TaskModalProps) => {
	modalsModel.pushSubModal({
		name: 'TASK_MODAL',
		data: data,
	});
};
