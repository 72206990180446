import { createEvent, createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { Transition } from 'history';
import { status, reset } from 'patronum';

import { boardViewsRequests } from '@shared/api';
import { modalsModel, noticesModel } from '@shared/ui';

const PageTagsGate = createGate<{ boardId?: string }>();

const toggleTagsOnTasks = createEvent();
const setShowTagsOnTasks = createEvent<Nullable<boolean>>();
const saveShowTagsOnTasks = createEvent<Nullable<Transition['retry']>>();
const resetShowTagsOnTasksChanged = createEvent();
const resetAllStores = createEvent();

const toggleTagsOnTasksFx = createEffect(boardViewsRequests.toggleTagsOnTasks);
const retryRouteFx = createEffect(
	(retry: Nullable<Transition['retry']>) => retry && queueMicrotask(retry)
);

const $showTagsOnTasks = createStore<Nullable<boolean>>(null);
const $showTagsOnTasksForCompare = createStore<Nullable<boolean>>(null);
const $showTagsOnTasksChanged = createStore(false).reset(resetShowTagsOnTasksChanged);
const $retryRoute = createStore<Nullable<Transition['retry']>>(null);

const $toggleTagsOnTasksStatus = status({ effect: toggleTagsOnTasksFx });

/**
 * Toggle show tags on tasks
 */
sample({
	clock: setShowTagsOnTasks,
	target: $showTagsOnTasks,
});

/**
 * Binding events and effects
 */
sample({
	source: PageTagsGate.state,
	clock: toggleTagsOnTasks,
	fn: ({ boardId }) => ({ boardId: boardId ?? '' }),
	target: toggleTagsOnTasksFx,
});

/**
 * Set $showTagsOnTasksForCompare
 */
sample({
	source: { initialState: $showTagsOnTasksForCompare, changedState: $showTagsOnTasks },
	clock: $showTagsOnTasks,
	fn: ({ initialState, changedState }) => initialState !== changedState,
	target: $showTagsOnTasksChanged,
});

/**
 * Set $retryRoute and save showing tags on task
 */
sample({
	clock: saveShowTagsOnTasks,
	target: [$retryRoute, toggleTagsOnTasks],
});

/**
 * Go forward after request
 */
sample({
	source: $retryRoute,
	clock: toggleTagsOnTasksFx.doneData,
	fn: (source) => source,
	target: [retryRouteFx, modalsModel.closeLastModal, resetShowTagsOnTasksChanged],
});

/**
 * Update $showTagsOnTaskForCompare after request
 */
sample({
	source: $showTagsOnTasks,
	clock: toggleTagsOnTasksFx.doneData,
	fn: (source) => source,
	target: $showTagsOnTasksForCompare,
});

/**
 * Notifications
 */
sample({
	clock: toggleTagsOnTasksFx.failData,
	fn: () => ({
		type: 'error' as const,
		text: 'Что-то пошло не так, попробуйте позже.',
	}),
	target: noticesModel.add,
});

/**
 * Reset states
 */
reset({
	clock: [PageTagsGate.close, resetAllStores],
	target: [
		$showTagsOnTasksForCompare,
		$showTagsOnTasksChanged,
		$retryRoute,
		$toggleTagsOnTasksStatus,
	],
});

export const model = {
	PageTagsGate,
	setShowTagsOnTasks,
	saveShowTagsOnTasks,
	resetShowTagsOnTasksChanged,
	resetAllStores,
	$showTagsOnTasks,
	$showTagsOnTasksForCompare,
	$showTagsOnTasksChanged,
	$toggleTagsOnTasksStatus,
};
